import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Form,
  Col,
  Input,
  Space,
  Row,
} from "antd";
import { DateRangePicker } from "rsuite";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";

export default function ManagePurchase() {
  const [purchaseList, setPurchaseList] = useState([]);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [purchaseDetailsModal, setPurchaseDetailsModal] = useState(false);
  const [form] = Form.useForm();

  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );

  const fetchPurchaseList = () => {
    axios
      .get(`${baseUrl}/inventory/purchase_list/${userProfile?.employee_id}/`, {
        headers: authHeader(),
        params: {
          dateRange: selectedDateRange,
          page:currentPage,
        },
      })
      .then((response) => {
        setPurchaseList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchPurchaseList();
  }, [currentPage, selectedDateRange]);

  const handleDateRage = (value) => {
    setSelectedDateRange(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDetailsModal = (value) => {
    setPurchaseDetailsModal(true);
    const stock_products = value?.stock_products;
    const modifiedStockProducts = stock_products?.map((item) => ({
      ...item,
      product_name: item.product.product_name,
      expire_date: new Date(item.expire_date).toISOString().split("T")[0], // Format expire_date as YYYY-MM-DD
    }));
    form.setFieldsValue({
      products: modifiedStockProducts,
      supplier: value?.supplier?.supplier_name,
      grn: value?.grn,
      total_price: value?.total_price,
      total_paid: value?.total_paid,
      due: value?.due,
    });
  };
  const handleDetailsModalClose = () => {
    setPurchaseDetailsModal(false);
  };
  const handleDeletePurchase = (record) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to delete ${record?.purchase_id} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/inventory/purchase_delete/${record?.purchase_id}`, {
            headers: authHeader(),
          })
          .then((data) => {
            if (data?.status === 204) {
              Swal.fire({
                title: "Success",
                text: `${record?.purchase_id} deleted successfully`,
                icon: "success",
                confirmButtonText: "Ok",
              }).then(() => {
                fetchPurchaseList();
              });
            }
          })
          .catch((error) => {
            console.log(error)
            Swal.fire({
              title: "Error",
              text: error.response.data.error,
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };

  const purchaseColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record.created_at).toISOString().slice(0, 10)
          : "",
      width: "8%",
    },
    {
      title: "Purchase ID",
      dataIndex: "purchase_id",
      width: "8%",
    },
    {
      title: "Work Order",
      dataIndex: "work_order",
      width: "8%",
    },
    {
      title: "Challan",
      dataIndex: "challan",
      width: "8%",
    },
    {
      title: "Receive Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.receive_date).toISOString().slice(0, 10)
          : "",
      width: "8%",
    },
    {
      title: "GRN",
      dataIndex: "grn",
      width: "8%",
    },
    {
      title: "Supplier",
      render: (record) => record?.supplier?.supplier_name,
      width: "10%",
    },
    {
      title: (
        <div className="row">
          <div className="col-sm">Product</div>
          <div className="col-sm">Stock In</div>
        </div>
      ),
      width: "18%",
      render: (record) => {
        if (record?.stock_products) {
          return record.stock_products.map((product, index) => (
            <div className="row" key={index}>
              <div className="col-sm border">
                {product?.product?.product_name}
              </div>
              <div className="col-sm border">{product?.stock_in}</div>
            </div>
          ));
        }
        return null;
      },
    },
    {
      title: "Total",
      render: (record) => record?.total_price?.toLocaleString(),
      width: "7%",
    },
    {
      title: "Paid",
      render: (record) => record?.total_paid?.toLocaleString(),
      width: "7%",
    },
    {
      title: "Due",
      render: (record) => record?.due?.toLocaleString(),
      width: "5%",
    },
    {
      title: "Action",
      width: "5%",
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleDetailsModal(record)}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Details">
            <Button
              className=" ms-1 d-flex align-items-center"
              onClick={() => handleDeletePurchase(record)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Purchase Details"
        width="70%"
        open={purchaseDetailsModal}
        onCancel={handleDetailsModalClose}
        footer={false}
      >
        <Form form={form} color="#203509" layout="vertical">
          <div className="d-flex justify-content-between">
            <Col style={{ width: "400px" }}>
              <Form.Item name="supplier">
                <Input placeholder="Supplier" readOnly />
              </Form.Item>
            </Col>
            <Col style={{ width: "300px" }}>
              <Form.Item name="grn">
                <Input placeholder="GRN" readOnly />
              </Form.Item>
            </Col>
          </div>

          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space key={key} align="baseline">
                    <Row gutter={[8, 16, 24, 32]}>
                      <Col style={{ width: "300px" }}>
                        <Form.Item
                          {...restField}
                          label="Product Name"
                          name={[name, `product_name`]}
                        >
                          <Input placeholder=" Product Name" readOnly />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: "120px" }}>
                        <Form.Item label="MRP" name={[name, `mrp`]}>
                          <Input placeholder="MRP" readOnly />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: "150px" }}>
                        <Form.Item
                          {...restField}
                          label="Price"
                          name={[name, `purchase_price`]}
                        >
                          <Input placeholder="Price" readOnly />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: "120px" }}>
                        <Form.Item
                          {...restField}
                          label="Quantity"
                          name={[name, `stock_in`]}
                        >
                          <Input placeholder="Quantity" readOnly />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: "200px" }}>
                        <Form.Item
                          {...restField}
                          label="Expire Date"
                          name={[name, `expire_date`]}
                        >
                          <Input placeholder="Expire Date" readOnly />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: "120px" }}>
                        <Form.Item
                          {...restField}
                          label="Discount"
                          name={[name, `discount`]}
                        >
                          <Input placeholder="Discount" readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
          <p className="text-center" style={{ color: "#203509", fontSize: 16 }}>
            {" "}
            <strong>Payment Method </strong>
          </p>
          <div className="d-flex shadow-sm mb-4">
            <div
              className="w-50 pt-4 d-flex justify-content-center"
              style={{ color: "#203509", border: "1px solid #203509" }}
            >
              <Form.Item className="ms-2">
                <Input
                  className="py-2 text-center custom-placeholder-color"
                  placeholder="Cash"
                  disabled
                  style={{ color: "#203509", borderColor: "#203509" }}
                />
              </Form.Item>
              <Form.Item
                className="ms-2"
                name="total_paid"
                rules={[
                  {
                    required: true,
                    message: "Total paid required",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Only english number",
                  },
                ]}
              >
                <Input
                  className="py-2 text-center"
                  placeholder="Paid"
                  style={{ color: "#203509", borderColor: "#203509" }}
                  readonly
                />
              </Form.Item>
            </div>
            <div
              className="w-50 pt-4"
              style={{ color: "#203509", border: "1px solid #203509" }}
            >
              <div className="d-flex justify-content-center">
                <Form.Item className="ms-2">
                  <Input
                    className="py-2 text-center custom-placeholder-color"
                    placeholder="Total"
                    disabled
                    style={{ color: "#203509", borderColor: "#203509" }}
                  />
                </Form.Item>

                <Form.Item
                  className="ms-2"
                  name="total_price"
                  rules={[
                    {
                      required: true,
                      message: "Total price missing",
                    },
                  ]}
                >
                  <Input
                    className="py-2 text-center"
                    placeholder="Total Price"
                    readOnly
                    style={{ color: "#203509", borderColor: "#203509" }}
                  />
                </Form.Item>
              </div>
              <div className="d-flex justify-content-center">
                <Form.Item className="ms-2">
                  <Input
                    className="py-2 text-center custom-placeholder-color"
                    placeholder="Due"
                    disabled
                    style={{ color: "#203509", borderColor: "#203509" }}
                  />
                </Form.Item>
                <Form.Item className="ms-2" name="due">
                  <Input
                    className="py-2 text-center"
                    placeholder="Due Amount"
                    readOnly
                    style={{ color: "#203509", borderColor: "#203509" }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>MANAGE PURCHASE</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="d-flex border-bottom mb-4">
                <div className="mb-1">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    onChange={handleDateRage}
                    value={selectedDateRange}
                    style={{ width: "250px" }}
                    showOneCalendar
                  />
                </div>
              </div>
              <Table
                columns={purchaseColumn}
                dataSource={purchaseList}
                loading={purchaseLoading}
                rowKey={(record) => record?.purchase_id}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
