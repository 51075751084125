import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import "./Survey.css";
import { Link } from "react-router-dom/";

export default function Survey() {
  const { Option } = Select;
  const today = new Date();
  const [retailerKYC, setRetailerKYC] = useState([]);
  const [farmerKYC, setFarmerKYC] = useState([]);
  const [lspKYC, setLSPKYC] = useState([]);

  const [agroProcessorSurvey, setAgroProcessorSurvey] = useState([]);
  const [dairySurvey, setDairySurvey] = useState([]);
  const [beefSurvey, setBeefSurvey] = useState([]);
  const [warehouseSurvey, setWarehouseSurvey] = useState([]);
  const [poultrySurvey, setPoultrySurvey] = useState([]);

  const [retailerSelectedDateRange, setRetailerSelectedDateRange] =
    useState(null);
  const [retailerSelectedDistrict, setRetailerSelectedDistrict] =
    useState("All Districts");
  const [retailerSelectedType, setRetailerSelectedType] = useState("All Types");

  const [farmerSelectedDateRange, setFarmerSelectedDateRange] = useState(null);
  const [farmerSelectedDistrict, setFarmerSelectedDistrict] =
    useState("All Districts");
  const [farmerSelectedType, setFarmerSelectedType] = useState("All Types");

  const [lspSelectedDateRange, setLSPSelectedDateRange] = useState(null);
  const [lspSelectedDistrict, setLSPSelectedDistrict] =
    useState("All Districts");

  const [agroProcessorSelectedDateRange, setAgroProcessorSelectedDateRange] =
    useState(null);
  const [agroProcessorSelectedDistrict, setAgroProcessorSelectedDistrict] =
    useState("All Districts");

  const [dairySelectedDateRange, setDairySelectedDateRange] = useState(null);
  const [dairySelectedDistrict, setDairySelectedDistrict] =
    useState("All Districts");

  const [beefSelectedDateRange, setBeefSelectedDateRange] = useState(null);
  const [beefSelectedDistrict, setBeefSelectedDistrict] =
    useState("All Districts");

  const [warehouseSelectedDateRange, setWarehouseSelectedDateRange] =
    useState(null);
  const [warehouseSelectedDistrict, setWarehouseSelectedDistrict] =
    useState("All Districts");

  const [poultrySelectedDateRange, setPoultrySelectedDateRange] =
    useState(null);
  const [poultrySelectedDistrict, setPoultrySelectedDistrict] =
    useState("All Districts");

  const handleRetailerDateRange = (value) => {
    setRetailerSelectedDateRange(value);
  };
  const handleRetailerDistrict = (value) => {
    setRetailerSelectedDistrict(value);
  };
  const handleRetailerType = (value) => {
    setRetailerSelectedType(value);
  };

  const handleFarmerDateRange = (value) => {
    setFarmerSelectedDateRange(value);
  };
  const handleFarmerDistrict = (value) => {
    setFarmerSelectedDistrict(value);
  };
  const handleFarmerType = (value) => {
    setFarmerSelectedType(value);
  };

  const handleLSPDateRange = (value) => {
    setLSPSelectedDateRange(value);
  };
  const handleLSPDistrict = (value) => {
    setLSPSelectedDistrict(value);
  };

  const handleAgroProcessorDateRange = (value) => {
    setAgroProcessorSelectedDateRange(value);
  };
  const handleAgroProcessorDistrict = (value) => {
    setAgroProcessorSelectedDistrict(value);
  };

  const handleDairyDateRange = (value) => {
    setDairySelectedDateRange(value);
  };
  const handleDairyDistrict = (value) => {
    setDairySelectedDistrict(value);
  };

  const handleBeefDateRange = (value) => {
    setBeefSelectedDateRange(value);
  };
  const handleBeefDistrict = (value) => {
    setBeefSelectedDistrict(value);
  };
  const handleWarehouseDateRange = (value) => {
    setWarehouseSelectedDateRange(value);
  };
  const handleWarehouseDistrict = (value) => {
    setWarehouseSelectedDistrict(value);
  };

  const handlePoultryDateRange = (value) => {
    setPoultrySelectedDateRange(value);
  };
  const handlePoultryDistrict = (value) => {
    setPoultrySelectedDistrict(value);
  };

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycby5cUvzcQ-ekH2O-xv0usK-sKB0_5DI0zrl2n7DkkxuBbORbpkdw7VV9qOSYh_wrHE17Q/exec"
    )
      .then((response) => response.json())
      .then((data) => setFarmerKYC(data));
  }, [farmerKYC]);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbw2j3ejdKTPtyfHUjtGXuCGtJdNq9GanOd1uukZmtnL1MKHggmODX005KARZMTl7RSL/exec"
    )
      .then((response) => response.json())
      .then((data) => setRetailerKYC(data));
  }, [retailerKYC]);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbw66KO3qu8xdsACgmtuHFqcVaJqJydk6plWSxdXRn5Tg8TAaQQb0ldzsk7FusmhYLI/exec"
    )
      .then((response) => response.json())
      .then((data) => setLSPKYC(data));
  }, [lspKYC]);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbydxujORVwtU6ZGcvsRQXSrC4a6yc8eau0FK1xxDBDEyY4Iir33yEdHF0uo5YoxDeq8/exec"
    )
      .then((response) => response.json())
      .then((data) => setAgroProcessorSurvey(data));
  }, [agroProcessorSurvey]);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbydjny9cA7lGvy_Kh-KqgKcBhTL5Qa_j_DAizehHfGdWVRzYHZ6igxLJC0zQDiMlt6arA/exec"
    )
      .then((response) => response.json())
      .then((data) => setDairySurvey(data));
  }, [dairySurvey]);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbyFm3LSqdDO_s2WNMxvWgoZdpF2chHmN1t8_htMtAxRrGY_BJ9ZqMtlNsb3lYQw-YqdNQ/exec"
    )
      .then((response) => response.json())
      .then((data) => setBeefSurvey(data));
  }, [beefSurvey]);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbzOuqEqgXmw65Uv6zAdc2sgUtL_TSiY3hkjDX6G95Y-RW8HNUXs0lUfD209cKTzmNw/exec"
    )
      .then((response) => response.json())
      .then((data) => setWarehouseSurvey(data));
  }, [warehouseSurvey]);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxqpGVUp8N-zPqvCiSpxE56Usuk58vnGaj-ruRGFBCplQRWgYC0MIF7waqXZoKFZ-7ILw/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setPoultrySurvey(data?.data);
      });
  }, [poultrySurvey]);

  const retailerFilteredData = retailerKYC?.filter((item) => {
    const itemDate = new Date(item.Date);
    let retailerStartDate = null;
    let retailerEndDate = null;

    if (retailerSelectedDateRange !== null) {
      retailerStartDate = new Date(retailerSelectedDateRange[0]);
      retailerEndDate = new Date(retailerSelectedDateRange[1]);
      retailerStartDate.setHours(0, 0, 0, 0);
      retailerEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      retailerSelectedDistrict === "All Districts" ||
      retailerSelectedDistrict === item?.District_Name;
    const isTypeMatched =
      retailerSelectedType === "All Types" ||
      retailerSelectedType.toLowerCase() === item?.Business_Type.toLowerCase();
    const isDateInRange =
      retailerStartDate === null ||
      (itemDate >= retailerStartDate && itemDate <= retailerEndDate);
    return isDistrictMatched && isTypeMatched && isDateInRange;
  });

  const farmerFilteredData = farmerKYC?.filter((item) => {
    const itemDate = new Date(item.Date);
    let farmerStartDate = null;
    let farmerEndDate = null;

    if (farmerSelectedDateRange !== null) {
      farmerStartDate = new Date(farmerSelectedDateRange[0]);
      farmerEndDate = new Date(farmerSelectedDateRange[1]);
      farmerStartDate.setHours(0, 0, 0, 0);
      farmerEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      farmerSelectedDistrict === "All Districts" ||
      farmerSelectedDistrict === item?.District_Name;
    const isTypeMatched =
      farmerSelectedType === "All Types" ||
      farmerSelectedType.toLowerCase() === item?.farmer_type.toLowerCase();
    const isDateInRange =
      farmerStartDate === null ||
      (itemDate >= farmerStartDate && itemDate <= farmerEndDate);
    return isDistrictMatched && isTypeMatched && isDateInRange;
  });

  const lspFilteredData = lspKYC?.filter((item) => {
    const itemDate = new Date(item.Date);
    let lspStartDate = null;
    let lspEndDate = null;

    if (lspSelectedDateRange !== null) {
      lspStartDate = new Date(lspSelectedDateRange[0]);
      lspEndDate = new Date(lspSelectedDateRange[1]);
      lspStartDate.setHours(0, 0, 0, 0);
      lspEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      lspSelectedDistrict === "All Districts" ||
      lspSelectedDistrict === item?.District_Name;
    const isDateInRange =
      lspStartDate === null ||
      (itemDate >= lspStartDate && itemDate <= lspEndDate);
    return isDistrictMatched && isDateInRange;
  });

  const agroProcessorFilteredData = agroProcessorSurvey?.filter((item) => {
    const itemDate = new Date(item.Date);
    let agroProcessorStartDate = null;
    let agroProcessorEndDate = null;

    if (agroProcessorSelectedDateRange !== null) {
      agroProcessorStartDate = new Date(agroProcessorSelectedDateRange[0]);
      agroProcessorEndDate = new Date(agroProcessorSelectedDateRange[1]);
      agroProcessorStartDate.setHours(0, 0, 0, 0);
      agroProcessorEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      agroProcessorSelectedDistrict === "All Districts" ||
      agroProcessorSelectedDistrict === item?.District_Name;
    const isDateInRange =
      agroProcessorStartDate === null ||
      (itemDate >= agroProcessorStartDate && itemDate <= agroProcessorEndDate);
    return isDistrictMatched && isDateInRange;
  });

  const dairyFilteredData = dairySurvey?.filter((item) => {
    const itemDate = new Date(item.Date);
    let dairyStartDate = null;
    let dairyEndDate = null;

    if (dairySelectedDateRange !== null) {
      dairyStartDate = new Date(dairySelectedDateRange[0]);
      dairyEndDate = new Date(dairySelectedDateRange[1]);
      dairyStartDate.setHours(0, 0, 0, 0);
      dairyEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      dairySelectedDistrict === "All Districts" ||
      dairySelectedDistrict === item?.District_Name;
    const isDateInRange =
      dairyStartDate === null ||
      (itemDate >= dairyStartDate && itemDate <= dairyEndDate);
    return isDistrictMatched && isDateInRange;
  });

  const beefFilteredData = beefSurvey?.filter((item) => {
    const itemDate = new Date(item.Date);
    let beefStartDate = null;
    let beefEndDate = null;

    if (beefSelectedDateRange !== null) {
      beefStartDate = new Date(beefSelectedDateRange[0]);
      beefEndDate = new Date(beefSelectedDateRange[1]);
      beefStartDate.setHours(0, 0, 0, 0);
      beefEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      beefSelectedDistrict === "All Districts" ||
      beefSelectedDistrict === item?.District_Name;
    const isDateInRange =
      beefStartDate === null ||
      (itemDate >= beefStartDate && itemDate <= beefEndDate);
    return isDistrictMatched && isDateInRange;
  });

  const warehouseFilteredData = warehouseSurvey?.filter((item) => {
    const itemDate = new Date(item.Date);
    let warehouseStartDate = null;
    let warehouseEndDate = null;

    if (warehouseSelectedDateRange !== null) {
      warehouseStartDate = new Date(warehouseSelectedDateRange[0]);
      warehouseEndDate = new Date(warehouseSelectedDateRange[1]);
      warehouseStartDate.setHours(0, 0, 0, 0);
      warehouseEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      warehouseSelectedDistrict === "All Districts" ||
      warehouseSelectedDistrict === item?.District_Name;
    const isDateInRange =
      warehouseStartDate === null ||
      (itemDate >= warehouseStartDate && itemDate <= warehouseEndDate);
    return isDistrictMatched && isDateInRange;
  });

  const poultryFilteredData = poultrySurvey?.filter((item) => {
    const itemDate = new Date(item.Date);
    let poultryStartDate = null;
    let poultryEndDate = null;

    if (poultrySelectedDateRange !== null) {
      poultryStartDate = new Date(poultrySelectedDateRange[0]);
      poultryEndDate = new Date(poultrySelectedDateRange[1]);
      poultryStartDate.setHours(0, 0, 0, 0);
      poultryEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      warehouseSelectedDistrict === "All Districts" ||
      warehouseSelectedDistrict === item?.District_Name;
    const isDateInRange =
      poultryStartDate === null ||
      (itemDate >= poultryStartDate && itemDate <= poultryEndDate);
    return isDistrictMatched && isDateInRange;
  });

  function filterData(filteredData, selectedDateRange) {
    return filteredData?.filter((item) => {
      const itemDate = new Date(item?.Date);

      if (selectedDateRange) {
        const startDate = new Date(selectedDateRange[0]);
        const endDate = new Date(selectedDateRange[1]);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return itemDate >= startDate && itemDate <= endDate;
      } else {
        const todayStart = new Date(today);
        todayStart.setHours(0, 0, 0, 0);
        const todayEnd = new Date(today);
        todayEnd.setHours(23, 59, 59, 999);
        return itemDate >= todayStart && itemDate <= todayEnd;
      }
    });
  }

  function getDistinctValues(data) {
    return [...new Set(data?.map((item) => item?.District_Name))];
  }
  const distinctRetailerDistrict = getDistinctValues(retailerKYC);
  const distinctFarmerDistrict = getDistinctValues(farmerKYC);
  const distinctLSPDistrict = getDistinctValues(lspKYC);
  const distinctAgroProcessorDistrict = getDistinctValues(agroProcessorSurvey);
  const distinctDairyDistrict = getDistinctValues(dairySurvey);
  const distinctBeefDistrict = getDistinctValues(beefSurvey);
  const distinctWarehouseDistrict = getDistinctValues(warehouseSurvey);
  const distinctPoultryDistrict = getDistinctValues(poultrySurvey);

  const distinctRetailer = [
    ...new Set(retailerFilteredData?.map((item) => item?.Retailer_ID)),
  ];
  const distinctFarmer = [
    ...new Set(farmerFilteredData?.map((item) => item?.Farmer_ID)),
  ];
  const distinctLSP = [
    ...new Set(lspFilteredData?.map((item) => item?.LSP_ID)),
  ];
  const distinctAgroProcessor = [
    ...new Set(
      agroProcessorFilteredData?.map((item) => item?.Manager_Phone_Number)
    ),
  ];
  const distinctDairy = [
    ...new Set(dairyFilteredData?.map((item) => item?.Farmer_ID)),
  ];
  const distinctBeef = [
    ...new Set(beefFilteredData?.map((item) => item?.Farmer_ID)),
  ];
  const distinctWarehouse = [
    ...new Set(warehouseFilteredData?.map((item) => item?.Contract_No)),
  ];
  const distinctPoultry = [
    ...new Set(poultryFilteredData?.map((item) => item?.Phone_Number)),
  ];

  const retailerTodaysData = filterData(
    retailerFilteredData,
    retailerSelectedDateRange
  );
  const todaysDistinctRetailer = [
    ...new Set(retailerTodaysData.map((item) => item?.Retailer_ID)),
  ];
  const farmerTodaysData = filterData(
    farmerFilteredData,
    farmerSelectedDateRange
  );
  const todaysDistinctFarmer = [
    ...new Set(farmerTodaysData.map((item) => item?.Farmer_ID)),
  ];
  const lspTodaysData = filterData(lspFilteredData, lspSelectedDateRange);
  const todaysDistinctLSP = [
    ...new Set(lspTodaysData.map((item) => item?.LSP_ID)),
  ];
  const agroProcessorTodaysData = filterData(
    agroProcessorFilteredData,
    agroProcessorSelectedDateRange
  );
  const todaysDistinctAgroProcessor = [
    ...new Set(
      agroProcessorTodaysData.map((item) => item?.Manager_Phone_Number)
    ),
  ];
  const dairyTodaysData = filterData(dairyFilteredData, dairySelectedDateRange);
  const todaysDistinctDairy = [
    ...new Set(dairyTodaysData.map((item) => item?.Farmer_ID)),
  ];
  const beefTodaysData = filterData(beefFilteredData, beefSelectedDateRange);
  const todaysDistinctBeef = [
    ...new Set(beefTodaysData.map((item) => item?.Farmer_ID)),
  ];
  const warehouseTodaysData = filterData(
    warehouseFilteredData,
    warehouseSelectedDateRange
  );
  const todaysDistinctWarehouse = [
    ...new Set(warehouseTodaysData.map((item) => item?.Farmer_ID)),
  ];

  const poultryTodaysData = filterData(
    poultryFilteredData,
    poultrySelectedDateRange
  );
  const todaysDistinctPoultry = [
    ...new Set(poultryTodaysData.map((item) => item?.Farmer_ID)),
  ];

  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h3 style={{ color: "#38512e" }}>SURVEY DASHBOARD</h3>
      </div>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div
          className="pt-3 pb-3"
          style={{
            boxShadow: "#38512e 1px 3px 5px",
          }}
        >
          <div className="totalBoxSection">
            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                Farmer KYC
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    value={farmerSelectedDateRange}
                    onChange={handleFarmerDateRange}
                    showOneCalendar
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    placeholder="Select District"
                    style={{ width: "120px" }}
                    showSearch
                    value={farmerSelectedDistrict}
                    onChange={handleFarmerDistrict}
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctFarmerDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="ms-2 mt-3">
                  <p className="m-0">Type: </p>
                  <Select
                    placeholder="Select Type"
                    style={{ width: "120px" }}
                    showSearch
                    value={farmerSelectedType}
                    onChange={handleFarmerType}
                  >
                    <Option value="All Types">All Types</Option>
                    <Option value="Crop">Crop</Option>
                    <Option value="Livestock">Livestock</Option>
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>{distinctFarmer ? distinctFarmer.length : 0}</h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {farmerSelectedDateRange
                        ? farmerSelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          farmerSelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>
                    {todaysDistinctFarmer ? todaysDistinctFarmer.length : 0}
                  </h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/farmerKyc" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>
            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                Retailer KYC
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    value={retailerSelectedDateRange}
                    onChange={handleRetailerDateRange}
                    showOneCalendar
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    placeholder="Select District"
                    style={{ width: "120px" }}
                    value={retailerSelectedDistrict}
                    onChange={handleRetailerDistrict}
                    showSearch
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctRetailerDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="ms-2 mt-3">
                  <p className="m-0">Type: </p>
                  <Select
                    placeholder="Select Type"
                    style={{ width: "120px" }}
                    value={retailerSelectedType}
                    onChange={handleRetailerType}
                    showSearch
                  >
                    <Option value="All Types">All Types</Option>
                    <Option value="Crop">Crop</Option>
                    <Option value="Livestock">Livestock</Option>
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>{distinctRetailer ? distinctRetailer.length : 0}</h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {retailerSelectedDateRange
                        ? retailerSelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          retailerSelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>
                    {todaysDistinctRetailer ? todaysDistinctRetailer.length : 0}
                  </h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/retailerKyc" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>
            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                LSP KYC
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    value={lspSelectedDateRange}
                    onChange={handleLSPDateRange}
                    showOneCalendar
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    value={lspSelectedDistrict}
                    onChange={handleLSPDistrict}
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctLSPDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>{distinctLSP ? distinctLSP.length : 0}</h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {lspSelectedDateRange
                        ? lspSelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          lspSelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>{todaysDistinctLSP ? todaysDistinctLSP.length : 0}</h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/lspKyc" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>
            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                Dairy Survey
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    showOneCalendar
                    value={dairySelectedDateRange}
                    onChange={handleDairyDateRange}
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    value={dairySelectedDistrict}
                    onChange={handleDairyDistrict}
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctDairyDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>{distinctDairy ? distinctDairy.length : 0}</h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {dairySelectedDateRange
                        ? dairySelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          dairySelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>
                    {todaysDistinctDairy ? todaysDistinctDairy.length : 0}
                  </h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/dairy" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>
            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                Beef Survey
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    showOneCalendar
                    value={beefSelectedDateRange}
                    onChange={handleBeefDateRange}
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    value={beefSelectedDistrict}
                    onChange={handleBeefDistrict}
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctBeefDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>{distinctBeef ? distinctBeef.length : 0}</h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {beefSelectedDateRange
                        ? beefSelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          beefSelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>{todaysDistinctBeef ? todaysDistinctBeef.length : 0}</h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/beef" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>
            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                Agro Processor Survey
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    showOneCalendar
                    value={agroProcessorSelectedDateRange}
                    onChange={handleAgroProcessorDateRange}
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    value={agroProcessorSelectedDistrict}
                    onChange={handleAgroProcessorDistrict}
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctAgroProcessorDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>
                    {distinctAgroProcessor ? distinctAgroProcessor.length : 0}
                  </h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {agroProcessorSelectedDateRange
                        ? agroProcessorSelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          agroProcessorSelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>
                    {todaysDistinctAgroProcessor
                      ? todaysDistinctAgroProcessor.length
                      : 0}
                  </h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/agroProcessor" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>

            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                Warehouse Survey
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    showOneCalendar
                    value={warehouseSelectedDateRange}
                    onChange={handleWarehouseDateRange}
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    value={warehouseSelectedDistrict}
                    onChange={handleWarehouseDistrict}
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctWarehouseDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>{distinctWarehouse ? distinctWarehouse.length : 0}</h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {warehouseSelectedDateRange
                        ? warehouseSelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          warehouseSelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>
                    {todaysDistinctWarehouse
                      ? todaysDistinctWarehouse.length
                      : 0}
                  </h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/warehouse" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>

            <div className="cardDesign">
              <p className="m-3" style={{ fontSize: "16px" }}>
                Poultry Survey
              </p>
              <div className="SurveyCardDesignFilter">
                <div className="ms-2 mt-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    showOneCalendar
                    value={poultrySelectedDateRange}
                    onChange={handlePoultryDateRange}
                  />
                </div>
                <div className="ms-2 mt-3">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    value={poultrySelectedDistrict}
                    onChange={handlePoultryDistrict}
                  >
                    <Option value="All Districts">All Districts</Option>
                    {distinctPoultryDistrict?.map((district, index) => (
                      <Option value={district} key={index}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top">
                <div className="surveyCardInnerBody border-end mt-3">
                  <h6>
                    <strong>Total</strong>
                  </h6>
                  <h6>{distinctPoultry ? distinctPoultry.length : 0}</h6>
                </div>
                <div className="surveyCardInnerBody mt-3">
                  <h6>
                    <strong>
                      {poultrySelectedDateRange
                        ? poultrySelectedDateRange[0].toLocaleDateString() +
                          "-" +
                          poultrySelectedDateRange[1].toLocaleDateString()
                        : today.toLocaleDateString()}
                    </strong>
                  </h6>
                  <h6>
                    {todaysDistinctPoultry
                      ? todaysDistinctPoultry.length
                      : 0}
                  </h6>
                </div>
              </div>
              <div className="text-end">
                <Link to="/poultry" target="_blank">
                  <Button className="text-success me-2 mt-2">
                    See details...
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
