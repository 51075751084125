import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  Modal,
  DatePicker,
  Row,
  Col,
  Table,
  Image,
} from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";

export default function TransactionSalesUpdateModal({
  visible,
  handleTransactionSalesUpdateModalClose,
  fetchIPTransaction,
  workOrder,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [selectedGDN, setSelectedGDN] = useState(null);
  const [WORelatedInfo, setWORelatedInfo] =
    useState([]);
  const [due, setDue] = useState(null);
  const [paymentInfoList, setPaymentInfoList] = useState([]);
  const [selectedGDNData, setSelectedGDNData] = useState(null);
  const [sourcingType, setSourcingType] = useState([]);

  const handleModalClose = () => {
    handleTransactionSalesUpdateModalClose();
    form.resetFields();
  };

  const handleBillReceiveDateChange = (date, dateString) => {
    setBillReceiveDate(dateString);
  };
  const [billReceiveDate, setBillReceiveDate] = useState(
    form.getFieldValue("bill_receive_date")
  );

  const handleSelectWO = () => {
    axios
      .get(
        `${baseUrl}/sourcing/woUpdateBuyingInfo/${workOrder?.work_order_id}`,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        if (
          data?.data?.direct?.length > 0 ||
          data?.data?.warehouse?.length > 0
        ) {
          setWORelatedInfo(data?.data);
          form.setFieldsValue({
            work_order_id: workOrder?.work_order_id,
            po_no: workOrder?.purchase_order,
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "There are no assigned GDN with this Work Order",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {}, [form, selectedGDN, due]);

  useEffect(() => {
    handleSelectWO();
  }, [workOrder?.work_order_id]);

  const handleSourcingType = (value) => {
    setSourcingType(value);
  };


  const handleGDNSelect = (value) => {
    setSelectedGDN(value);
    axios
      .get(`${baseUrl}/ip/ip_transaction_info`, {
        headers: authHeader(),
        params: {
          wo: workOrder?.work_order_id,
          gdn: value,
          sourcingType: sourcingType
        },
      })
      .then((response) => {
        const data = response?.data;
        setSelectedGDNData(data);
        form.setFieldsValue({
          grn: data?.grn,
          product_receive_date: data?.product_receive_date,
          product_name: data?.product_name,
          provided_quantity: data?.provided_quantity,
          received_quantity: data?.received_quantity,
          product_sale_rate_per_kg: data?.work_order?.rate_tk_per_kg,
          other_cost: data?.other_cost,
          total_receivable: data?.total_receivable,
          total_received: data?.total_received,
          due_amount: data?.due_amount,
          grn_date: data?.grn_date,
          remarks: data?.remarks,
        });
        const getDue = form.getFieldValue("due_amount");
        const parsedDue = getDue === "" ? 0 : parseFloat(getDue);
        setDue(parsedDue);
        axios
          .get(`${baseUrl}/ip/salesPaymentList/${value}/`, {
            headers: authHeader(),
          })
          .then((data) => {
            setPaymentInfoList(data?.data);
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error?.response?.data?.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReceivedAmount = (value) => {
    let totalReceived, totalDue;

    if (value === "") {
      totalReceived = parseFloat(selectedGDNData?.total_received) || 0;
      totalDue = parseFloat(selectedGDNData?.due_amount) || 0;
    } else {
      const paymentAmount = parseFloat(value);
      totalReceived = parseFloat(selectedGDNData?.total_received) || 0;
      totalDue = parseFloat(selectedGDNData?.due_amount) || 0;

      if (!isNaN(paymentAmount)) {
        if (paymentAmount > totalDue) {
          totalReceived += totalDue;
          totalDue = 0;
        } else {
          totalReceived += paymentAmount;
          totalDue -= paymentAmount;
        }
      }
    }

    form.setFieldsValue({
      total_received: totalReceived.toFixed(2),
      due_amount: totalDue.toFixed(2),
    });
  };

  const handleSubmit = (data) => {
    data.bill_receive_date = billReceiveDate;
    axios
      .patch(
        `${baseUrl}/ip/ip_transaction_update/?wo=${workOrder?.work_order_id}&gdn=${selectedGDN}&sourcingType=${sourcingType}`,
        data,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        if (data?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Transaction Sales Update Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            handleTransactionSalesUpdateModalClose();
            fetchIPTransaction();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  const paymentInfoColumn = [
    {
      title: "Received Amount",
      render: (record) => record?.payment_amount?.toLocaleString(),
    },
    {
      title: "Bill Receive Date",
      dataIndex: "payment_date",
    },
  ];
  return (
    <Modal
      title="Transaction Sales Update"
      open={visible}
      onCancel={handleModalClose}
      footer={false}
      width="50%"
    >
      <Form
        className="register-form"
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="WO No"
              name="work_order_id"
              rules={[{ required: true, message: "Please select WO" }]}
            >
              <Input placeholder="WO ID" readOnly />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="PO No"
              name="po_no"
              // rules={[{ required: true, message: "PO required" }]}
            >
              <Input placeholder="PO number" readOnly />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="Sourcing Type"
              name="sourcing_type"
              rules={[{ required: true, message: "Sourcing type required" }]}
            >
              <Select
                placeholder="Select sourcing type"
                onChange={handleSourcingType}
              >
                <Option value="direct">Direct</Option>
                <Option value="warehouse">Warehouse</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="GDN"
              name="gdn_number"
              rules={[{ required: true, message: "Please select GDN" }]}
            >
              <Select
                placeholder="Select GDN"
                showSearch
                onChange={handleGDNSelect}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {sourcingType.includes("direct") &&
                  WORelatedInfo?.direct?.map((item) => (
                    <Option key={item.gdn_number} value={item.gdn_number}>
                      {item.gdn_number}
                    </Option>
                  ))}
                {sourcingType.includes("warehouse") &&
                  WORelatedInfo?.warehouse?.map((item) => (
                    <Option key={item.gdn_number} value={item.gdn_number}>
                      {item.gdn_number}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="GRN"
              name="grn"
              rules={[
                {
                  required: true,
                  message: "GRN Required",
                },
              ]}
            >
              <Input placeholder="GRN" readOnly />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="GRN Date"
              name="grn_date"
              rules={[{ required: true, message: "GRN date required" }]}
            >
              <Input placeholder="GRN Date" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Product Receive Date"
              name="product_receive_date"
              rules={[
                {
                  required: true,
                  message: "Required product receive date",
                },
              ]}
            >
              <Input placeholder="Product Receive Date" readOnly />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Product Name"
              name="product_name"
              rules={[
                {
                  required: true,
                  message: "Required product name",
                },
              ]}
            >
              <Input placeholder="Product Name" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Provided Quantity"
              name="provided_quantity"
              rules={[
                {
                  required: true,
                  message: "Required provided quantity",
                },
              ]}
            >
              <Input placeholder="Provided Quantity" readOnly />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Received Quantity"
              name="received_quantity"
              rules={[
                {
                  required: true,
                  message: "Required receive quantity",
                },
              ]}
            >
              <Input placeholder="Receive Quantity" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Sale (Rate/KG)"
              name="product_sale_rate_per_kg"
              rules={[
                {
                  required: true,
                  message: "Required Product Sale (Rate/KG)",
                },
              ]}
            >
              <Input placeholder="Product Sale (Rate/KG)" readOnly />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item hasFeedback label="Other Cost" name="other_cost">
              <Input placeholder="Other Cost" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="Total Receivable"
              name="total_receivable"
              rules={[
                {
                  required: true,
                  message: "Required total receivable",
                },
              ]}
            >
              <Input placeholder="Total Receivable" readOnly />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="Total Received"
              name="total_received"
              rules={[
                {
                  required: true,
                  message: "Required total received",
                },
              ]}
            >
              <Input placeholder="Total Received" readOnly />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item hasFeedback label="Due Amount" name="due_amount">
              <Input placeholder="Due Amount" readOnly />
            </Form.Item>
          </Col>
        </Row>
        {paymentInfoList?.length > 0 && (
          <Table
            className="ms-1 bordered"
            columns={paymentInfoColumn}
            dataSource={paymentInfoList}
            size="small"
            rowKey={(record) => record?.created_at}
            pagination={{
              pageSize: 5,
            }}
          />
        )}
        {due > 0 ? (
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col lg={12}>
              <Form.Item
                hasFeedback
                label="Received Amount"
                name="received_amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter received amount",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english number",
                  },
                ]}
              >
                <Input
                  placeholder="Received Amount"
                  onChange={(e) => handleReceivedAmount(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                hasFeedback
                label="Bill Receive Date"
                name="bill_receive_date"
                rules={[
                  { required: true, message: "Please enter bill receive date" },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={handleBillReceiveDateChange}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item hasFeedback label="Remarks" name="remarks">
              <Input placeholder="Total Remarks" />
            </Form.Item>
          </Col>
          {paymentInfoList?.length > 0 && (
            <Col lg={12}>
              <Form.Item hasFeedback label="GRN Image" name="grn_image">
                <Image src={selectedGDNData?.grn_image} alt="GRN Image" />
              </Form.Item>
            </Col>
          )}
        </Row>
        {due > 0 ? (
          <div className="d-flex justify-content-end">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#38512e",
                  color: "#ffffff",
                }}
                disabled={loading}
              >
                Update
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </div>
        ) : null}
      </Form>
    </Modal>
  );
}
