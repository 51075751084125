import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Space,
  DatePicker,
} from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import "./Inventory.css";
import InvoiceModal from "./InvoiceModal";

export default function GUISales() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [orderType, setOrderType] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [filteredProductsList, setFilteredProductsList] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceResponseData, setInvoiceResponse] = useState(null);

  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);

  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );

  const redirectToExternalUrl = () => {
    window.open(
      "https://mis-operation.agrigate.network/registration",
      "_blank"
    );
  };

  const fetchGuiProductList = () => {
    axios
      .get(`${baseUrl}/inventory/gui_stock_list/${userProfile?.employee_id}/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setProductList(response?.data || []);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/contractFarming/projects/`, {
        headers: authHeader(),
      })
      .then((response) => setProjects(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchGuiProductList();
  }, [userProfile?.employee_id]);

  const distinct_product_name = [
    ...new Set(productList?.map((item) => item?.product?.product_name)),
  ];

  const filterProductList = (value, index) => {
    const filteredProducts = productList.filter(
      (product) =>
        product.product.product_name.toLowerCase() === value.toLowerCase()
    );
    setFilteredProductsList(filteredProducts);
    const newValues = form.getFieldsValue();
    newValues.products[index] = {
      ...newValues.products[index],
      batch_id: undefined,
      quantity: undefined,
      discount: undefined,
      product_total_price: undefined,
      remaining_stock: undefined,
      selling_price: undefined,
    };

    form.setFieldsValue({ products: [...newValues.products] });
  };

  const handleOrderType = (value) => {
    setOrderType(value);
    form.setFieldsValue({
      products: [],
      total_paid: null,
      due: null,
      farmer_name: null,
      farmer_id: null,
      phoneNumber: null,
      retailer_name: null,
      retailer_id: null,
      phone_number: null,
      contractPhoneNumber: null,
      contract_farmer_name: null,
      contract_farmer_id: null,
      group_name: null,
      project_id: null,
      institutional_buyer_name: null,
    });
  };

  const handleProductSelect = (value, index) => {
    const newValues = { ...form.getFieldsValue() };
    const rateKey =
      orderType.toLowerCase() === "retailer"
        ? "selling_rate_retailer"
        : orderType.toLowerCase() === "farmer"
        ? "selling_rate_farmer"
        : orderType.toLowerCase() === "institutional"
        ? "selling_rate_institutional_buyer"
        : "selling_rate_contract_farmer";
    const selectedProduct = filteredProductsList.find(
      (product) => product.batch_id === value
    );

    newValues.products[index].purchase_price = selectedProduct?.purchase_price;
    newValues.products[index].remaining_stock = selectedProduct?.stock;
    newValues.products[index].selling_price = selectedProduct[rateKey];
    newValues.products[index]["product_id"] =
      selectedProduct.product.product_id;
    form.setFieldsValue({ ...newValues });
  };
  const handleProjectInfo = (value) => {
    const selectedProjectDetails = projects?.find(
      (project) => project.project_id === value
    );
    setSelectedProject(selectedProjectDetails);
  };
  const handleContractFarmerGroup = (value) => {
    setSelectedGroup(value);
  };

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleRetailerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/retailer/retailer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            retailer_name: data?.data?.name,
            retailer_id: data?.data?.retailer_id,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            retailer_name: undefined,
            retailer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Retailer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleContractFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/contract_farmer_info_ops/`, {
          headers: authHeader(),
          params: {
            project: selectedProject?.project_id,
            group_name: selectedGroup,
            phone: phone,
          },
        })
        .then((data) => {
          console.log(data);
          form.setFieldsValue({
            contract_farmer_name: data?.data?.farmer?.name,
            contract_farmer_id: data?.data?.farmer?.farmer_id,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            contract_farmer_name: undefined,
            contract_farmer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleInstitutionalInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/trader/ibInfo/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            institutional_buyer_name: data?.data?.institutional_buyer_name,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            institutional_buyer_name: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleQuantityChange = (value, index) => {
    const newValues = { ...form.getFieldsValue() };
    const rate = parseFloat(newValues.products[index].selling_price) || 0;
    const discount = parseFloat(newValues.products[index].discount) || 0;
    const quantity = value === "" ? 0 : parseInt(value);
    const calculatedTotalPrice = rate * quantity - discount;

    newValues.products[index].product_total_price = calculatedTotalPrice;

    form.setFieldsValue({ ...newValues });
    calculateTotalPrice();
  };

  const handleDiscountAdd = (value, index) => {
    const newValues = form.getFieldsValue();
    const quantity = newValues.products[index].quantity;
    const rateKey = newValues.products[index].selling_price;

    const calculatedTotalPrice = rateKey * quantity;

    if (value) {
      const discountedTotalPrice = Math.max(calculatedTotalPrice - value, 0);
      newValues.products[index].product_total_price = discountedTotalPrice;
    } else {
      newValues.products[index].product_total_price = calculatedTotalPrice;
    }

    form.setFieldsValue(newValues);
    calculateTotalPrice();
  };

  const calculateTotalPrice = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalPriceSum = products.reduce((sum, product) => {
      return sum + (product.product_total_price || 0);
    }, 0);

    form.setFieldValue("total_price", newTotalPriceSum);
    handleTotalPaidChange();
  };

  const handleTotalPaidChange = () => {
    const totalPaid = form.getFieldValue("total_paid");
    const totalPrice = form.getFieldValue("total_price");

    const due = Math.max(totalPrice - totalPaid, 0);

    form.setFieldsValue({
      due: due.toFixed(2),
    });
  };

  const handleSubmit = (data) => {
    const totalPurchasePrice = data?.products?.reduce((total, item) => {
      return total + (parseFloat(item?.purchase_price) * parseFloat(item?.quantity));
    }, 0);
    data.product_total_purchase_price = totalPurchasePrice;

    setLoading(true);
    axios
      .post(
        `${baseUrl}/inventory/sales_create/${userProfile?.employee_id}/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((result) => {
        setLoading(false);
        if (result?.status === 201) {
          Swal.fire({
            title: "Success",
            text: "Invoice Created Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            fetchGuiProductList();
            form.resetFields();
            form.setFieldsValue({
              products: [],
            });
            setOrderType([]);
            setViewModal(true);
            setInvoiceResponse(result?.data);
            setInvoiceData(data);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleInvoiceModalClose = () => {
    setViewModal(false);
  };

  return (
    <>
      <InvoiceModal
        visible={viewModal}
        handleInvoiceModalClose={handleInvoiceModalClose}
        responseData={invoiceResponseData}
        data={invoiceData}
      />

      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>GUI POS</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div
              className="shadow-sm p-3"
              style={{ border: "1px solid #203509" }}
            >
              <Form
                form={form}
                color="#203509"
                onFinish={handleSubmit}
                layout="vertical"
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                      <Col style={{ width: "400px" }}>
                        <Form.Item
                          hasFeedback
                          label="Customer Type"
                          name="customer_type"
                          rules={[
                            {
                              required: true,
                              message: "Please select customer type",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Type"
                            onChange={handleOrderType}
                          >
                            <Option value="Farmer">Farmer</Option>
                            <Option value="Retailer">Retailer</Option>
                            <Option value="Contract">Contract Farmer</Option>
                            <Option value="Institutional">
                              Institutional Buyer
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {orderType.includes("Farmer") && (
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            label="Customer Number"
                            name="phoneNumber"
                            onChange={handleFarmerInfo}
                            rules={[
                              {
                                required: true,
                                message: "Please enter customer's phone number",
                              },
                              {
                                pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <Input placeholder="Phone Number" type="tel" />
                          </Form.Item>
                        </Col>
                      )}

                      {orderType.includes("Retailer") && (
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            label="Customer Number"
                            name="phone_number"
                            onChange={handleRetailerInfo}
                            rules={[
                              {
                                required: true,
                                message: "Please enter customer's phone number",
                              },
                              {
                                pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <Input placeholder="Phone Number" type="tel" />
                          </Form.Item>
                        </Col>
                      )}
                      {orderType.includes("Contract") && (
                        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              hasFeedback
                              label="Project Name"
                              name="project_id"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select project",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Project"
                                onChange={handleProjectInfo}
                              >
                                {projects?.map((project, index) => (
                                  <Option
                                    value={project?.project_id}
                                    key={index}
                                  >
                                    {project?.project_name +
                                      " - " +
                                      project?.project_type +
                                      " - " +
                                      project?.year}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              hasFeedback
                              label="Group Name"
                              name="group_name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select group name",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select group"
                                showSearch
                                onChange={handleContractFarmerGroup}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {selectedProject?.names?.map((group, index) => (
                                  <Option key={index} value={group.group}>
                                    {group.group}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {orderType.includes("Institutional") && (
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            label="Customer Number"
                            name="phoneNumber"
                            onChange={handleInstitutionalInfo}
                            rules={[
                              {
                                required: true,
                                message: "Please enter customer's phone number",
                              },
                              {
                                pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <Input placeholder="Phone Number" type="tel" />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    {orderType.includes("Farmer") && (
                      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            name="farmer_name"
                            rules={[
                              {
                                required: true,
                                message: "Farmer name required",
                              },
                            ]}
                          >
                            <Input placeholder="Farmer Name" readOnly />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            name="farmer_id"
                            rules={[
                              {
                                required: true,
                                message: "Farmer ID required",
                              },
                            ]}
                          >
                            <Input placeholder="Farmer ID" readOnly />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    {orderType.includes("Retailer") && (
                      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            name="retailer_name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter retailer's name",
                              },
                            ]}
                          >
                            <Input placeholder="Retailer Name" readOnly />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            name="retailer_id"
                            rules={[
                              {
                                required: true,
                                message: "Retailer ID is required",
                              },
                            ]}
                          >
                            <Input placeholder="Retailer ID" readOnly />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}

                    {orderType.includes("Contract") && (
                      <>
                        <Col style={{ width: "400px" }}>
                          <Form.Item
                            hasFeedback
                            label="Customer Number"
                            name="contractPhoneNumber"
                            onChange={handleContractFarmerInfo}
                            rules={[
                              {
                                required: true,
                                message: "Please enter customer's phone number",
                              },
                              {
                                pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <Input placeholder="Phone Number" type="tel" />
                          </Form.Item>
                        </Col>
                        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                          <Col style={{ width: "400px" }}>
                            <Form.Item
                              hasFeedback
                              name="contract_farmer_name"
                              rules={[
                                {
                                  required: true,
                                  message: "Farmer name required",
                                },
                              ]}
                            >
                              <Input placeholder="Farmer Name" readOnly />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "400px" }}>
                            <Form.Item
                              hasFeedback
                              name="contract_farmer_id"
                              rules={[
                                {
                                  required: true,
                                  message: "Farmer ID required",
                                },
                              ]}
                            >
                              <Input placeholder="Farmer ID" readOnly />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                    {orderType.includes("Institutional") && (
                      <Col style={{ width: "400px" }}>
                        <Form.Item
                          hasFeedback
                          name="institutional_buyer_name"
                          rules={[
                            {
                              required: true,
                              message: "Institutional Buyer name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Institutional Buyer Name"
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </div>

                  <Col>
                    <Form.Item>
                      <Button
                        className="mt-4"
                        onClick={redirectToExternalUrl}
                        style={{ color: "#ffffff", backgroundColor: "#203509" }}
                      >
                        Add Customer
                      </Button>
                    </Form.Item>
                  </Col>
                </div>

                <Form.List name="products">
                  {(fields, { add, remove }) => (
                    <div
                      className="p-3 mb-5"
                      style={{ color: "#203509", border: "1px solid #203509" }}
                    >
                      <Col lg={4}>
                        <Form.Item>
                          <Button
                            type="default"
                            onClick={() => add()}
                            block
                            size="small"
                            icon={<PlusOutlined />}
                            style={{ color: "#000000" }}
                          >
                            Add product
                          </Button>
                        </Form.Item>
                      </Col>
                      {fields.length === 0 && (
                        <Space key="initial" align="baseline">
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              label="Product Name"
                              name={[0, `product_name`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select product name"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) =>
                                  filterProductList(value, 0)
                                }
                              >
                                {distinct_product_name?.map(
                                  (product, index) => (
                                    <Option key={index} value={product}>
                                      {product}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "220px" }}>
                            <Form.Item
                              label="Batch ID"
                              name={[0, `batch_id`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select batch ",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select batch id"
                                showSearch
                                onChange={(value) =>
                                  handleProductSelect(value, 0)
                                }
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {filteredProductsList?.map((item) => (
                                  <Option
                                    key={item.batch_id}
                                    value={item.batch_id}
                                  >
                                    {item.batch_id}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "120px" }}>
                            <Form.Item
                              label="Remaining Stock"
                              name={[0, `remaining_stock`]}
                            >
                              <Input placeholder="Remaining Stock" readOnly />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "0px" }}>
                            <Form.Item name={[0, `purchase_price`]}>
                              <Input hidden />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              label="Price"
                              name={[0, `selling_price`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product price required",
                                },
                              ]}
                            >
                              <Input placeholder="Price" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              label="Quantity"
                              name={[0, `quantity`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Purchase quantity required",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Quantity"
                                onChange={(e) =>
                                  handleQuantityChange(e.target.value, 0)
                                }
                              />
                            </Form.Item>
                          </Col>

                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              label="Discount"
                              name={[0, `discount`]}
                              initialValue={0}
                              rules={[
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Discount"
                                onChange={(e) =>
                                  handleDiscountAdd(e.target.value, 0)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              label="Product Total Price"
                              name={[0, `product_total_price`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product Total Price required",
                                },
                                {
                                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Product Total Price"
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Space>
                      )}
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space key={key} align="baseline">
                          <Row gutter={[8, 16, 24, 32]}>
                            <Col style={{ width: "200px" }}>
                              <Form.Item
                                {...restField}
                                label="Product Name"
                                name={[name, `product_name`]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Product is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select product name"
                                  showSearch
                                  onChange={(value) =>
                                    filterProductList(value, index)
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {distinct_product_name?.map(
                                    (product, index) => (
                                      <Option key={index} value={product}>
                                        {product}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col style={{ width: "220px" }}>
                              <Form.Item
                                label="Batch ID"
                                name={[name, `batch_id`]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select batch ",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select batch id"
                                  showSearch
                                  onChange={(value) =>
                                    handleProductSelect(value, index)
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {filteredProductsList?.map((item) => (
                                    <Option
                                      key={item.batch_id}
                                      value={item.batch_id}
                                    >
                                      {item.batch_id}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col style={{ width: "120px" }}>
                              <Form.Item
                                label="Remaining Stock"
                                name={[name, `remaining_stock`]}
                              >
                                <Input placeholder="Remaining Stock" readOnly />
                              </Form.Item>
                            </Col>

                            <Col style={{ width: "0px" }}>
                              <Form.Item name={[name, `purchase_price`]}>
                                <Input hidden />
                              </Form.Item>
                            </Col>

                            <Col style={{ width: "150px" }}>
                              <Form.Item
                                {...restField}
                                label="Price"
                                name={[name, `selling_price`]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Selling price required",
                                  },
                                ]}
                              >
                                <Input placeholder="Price" readOnly />
                              </Form.Item>
                            </Col>
                            <Col style={{ width: "150px" }}>
                              <Form.Item
                                {...restField}
                                label="Quantity"
                                name={[name, `quantity`]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Purchase quantity required",
                                  },
                                  {
                                    pattern: /^[0-9]+$/,
                                    message: "Only english number",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    handleQuantityChange(e.target.value, index)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ width: "150px" }}>
                              <Form.Item
                                {...restField}
                                label="Discount"
                                name={[name, `discount`]}
                                initialValue={0}
                                rules={[
                                  {
                                    pattern: /^[0-9]+$/,
                                    message: "Only english number",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Discount"
                                  onChange={(e) =>
                                    handleDiscountAdd(e.target.value, index)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{ width: "200px" }}>
                              <Form.Item
                                {...restField}
                                label="Product Total Price"
                                name={[name, `product_total_price`]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Product Total Price required",
                                  },
                                  {
                                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                                    message: "Only english number",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Product Total Price"
                                  readOnly
                                />
                              </Form.Item>
                            </Col>

                            {fields.length > 1 ? (
                              <Button
                                className="align-self-center"
                                onClick={() => {
                                  form.setFieldsValue({
                                    total_price: undefined,
                                    due: undefined,
                                    total_paid: undefined,
                                  });
                                  remove(name);
                                  calculateTotalPrice();
                                }}
                                style={{
                                  backgroundColor: "#c21807",
                                  color: "#ffffff",
                                }}
                              >
                                <MinusCircleOutlined />
                              </Button>
                            ) : null}
                          </Row>
                        </Space>
                      ))}
                    </div>
                  )}
                </Form.List>
                <p
                  className="text-center"
                  style={{ color: "#203509", fontSize: 16 }}
                >
                  {" "}
                  <strong>Payment Method </strong>
                </p>
                <div className="d-flex shadow-sm mb-4">
                  <div
                    className="w-50 pt-4 d-flex justify-content-center"
                    style={{ color: "#203509", border: "1px solid #203509" }}
                  >
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Cash"
                        disabled
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="ms-2"
                      name="total_paid"
                      rules={[
                        {
                          required: true,
                          message: "Total paid required",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Only english number",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Paid"
                        onChange={handleTotalPaidChange}
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                  </div>
                  <div
                    className="w-50 pt-4"
                    style={{ color: "#203509", border: "1px solid #203509" }}
                  >
                    <div className="d-flex justify-content-center">
                      <Form.Item className="ms-2">
                        <Input
                          className="py-2 text-center custom-placeholder-color"
                          placeholder="Total"
                          disabled
                          style={{ color: "#203509", borderColor: "#203509" }}
                        />
                      </Form.Item>

                      <Form.Item
                        className="ms-2"
                        name="total_price"
                        rules={[
                          {
                            required: true,
                            message: "Total price missing",
                          },
                        ]}
                      >
                        <Input
                          className="py-2 text-center"
                          placeholder="Total Price"
                          readOnly
                          style={{ color: "#203509", borderColor: "#203509" }}
                        />
                      </Form.Item>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Form.Item className="ms-2">
                        <Input
                          className="py-2 text-center custom-placeholder-color"
                          placeholder="Due"
                          disabled
                          style={{ color: "#203509", borderColor: "#203509" }}
                        />
                      </Form.Item>
                      <Form.Item className="ms-2" name="due">
                        <Input
                          className="py-2 text-center"
                          placeholder="Due Amount"
                          readOnly
                          style={{ color: "#203509", borderColor: "#203509" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <Form.Item className="d-flex justify-content-end ">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    style={{ color: "#ffffff", backgroundColor: "#203509" }}
                  >
                    Submit
                    {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
