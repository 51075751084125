import { Input, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";

export default function DueDetailsModal({ projectId, closeModal, openModal }) {
  const [projectDetails, setProjectDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Search } = Input;
  const [searchedPhone, setSearchedPhone] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageChange = (value) => {
    setPageNumber(value);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${baseUrl}/contractFarming/CFDueList/${projectId}/`, {
      headers: authHeader(),
      params: {
        phone: searchedPhone,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setProjectDetails(result?.results);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [projectId, searchedPhone]);

  const handlePhoneNumber = (value) => {
    setSearchedPhone(value);
  };
  const projectDetailsColumn = [
    {
      title: "Project Name",
      render: (record) => record?.project?.project_name,
    },
    {
      title: "Project Year",
      render: (record) => record?.project?.year,
    },
    {
      title: "Project Type",
      render: (record) => record?.project?.project_type,
    },
    {
      title: "Center",
      render: (record) => record?.project?.district,
    },
    {
      title: "Customer Id",
      render: (record) => record?.farmer?.farmer_id,
    },
    {
      title: "Customer Name",
      render: (record) => record?.farmer?.name,
    },
    {
      title: "Number",
      render: (record) => "0"+(record?.farmer?.phone),
    },
    {
      title: "Credit",
      render: (record) => parseFloat(record?.total_price)?.toLocaleString(),
    },
    {
      title: "Paid",
      render: (record) => parseFloat(record?.total_paid)?.toLocaleString(),
    },
    {
      title: "Due",
      render: (record) => parseFloat(record?.due)?.toLocaleString(),
    },
    {
      title: "Last Payment",
      dataIndex: "due_payment_date",
    },
  ];
  return (
    <Modal
      //   title="Project Details"
      open={openModal}
      footer={false}
      onCancel={closeModal}
      width="70%"
    >
      <div className="m-2">
        <div className="d-flex flex-end">
          <Search
            className="mb-2"
            placeholder="Search number"
            style={{ width: "400px" }}
            onSearch={handlePhoneNumber}
            allowClear
          />
        </div>
        <Table
          dataSource={projectDetails}
          columns={projectDetailsColumn}
          loading={loading}
          pagination={{
            current: pageNumber,
            total: totalItems,
            pageSize: 10,
            onChange: handlePageChange,
          }}
        />
      </div>
    </Modal>
  );
}
