import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Table,
  Select,
} from "antd";
import baseUrl from "../../../config";
import Tooltip from "@mui/material/Tooltip";
import { EditOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import authHeader from "../../Authentication/authHeader";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "./ProductPrice.css";

export default function ProductPrice() {
  const [productPriceList, setProductPriceList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const { Option } = Select;

  const fetchProductList = () => {
    setDataLoading(true);
    axios
      .get(`${baseUrl}/retailer/productPriceList`, {
        headers: authHeader(),
      })
      .then((results) => {
        setProductPriceList(results?.data);
        setDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  const columns = [
    {
      title: "Product ID",
      dataIndex: "product_id",
      width: "10%",
      key: "product_id",
      filters: productPriceList?.map((obj) => ({
        text: obj?.product_id,
        value: obj?.product_id,
      })),
      onFilter: (value, record) => record?.product_id?.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Product Name (English)",
      dataIndex: "product_name_english",
      filters: productPriceList?.map((obj) => ({
        text: obj?.product_name_english,
        value: obj?.product_name_english,
      })),
      onFilter: (value, record) =>
        record?.product_name_english?.startsWith(value),
      filterSearch: true,
      width: "15%",
    },
    {
      title: "Product Name (Bangla)",
      dataIndex: "product_name_bangla",
      width: "14%",
    },
    {
      title: "Purchase Price",
      dataIndex: "purchase_price",
      width: "14%",
    },
    {
      title: "Selling Price (Farmer)",
      dataIndex: "selling_price_farmer",
      width: "14%",
    },
    {
      title: "Selling Price (Retailer)",
      dataIndex: "selling_price_retailer",
      width: "14%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="Update">
            <Button
              className="actionBtnDesign"
              onClick={() => handleUpdateClick(record)}
            >
              <EditOutlined />
              Edit
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  const handleUpdateClick = (record) => {
    form.setFieldsValue(record);
    setSelectedProduct(record);
    setUpdateModalVisible(true);
  };

  const handleAddClick = () => {
    form.resetFields();
    setSelectedProduct(null);
    setUpdateModalVisible(true);
  };

  const handleSubmit = (data) => {
    if (selectedProduct) {
      setLoading(true);
      axios
        .put(
          `${baseUrl}/retailer/update_product/${data.product_id}/`,
          {
            data: data,
          },
          {
            headers: {
              ...authHeader(),
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response?.data?.success === true) {
            Swal.fire({
              title: "Success",
              text: "Product price update successful",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              setUpdateModalVisible(false);
              fetchProductList();
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error?.response?.data?.message);
          Swal.fire({
            title: "error",
            text: error?.response?.data?.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } else {
      setLoading(true);
      axios
        .post(
          `${baseUrl}/retailer/products/`,
          {
            data: data,
          },
          {
            headers: {
              ...authHeader(),
            },
          }
        )
        .then((response) => {
          setLoading(false);
          console.log(response?.status);
          if (response?.status === 201) {
            Swal.fire({
              title: "Success",
              text: "Product create successful",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              setUpdateModalVisible(false);
              fetchProductList();
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const errorMessages = error.response.data.errors;
            const errorMessageList = Object.values(errorMessages)
              .flat()
              .join("\n");
            console.log(error);
            Swal.fire({
              title: "error",
              text: errorMessageList,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        });
    }
  };
  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h3 style={{ color: "#38512e" }}>ORDER TAKEN PRICE LIST</h3>
      </div>
      <Modal
        title="Update Product Price"
        centered
        open={updateModalVisible}
        // onOk={() => setUpdateModal(false)}
        onCancel={() => setUpdateModalVisible(false)}
        width={"60%"}
        footer={null}
      >
        {
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Product ID"
                  name="product_id"
                  rules={[
                    { required: true, message: "Product ID required" },
                    {
                      pattern: /^[A-Za-z0-9\s\-_*]+$/,
                      message: "English characters and numbers are allowed",
                    },
                  ]}
                >
                  <Input placeholder="Product ID" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="Product Name (English)"
                  name="product_name_english"
                  rules={[
                    {
                      required: true,
                      message: "Product Name (English) is required",
                    },
                    {
                      pattern: /^[A-Za-z0-9\s\-_*]+$/,
                      message: "English characters and numbers are allowed",
                    },
                  ]}
                >
                  <Input placeholder="Product Name (English)" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="Product Name (Bangla)"
                  name="product_name_bangla"
                  rules={[
                    {
                      required: true,
                      message: "Product Name (Bangla) is required",
                    },
                  ]}
                >
                  <Input placeholder="Product Name (Bangla)" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Category"
                  name="category"
                  rules={[
                    { required: true, message: "Product category is required" },
                    {
                      pattern: /^[A-Za-z0-9\s\-_*]+$/,
                      message: "English characters and numbers are allowed",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Product Category"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="Livestock">Livestock</Option>
                    <Option value="Medicine">Medicine</Option>
                    <Option value="Seed">Seed</Option>
                    <Option value="PGR">PGR</Option>
                    <Option value="Pesticides">Pesticides</Option>
                    <Option value="Micro-Nutrient">Micro-Nutrient</Option>
                    <Option value="Herbicides">Herbicides</Option>
                    <Option value="Fungicides">Fungicides</Option>
                    <Option value="Feed">Feed</Option>
                    <Option value="Bio Fertilizer">Bio Fertilizer</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="Brand"
                  name="brand"
                  rules={[
                    {
                      required: true,
                      message: "Product brand name is required",
                    },
                    {
                      pattern: /^[A-Za-z0-9\s\-_*]+$/,
                      message:
                        "Only English characters and numbers are allowed",
                    },
                  ]}
                >
                  <Input placeholder="Brand Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  label="Purchase Price"
                  name="purchase_price"
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="Purchase Price" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Selling Price (Farmer)"
                  name="selling_price_farmer"
                  rules={[
                    {
                      required: true,
                      message: "Farmer product selling price is required",
                    },
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="Selling Price (Farmer)" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  label="Selling Price (Retailer)"
                  name="selling_price_retailer"
                  rules={[
                    {
                      pattern: /^[0-9]+(\.[0-9]+)?$/,
                      message: "Only English numbers",
                    },
                  ]}
                >
                  <Input placeholder="Selling Price (Retailer)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={3} lg={2}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    {selectedProduct ? "Update" : "Create"}
                    {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={3} lg={2}>
                <Form.Item>
                  <Button
                    style={{ background: "white", color: "black" }}
                    onClick={() => setUpdateModalVisible(false)}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      </Modal>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="productAddSection">
          <button
            size="large"
            className="px-3 py-1"
            style={{ backgroundColor: "#5f8b3d", color: "#ffffff" }}
            onClick={handleAddClick}
          >
            <AddBoxIcon style={{ fontSize: "30px" }} /> Add Product
          </button>
        </div>
        <div className="m-2 border">
          <Table
            columns={columns}
            dataSource={productPriceList}
            loading={dataLoading}
            rowKey={(record) => record.product_id}
          />
        </div>
      </div>
    </div>
  );
}
