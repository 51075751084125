import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";

export default function SalesDashboard() {
  const [totalPurchaseRevenueData, setTotalPurchaseRevenueData] =
    useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/inventory/purchase_revenue_data/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setTotalPurchaseRevenueData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);
  return (
    <div className="consultationDashboard">
      <div className="dashboardNameSection">
        <h3 style={{ color: "#38512e" }}>SALES DASHBOARD</h3>
      </div>
      <div className="consultationDashboardContainer shadow-sm rounded">
        <div className="px-3 pt-4">
          <div className="shadow-sm border text-center mx-2">
            <p
              className="py-1 m-0"
              style={{ backgroundColor: "#203509", color: "#ffffff" }}
            >
              Sales
            </p>
            <div className="consultationGridLower px-2 mb-4">
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Total Purchase (BDT)
                </p>
                <p className="py-5">
                  {" "}
                  {totalPurchaseRevenueData?.total_purchase_amount?.toLocaleString() ||
                    0}
                </p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/cashSales">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Total Revenue (BDT)
                </p>
                <p className="py-5">
                  {totalPurchaseRevenueData?.total_revenue?.toLocaleString() ||
                    0}
                </p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/cashSales">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              {/* <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Gross Margin
                </p>
                <p className="py-5">0</p>
              </div> */}
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Total Order Taken (BDT)
                </p>
                <p className="py-5">
                  {totalPurchaseRevenueData?.total_order_taken_amount?.toLocaleString() ||
                    0}
                </p>
                <p className="mt-4">
                  <Link
                    className="farmerCashSalesDashboardBtn"
                    to="/orderTaking"
                  >
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Total Order Fulfilled (BDT)
                </p>
                <p className="py-5">
                  {totalPurchaseRevenueData?.total_order_fulfilled_amount?.toLocaleString() ||
                    0}
                </p>
                <p className="mt-4">
                  <Link
                    className="farmerCashSalesDashboardBtn"
                    to="/orderFulfillment"
                  >
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
            </div>
            {/* <p
              className="py-1 m-0 mt-5"
              style={{ backgroundColor: "#203509", color: "#ffffff" }}
            >
              Sales Timeline
            </p>
            <div className="timeLineSection">

            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
