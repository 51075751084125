import React, { useEffect } from 'react';

const JitsiMeetComponent = ({ roomName }) => {
  useEffect(() => {
    const api = new window.JitsiMeetExternalAPI("8x8.vc", {
      roomName: roomName,
      parentNode: document.querySelector('#jaas-container'),
      // Add any other configuration options as needed
    });

    return () => {
      // Cleanup or disconnect the JitsiMeetExternalAPI when the component unmounts
      api.dispose();
    };
  }, [roomName]);

  return (
    <div id="jaas-container" style={{ height: '100vh' }}>
      {/* JitsiMeetExternalAPI will render the video conference here */}
    </div>
  );
};

export default JitsiMeetComponent;