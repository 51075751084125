import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  Row,
  Col,
  Spin,
  Divider,
  Modal,
  DatePicker,
  Image,
  Table,
} from "antd";

import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import dayjs from "dayjs";

export default function SourcingBuyingModal({
  visible,
  data,
  workOrderInfo,
  handleSourcingBuyingModalClose,
  fetchSourcingBuying,
}) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankReceiptImageUrl, setBankReceiptImageUrl] = useState("");
  const dateFormat = "YYYY-MM-DD";
  const [paymentDate, setPaymentDate] = useState(null);
  const [paymentInfoList, setPaymentInfoList] = useState([]);
  const [purchaseDate,setPurchaseDate] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      purchase_date: data?.purchase_date
        ? dayjs(data?.purchase_date, dateFormat)
        : undefined,
      seller_type: data?.seller_type,
      phoneNumber: "0" + (data?.farmer?.phone || data?.trader?.phone),
      seller_name: data?.farmer?.name || data?.trader?.name,
      seller_id: data?.farmer?.farmer_id || data?.trader?.trader_id,
      district:
        data?.farmer?.district?.district_name ||
        data?.trader?.district?.district_name,
      upazila:
        data?.farmer?.upazila?.upazila_name ||
        data?.trader?.upazila?.upazila_name,
      union: data?.farmer?.union?.union_name || data?.trader?.union?.union_name,
      village: data?.village || "",
      routing_no: data?.banking_info?.routing_number,
      seller_bank_name: data?.banking_info?.bank_name,
      seller_account_name: data?.banking_info?.account_name,
      seller_account_no: data?.banking_info?.account_number,
      product_name: data?.product_name,
      purchase_quantity: data?.purchase_quantity_kg,
      anl_purchased_rate: data?.purchase_rate_tk_per_kg,
      gdn_no: data?.gdn_number,
      moisture: data?.moisture,
      dust: data?.dust,
      fungus: data?.fungus,
      comment: data?.comment,
      driver_name: data?.driver_name,
      driver_number: "0" + data?.driver_number,
      truck_no: data?.truck_no,
      number_of_sac: data?.number_of_sac,
      transport_cost_tk: data?.transport_cost_tk,
      transport_rate_tk_per_kg: data?.transport_rate_tk_per_kg,
      transport_rent_status: data?.transport_rent_status,
      work_order: data?.work_order?.work_order_id,
      payment_status: data?.payment_status,
      payment_date: data?.payment_date
        ? dayjs(data?.payment_date, dateFormat)
        : undefined,
      received_company_name: data?.work_order?.company?.company_name,
      total_paid_amount: data?.total_paid_amount,
      total_due: parseFloat(data?.total_due),
      total_payable: (
        parseFloat(data?.purchase_quantity_kg) *
        parseFloat(data?.purchase_rate_tk_per_kg)
      )?.toLocaleString(),
    });
  }, [data]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/sourcing/purchasePaymentList/${data?.gdn_number}/`, {
        headers: authHeader(),
      })
      .then((data) => {
        setPaymentInfoList(data?.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }, [data]);

  const handlePaymentDateChange = (date, dateString) => {
    setPaymentDate(dateString);
  };
  const handlePurchaseDateChange = (date, dateString) => {
    setPurchaseDate(dateString);
  };

  const handleSubmit = (submittedData) => {
    submittedData.payment_date = paymentDate;
    submittedData.bank_receipt_image = bankReceiptImageUrl;
    submittedData.purchase_date = purchaseDate ? purchaseDate : data?.purchaseDate;
    axios
      .patch(
        `${baseUrl}/sourcing/sourcingBuyingUpdate/${submittedData?.gdn_no}/`,
        submittedData,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        if (data?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Sourcing buying info update successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            handleSourcingBuyingModalClose();
            fetchSourcingBuying();
            form.resetFields();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleWorkOrderSelect = (value) => {
    const selectedWorkOrder = workOrderInfo?.find(
      (item) => item.work_order_id === value
    );
    form.setFieldsValue({
      received_company_name: selectedWorkOrder.company.company_name,
    });
  };

  const handleCancel = () => {
    handleSourcingBuyingModalClose();
    fetchSourcingBuying();
    form.resetFields();
  };

  const onPaymentAmountChange = (value) => {
    let totalPaid, totalDue;

    if (value === "") {
      totalPaid = parseFloat(data?.total_paid_amount) || 0;
      totalDue = parseFloat(data?.total_due) || 0;
    } else {
      const paymentAmount = parseFloat(value);
      totalPaid = parseFloat(data?.total_paid_amount) || 0;
      totalDue = parseFloat(data?.total_due) || 0;

      if (!isNaN(paymentAmount)) {
        // Check if the payment amount exceeds the total due
        if (paymentAmount > totalDue) {
          // If the payment amount exceeds the total due, set it to total due
          totalPaid += totalDue;
          totalDue = 0;
        } else {
          // Otherwise, proceed with the normal payment calculation
          totalPaid += paymentAmount;
          totalDue -= paymentAmount;
        }
      }
    }

    form.setFieldsValue({
      total_paid_amount: totalPaid.toFixed(2),
      total_due: totalDue.toFixed(2),
    });
  };

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };
    const s3 = new awsS3({
      accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });
      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const paymentInfoColumn = [
    {
      title: "Paid Amount",
      render: (record) => record?.payment_amount?.toLocaleString(),
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
    },
    {
      title: "Bank Receipt Image",
      render: (record) => (
        <div>
          <Image
            src={record?.bank_receipt}
            alt="Bank Receipt Image"
            width={100}
          />
        </div>
      ),
    },
  ];

  return (
    <Modal
      width="55%"
      open={visible}
      onCancel={handleCancel}
      footer={false}
      title="Souring Buying Information"
    >
      <Form
        className="register-form mt-5"
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item hasFeedback label="Purchase Date" name="purchase_date">
            <DatePicker
              format="YYYY-MM-DD"
              onChange={handlePurchaseDateChange}
            />
          </Form.Item>
        </Col>
        <header className="my-3" style={{ fontSize: 16 }}>
          <strong>Seller Info :</strong>
        </header>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Seller Name"
              name="seller_name"
              rules={[{ required: true, message: "Seller's name required" }]}
            >
              <Input placeholder="Seller Name" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Seller ID"
              name="seller_id"
              rules={[{ required: true, message: "Seller's ID required" }]}
            >
              <Input placeholder="Seller ID" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Seller Phone Number"
              name="phoneNumber"
            >
              <Input
                placeholder="Seller Phone Number"
                type="tel"
                rules={[
                  { required: true, message: "Seller's number required" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              label="Seller Type"
              name="seller_type"
              rules={[{ required: true, message: "Seller type required" }]}
            >
              <Input placeholder="Seller Type" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="District"
              name="district"
              rules={[
                { required: true, message: "Seller's District required" },
              ]}
            >
              <Input placeholder="District" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Upazila"
              name="upazila"
              rules={[{ required: true, message: "Seller's upazila required" }]}
            >
              <Input placeholder="Upazila" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Union"
              name="union"
              rules={[{ required: true, message: "Seller's name required" }]}
            >
              <Input placeholder="Union" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item hasFeedback label="Village" name="village">
              <Input placeholder="Village Name" readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Seller Bank Name"
              name="seller_bank_name"
              rules={[
                {
                  required: true,
                  message: "Please enter seller bank name",
                },
              ]}
            >
              <Input placeholder="Bank Name" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Seller Bank A/C Name"
              name="seller_account_name"
              rules={[
                {
                  required: true,
                  message: "Please enter seller bank A/C name",
                },
              ]}
            >
              <Input placeholder="Bank A/C Name" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Seller A/C No"
              name="seller_account_no"
              rules={[
                {
                  required: true,
                  message: "Please enter seller A/C no",
                },
              ]}
            >
              <Input placeholder="A/C No" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Routing No"
              name="routing_no"
              rules={[
                {
                  required: true,
                  message: "Please enter routing no",
                },
              ]}
            >
              <Input placeholder="Routing No" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Product Name"
              name="product_name"
              rules={[
                {
                  required: true,
                  message: "Product name required",
                },
              ]}
            >
              <Input placeholder="Product Name" readOnly></Input>
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Purchase Quantity (Kg)"
              name="purchase_quantity"
              rules={[
                {
                  required: true,
                  message: "Purchase quantity required",
                },
              ]}
            >
              <Input placeholder="Purchase Quantity (Kg)" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="ANL Purchased Rate(Taka/Kg)"
              name="anl_purchased_rate"
              rules={[
                {
                  required: true,
                  message: "ANL purchased rate required",
                },
              ]}
            >
              <Input placeholder="ANL Purchased Rate" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="GDN No"
              name="gdn_no"
              rules={[
                {
                  required: true,
                  message: "GDN Number required",
                },
              ]}
            >
              <Input placeholder="GDN No" readOnly />
            </Form.Item>
          </Col>
        </Row>

        <header className="my-3" style={{ fontSize: 16 }}>
          <strong>Quality Check Info :</strong>
        </header>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Moisture"
              name="moisture"
              rules={[
                {
                  required: true,
                  message: "Moisture level required",
                },
              ]}
            >
              <Input placeholder="Moisture" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item hasFeedback label="Dust" name="dust">
              <Input placeholder="Dust" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item hasFeedback label="Fungus" name="fungus">
              <Input placeholder="Fungus" readOnly />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Comment"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Comment required",
                },
              ]}
            >
              <Input placeholder="Comment" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <header className="mb-3" style={{ fontSize: 16 }}>
          <strong>Truck/Trolly Driver Info :</strong>
        </header>

        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Truck/Trolly Driver Name"
              name="driver_name"
              rules={[
                {
                  required: true,
                  message: "Please enter driver name",
                },
              ]}
            >
              <Input placeholder="Truck/Trolly Driver Name" />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Driver Number"
              name="driver_number"
              rules={[
                {
                  required: true,
                  message: "Please enter driver's phone number",
                },
              ]}
            >
              <Input placeholder="Driver Phone Number" type="tel" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Number of Sac"
              name="number_of_sac"
              initialValue=""
            >
              <Input placeholder="Number of Sac" readOnly />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Truck No"
              name="truck_no"
              initialValue=""
            >
              <Input placeholder="Truck No" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Transport Cost"
              name="transport_cost_tk"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: "Please enter transport cost",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/, // Regular expression to match English characters and spaces
                  message: "Only English numbers",
                },
              ]}
            >
              <Input placeholder="Transport Cost" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Transport Rate(Taka/kg)"
              name="transport_rate_tk_per_kg"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: "Please enter transport rate per kg",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/, // Regular expression to match English characters and spaces
                  message: "Only English numbers",
                },
              ]}
            >
              <Input placeholder="Transport Rate" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              label="Transport Rent Status"
              name="transport_rent_status"
              initialValue=""
            >
              <Select placeholder="Select Transport Rent Status">
                <Option value="N/A">N/A</Option>
                <Option value="Paid">Paid</Option>
                <Option value="Pending">Pending</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        {data?.sourcing_type === "Direct" ? (
          <>
            <header className="mb-3" style={{ fontSize: 16 }}>
              <strong>Company Info :</strong>
            </header>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col lg={6}>
                <Form.Item
                  hasFeedback
                  label="WO No"
                  name="work_order"
                  rules={[
                    {
                      required: true,
                      message: "please select work order",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select work order"
                    onChange={handleWorkOrderSelect}
                  >
                    {workOrderInfo?.map((workOrder) => (
                      <Option
                        key={workOrder.work_order_id}
                        value={workOrder.work_order_id}
                      >
                        {workOrder.work_order_id}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Form.Item
                  label="Received Company Name"
                  name="received_company_name"
                  rules={[
                    {
                      required: true,
                      message: "Received company name required",
                    },
                  ]}
                >
                  <Input placeholder="Received company name" disabled />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        <Divider />
        <header className="mb-3" style={{ fontSize: 16 }}>
          <strong>Payment Info:</strong>
        </header>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Total Payable"
              name="total_payable"
              rules={[
                {
                  required: true,
                  message: "Total payable required",
                },
              ]}
            >
              <Input placeholder="Total Payable" disabled />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              hasFeedback
              label="Total Paid"
              name="total_paid_amount"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: "Total paid required",
                },
              ]}
            >
              <Input placeholder="Total Paid" disabled />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item hasFeedback label="Total Due" name="total_due">
              <Input placeholder="Total Due" disabled />
            </Form.Item>
          </Col>
        </Row>
        {paymentInfoList?.length > 0 && (
          <Table
            className="ms-1"
            columns={paymentInfoColumn}
            dataSource={paymentInfoList}
            size="small"
            rowKey={(record) => record?.created_at}
            pagination={{
              pageSize: 5,
            }}
          />
        )}
        {data?.payment_status === "Paid" ? null : (
          <Row className="mt-3" gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col lg={6}>
              <Form.Item
                hasFeedback
                label="Payment Amount"
                name="payment_amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter payment amount",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/, // Regular expression to match English characters and spaces
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input
                  placeholder="Payment Amount"
                  onChange={(e) => onPaymentAmountChange(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item
                hasFeedback
                label="ANL Payment Date"
                name="payment_date"
                rules={[
                  {
                    required: true,
                    message: "Please select payment date",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={handlePaymentDateChange}
                />
              </Form.Item>
            </Col>

            <Col lg={6}>
              <Form.Item
                hasFeedback
                label="Image of Bank Receipt"
                name="bank_receipt_image"
                valuePropName="fileList"
                placeholder="Upload bank receipt image"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Please insert bank receipt image",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setBankReceiptImageUrl)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                    style={{
                      backgroundColor: "#38512e",
                      borderColor: "#38512e",
                    }}
                  >
                    {uploading ? "Uploading" : "Upload Bank Receipt Image"}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Col lg={6}>
          <Form.Item label="ANL Payment Status" name="payment_status">
            <Select placeholder="Select ANL Payment Status">
              <Option value="Paid">Paid</Option>
              <Option value="Partially Paid">Partially Paid</Option>
              <Option value="Unpaid">Unpaid</Option>
            </Select>
          </Form.Item>
        </Col>

        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={6}>
            <Form.Item name="gdn_image" label="GDN Image">
              <Image src={data?.gdn_image} />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item name="weight_bill_image" label="Weight Bill Image">
              <Image src={data?.weight_bill_image} />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item name="seller_nid_image" label="Seller Nid Image">
              <Image src={data?.seller_nid_image} />
            </Form.Item>
          </Col>
        </Row>

        {data?.payment_status === "Paid" ? null : (
          <Col lg={6}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                style={{
                  backgroundColor: "#38512e",
                  borderColor: "#38512e",
                }}
              >
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        )}
      </Form>
    </Modal>
  );
}
