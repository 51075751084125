import React, { useEffect, useState } from "react";
import DailyUpdate from "../Sales/DailyUpdate/DailyUpdate";
import axios from "axios";
import "./DailyReport.css";
import SurveyTableComponent from "./SurveyTableComponent";
import RegistrationTableComponent from "./RegistrationTableComponent";
import SourcingTableComponent from "./SourcingTableComponent";
import baseUrl from "../../config";

export default function DailyReport() {
  const [locationWiseContractDetails, setLocationWiseContractDetails] =
    useState([]);
  const [wholesalerDailyUpdate, setWholesalerDailyUpdate] = useState();
  const [riceMillDailyUpdate, setRiceMillDailyUpdate] = useState();
  const [tradersDailyUpdate, setTradersDailyUpdate] = useState();
  const [farmerRegistrationDailyUpdate, setFarmerRegistrationDailyUpdate] =
    useState();
  const [retailerRegistrationDailyUpdate, setRetailerRegistrationDailyUpdate] =
    useState();

  const [LSPRegistrationDailyUpdate, setLSPRegistrationDailyUpdate] =
    useState();
  const [sourcingDailyUpdate, setSourcingDailyUpdate] = useState();
  const [dairySurveyDailyUpdate, setDairySurveyDailyUpdate] = useState();
  let startDate = new Date();
  let endDate = new Date();
  startDate.setDate(startDate.getDate() - 1);
  endDate.setDate(endDate.getDate() - 1);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbwjKPahrNWPfEtkwdq2zBYsfUPOr49ewhrVlromJeSBAeByk4mP-nZVgcZuYkEADDIL/exec?district=`
      )
      .then((data) => {
        setLocationWiseContractDetails(data?.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbxNQ9ZliPFy5XG1S0bvxPvzWWUbkBR-UwQHyH2WwilQooAq80B4ONheC7uIQDFREEdxiA/exec?date=${JSON.stringify(
          [endDate]
        )}`
      )
      .then((data) => {
        setWholesalerDailyUpdate(data?.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbwrXRHgy4ZlOLx2a1qhNJHE__IHH05_Vvo8zIMj47uChTACNGVAt9tZuiDko0tDFEoC/exec?date=${JSON.stringify(
          [endDate]
        )}`
      )
      .then((data) => {
        setRiceMillDailyUpdate(data?.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.get(`${baseUrl}/api/combined_data/`).then((data) => {
      countByLocation(data?.data?.traders, 'trader');
      countByLocation(data?.data?.farmers, 'farmer');
      countByLocation(data?.data?.retailers, 'retailer');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbznzbTR1ggnmV3qV5wqIvd064_vEEBJNmxifDeaCzIf3edGZk2ApuMdee52b-Q0udSKwQ/exec`
      )
      .then((data) => {
        setSourcingDailyUpdate(data?.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbyTH_Pt_z2C_E2H32HWzdIcGM6huWJ0lY3MXbeQAfIiyxHz89vsSWDuzT-HYqbXMkgD/exec?date=${JSON.stringify(
          [endDate]
        )}`
      )
      .then((data) => {
        setDairySurveyDailyUpdate(data?.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function countByLocation(data,type) {
    const allLocationCounts = {};
    const todaysLocationCounts = {};
    let todayCount = 0;
    let totalCount = 0;

    // Get today's date in the format "yyyy-mm-dd"
    const todayDate = new Date().toISOString().split("T")[0];

    // Loop through the data
    data.forEach((trader) => {
      const district = trader.district;
      const traderDate = trader.date ? trader.date.split("T")[0] : null;

      // Count traders by district
      if (allLocationCounts[district]) {
        allLocationCounts[district]++;
      } else {
        allLocationCounts[district] = 1;
      }

      // Check if the trader's date is today
      if (traderDate === todayDate) {
        if (todaysLocationCounts[district]) {
          todaysLocationCounts[district]++;
        } else {
          todaysLocationCounts[district] = 1;
        }
        todayCount++;
      }

      totalCount++;
    });

    // Format the results
    const allocationWiseData = Object.entries(allLocationCounts).map(
      ([location, count]) => ({
        location,
        count,
      })
    );
    const todaysLocationWiseData = Object.entries(todaysLocationCounts).map(
      ([location, count]) => ({
        location,
        count,
      })
    );

    const result = {
      allocationWiseData,
      todaysLocationWiseData,
      todaysCount: todayCount,
      totalCount,
    };
    if(type === "trader"){
      setTradersDailyUpdate(result);
    }
    if(type === "farmer"){
      setFarmerRegistrationDailyUpdate(result);
    }
    if(type === "retailer"){
      setRetailerRegistrationDailyUpdate(result);
    }
    
  }

  return (
    <div className="mt-5 container">
      <div className="salesUpdate">
        <p
          className="text-center shadow-sm m-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Cash Sales and Daily Stock Update</small>
          </strong>
        </p>
        <DailyUpdate></DailyUpdate>
      </div>
      <div className="productSourcingUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Contract Farming Update</small>
          </strong>
        </p>
        <table
          className="table table-bordered"
          style={{ fontSize: "12px", lineHeight: "10px" }}
        >
          <thead>
            <tr>
              <th scope="col">Location</th>
              <th scope="col">Season</th>
              <th scope="col">Crop</th>
              <th scope="col">Total Credit Amount</th>
              <th scope="col">Total Recovered</th>
              <th scope="col">Total Pending</th>
              <th scope="col">Done Count</th>
              <th scope="col">Pending Count</th>
              <th scope="col">Total Given</th>
              <th scope="col">Recovery Percentage</th>
              <th scope="col">Pending Percentage</th>
            </tr>
          </thead>
          <tbody>
            {locationWiseContractDetails?.map((item, index) => (
              <tr key={index}>
                <td>{item.key0}</td>
                <td>{item.key1}</td>
                <td>{item.key2}</td>
                <td>{parseFloat(item.key3).toLocaleString()}</td>
                <td>{parseFloat(item.key4).toLocaleString()}</td>
                <td>{parseFloat(item.key5).toLocaleString()}</td>
                <td>{item.key6}</td>
                <td>{item.key7}</td>
                <td>{item.key8}</td>
                <td>{(item.key9 * 100).toFixed(2)}%</td>
                <td>{(item.key10 * 100).toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="wholesalerUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Wholesaler Daily Update</small>
          </strong>
        </p>
        <SurveyTableComponent data={wholesalerDailyUpdate} />
      </div> */}
      {/* <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Rice Mill Daily Update</small>
          </strong>
        </p>
        <SurveyTableComponent data={riceMillDailyUpdate} />
      </div> */}
      <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Traders Daily Update</small>
          </strong>
        </p>
        <RegistrationTableComponent data={tradersDailyUpdate} />
      </div>
      <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Farmer Registration Daily Update</small>
          </strong>
        </p>
        <RegistrationTableComponent data={farmerRegistrationDailyUpdate} />
      </div>
      <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Retailer Registration Daily Update</small>
          </strong>
        </p>
        <RegistrationTableComponent data={retailerRegistrationDailyUpdate} />
      </div>
      {/* <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>LSP Registration Daily Update</small>
          </strong>
        </p>
        <RegistrationTableComponent data={LSPRegistrationDailyUpdate} />
      </div> */}
      {/* <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Sourcing Daily Update</small>
          </strong>
        </p>
        <SourcingTableComponent data={sourcingDailyUpdate} />
      </div> */}
      <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Dairy Survey Daily Update</small>
          </strong>
        </p>
        <SurveyTableComponent
          data={dairySurveyDailyUpdate}
          surveyType="dairy"
        />
      </div>

      <div className="riceMillUpdate">
        <p
          className="text-center shadow-sm mb-1 p-1"
          style={{ backgroundColor: "#d9d8d8" }}
        >
          <strong>
            <small>Price App Daily Update</small>
          </strong>
        </p>
      </div>
    </div>
  );
}
