import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Tabs,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import {
  EditOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import awsS3 from "aws-sdk/clients/s3";
import Swal from "sweetalert2";
import TextArea from "antd/es/input/TextArea";

export default function ProductList() {
  const [form] = Form.useForm();
  const [productUpdateForm] = Form.useForm();
  const { Option } = Select;
  const { Search } = Input;
  const [uploading, setUploading] = useState(false);
  const [productImageUrl, setProductImageUrl] = useState(null);
  const [updateProductImageUrl, setUpdateProductImageUrl] = useState(null);
  const [productList, setProductList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [productModal, setProductModal] = useState(false);
  const [productAddLoading, setProductAddLoading] = useState(false);
  const [searchedProduct, setSearchedProduct] = useState("");

  const s3 = new awsS3({
    accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
    secretAccessKey: "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",
    region: "ap-southeast-1",
  });

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });

      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const fetchProductList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/inventory/product_list/`, {
        headers: authHeader(),
        params: {
          page: currentPage,
          filter: searchedProduct,
        },
      });
      setTotalItems(response?.data?.count);
      setProductList(response?.data?.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchProductList();
  }, [searchedProduct, currentPage]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/category_list`, {
        headers: authHeader(),
      })
      .then((response) => {
        setCategoryList(response?.data || []);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/brand_list`, {
        headers: authHeader(),
      })
      .then((response) => {
        setBrandList(response?.data || []);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  const handleProductSearch = (value) => {
    setCurrentPage(1);
    setSearchedProduct(value);
  };

  const handleProductPageChange = (value) => {
    setCurrentPage(value);
  };

  const handleProductEditModal = (record) => {
    setProductModal(true);
    productUpdateForm.setFieldsValue({
      product_id: record?.product_id,
      product_name: record?.product_name,
      agri_type: record?.agri_type,
      category_id: record?.category?.category_id,
      brand_id: record?.brand?.brand_id,
      product_description: record?.product_description,
    });
    setUpdateProductImageUrl(record?.product_image || null);
  };
  const handleProductModalClose = () => {
    setProductModal(false);
    productUpdateForm.resetFields();
  };

  const handleProductDelete = (record) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to delete ${record?.product_name} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/inventory/product_delete/${record?.product_id}`, {
            headers: authHeader(),
          })
          .then((data) => {
            if (data?.status === 204) {
              Swal.fire({
                title: "Success",
                text: `${record?.product_name} deleted successfully`,
                icon: "success",
                confirmButtonText: "Ok",
              }).then(() => {
                fetchProductList();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error,
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };
  const handleSubmit = (data) => {
    data.product_image = productImageUrl;
    axios
      .post(
        `${baseUrl}/inventory/product_create/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response?.status === 201) {
          console.log(response);
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            fetchProductList();
            form.resetFields();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  const handleUpdate = (data) => {
    data.product_image = updateProductImageUrl;
    axios
      .patch(`${baseUrl}/inventory/product_update/${data?.product_id}`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Product Updated Successful",
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            setProductModal(false);
            fetchProductList();
            productUpdateForm.resetFields();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  const productColumn = [
    {
      title: "Product ID",
      dataIndex: "product_id",
      width: "15%",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      width: "20%",
    },
    {
      title: "Category",
      render: (record) => record?.category?.category_name,
      width: "15%",
    },
    {
      title: "Agri Type",
      dataIndex: "agri_type",
      width: "15%",
    },
    {
      title: "Brand",
      render: (record) => record?.brand?.brand_name,
      width: "20%",
    },
    {
      title: "Action",
      render: (record) => (
        <div className="d-flex">
          <Button
            className="d-flex align-items-center"
            onClick={() => handleProductEditModal(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            className="d-flex align-items-center"
            onClick={() => handleProductDelete(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
      width: "15%",
    },
  ];

  const items = [
    {
      key: "Product List",
      label: (
        <div
          className="text-center"
          style={{ width: "710px", fontSize: 18, color: "#203509" }}
        >
          Product List
        </div>
      ),
      children: (
        <div className="pe-2 pb-3">
          <p
            className="text-center py-2 border"
            style={{ backgroundColor: "lightgray" }}
          >
            PRODUCT LIST
          </p>
          <div className="m-2 mt-4 d-flex justify-content-end align-items-center">
            <Search
              className="w-25"
              placeholder="search here..."
              allowClear
              onSearch={handleProductSearch}
              enterButton={
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#38512e",
                    borderColor: "#38512e",
                  }}
                >
                  Search
                </Button>
              }
            />
          </div>
          <Table
            className="mt-4 border"
            columns={productColumn}
            dataSource={productList}
            rowKey={(record) => record.product_id}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10,
              onChange: handleProductPageChange,
            }}
          />
        </div>
      ),
    },
    {
      key: "Add Product",
      label: (
        <div
          className="text-center"
          style={{ width: "710px", fontSize: 18, color: "#203509" }}
        >
          Add Product
        </div>
      ),
      children: (
        <div className="pe-2 pb-3">
          <p
            className="text-center py-2 border"
            style={{ backgroundColor: "lightgray" }}
          >
            ADD PRODUCT
          </p>
          <div className="w-50 m-auto shadow-sm border p-3">
            <Form form={form} onFinish={handleSubmit} layout="horizontal">
              <Form.Item
                label="Product ID"
                name="product_id"
                labelCol={{ span: 5 }}
                rules={[{ required: true, message: "Please enter product id" }]}
              >
                <Input placeholder="Product ID" />
              </Form.Item>
              <Form.Item
                label="Product Name"
                name="product_name"
                labelCol={{ span: 5 }}
                rules={[{ required: true, message: "Please enter product id" }]}
              >
                <Input placeholder="Product Name" />
              </Form.Item>
              <Form.Item
                label="Agri Type"
                name="agri_type"
                labelCol={{ span: 5 }}
                rules={[{ required: true, message: "Please select Agri Type" }]}
              >
                <Select placeholder="Select Agri Type">
                  <Option value="Crop">Crop</Option>
                  <Option value="Livestock">Livestock</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Brand"
                name="brand_id"
                labelCol={{ span: 5 }}
                rules={[{ required: true, message: "Please select Brand" }]}
              >
                <Select
                  placeholder="Select Brand"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {brandList?.map((brand) => (
                    <Option value={brand?.brand_id} key={brand?.brand_id}>
                      {brand?.brand_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Category"
                name="category_id"
                labelCol={{ span: 5 }}
                rules={[{ required: true, message: "Please select Category" }]}
              >
                <Select
                  placeholder="Select Category"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {categoryList?.map((category) => (
                    <Option
                      value={category?.category_id}
                      key={category?.category_id}
                    >
                      {category?.category_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Product Description"
                name="product_description"
                labelCol={{ span: 5 }}
                initialValue=""
              >
                <TextArea placeholder="Product Description" />
              </Form.Item>
              <Form.Item
                className="ms-5"
                name="product_image"
                labelCol={{ span: 5 }}
                valuePropName="fileList"
                placeholder="Upload product image"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 0) {
                        const file = value[0];
                        const fileType = file.type;
                        const acceptedFormats = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "image/heic",
                        ];
                        if (acceptedFormats.includes(fileType)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Upload
                  beforeUpload={() => false}
                  onChange={(info) =>
                    handleImageUpload(info, setProductImageUrl)
                  }
                  listType="picture"
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    loading={uploading}
                    style={{ backgroundColor: "#203509", color: "#ffffff" }}
                  >
                    {uploading ? "Uploading" : "Upload Product Image"}
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item className="d-flex justify-content-end">
                <Button
                  htmlType="submit"
                  disabled={productAddLoading}
                  style={{ backgroundColor: "#203509", color: "#ffffff" }}
                >
                  Submit
                  {productAddLoading && (
                    <Spin size="small" style={{ marginLeft: 8 }} />
                  )}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        open={productModal}
        onCancel={handleProductModalClose}
        title="Update Product"
        footer={false}
        width="40%"
      >
        <Form
          form={productUpdateForm}
          onFinish={handleUpdate}
          layout="horizontal"
        >
          <Form.Item
            label="Product ID"
            name="product_id"
            labelCol={{ span: 5 }}
            rules={[{ required: true, message: "Product ID required" }]}
          >
            <Input placeholder="Product ID" readOnly />
          </Form.Item>
          <Form.Item
            label="Product Name"
            name="product_name"
            labelCol={{ span: 5 }}
            rules={[{ required: true, message: "Please enter product id" }]}
          >
            <Input placeholder="Product Name" />
          </Form.Item>
          <Form.Item
            label="Agri Type"
            name="agri_type"
            labelCol={{ span: 5 }}
            rules={[{ required: true, message: "Please select Agri Type" }]}
          >
            <Select placeholder="Select Agri Type">
              <Option value="Crop">Crop</Option>
              <Option value="Crop">Livestock</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Brand"
            name="brand_id"
            labelCol={{ span: 5 }}
            rules={[{ required: true, message: "Please select Brand" }]}
          >
            <Select
              placeholder="Select Brand"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {brandList?.map((brand) => (
                <Option value={brand?.brand_id} key={brand?.brand_id}>
                  {brand?.brand_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Category"
            name="category_id"
            labelCol={{ span: 5 }}
            rules={[{ required: true, message: "Please select Category" }]}
          >
            <Select
              placeholder="Select Category"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {categoryList?.map((category) => (
                <Option
                  value={category?.category_id}
                  key={category?.category_id}
                >
                  {category?.category_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Product Description"
            name="product_description"
            labelCol={{ span: 5 }}
            initialValue=""
          >
            <TextArea placeholder="Product Description" />
          </Form.Item>
          {updateProductImageUrl ? (
            <Form.Item label="Product Image" labelCol={{ span: 5 }}>
              <Image src={updateProductImageUrl} />
            </Form.Item>
          ) : null}
          <Form.Item
            className="ms-5"
            name="product_image"
            labelCol={{ span: 5 }}
            valuePropName="fileList"
            placeholder="Upload product image"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 0) {
                    const file = value[0];
                    const fileType = file.type;
                    const acceptedFormats = [
                      "image/jpeg",
                      "image/png",
                      "image/jpg",
                      "image/heic",
                    ];
                    if (acceptedFormats.includes(fileType)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                      );
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              beforeUpload={() => false}
              onChange={(info) =>
                handleImageUpload(info, setUpdateProductImageUrl)
              }
              listType="picture"
            >
              <Button
                type="primary"
                icon={<UploadOutlined />}
                loading={uploading}
                style={{ backgroundColor: "#203509", color: "#ffffff" }}
              >
                {uploading ? "Uploading" : "Upload Product Image"}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item className="d-flex justify-content-end">
            <Button
              htmlType="submit"
              disabled={productAddLoading}
              style={{ backgroundColor: "#203509", color: "#ffffff" }}
            >
              Update
              {productAddLoading && (
                <Spin size="small" style={{ marginLeft: 8 }} />
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className="consultationDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>PRODUCT LIST</h3>
        </div>
        <div className="consultationDashboardContainer shadow-sm rounded">
          <div className="mainView">
            <Tabs
              className="px-4 mt-4"
              defaultActiveKey="Product List"
              items={items}
            />
          </div>
        </div>
      </div>
    </>
  );
}
