import React from "react";

export default function SalesTableComponent({ data }) {
  return (
    <div>
      <p
        className="text-center shadow-sm mb-1"
        style={{ backgroundColor: "#d9d8d8" }}
      >
        <strong>
          <small>Cash Sales</small>
        </strong>
      </p>
      <table
        className="table table-bordered"
        style={{ fontSize: "11px", lineHeight: "10px" }}
      >
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Product Name</th>
            <th scope="col">Farmer/Retailer</th>
            <th scope="col">Sell Quantity(Piece)</th>
            <th scope="col">Total Revenue(BDT)</th>
            <th scope="col">Total Amount (kg/L)</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{item.key0}</td>
              <td>{item.key9}</td>
              <td>{item.key7Sum}</td>
              <td>{item.key6Sum}</td>
              <td>{parseFloat(item.key8Sum).toFixed(1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
