import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Space,
  DatePicker,
} from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import "./Inventory.css";

export default function PurchaseProduct() {
  const { Option } = Select;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/center_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setCenterList(response?.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/supplier_list`, {
        headers: authHeader(),
      })
      .then((response) => {
        setSupplierList(response?.data || []);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/purchase_product_list`, {
        headers: authHeader(),
      })
      .then((response) => {
        setProductList(response?.data || []);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  const handleCenter = (value) => {
    setSelectedCenter(value);
  };

  const handlePriceChange = (value, index) => {
    const newValues = { ...form.getFieldsValue() };
    const rate = value === "" ? 0 : parseFloat(value);
    const discount = parseFloat(newValues.products[index].discount) || 0;
    const quantity = parseFloat(newValues.products[index].quantity) || 0;
    const calculatedTotalPrice = rate * quantity - discount;

    newValues.products[index].product_total_price =
      calculatedTotalPrice?.toFixed(2);

    form.setFieldsValue({ ...newValues });
    calculateTotalPrice();
  };

  const handleQuantityChange = (value, index) => {
    const newValues = { ...form.getFieldsValue() };
    const rate = parseFloat(newValues.products[index].purchase_price) || 0;
    const discount = parseFloat(newValues.products[index].discount) || 0;
    const quantity = value === "" ? 0 : parseInt(value);
    const calculatedTotalPrice = rate * quantity - discount;

    newValues.products[index].product_total_price =
      calculatedTotalPrice?.toFixed(2);

    form.setFieldsValue({ ...newValues });
    calculateTotalPrice();
  };

  const handleDiscountAdd = (value, index) => {
    const newValues = form.getFieldsValue();
    const quantity = newValues.products[index].quantity;
    const rateKey = newValues.products[index].purchase_price;

    const calculatedTotalPrice = rateKey * quantity;

    if (value) {
      const discountedTotalPrice = Math.max(calculatedTotalPrice - value, 0);
      newValues.products[index].product_total_price =
        discountedTotalPrice?.toFixed(2);
    } else {
      newValues.products[index].product_total_price =
        calculatedTotalPrice?.toFixed(2);
    }

    form.setFieldsValue(newValues);
    calculateTotalPrice();
  };

  const calculateTotalPrice = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalPriceSum = products.reduce((sum, product) => {
      return sum + (parseFloat(product.product_total_price) || 0);
    }, 0);

    form.setFieldValue("total_price", newTotalPriceSum);
    handleTotalPaidChange();
  };

  const handleTotalPaidChange = () => {
    const totalPaid = parseFloat(form.getFieldValue("total_paid")) || 0;
    const totalPrice = parseFloat(form.getFieldValue("total_price")) || 0;

    const due = Math.max(totalPrice - totalPaid, 0);

    form.setFieldsValue({
      due: due.toFixed(2),
    });
  };
  const handleSubmit = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/inventory/purchase_create/${selectedCenter}/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((result) => {
        setLoading(false);
        if (result?.status === 201) {
          Swal.fire({
            title: "Success",
            text: result?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            form.resetFields();
            form.setFieldsValue({
              products: [],
            });
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h3 style={{ color: "#38512e" }}>PURCHASE PRODUCT</h3>
      </div>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
          <div
            className="shadow-sm p-3"
            style={{ border: "1px solid #203509" }}
          >
            <Form
              form={form}
              color="#203509"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <div className="d-flex justify-content-between">
                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="center_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select center",
                      },
                    ]}
                  >
                    <Select placeholder="Select Center" onChange={handleCenter}>
                      {centerList?.map((center) => (
                        <Option
                          value={center?.center?.employee_id}
                          key={center?.center?.employee_id}
                        >
                          {center?.center_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="work_order"
                    rules={[
                      {
                        required: true,
                        message: "Please insert work order",
                      },
                    ]}
                  >
                    <Input placeholder="Work Order" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "250px" }}>
                  <Form.Item
                    hasFeedback
                    name="supplier"
                    rules={[
                      {
                        required: true,
                        message: "Please select supplier",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Supplier"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {supplierList?.map((supplier) => (
                        <Option
                          value={supplier?.supplier_id}
                          key={supplier?.supplier_id}
                        >
                          {supplier?.supplier_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="challan"
                    rules={[
                      {
                        required: true,
                        message: "Please insert challan",
                      },
                    ]}
                  >
                    <Input placeholder="Challan" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="receive_date"
                    rules={[
                      {
                        required: true,
                        message: "Please insert receive date",
                      },
                    ]}
                  >
                    <DatePicker format="YYYY-MM-DD" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="grn"
                    rules={[
                      {
                        required: true,
                        message: "Please insert GRN",
                      },
                    ]}
                  >
                    <Input placeholder="GRN" />
                  </Form.Item>
                </Col>
              </div>

              <Form.List name="products">
                {(fields, { add, remove }) => (
                  <div
                    className="p-3 mb-5"
                    style={{ color: "#203509", border: "1px solid #203509" }}
                  >
                    <Col lg={4}>
                      <Form.Item>
                        <Button
                          type="default"
                          onClick={() => add()}
                          block
                          size="small"
                          icon={<PlusOutlined />}
                          style={{ color: "#000000" }}
                        >
                          Add product
                        </Button>
                      </Form.Item>
                    </Col>
                    {fields.length === 0 && (
                      <Space key="initial" align="baseline">
                        <Col style={{ width: "200px" }}>
                          <Form.Item
                            label="Product Name"
                            name={[0, `product_name`]}
                            rules={[
                              {
                                required: true,
                                message: "Product is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select product name"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {productList?.map((product, index) => (
                                <Option key={index} value={product.product_id}>
                                  {product.product_name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="MRP"
                            name={[0, `mrp`]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter MRP",
                              },
                              {
                                pattern: /^[0-9]+(\.[0-9]+)?$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input placeholder="MRP" />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="Price"
                            name={[0, `purchase_price`]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter product price",
                              },
                              {
                                pattern: /^[0-9]+(\.[0-9]+)?$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Price"
                              onChange={(e) =>
                                handlePriceChange(e.target.value, 0)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="Quantity"
                            name={[0, `quantity`]}
                            rules={[
                              {
                                required: true,
                                message: "Purchase quantity required",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Quantity"
                              onChange={(e) =>
                                handleQuantityChange(e.target.value, 0)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "200px" }}>
                          <Form.Item
                            label="Expiry Date"
                            name={[0, `expiry_date`]}
                            rules={[
                              {
                                required: true,
                                message: "Please insert expiry date",
                              },
                            ]}
                          >
                            <DatePicker format="YYYY-MM-DD" />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="Discount"
                            name={[0, `discount`]}
                            initialValue={0}
                            rules={[
                              {
                                pattern: /^[0-9]+$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Discount"
                              onChange={(e) =>
                                handleDiscountAdd(e.target.value, 0)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "200px" }}>
                          <Form.Item
                            label="Product Total Price"
                            name={[0, `product_total_price`]}
                            rules={[
                              {
                                required: true,
                                message: "Product Total Price required",
                              },
                              {
                                pattern: /^[0-9]+(\.[0-9]+)?$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input placeholder="Product Total Price" readOnly />
                          </Form.Item>
                        </Col>
                      </Space>
                    )}
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space key={key} align="baseline">
                        <Row gutter={[8, 16, 24, 32]}>
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              {...restField}
                              label="Product Name"
                              name={[name, `product_id`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select product name"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {productList?.map((product, index) => (
                                  <Option
                                    key={index}
                                    value={product.product_id}
                                  >
                                    {product.product_name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              label="MRP"
                              name={[name, `mrp`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter MRP",
                                },
                                {
                                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input placeholder="MRP" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="Price"
                              name={[name, `purchase_price`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter product price",
                                },
                                {
                                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Price"
                                onChange={(e) =>
                                  handlePriceChange(e.target.value, index)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="Quantity"
                              name={[name, `quantity`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Purchase quantity required",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Quantity"
                                onChange={(e) =>
                                  handleQuantityChange(e.target.value, index)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              {...restField}
                              label="Expiry Date"
                              name={[name, `expiry_date`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please insert expiry date",
                                },
                              ]}
                            >
                              <DatePicker format="YYYY-MM-DD" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="Discount"
                              name={[name, `discount`]}
                              initialValue={0}
                              rules={[
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Discount"
                                onChange={(e) =>
                                  handleDiscountAdd(e.target.value, index)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              {...restField}
                              label="Product Total Price"
                              name={[name, `product_total_price`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product Total Price required",
                                },
                                {
                                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Product Total Price"
                                readOnly
                              />
                            </Form.Item>
                          </Col>

                          {fields.length > 1 ? (
                            <Button
                              className="align-self-center"
                              onClick={() => {
                                form.setFieldsValue({
                                  total_price: undefined,
                                  due: undefined,
                                  total_paid: undefined,
                                });
                                remove(name);
                                calculateTotalPrice();
                              }}
                              style={{
                                backgroundColor: "#c21807",
                                color: "#ffffff",
                              }}
                            >
                              <MinusCircleOutlined />
                            </Button>
                          ) : null}
                        </Row>
                      </Space>
                    ))}
                  </div>
                )}
              </Form.List>
              <p
                className="text-center"
                style={{ color: "#203509", fontSize: 16 }}
              >
                {" "}
                <strong>Payment Method </strong>
              </p>
              <div className="d-flex shadow-sm mb-4">
                <div
                  className="w-50 pt-4 d-flex justify-content-center"
                  style={{ color: "#203509", border: "1px solid #203509" }}
                >
                  <Form.Item className="ms-2">
                    <Input
                      className="py-2 text-center custom-placeholder-color"
                      placeholder="Cash"
                      disabled
                      style={{ color: "#203509", borderColor: "#203509" }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="ms-2"
                    name="total_paid"
                    rules={[
                      {
                        required: true,
                        message: "Total paid required",
                      },
                      {
                        pattern: /^[0-9]+(\.[0-9]+)?$/,
                        message: "Only english number",
                      },
                    ]}
                  >
                    <Input
                      className="py-2 text-center"
                      placeholder="Paid"
                      onChange={handleTotalPaidChange}
                      style={{ color: "#203509", borderColor: "#203509" }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="w-50 pt-4"
                  style={{ color: "#203509", border: "1px solid #203509" }}
                >
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Total"
                        disabled
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="total_price"
                      rules={[
                        {
                          required: true,
                          message: "Total price missing",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Total Price"
                        readOnly
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Due"
                        disabled
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                    <Form.Item className="ms-2" name="due" initialValue={0}>
                      <Input
                        className="py-2 text-center"
                        placeholder="Due Amount"
                        readOnly
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Form.Item className="d-flex justify-content-end ">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  style={{ color: "#ffffff", backgroundColor: "#203509" }}
                >
                  Submit
                  {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
