import React, { useContext, useState } from "react";
import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import InventoryIcon from "@mui/icons-material/Inventory";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import StorageIcon from "@mui/icons-material/Storage";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import BadgeIcon from "@mui/icons-material/Badge";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListIcon from "@mui/icons-material/List";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import PinDropIcon from "@mui/icons-material/PinDrop";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { AppRegistrationOutlined } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./SideBar.css";
import authService from "../Authentication/authService";
import { loginContext } from "../../App";
import Groups3Icon from "@mui/icons-material/Groups3";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PollIcon from "@mui/icons-material/Poll";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddchartIcon from "@mui/icons-material/Addchart";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FactoryIcon from "@mui/icons-material/Factory";
import LaptopIcon from "@mui/icons-material/Laptop";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MapIcon from "@mui/icons-material/Map";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RouteIcon from "@mui/icons-material/Route";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import StorefrontIcon from "@mui/icons-material/Storefront";
import GradingIcon from "@mui/icons-material/Grading";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GridViewIcon from "@mui/icons-material/GridView";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BackupTableOutlinedIcon from "@mui/icons-material/BackupTableOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";

export default function SideBar() {
  const { collapseSidebar } = useProSidebar();
  const [IS_SIDEBAR_OPEN, SET_IS_SIDEBAR_OPEN] = useState(true);
  const { userPermissions, setuserPermissions } = useContext(loginContext);
  const logoutHandle = () => {
    authService.logout();
    window.location.reload(false);
  };

  const [activePage, setActivePage] = useState(null);

  function handleActive(event) {
    if (!event.target.classList.value.includes("active")) {
      event.target.classList.toggle("active");
      if (activePage) activePage.classList.remove("active");
      setActivePage(event.target);
    }
  }
  const webmailUrl = "http://webmail.agrigate.asia/";
  const oneThreadUrl = "https://onethread.agrigate.network/";

  return (
    <>
      <div style={{ display: "flex", height: "100%" }}>
        <Sidebar>
          <h1
            className="text-center mt-3"
            style={{
              color: "#38512e",
            }}
          >
            {IS_SIDEBAR_OPEN ? "Agrigate" : null}
            <MenuIcon
              onClick={() => {
                collapseSidebar();
                SET_IS_SIDEBAR_OPEN(!IS_SIDEBAR_OPEN);
              }}
              style={{ fontSize: "30" }}
            />
          </h1>
          <Menu>
            {userPermissions?.includes("view_home_menu") && (
              <MenuItem
                className="py-3 NavigationLink"
                icon={<DashboardIcon style={{ color: "#8f92b1" }} />}
                component={
                  <Link
                    className="NavigationLink"
                    to="/home"
                    onClick={handleActive}
                  />
                }
              >
                {IS_SIDEBAR_OPEN ? "Home" : null}
              </MenuItem>
            )}
            {userPermissions?.includes("view_webmail_menu") && (
              <MenuItem
                className="pb-3 NavigationLink"
                icon={<LocalPostOfficeIcon style={{ color: "#8f92b1" }} />}
                component={
                  <a
                    className="NavigationLink"
                    href={webmailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleActive}
                  >
                    {IS_SIDEBAR_OPEN ? "WebMail" : null}
                  </a>
                }
              >
                {IS_SIDEBAR_OPEN ? "WebMail" : null}
              </MenuItem>
            )}
            {userPermissions?.includes("view_general_meeting") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<VideoCameraBackIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Meeting" : null}
              >
                {userPermissions?.includes("view_general_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<Groups3Icon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/generalMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "General" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_hr_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<HandshakeIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/hrMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "HR" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_operations_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<Diversity3Icon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/opsMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Operations" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sourcing_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<LocalShippingIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/sourcingMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Sourcing" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sales_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<ShoppingCartIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/salesMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Sales" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_tech_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<LaptopIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/techMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Tech" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_accounts_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<AccountBalanceIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/accountsMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Accounts" : null}
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_operations_menu") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<Groups3Icon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Operations" : null}
              >
                {userPermissions?.includes("view_operations_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<GridViewIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/operationsDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Dashboard" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_consultation_menu") && (
                  <SubMenu
                    className="NavigationLink"
                    icon={
                      <AnalyticsOutlinedIcon style={{ color: "#8f92b1" }} />
                    }
                    label={IS_SIDEBAR_OPEN ? "Consultation" : null}
                  >
                    {userPermissions?.includes("view_consultation_menu") && (
                      <MenuItem
                        className="NavigationLink"
                        icon={
                          <AnalyticsOutlinedIcon style={{ color: "#8f92b1" }} />
                        }
                        component={
                          <Link
                            className="NavigationLink"
                            to="/consultationDashboard"
                            onClick={handleActive}
                          />
                        }
                      >
                        {IS_SIDEBAR_OPEN ? "Admin" : null}
                      </MenuItem>
                    )}
                    {userPermissions?.includes("view_consultant_menu") && (
                      <MenuItem
                        className="NavigationLink"
                        icon={
                          <AppRegistrationOutlined
                            style={{ color: "#8f92b1" }}
                          />
                        }
                        component={
                          <Link
                            className="NavigationLink"
                            to="/consultant"
                            onClick={handleActive}
                          />
                        }
                      >
                        {IS_SIDEBAR_OPEN ? "Consultant" : null}
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
                <SubMenu
                  className="NavigationLink"
                  icon={<HistoryEduIcon style={{ color: "#8f92b1" }} />}
                  label={IS_SIDEBAR_OPEN ? "Contract Farming" : null}
                >
                  {userPermissions?.includes("view_contract_farming_menu") && (
                    <MenuItem
                      className="NavigationLink"
                      icon={<HistoryEduIcon style={{ color: "#8f92b1" }} />}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/contractFarming"
                          onClick={handleActive}
                        />
                      }
                    >
                      {IS_SIDEBAR_OPEN ? "Dashboard" : null}
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_contract_farming_reg") && (
                    <MenuItem
                      className="NavigationLink"
                      icon={
                        <AppRegistrationOutlined style={{ color: "#8f92b1" }} />
                      }
                      component={
                        <Link
                          className="NavigationLink"
                          to="/contractFarmingReg"
                          onClick={handleActive}
                        />
                      }
                    >
                      {IS_SIDEBAR_OPEN ? "Registration" : null}
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_contract_farming_menu") && (
                    <MenuItem
                      className="NavigationLink"
                      icon={<CheckBoxIcon style={{ color: "#8f92b1" }} />}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/productDemand"
                          onClick={handleActive}
                        />
                      }
                    >
                      {IS_SIDEBAR_OPEN ? "Product Demand" : null}
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_contract_farming_menu") && (
                    <MenuItem
                      className="NavigationLink"
                      icon={<AccessTimeIcon style={{ color: "#8f92b1" }} />}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/contractFarmingHistory"
                          onClick={handleActive}
                        />
                      }
                    >
                      {IS_SIDEBAR_OPEN ? "History" : null}
                    </MenuItem>
                  )}
                </SubMenu>
                {userPermissions?.includes("view_survey_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<PollIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/survey"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Survey" : null}
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_inventory_menu") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<InventoryIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Inventory" : null}
              >
                <SubMenu
                  className="NavigationLink"
                  icon={<PointOfSaleIcon style={{ color: "#8f92b1" }} />}
                  label={IS_SIDEBAR_OPEN ? "POS" : null}
                >
                  {userPermissions?.includes("view_gui_menu") && (
                    <MenuItem
                      icon={<ReceiptLongIcon style={{ color: "#8f92b1" }} />}
                      className="NavigationLink"
                      component={
                        <Link
                          className="NavigationLink"
                          to="/gui"
                          onClick={handleActive}
                        />
                      }
                    >
                      GUI
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_manage_sales_menu") && (
                    <MenuItem
                      icon={<ReceiptIcon style={{ color: "#8f92b1" }} />}
                      className="NavigationLink"
                      component={
                        <Link
                          className="NavigationLink"
                          to="/manageSales"
                          onClick={handleActive}
                        />
                      }
                    >
                      Manage Sales
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_pos_menu") && (
                    <MenuItem
                      icon={
                        <BackupTableOutlinedIcon style={{ color: "#8f92b1" }} />
                      }
                      className="NavigationLink"
                      component={
                        <Link
                          className="NavigationLink"
                          to="/customerLedger"
                          onClick={handleActive}
                        />
                      }
                    >
                      Customer Ledger
                    </MenuItem>
                  )}
                </SubMenu>
                {userPermissions?.includes("view_purchase_menu") && (
                  <SubMenu
                    className="NavigationLink"
                    icon={<LocalMallIcon style={{ color: "#8f92b1" }} />}
                    label={IS_SIDEBAR_OPEN ? "Purchase" : null}
                  >
                    {userPermissions?.includes(
                      "view_purchase_product_menu"
                    ) && (
                      <MenuItem
                        icon={
                          <ShoppingCartCheckoutIcon
                            style={{ color: "#8f92b1" }}
                          />
                        }
                        className="NavigationLink"
                        component={
                          <Link
                            className="NavigationLink"
                            to="/purchaseProduct"
                            onClick={handleActive}
                          />
                        }
                      >
                        Product
                      </MenuItem>
                    )}
                    {userPermissions?.includes("view_purchase_menu") && (
                      <MenuItem
                        icon={<ReceiptIcon style={{ color: "#8f92b1" }} />}
                        className="NavigationLink"
                        component={
                          <Link
                            className="NavigationLink"
                            to="/managePurchase"
                            onClick={handleActive}
                          />
                        }
                      >
                        Manage
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
                {userPermissions?.includes("view_stock_menu") && (
                  <MenuItem
                    icon={<ShowChartIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/stock"
                        onClick={handleActive}
                      />
                    }
                  >
                    Stock
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_sales_menu") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<ShoppingCartIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Sales" : null}
              >
                {userPermissions?.includes("view_sales_dashboard") && (
                  <MenuItem
                    icon={<GridViewIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/salesDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Dashboard" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_product_menu") && (
                  <MenuItem
                    icon={<ListAltIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/productList"
                        onClick={handleActive}
                      />
                    }
                  >
                    Product
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sales_dashboard") && (
                  <MenuItem
                    icon={<ShoppingBasketIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/cashSales"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Sales Details" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_product_price") && (
                  <MenuItem
                    icon={<ListIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/productPrice"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Order Price List" : null}
                  </MenuItem>
                )}
                {/* {userPermissions?.includes("view_credit_status_menu") && (
                  <MenuItem
                    icon={<CreditCardIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/creditStatus"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Credit Status" : null}
                  </MenuItem>
                )} */}
                {userPermissions?.includes("view_order_taking_menu") && (
                  <MenuItem
                    icon={<AddchartIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/orderTaking"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Order Taking" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_order_taking_menu") && (
                  <MenuItem
                    icon={<GradingIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/orderFulfillment"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Order Fulfillment" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_route_map_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<ForkRightIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/routeMap"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Route Map" : null}
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_sourcing_menu") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<LocalShippingIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Sourcing" : null}
              >
                {userPermissions?.includes("view_product_sourcing_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<GridViewIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/sourcingDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Dashboard" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_product_sourcing_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<LocalShippingIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/productSourcing"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Product Sourcing" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_price_app_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<PriceCheckIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/priceApp"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Price App" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sourcing_product_list") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<ListIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/sourcingList"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Sourcing Products" : null}
                  </MenuItem>
                )}

                {userPermissions?.includes("view_sourcing_menu") && (
                  <SubMenu
                    className="NavigationLink"
                    icon={<WarehouseIcon style={{ color: "#8f92b1" }} />}
                    label={IS_SIDEBAR_OPEN ? "Warehouse" : null}
                  >
                    {userPermissions?.includes("view_sourcing_menu") && (
                      <MenuItem
                        icon={<InputIcon style={{ color: "#8f92b1" }} />}
                        className="NavigationLink"
                        component={
                          <Link
                            className="NavigationLink"
                            to="/warehouseStockIn"
                            onClick={handleActive}
                          />
                        }
                      >
                        Stock In
                      </MenuItem>
                    )}
                    {userPermissions?.includes("view_sourcing_menu") && (
                      <MenuItem
                        icon={<OutputIcon style={{ color: "#8f92b1" }} />}
                        className="NavigationLink"
                        component={
                          <Link
                            className="NavigationLink"
                            to="/warehouseStockOut"
                            onClick={handleActive}
                          />
                        }
                      >
                        Stock Out
                      </MenuItem>
                    )}
                  </SubMenu>
                )}

                {userPermissions?.includes("view_industrial_partner_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<FactoryIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/ipInfo"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Industrial Partner Info" : null}
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_employee_menu") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<EngineeringIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Employee" : null}
              >
                {userPermissions?.includes("view_create_user") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<PersonIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/user"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "User" : null}
                  </MenuItem>
                )}
                {userPermissions?.includes("view_create_employee_profile") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<BadgeIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/employee"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Employee List" : null}
                  </MenuItem>
                )}

                {/* {userPermissions?.includes("view_harmony_access") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<PinDropIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/harmony"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Harmony" : null}
                  </MenuItem>
                )} */}
              </SubMenu>
            )}

            {userPermissions?.includes("view_database_menu") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<StorageIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Databases" : null}
              >
                {userPermissions?.includes("view_database_menu") && (
                  <MenuItem
                    icon={<GridViewIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databaseDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    Dashboard
                  </MenuItem>
                )}
                {userPermissions?.includes("view_farmer_database") && (
                  <MenuItem
                    icon={<AgricultureIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/farmer"
                        onClick={handleActive}
                      />
                    }
                  >
                    Farmer
                  </MenuItem>
                )}
                {userPermissions?.includes("view_retailer_database") && (
                  <MenuItem
                    icon={
                      <StoreMallDirectoryIcon style={{ color: "#8f92b1" }} />
                    }
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/retailer"
                        onClick={handleActive}
                      />
                    }
                  >
                    Retailer
                  </MenuItem>
                )}
                {userPermissions?.includes("view_asp_database") && (
                  <MenuItem
                    icon={
                      <PrecisionManufacturingIcon
                        style={{ color: "#8f92b1" }}
                      />
                    }
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/asp"
                        onClick={handleActive}
                      />
                    }
                  >
                    ASP
                  </MenuItem>
                )}
                {userPermissions?.includes("view_lsp_database") && (
                  <MenuItem
                    icon={
                      <VolunteerActivismIcon style={{ color: "#8f92b1" }} />
                    }
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/lsp"
                        onClick={handleActive}
                      />
                    }
                  >
                    LSP
                  </MenuItem>
                )}
                {userPermissions?.includes("view_trader_database") && (
                  <MenuItem
                    icon={<StorefrontIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/trader"
                        onClick={handleActive}
                      />
                    }
                  >
                    Trader
                  </MenuItem>
                )}
                {userPermissions?.includes("view_agro_processor_database") && (
                  <MenuItem
                    icon={<CoffeeMakerIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/institutionalBuyer"
                        onClick={handleActive}
                      />
                    }
                  >
                    Institutional Buyer
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {/* {userPermissions?.includes("view_map_menu") && (
              <SubMenu
                className="NavigationLink pb-3"
                icon={<MapIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Map" : null}
              >
                {userPermissions?.includes("view_location_map_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<LocationOnIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/locationMap"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Location Map" : null}
                  </MenuItem>
                )}

                {userPermissions?.includes("view_map_tacking_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<RouteIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/tracking"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Map Tracking" : null}
                  </MenuItem>
                )}
              </SubMenu>
            )} */}

            {userPermissions?.includes("view_daily_report") && (
              <MenuItem
                className="pb-3 NavigationLink"
                icon={<AssignmentTurnedInIcon style={{ color: "#8f92b1" }} />}
                component={
                  <a
                    className="NavigationLink"
                    href={oneThreadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleActive}
                  >
                    {IS_SIDEBAR_OPEN ? "Agenda" : null}
                  </a>
                }
              >
                {IS_SIDEBAR_OPEN ? "Agenda" : null}
              </MenuItem>
            )}
            {/* {userPermissions?.includes("view_daily_report") && (
              <MenuItem
                className="pb-3 NavigationLink"
                icon={<SummarizeIcon style={{ color: "#8f92b1" }} />}
                component={
                  <Link
                    className="NavigationLink"
                    to="/dailyReport"
                    onClick={handleActive}
                  />
                }
              >
                {IS_SIDEBAR_OPEN ? "Daily Report" : null}
              </MenuItem>
            )} */}
          </Menu>
          <div className="text-center">
            <button
              className="logoutBtnDesign"
              style={{ padding: IS_SIDEBAR_OPEN ? "10px 60px" : "5px 5px" }}
              onClick={logoutHandle}
            >
              {IS_SIDEBAR_OPEN ? "Logout" : null}
              <LogoutIcon />
            </button>
          </div>
        </Sidebar>
      </div>
    </>
  );
}
