import React, { useEffect, useState } from "react";
import "./farmerReport.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import logo from "../../../assets/Agrigate.png";
import baseUrl from "../../../config";
import { Button, Input, Modal } from "antd";
import Swal from "sweetalert2";

export default function FarmerReport() {
  const [farmerProfileDetails, setFarmerProfileDetails] = useState([]);
  const [printBtnVisible, setPrintBtnVisible] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { farmer_id } = useParams();
  useEffect(() => {
    axios.get(`${baseUrl}/api/farmerProfile/${farmer_id}/`).then((data) => {
      console.log(data?.data);
      setFarmerProfileDetails(data?.data);
    });
  }, [farmer_id]);

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePhoneNumberCheck = () => {
    if (parseInt(phoneNumber) === parseInt(farmerProfileDetails?.phone)) {
      setOpenModal(false);
    } else {
      Swal.fire({
        title: "Error",
        text: "Phone number didn't match",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePrint = () => {
    setPrintBtnVisible(false);
    document.title = `Farmer Profile -${farmerProfileDetails?.farmer_id}`;
    setTimeout(() => {
      window.print();
      window.close();
      document.title = "Agrigate Dashboard";
      setPrintBtnVisible(true);
    }, 5000);
  };

  return (
    <>
      <div></div>
      <Modal
        title="Please enter registered farmer phone number (রেজিস্টার্ড ফার্মার এর মোবাইল নম্বর )"
        centered
        closable={false}
        open={openModal}
        footer={[
          <Button key="submit" type="primary" onClick={handlePhoneNumberCheck}>
            OK
          </Button>,
        ]}
      >
        <Input
          placeholder="Farmer Phone Number"
          inputMode="numeric"
          onChange={handlePhoneNumber}
        />
      </Modal>
      <div
        className="p-5 container"
        style={{ display: openModal ? "none" : "block" }}
      >
        <div className="text-center">
          <button className="printBtnDesign" onClick={handlePrint}>
            {printBtnVisible ? "Save PDF" : ""}
          </button>
        </div>

        <div id="pdfComponent">
          <section className="headSection">
            <div>
              <img
                src={logo}
                style={{ width: "100px", height: "100px" }}
                alt="agrigate"
              />
            </div>
            <div className="text-center">
              <h3>Agrigate Network Limited</h3>
              <h3>Farmers ID Card</h3>
            </div>
          </section>
          <section className="personalInformation">
            <div className="personalInformationLeftSection">
              <p
                style={{
                  textAlign: "center",
                  border: "solid 1px black",
                  backgroundColor: "lightgray",
                }}
              >
                <strong>Personal Information</strong>
              </p>
              <table className="InfoTable">
                <tbody>
                  <tr>
                    <td>
                      <strong>FID Number</strong>
                    </td>
                    <td>{farmerProfileDetails?.farmer_id}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <strong>Farmers Name</strong>
                    </td>
                    <td>{farmerProfileDetails?.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Phone Number</strong>
                    </td>
                    <td>0{farmerProfileDetails?.phone}</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Date Of Birth</strong>
                    </td>
                    <td>{farmerProfileDetails?.dob}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Gender</strong>
                    </td>
                    <td>{farmerProfileDetails?.gender}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>National ID Number</strong>
                    </td>
                    <td>{farmerProfileDetails?.nid}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Address</strong>
                    </td>
                    <td>
                      {farmerProfileDetails?.union?.union_name},{" "}
                      {farmerProfileDetails?.upazila?.upazila_name},{" "}
                      {farmerProfileDetails?.district?.district_name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Agricultural Activities</strong>
                    </td>
                    <td>{farmerProfileDetails?.agricultural_activities}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>GPS Location</strong>
                    </td>
                    <td>
                      {farmerProfileDetails?.latitude},
                      {farmerProfileDetails?.longitude}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="personalInformationRightSection ms-4 shadow">
              <img
                className="img-fluid w-100"
                // height="550"
                src={farmerProfileDetails.profile_image}
                alt="farmer"
              />
            </div>
          </section>
          <section className="bankingInformation">
            <p
              className="p-1"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>Banking Information</strong>
            </p>
            <table className="InfoTable">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <strong>Bank Name</strong>
                  </td>
                  {farmerProfileDetails?.bank_info?.map((item, index) => (
                    <td key={index + 1}>{item?.bank_name}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Bank Account Name</strong>
                  </td>
                  {farmerProfileDetails?.bank_info?.map((item, index) => (
                    <td key={index + 2}>{item?.account_name}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Branch Name</strong>
                  </td>
                  {farmerProfileDetails?.bank_info?.map((item, index) => (
                    <td key={index + 3}>{item?.branch_name}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Bank Account Number</strong>
                  </td>
                  {farmerProfileDetails?.bank_info?.map((item, index) => (
                    <td key={index + 4}>{item?.account_number}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Routing Number</strong>
                  </td>
                  {farmerProfileDetails?.bank_info?.map((item, index) => (
                    <td key={index + 5}>{item?.routing_number}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </section>
          <section className="agriculturalActivities">
            <p
              className="p-1"
              style={{
                textAlign: "center",
                border: "solid 1px black",
                backgroundColor: "lightgray",
              }}
            >
              <strong>Agricultural Activities</strong>
            </p>
            <p
              className="p-1"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>Land / Farm Information</strong>
            </p>
            <table className="InfoTable">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <strong>Own Land Size</strong>
                  </td>
                  {farmerProfileDetails?.property_info?.map((item, index) => (
                    <td key={index + 1}>{item.own_land_size}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Lease Land Size</strong>
                  </td>
                  {farmerProfileDetails?.property_info?.map((item, index) => (
                    <td key={index + 2}>{item.lease_land_size}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Land Type</strong>
                  </td>
                  {farmerProfileDetails?.property_info?.map((item, index) => (
                    <td key={index + 3}>{item.land_type}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Type of Farm</strong>
                  </td>
                  {farmerProfileDetails?.property_info?.map((item, index) => (
                    <td key={index + 4}>{item.farm_type}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong> Agrigate Contract Land Size </strong>
                  </td>
                  {farmerProfileDetails?.property_info?.map((item, index) => (
                    <td key={index + 5}>{item.agrigate_contract_land_size}</td>
                  ))}
                </tr>
              </tbody>
            </table>

            <p
              className="p-1"
              style={{
                marginTop: "50px",
                border: "solid 1px black",
                width: "30%",
              }}
            >
              <strong>Cropping Pattern</strong>
            </p>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Kharif-1 Season</th>
                  <th>Season Year</th>
                  <th>Kharif-2 Season</th>
                  <th>Season Year</th>
                  <th>Robi Season</th>
                  <th>Season Year</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.cropping_pattern?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.kharif1}</td>
                    {item.kharif1 ? <td>{item.season_year}</td> : <td></td>}
                    <td>{item.kharif2}</td>
                    {item.kharif2 ? <td>{item.season_year}</td> : <td></td>}
                    <td>{item.robi}</td>
                    {item.robi ? <td>{item.season_year}</td> : <td></td>}
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="InfoTable mt-5">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <strong>Agrigate Contract Crop Name</strong>
                  </td>
                  {farmerProfileDetails?.crop_name?.map((item, index) => (
                    <td key={index}>{item.crop_name}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </section>
          <section className="liveStockInfo">
            <p
              className="p-1"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>LiveStock Information</strong>
            </p>

            <p
              className="p-1"
              style={{ border: "solid 1px black", width: "20%" }}
            >
              Beef Cattle
            </p>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <tbody>
                <tr>
                  <th>Breed Name</th>
                  <th>Total Bull</th>
                </tr>

                <tr>
                  {farmerProfileDetails?.beef_info?.map((item, index) => (
                    <td key={index + 1}>{item.breed_name}</td>
                  ))}
                  {farmerProfileDetails?.beef_info?.map((item, index) => (
                    <td key={index + 1}>{item.total_bull}</td>
                  ))}
                </tr>
              </tbody>
            </table>

            <p
              className="p-1 mt-3"
              style={{ border: "solid 1px black", width: "20%" }}
            >
              Dairy Cattle
            </p>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Breed Name</th>
                  <th>Total Heifer</th>
                  <th>Total Pregnant Cow</th>
                  <th>Total Milking Cow</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {farmerProfileDetails?.dairy?.map((item, index) => (
                    <td key={index + 1}>{item.breed_name}</td>
                  ))}
                  {farmerProfileDetails?.dairy?.map((item, index) => (
                    <td key={index + 2}>{item.total_heifer}</td>
                  ))}
                  {farmerProfileDetails?.dairy?.map((item, index) => (
                    <td key={index + 3}>{item.total_pregnant_cow}</td>
                  ))}
                  {farmerProfileDetails?.dairy?.map((item, index) => (
                    <td key={index + 4}>{item.total_milking_cow}</td>
                  ))}
                </tr>
              </tbody>
            </table>

            <p
              className="p-1 mt-3"
              style={{ border: "solid 1px black", width: "20%" }}
            >
              Poultry
            </p>

            <table className="InfoTable">
              <tbody>
                <tr>
                  <td>
                    <strong>Types</strong>
                  </td>
                  <td>
                    <strong>Amount</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Broiler</strong>
                  </td>
                  {farmerProfileDetails?.polutry?.map((item, index) => (
                    <td key={index + 1}>{item.broiler}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Layer</strong>
                  </td>
                  {farmerProfileDetails?.polutry?.map((item, index) => (
                    <td key={index + 2}>{item.layer}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Sonali</strong>
                  </td>
                  {farmerProfileDetails?.polutry?.map((item, index) => (
                    <td key={index + 3}>{item.sonali}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Duck</strong>
                  </td>
                  {farmerProfileDetails?.polutry?.map((item, index) => (
                    <td key={index + 4}>{item.duck}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </section>
          <section className="agrigateInitiative">
            <p
              className="p-1"
              style={{
                textAlign: "center",
                border: "solid 1px black",
                backgroundColor: "lightgray",
              }}
            >
              <strong>Agrigate Initiative</strong>
            </p>
            <p
              className="p-1"
              style={{ border: "solid 1px black", width: "40%" }}
            >
              <strong>Product Buying Information</strong>
            </p>

            <div className="w-100 d-flex justify-content-between">
              <p
                className="p-1"
                style={{ border: "solid 1px black", width: "35%" }}
              >
                Agricultural Product Buying
              </p>
            </div>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Product Name</th>
                  <th>Quantity (KG)</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.crop_product_buying_info?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item?.sales_id}</td>
                      <td>{item?.invoice_products__product__product_name}</td>
                      <td>{item?.invoice_products__quantity}</td>
                      <td>{item?.invoice_products__product_total_price}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <div className="w-100 d-flex justify-content-between mt-4">
              <p
                className="p-1"
                style={{ border: "solid 1px black", width: "35%" }}
              >
                Livestock Product Buying
              </p>
            </div>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Product Name</th>
                  <th>Quantity (KG)</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.livestock_product_buying_info?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item?.sales_id}</td>
                      <td>{item?.invoice_products__product__product_name}</td>
                      <td>{item?.invoice_products__quantity}</td>
                      <td>{item?.invoice_products__product_total_price}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <div className="w-100 d-flex justify-content-between mt-4">
              <p
                className="p-1"
                style={{ border: "solid 1px black", width: "35%" }}
              >
                Product Selling Info
              </p>
            </div>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>GDN Number</th>
                  <th>Product Name</th>
                  <th>Quantity (KG)</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.product_Selling_info?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item?.gdn_number}</td>
                      <td>{item?.product_name}</td>
                      <td>{item?.purchase_quantity_kg}</td>
                      <td>{item?.purchase_rate_tk_per_kg}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <p
              className="p-1 mt-5"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>Contract Farming</strong>
            </p>
            <table className="InfoTable">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <strong>Season-Year</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 1}>{item?.year_season}</td>
                    )
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>Contract Farming Type</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 4}>{item?.contract_farming_type}</td>
                    )
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>Crop/Livestock Name</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 2}>{item?.crop_or_livestock_name}</td>
                    )
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>Project Name</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 3}>{item?.project_name}</td>
                    )
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>Land (Decimal) / Farm (sqft) Size</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 4}>{item?.land_or_farm_size}</td>
                    )
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>Group Name</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 5}>{item?.group_name}</td>
                    )
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>Location</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 6}>{item.district}</td>
                    )
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>BP Name</strong>
                  </td>
                  {farmerProfileDetails?.contract_farming_info?.map(
                    (item, index) => (
                      <td key={index + 7}>{item.bp_name}</td>
                    )
                  )}
                </tr>
              </tbody>
            </table>

            <p
              className="p-1 mt-5"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              Product Credit
            </p>

            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th>Payment Status</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.contract_product_credit?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item?.product_name}</td>
                      <td>{item?.quantity}</td>
                      <td>{item?.total_price}</td>
                      <td>{item?.payment_status}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <p
              className="p-1 mt-5"
              style={{ border: "solid 1px black", width: "40%" }}
            >
              Contract Product Selling Information
            </p>

            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Selling Amount</th>
                  <th>Price</th>
                  <th>GDN Number</th>
                  <th>Scale Number</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.contract_product_selling_info?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item?.product_name}</td>
                      <td>{item?.selling_quantity}</td>
                      <td>{item?.total_selling_price}</td>
                      <td>{item?.gdn_number}</td>
                      <td>{item?.scale_number}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <p
              className="p-1 mt-5"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>Insurance Information</strong>
            </p>
            <table className="InfoTable">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <strong>Insurance ID</strong>
                  </td>
                  {farmerProfileDetails?.insurance_info?.map((item, index) => (
                    <td key={index + 1}>{item.insurance_id}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Insurance Sector</strong>
                  </td>
                  {farmerProfileDetails?.insurance_info?.map((item, index) => (
                    <td key={index + 2}>{item.insurance_sector}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Insurance Method</strong>
                  </td>
                  {farmerProfileDetails?.insurance_info?.map((item, index) => (
                    <td key={index + 3}>{item.insurance_method}</td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong>Policy No</strong>
                  </td>
                  {farmerProfileDetails?.insurance_info?.map((item, index) => (
                    <td key={index + 4}>{item.policy_no}</td>
                  ))}
                </tr>
              </tbody>
            </table>

            <p
              className="p-1 mt-5"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>Consultation Section</strong>
            </p>
            <p
              className="p-1 mt-1"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              Crop Consultation
            </p>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Ticket Number</th>
                  <th>Crop Name</th>
                  <th>Symptoms</th>
                  <th>Solution</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.crop_consultation?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.ticket?.ticket_id}</td>
                    <td>{item?.ticket?.crop_or_livestock_name}</td>
                    <td>{item?.ticket?.issue_type?.issue_type}</td>
                    <td>{item?.advice}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <p
              className="p-1 mt-4"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              Livestock Consultation
            </p>
            <table style={{ border: "solid 1px" }} className="tableHeaderTable">
              <thead>
                <tr>
                  <th>Ticket Number</th>
                  <th>Livestock Name</th>
                  <th>Symptoms</th>
                  <th>Solution</th>
                </tr>
              </thead>
              <tbody>
                {farmerProfileDetails?.livestock_consultation?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item?.ticket?.ticket_id}</td>
                      <td>{item?.ticket?.crop_or_livestock_name}</td>
                      <td>{item?.ticket?.issue_type?.issue_type}</td>
                      <td>{item?.advice}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
      {/* <p
              className="p-1 mt-5"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>Total Points</strong>
            </p>
            <table className="InfoTable">
              <tr>
                <td style={{ width: "30%" }}>
                  <strong>Total Points</strong>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>Total Taka Redeemable</strong>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>Remarks</strong>
                </td>
                <td></td>
              </tr>
            </table>

            <p
              className="p-1 mt-5"
              style={{ border: "solid 1px black", width: "30%" }}
            >
              <strong>Total Transaction</strong>
            </p>
            <table className="InfoTable mb-5">
              <tr>
                <td style={{ width: "30%" }}>
                  <strong>Total Selling Amount</strong>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>Total Buying Amount</strong>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <strong>Total Agrigate Transaction</strong>
                </td>
                <td></td>
              </tr>
            </table> */}
    </>
  );
}
