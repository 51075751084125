import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Spin, Table, Tooltip } from "antd";
import { DateRangePicker } from "rsuite";
import Swal from "sweetalert2";
import axios from "axios";

export default function PoultrySurvey() {
  const { Option } = Select;
  const [poultrySurvey, setPoultrySurvey] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [viewModal, setViewModal] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [surveyDistinctValues, setSurveyDistinctValues] = useState({});
  const [selectedFarmSize, setSelectedFarmSize] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbzXv6jJLlNmRTyhgw4qMhXus3-x4vLdJjBLdb1QucffJw2N4nMU8m6Pi6tU6ItaWnYmAg/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setSurveyDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };
  const handleViewModal = (record) => {
    setLoading(true);
    setViewModal(true);
    setModalData(record);
  };
  const handleFarmSizeFilter = (value) => {
    setSelectedFarmSize(value)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbysvmoPX5SNWd1XKbATX1DYW2HaeGPCEzqrSzekF8mqWRCg5S259sybx6Lq957rLKDx/exec?page=${currentPage}&farmSize=${selectedFarmSize}&district=${selectedDistrict}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setPoultrySurvey(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [currentPage, selectedDateRange, selectedDistrict, selectedFarmSize]);

  const poultryColumns = [
    {
      title: "Survey Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Date);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Farmer ID",
      width: "12%",
      dataIndex: "Farmer_ID",
    },
    {
      title: "Farmer Name",
      width: "12%",
      dataIndex: "Farmer_Name",
    },
    {
      title: "Phone Number",
      width: "12%",
      render: (record) => {
        return "0" + record?.Phone_Number; // Add '0' to the beginning of the phone number
      },
    },
    {
      title: "District",
      width: "12%",
      dataIndex: "District_Name",
    },

    {
      title: "Poultry Farm Running Time",
      dataIndex: "How_long_have_you_run_your_poultry_farm",
      width: "10%",
    },
    {
      title: "No of Chickens",
      dataIndex: "How_many_chickens_are_on_your_farm",
      width: "10%",
    },
    {
      title: "FO Name",
      dataIndex: "Field_Officer_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Poultry Survey"
        centered
        open={viewModal}
        onCancel={() => {
          setViewModal(false);
        }}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Farmer Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Farmer Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farmer_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Farmer Phone</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Phone_Number}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData.District_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData.Upazila}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData.Union}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Farm Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2">Poultry farm running time</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.How_long_have_you_run_your_poultry_farm}
                </td>
              </tr>
              <tr>
                <th className="p-2">Number of chicken</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.How_many_chickens_are_on_your_farm}
                </td>
              </tr>
              <tr>
                <th className="p-2">Type of Chickens</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.type_of_chickens}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2">Size of Farm</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.size_of_farm}
                </td>
              </tr>
              <tr>
                <th className="p-2 w-25">Eggs or Meat Produce Monthly</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {
                    modalData.How_many_eggs_or_how_much_chicken_meat_do_you_produce_monthly
                  }
                </td>
              </tr>
              <tr>
                <th className="p-2">Price of products</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData.Where_and_how_do_you_sell_your_products}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Other Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2">Involve with any company</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Involve_with_any_company}
                </td>
              </tr>
              <tr>
                <th className="p-2">Company Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Company_name}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2">Interested to do contract farming with us?</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.interested_in_working_with_us}
                </td>
              </tr>
            </tbody>
          </table>
        </div>  
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>POULTRY SURVEY DETAILS</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {surveyDistinctValues?.distinctDistrict?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">Farm Size: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleFarmSizeFilter}
                value={selectedFarmSize}
              >
                <Option value="">All Size</Option>
                <Option value="less_than_1000">Less than 1000 sqft</Option>
                <Option value="greater_than_1000">Greater than 1000 sqft</Option>
              </Select>
            </div>
          </div>
          <Table
            columns={poultryColumns}
            dataSource={poultrySurvey}
            loading={tableLoading}
            rowKey={(record) => record.Phone_Number}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
