import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Image, Input, Modal, Select, Spin, Table } from "antd";
import PortraitIcon from "@mui/icons-material/Portrait";
import Tooltip from "@mui/material/Tooltip";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";

export default function TraderDashboard() {
  const { Search } = Input;
  const { Option } = Select;
  const [traderList, setTraderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [traderCountData, setTraderCountData] = useState({});
  const [traderFilterData, setTraderFilterData] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [traderSellingInfo, setTraderSellingInfo] = useState([]);

  const fetchSellingInfo = (trader_id) => {
    axios
      .get(`${baseUrl}/trader/traderSellingInfo/${trader_id}/`, {
        headers: authHeader(),
      })
      .then((results) => {
        setTraderSellingInfo(results?.data || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/traderCount/${traderFilterData}/`, {
        headers: authHeader(),
      })
      .then((results) => {
        setTraderCountData(results?.data);
      });
  }, [traderFilterData]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/trader/traderList/`, {
        headers: authHeader(),
        params: {
          page: currentPage,
          filter: filter,
        },
      })
      .then((results) => {
        setTraderList(results?.data.results || []);
        setTotalItems(results?.data.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, filter]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleProfileDetails = (record) => {
    setViewModal(true);
    setModalData(record);
    fetchSellingInfo(record?.trader_id);
  };
  const handleImageClick = (url) => {
    setViewImageModal(true);
    setImageUrl(url);
  };

  const columns = [
    {
      title: "Trader ID",
      dataIndex: "trader_id",
      width: "18%",
    },
    {
      title: "Trader Name",
      dataIndex: "name",
      width: "13%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: "12%",
      render: (text) => {
        return "0" + text; // Add '0' to the beginning of the phone number
      },
    },
    {
      title: "District",
      render: (record) => record?.district?.district_name,
      width: "12%",
    },
    {
      title: "Upazila",
      render: (record) => record?.upazila?.upazila_name,
      width: "12%",
    },
    {
      title: "Union",
      render: (record) => record?.union?.union_name,
      width: "12%",
    },
    {
      title: "Village",
      render: (record) => record?.village,
      filterSearch: true,
      width: "14%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="Profile">
            <Button onClick={() => handleProfileDetails(record)}>
              <PortraitIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  const traderDistrictValue = (value) => {
    setTraderFilterData(value);
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };

  return (
    <>
      <Modal
        title={false}
        centered
        open={viewImageModal}
        footer={null}
        onCancel={() => {
          setViewImageModal(false);
        }}
      >
        <Image width={200} src={imageUrl} alt="Signboard Image" />
      </Modal>
      <Modal
        title={false}
        centered
        open={viewModal}
        onCancel={() => {
          setViewModal(false);
        }}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Trader Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">TID Number</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.trader_id}
                </td>
              </tr>
              <tr>
                <th className="p-2 w-25">Trader Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Trader's Phone Number</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {"0" + modalData?.phone}
                </td>
              </tr>
              <tr>
                <th className="p-2">National ID</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.nid}
                </td>
              </tr>
              <tr>
                <th className="p-2">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.district?.district_name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.upazila?.upazila_name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.union?.union_name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Village</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.village}
                </td>
              </tr>
              <tr>
                <th className="p-2">GPS</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.latitude + ", " + modalData?.longitude}
                </td>
              </tr>
              <tr>
                <th className="p-2">Cheque Image</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  <Button
                    onClick={() => handleImageClick(modalData?.cheque_image)}
                  >
                    Click
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-50">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              <p style={{ color: "#203509" }}>
                <strong>Trader Image:</strong>
              </p>
              <Image
                className="border"
                width={300}
                src={modalData?.profile_image}
                alt="Trader Image"
              />
            </div>
            <div className="mt-4 w-100 d-flex flex-column justify-content-center align-items-center">
              <p style={{ color: "#203509" }}>
                <strong>Signboard Image:</strong>
              </p>
              <Image
                className="border"
                width={300}
                src={modalData?.signboard_image}
                alt="Signboard Image"
              />
            </div>
          </div>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Available Product Info
        </div>

        <table
          className="w-100 table-bordered text-center"
          style={{ border: "0.3px solid lightgray ", color: "#203509" }}
        >
          <tbody className="m-5">
            <tr>
              <th>Product Name</th>
              <th>Available Quantity (TON)</th>
              <th>Product Price/kg</th>
            </tr>
            {modalData?.products?.map((product, index) => (
              <tr key={index}>
                <td className="py-2">
                  {product?.sourcing_product?.product_name}
                </td>
                <td>{product.product_quantity_ton}</td>
                <td>{product.selling_price_per_kg}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Product Selling Info
        </div>
        <table
          className="w-100 table-bordered text-center"
          style={{ border: "0.3px solid lightgray ", color: "#203509" }}
        >
          <tbody className="m-5">
            <tr>
              <th>Date</th>
              <th>Product Name</th>
              <th>Comment</th>
              <th>Quantity (KG)</th>
              <th>Rate</th>
              <th>Selling Amount (Tk)</th>
              <th>GDN</th>
            </tr>
            {traderSellingInfo?.map((product, index) => (
              <tr key={index}>
                <td className="py-2">{product?.purchase_date}</td>
                <td>{product?.product_name}</td>
                <td>{product?.comment}</td>
                <td>{(product?.purchase_quantity_kg).toLocaleString()}</td>
                <td>{product?.purchase_rate_tk_per_kg}</td>
                <td>{(parseFloat(product?.purchase_rate_tk_per_kg).toFixed(2) * parseFloat(product?.purchase_quantity_kg).toFixed(2))?.toLocaleString()}</td>
                <td>{product?.gdn_number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>TRADER'S DASHBOARD</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="farmerBoxSection">
            <div className="farmerCardDesign">
              <p className="m-3" style={{ fontSize: "18px" }}>
                Total Registered Trader
              </p>
              <p className="m-3" style={{ fontSize: "30px" }}>
                {traderCountData?.total_count || 0}
              </p>
            </div>
            <div className="farmerCardDesign">
              <p className="mt-3 ms-3">District Filter:</p>
              <Select
                className="ms-3 w-50"
                name="Select"
                onChange={traderDistrictValue}
                placeholder="Select District"
                showSearch
              >
                <Option value="all">District</Option>
                {traderCountData?.district_values?.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="m-2 mt-4 d-flex justify-content-end align-items-center">
            <Search
              className="w-25"
              placeholder="search here..."
              allowClear
              onSearch={handleFilterChange}
              enterButton={
                <Button
                  type="primary"
                  style={{ backgroundColor: "#38512e", borderColor: "#38512e" }}
                >
                  Search
                </Button>
              }
            />
          </div>

          <div className="m-2 border">
            <Table
              columns={columns}
              dataSource={traderList}
              loading={loading}
              rowKey={(record) => record.trader_id}
              pagination={{
                current: currentPage,
                total: totalItems,
                pageSize: 10, // Set the number of items per page
                onChange: handlePageChange,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
