import React, { useEffect, useState } from "react";
import { Button, Input, Select, Table, Tooltip } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { EditOutlined } from "@ant-design/icons";
import StockUpdateModal from "./StockUpdateModal";
import { DateRangePicker } from "rsuite";

export default function CenterStock() {
  const { Search } = Input;
  const { Option } = Select;
  const [stockList, setStockList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [centerIds, setCenterIds] = useState([]);
  const [stockLoading, setStockLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [stockUpdateModal, setUpdateProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [search, setSearch] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  const handleSalesDateRage = (value) => {
    setSelectedDateRange(value);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };
  const handleCenter = (value) => {
    setSelectedCenter(value);
  };
  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  const handleUpdateModal = (record) => {
    setUpdateProductModal(true);
    setSelectedProduct(record);
  };
  const handleUpdateModalClose = () => {
    setUpdateProductModal(false);
  };

  const fetchStockList = () => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/inventory/stock_list/${userProfile?.employee_id}/`, {
        headers: authHeader(),
        params: {
          product_name: search,
          searched_center_id: selectedCenter,
          dateRange: selectedDateRange,
          page: currentPage,
        },
      })
      .then((response) => {
        setStockLoading(false);
        setStockList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setStockLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchStockList();
  }, [currentPage, search, selectedCenter, selectedDateRange]);

  useEffect(() => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/inventory/center_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setStockLoading(false);
        setCenterList(response?.data);
        const employeeIds = response?.data?.map(
          (center) => center?.center?.employee_id
        );
        setCenterIds(employeeIds);
      })
      .catch((error) => {
        setStockLoading(true);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  const stockColumn = [
    {
      title: "Product ID",
      render: (record) => record?.product?.product_id,
      width: "8%",
    },
    {
      title: "Product Name",
      render: (record) => record?.product?.product_name,
      width: "20%",
    },
    {
      title: "Batch",
      dataIndex: "batch_id",
      width: "5%",
    },
    {
      title: "Agri Type",
      render: (record) => record?.product?.agri_type,
      width: "5%",
    },
    {
      title: "Category",
      render: (record) => record?.product?.category?.category_name,
      width: "5%",
    },
    {
      title: "Brand Name",
      render: (record) => record?.product?.brand?.brand_name,
      width: "10%",
    },
    {
      title: "Stock In",
      dataIndex: "stock_in",
      width: "4%",
    },
    {
      title: "Stock Out",
      dataIndex: "stock_out",
      width: "4%",
    },
    centerIds?.includes(userProfile?.employee_id) ? (
      <></>
    ) : (
      {
        title: "Purchase",
        render: (record) => record?.purchase_price?.toLocaleString(),
        width: "5%",
      }
    ),

    {
      title: "Remaining",
      dataIndex: "stock",
      width: "5%",
    },
    {
      title: "Expire",
      render: (record) =>
        record?.expire_date
          ? new Date(record?.expire_date).toISOString().slice(0, 10)
          : "",
      width: "5%",
    },
    centerIds?.includes(userProfile?.employee_id) ? (
      <></>
    ) : (
      {
        title: "Stock Value",
      render: (record) =>
        (record?.purchase_price * record?.stock).toLocaleString(),
      width: "5%",
      }
    ),
    {
      title: "Farmer",
      render: (record) => record?.selling_rate_farmer?.toLocaleString(),
      width: "4%",
    },
    {
      title: "Retailer",
      render: (record) => record?.selling_rate_retailer?.toLocaleString(),
      width: "4%",
    },
    {
      title: "Contract Farmer",
      render: (record) =>
        record?.selling_rate_contract_farmer?.toLocaleString(),
      width: "5%",
    },
    {
      title: "IB Rate",
      render: (record) =>
        record?.selling_rate_institutional_buyer?.toLocaleString(),
      width: "5%",
    },
    {
      title: "Action",
      width: "5%",
      render: (record) => {
        return (
          <React.Fragment>
            {centerIds.includes(userProfile?.employee_id) ? null : (
              <Tooltip title="Update">
                <Button
                  className="d-flex align-items-center"
                  onClick={() => handleUpdateModal(record)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
          </React.Fragment>
        );
      },
    },
  ];
  return (
    <>
      <StockUpdateModal
        visible={stockUpdateModal}
        handleModalClose={handleUpdateModalClose}
        data={selectedProduct}
        fetchStockList={fetchStockList}
      />
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>STOCK DETAILS</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                <div>
                  {centerIds?.includes(userProfile?.employee_id) ? null : (
                    <Select
                      placeholder="Select Center"
                      className="me-3"
                      onChange={handleCenter}
                    >
                      <Option value="">All Center</Option>
                      {centerList?.map((center) => (
                        <Option
                          value={center?.center?.employee_id}
                          key={center?.center?.employee_id}
                        >
                          {center?.center_name}
                        </Option>
                      ))}
                    </Select>
                  )}
                  <DateRangePicker
                    onChange={handleSalesDateRage}
                    value={selectedDateRange}
                    style={{ width: "190px" }}
                    showOneCalendar
                  />
                </div>
                <Search
                  className="w-25"
                  placeholder="search here..."
                  allowClear
                  onSearch={handleSearch}
                  enterButton={
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#38512e",
                        borderColor: "#38512e",
                      }}
                    >
                      Search
                    </Button>
                  }
                />
              </div>
              <Table
                className="border"
                columns={stockColumn}
                dataSource={stockList}
                loading={stockLoading}
                rowKey={(record, index) => index}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
