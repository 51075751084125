import { Button, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import Swal from "sweetalert2";

export default function FarmerKyc() {
  const { Option } = Select;
  const [farmerKYC, setFarmerKYC] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading,setTableLoading] = useState(true)
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [KYCDistinctValues, setKYCDistinctValues] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxmSTR8_uRBtOF4uvxYQLa4tJmhZZ88AmPz4A2YmAwCVAABvrlhYY4DTAGQZg22atahxw/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setKYCDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [farmerKYC]);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbzWM_HYuM1dRJZbb1o_Cf3szvraDz5EHvm-ejKKmwY5FIXRIRBZjKoXYea89WoosqodQQ/exec?page=${currentPage}&district=${selectedDistrict}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setFarmerKYC(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    selectedDateRange,
    selectedDistrict,
  ]);

  const farmerKycColumns = [
    {
      title: "KYC Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Date);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Farmer ID",
      width: "11%",
      dataIndex: "Farmer_ID",
    },
    {
      title: "Farmer Name",
      width: "12%",
      dataIndex: "Farmer_Name",
    },
    {
      title: "Farmer Phone",
      width: "12%",
      render: (record) => {
        return "0" + record?.Phone_Number;
      },
    },
    {
      title: "District",
      dataIndex: "District_Name",
      width: "10%",
    },
    {
      title: "FO Name",
      dataIndex: "Employee_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>

          {/* <Link
            className="actionBtnDesign"
            to={`/farmerProfileUpdate/${record?.farmer_id}`}
          >
            <Tooltip title="Update">Update</Tooltip>
          </Link> */}
        </>
      ),
    },
  ];

  const handleViewModal = (record) => {
    setViewModal(true);
    setModalData(record);
  };

  return (
    <>
      <Modal
        title="Farmer KYC"
        centered
        open={viewModal}
        onCancel={() => setViewModal(false)}
        width={"65%"}
        footer={null}
      >
        <table
          className="w-50 table-bordered "
          style={{ border: "0.3px solid lightgray ", color: "#203509" }}
        >
          <tbody className="m-5">
            <tr>
              <th className="p-2">Farmer ID</th>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                {modalData?.Farmer_ID}
              </td>
            </tr>
            <tr>
              <th className="p-2">Farmer Name</th>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                {modalData?.Farmer_Name}
              </td>
            </tr>
            <tr>
              <th className="p-2">Farmer Phone</th>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                {modalData?.Phone_Number}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="d-flex justify-content-between my-3"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          <div className="w-50 border text-center py-1">Address</div>
          <div className="w-50 border text-center py-1">Bank Information</div>
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.District}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farmer_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Phone_Number}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Bank Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Bank_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Account Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Bank_Account_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Account Number</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Account_No}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Property Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Land Info
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Land Type</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Land_Type}
                </td>
              </tr>
              <tr>
                <td className="p-2">Own Land Size</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Own_Land_Size} decimal
                </td>
              </tr>
              <tr>
                <td className="p-2">Lease Land Size</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Lease_Land_Size} decimal
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Farm Info
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Farm Type</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farm_Type}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Cultivable Products
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-100 table-bordered text-center"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center w-25">Season Name</th>
                <th className="p-2 text-center w-75">Crop Names</th>
              </tr>
              <tr>
                <td className="p-2">Kharif1</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Kharif1}
                </td>
              </tr>
              <tr>
                <td className="p-2">Kharif2</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Kharif2}
                </td>
              </tr>
              <tr>
                <td className="p-2">Robi</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Robi}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>FARMER KYC</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {KYCDistinctValues?.distinctDistrict?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </div>
          <Table
            columns={farmerKycColumns}
            dataSource={farmerKYC}
            loading={tableLoading}
            rowKey={(record) => record.Farmer_ID}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
