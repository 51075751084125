import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import SideBar from "./components/SideBar/SideBar";
import "./App.css";
import CashSalesDashboard from "./components/Sales/CashSalesDashboard";
import ConsultationDashboard from "./components/ConsultationDashboard/ConsultationDashboard";
import PriceAppDashboard from "./components/PriceAppDashboard/PriceAppDashboard";
import LoginPage from "./components/LoginPage/LoginPage";
import { createContext, useState } from "react";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { ProSidebarProvider } from "react-pro-sidebar";
import FarmerDashboard from "./components/FarmerDashboard/FarmerDashboard";
import FarmerReport from "./components/FarmerDashboard/FarmersTemplate/FarmerReport";
import FarmerUpdate from "./components/FarmerDashboard/FarmerProfileUpdate/FarmerUpdate";
import Employee from "./components/Employee/Employee";
import CreateProfilePage from "./components/Employee/CreateEmployeeProfile";
import RetailerDashboard from "./components/Retailer/RetailerDashobard";
import DailyReport from "./components/DailyReport/DailyReport";
import ProductPrice from "./components/Sales/ProductPrice/ProductPrice";
import ProductSourcingDashboard from "./components/ProductSourcingDashboard/ProductSourcingDashboard";
import JitsiMeetComponent from "./components/Meeting/JitsiMeetComponent";
import ContractFarmingReg from "./components/ContractFarming/ContractFarmingReg";
import OrderTaking from "./components/Sales/OrderTaking/OrderTaking";
import Survey from "./components/Survey/Survey";
import FarmerKyc from "./components/Survey/FarmerKyc";
import RetailerKyc from "./components/Survey/RetailerKyc";
import LSPKyc from "./components/Survey/LSPKyc";
import DairySurvey from "./components/Survey/DairySurvey";
import BeefSurvey from "./components/Survey/BeefSurvey";
import WarehouseSurvey from "./components/Survey/WarehouseSurvey";
import CreateUser from "./components/Employee/CreateUser";
import User from "./components/Employee/User";
import OrderFulfillment from "./components/Sales/OrderFulfillment/OrderFulfillment";
import ContractFarmingDashboard from "./components/ContractFarming/ContractFarmingDashboard";
import LSPDashboard from "./components/LSP/LSPDashboard";
import ConsultantDashboard from "./components/ConsultationDashboard/ConsultantDashboard";
import ProductDemandDashboard from "./components/ContractFarming/ProductDemandDashboard";
import ContractFarmingHistory from "./components/ContractFarming/ContractFarmingHistory";
import OperationsDashboard from "./components/ConsultationDashboard/OperationsDashboard";
import SalesDashboard from "./components/Sales/SalesDashboard";
import SourcingDashboard from "./components/ProductSourcingDashboard/SourcingDashboard";
import DatabaseDashboard from "./components/FarmerDashboard/DatabaseDashboard";
import TraderDashboard from "./components/Trader/TraderDashboard";
import PurchaseProduct from "./components/Inventory/PurchaseProduct";
import ManagePurchase from "./components/Inventory/ManagePurchase";
import CenterStock from "./components/Inventory/CenterStock";
import ProductList from "./components/Sales/ProductList";
import GUISales from "./components/Inventory/GUISales";
import ManageSales from "./components/Inventory/ManageSales";
import CustomerLedger from "./components/Inventory/CustomerLedger";
import SourcingProducts from "./components/ProductSourcingDashboard/SourcingProducts";
import RouteMap from "./components/Sales/RouteMap/RouteMap";
import RetailerProfile from "./components/Retailer/RetailerProfile";
import LSPProfile from "./components/LSP/LSPProfile";
import ASPDashboard from "./components/ASP/ASPDashboard";
import ASPProfile from "./components/ASP/ASPProfile";
import InstitutionalBuyer from "./components/InstitutionalBuyer/InstitutionalBuyer";
import AgroProcessorSurvey from "./components/Survey/AgroProcessorSurvey";
import WarehouseStockIn from "./components/ProductSourcingDashboard/WarehouseStockIn";
import WarehouseStockOut from "./components/ProductSourcingDashboard/WarehouseStockOut";
import PoultrySurvey from "./components/Survey/PoultrySurvey";

export const loginContext = createContext();
function getLocalStorage(key) {
  const stored = localStorage.getItem(key);
  return JSON.parse(stored);
}
export default function App() {
  const [loggedIn, setLoggedIn] = useState(getLocalStorage("isLoggedIn"));
  const [userPermissions, setUserPermissions] = useState(
    getLocalStorage("user_permissions")
  );

  const contextValues = {
    loggedIn,
    userPermissions,
    setLoggedIn,
    setUserPermissions,
  };

  return (
    <ProSidebarProvider>
      <loginContext.Provider value={contextValues}>
        <div className="App">
          <Router>
            {loggedIn && (
              <Route
                render={({ location }) =>
                  !location.pathname.startsWith("/farmerProfile/") &&
                  !location.pathname.startsWith("/retailerProfile/") &&
                  !location.pathname.startsWith("/lspProfile/") &&
                  !location.pathname.startsWith("/aspProfile/") && <SideBar />
                }
              />
            )}

            <Switch>
              <PrivateRoute path="/home">
                {userPermissions?.includes("view_home_menu") && <HomePage />}
              </PrivateRoute>

              <PrivateRoute path="/" exact>
                {userPermissions?.includes("view_home_menu") && <HomePage />}
              </PrivateRoute>

              <PrivateRoute path="/salesDashboard">
                {userPermissions?.includes("view_sales_dashboard") && (
                  <SalesDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/cashSales">
                {userPermissions?.includes("view_sales_dashboard") && (
                  <CashSalesDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/generalMeeting">
                {userPermissions?.includes("view_general_meeting") && (
                  <JitsiMeetComponent roomName="vpaas-magic-cookie-1e41125e61934f748a4bf8082da92d89/SampleAppSpecializedHallsCaterCommonly" />
                )}
              </PrivateRoute>

              <PrivateRoute path="/hrMeeting">
                {userPermissions?.includes("view_hr_meeting") && (
                  <JitsiMeetComponent roomName="vpaas-magic-cookie-323b2b1a1d094a9a9b26abf55ec48775/SampleAppAbsurdRunningsMatchFirstly" />
                )}
              </PrivateRoute>

              <PrivateRoute path="/techMeeting">
                {userPermissions?.includes("view_tech_meeting") && (
                  <JitsiMeetComponent roomName="vpaas-magic-cookie-0330cedcbc354bff963a95c6d680801b/SampleAppAwkwardPublicitiesSeparateToday" />
                )}
              </PrivateRoute>

              <PrivateRoute path="/accountsMeeting">
                {userPermissions?.includes("view_accounts_meeting") && (
                  <JitsiMeetComponent roomName="vpaas-magic-cookie-16b522c4091d46269a8e95d6a1ca1d40/SampleAppSympatheticQuotationsEstimateTotally" />
                )}
              </PrivateRoute>

              <PrivateRoute path="/salesMeeting">
                {userPermissions?.includes("view_sales_meeting") && (
                  <JitsiMeetComponent roomName="vpaas-magic-cookie-84c625b8c6934ab8a565d8750a381dff/SampleAppCountlessWeedsFearOverly" />
                )}
              </PrivateRoute>

              <PrivateRoute path="/opsMeeting">
                {userPermissions?.includes("view_operations_meeting") && (
                  <JitsiMeetComponent roomName="vpaas-magic-cookie-f5b81472a14d49a9b75048726b3d36f1/SampleAppBlankSkillsEarnAllegedly" />
                )}
              </PrivateRoute>

              <PrivateRoute path="/sourcingMeeting">
                {userPermissions?.includes("view_sourcing_meeting") && (
                  <JitsiMeetComponent roomName="vpaas-magic-cookie-a75481d447a145c39bb3b804e9cf0ba2/SampleAppRealisticCargoesDecreaseLittle" />
                )}
              </PrivateRoute>

              <PrivateRoute path="/productPrice">
                {userPermissions?.includes("view_product_price") && (
                  <ProductPrice />
                )}
              </PrivateRoute>
              <PrivateRoute path="/operationsDashboard">
                {userPermissions?.includes("view_operations_menu") && (
                  <OperationsDashboard />
                )}
              </PrivateRoute>
              <PrivateRoute path="/consultationDashboard">
                {userPermissions?.includes("view_consultation_menu") && (
                  <ConsultationDashboard />
                )}
              </PrivateRoute>
              <PrivateRoute path="/consultant">
                {userPermissions?.includes("view_consultant_menu") && (
                  <ConsultantDashboard />
                )}
              </PrivateRoute>
              <PrivateRoute path="/contractFarming">
                {userPermissions?.includes("view_contract_farming_menu") && (
                  <ContractFarmingDashboard />
                )}
              </PrivateRoute>
              <PrivateRoute path="/contractFarmingReg">
                {userPermissions?.includes("view_contract_farming_reg") && (
                  <ContractFarmingReg />
                )}
              </PrivateRoute>

              <PrivateRoute path="/productDemand">
                {userPermissions?.includes("view_contract_farming_menu") && (
                  <ProductDemandDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/contractFarmingHistory">
                {userPermissions?.includes("view_contract_farming_menu") && (
                  <ContractFarmingHistory />
                )}
              </PrivateRoute>
              <PrivateRoute path="/gui">
                {userPermissions?.includes("view_gui_menu") && <GUISales />}
              </PrivateRoute>
              <PrivateRoute path="/manageSales">
                {userPermissions?.includes("view_manage_sales_menu") && (
                  <ManageSales />
                )}
              </PrivateRoute>
              <PrivateRoute path="/customerLedger">
                {userPermissions?.includes("view_pos_menu") && (
                  <CustomerLedger />
                )}
              </PrivateRoute>
              <PrivateRoute path="/purchaseProduct">
                {userPermissions?.includes("view_purchase_product_menu") && (
                  <PurchaseProduct />
                )}
              </PrivateRoute>

              <PrivateRoute path="/managePurchase">
                {userPermissions?.includes("view_purchase_menu") && (
                  <ManagePurchase />
                )}
              </PrivateRoute>
              <PrivateRoute path="/stock">
                {userPermissions?.includes("view_stock_menu") && (
                  <CenterStock />
                )}
              </PrivateRoute>
              <PrivateRoute path="/priceApp">
                {userPermissions?.includes("view_price_app_menu") && (
                  <PriceAppDashboard />
                )}
              </PrivateRoute>
              <PrivateRoute path="/sourcingDashboard">
                {userPermissions?.includes("view_product_sourcing_menu") && (
                  <SourcingDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/productSourcing">
                {userPermissions?.includes("view_product_sourcing_menu") && (
                  <ProductSourcingDashboard />
                )}
              </PrivateRoute>
              <PrivateRoute path="/sourcingList">
                {userPermissions?.includes("view_sourcing_product_list") && (
                  <SourcingProducts />
                )}
              </PrivateRoute>

              <PrivateRoute path="/warehouseStockIn">
                {userPermissions?.includes("view_sourcing_menu") && (
                  <WarehouseStockIn />
                )}
              </PrivateRoute>
              <PrivateRoute path="/warehouseStockOut">
                {userPermissions?.includes("view_sourcing_menu") && (
                  <WarehouseStockOut />
                )}
              </PrivateRoute>

              <PrivateRoute path="/databaseDashboard">
                {userPermissions?.includes("view_database_menu") && (
                  <DatabaseDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/databases/farmer">
                {userPermissions?.includes("view_farmer_database") && (
                  <FarmerDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/databases/retailer">
                {userPermissions?.includes("view_retailer_database") && (
                  <RetailerDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/databases/trader">
                {userPermissions?.includes("view_trader_database") && (
                  <TraderDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/databases/lsp">
                {userPermissions?.includes("view_lsp_database") && (
                  <LSPDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/databases/asp">
                {userPermissions?.includes("view_asp_database") && (
                  <ASPDashboard />
                )}
              </PrivateRoute>

              <PrivateRoute path="/databases/institutionalBuyer">
                {userPermissions?.includes("view_wholesaler_database") && (
                  <InstitutionalBuyer />
                )}
              </PrivateRoute>


              <PrivateRoute path="/farmerProfileUpdate/:farmer_id">
                {userPermissions?.includes("view_farmer_database") && (
                  <FarmerUpdate />
                )}
              </PrivateRoute>

              <PrivateRoute path="/employee">
                {userPermissions?.includes("view_create_employee_profile") && (
                  <Employee />
                )}
              </PrivateRoute>

              <PrivateRoute path="/create_profile">
                {userPermissions?.includes("view_create_employee_profile") && (
                  <CreateProfilePage />
                )}
              </PrivateRoute>

              <PrivateRoute path="/dailyReport">
                {userPermissions?.includes("view_daily_report") && (
                  <DailyReport />
                )}
              </PrivateRoute>

              <PrivateRoute path="/productList">
                {userPermissions?.includes("view_product_menu") && (
                  <ProductList />
                )}
              </PrivateRoute>

              <PrivateRoute path="/orderTaking">
                {userPermissions?.includes("view_order_taking_menu") && (
                  <OrderTaking />
                )}
              </PrivateRoute>

              <PrivateRoute path="/orderFulfillment">
                {userPermissions?.includes("view_order_taking_menu") && (
                  <OrderFulfillment />
                )}
              </PrivateRoute>

              <PrivateRoute path="/survey">
                {userPermissions?.includes("view_survey_menu") && <Survey />}
              </PrivateRoute>

              <PrivateRoute path="/farmerKyc">
                {userPermissions?.includes("view_survey_menu") && <FarmerKyc />}
              </PrivateRoute>

              <PrivateRoute path="/retailerKyc">
                {userPermissions?.includes("view_survey_menu") && (
                  <RetailerKyc />
                )}
              </PrivateRoute>

              <PrivateRoute path="/lspKyc">
                {userPermissions?.includes("view_survey_menu") && <LSPKyc />}
              </PrivateRoute>

              <PrivateRoute path="/dairy">
                {userPermissions?.includes("view_survey_menu") && (
                  <DairySurvey />
                )}
              </PrivateRoute>

              <PrivateRoute path="/beef">
                {userPermissions?.includes("view_survey_menu") && (
                  <BeefSurvey />
                )}
              </PrivateRoute>

              <PrivateRoute path="/agroProcessor">
                {userPermissions?.includes("view_survey_menu") && (
                  <AgroProcessorSurvey />
                )}
              </PrivateRoute>

              <PrivateRoute path="/warehouse">
                {userPermissions?.includes("view_survey_menu") && (
                  <WarehouseSurvey />
                )}
              </PrivateRoute>
              <PrivateRoute path="/poultry">
                {userPermissions?.includes("view_survey_menu") && (
                  <PoultrySurvey />
                )}
              </PrivateRoute>

              <PrivateRoute path="/user">
                {userPermissions?.includes("view_create_user") && <User />}
              </PrivateRoute>

              <PrivateRoute path="/routeMap">
                {userPermissions?.includes("view_route_map_menu") && (
                  <RouteMap />
                )}
              </PrivateRoute>

              <PrivateRoute path="/createUser">
                {userPermissions?.includes("view_create_user") && (
                  <CreateUser />
                )}
              </PrivateRoute>

              <Route path="/farmerProfile/:farmer_id">
                <FarmerReport />
              </Route>
              <Route path="/retailerProfile/:retailer_id">
                <RetailerProfile />
              </Route>
              <Route path="/lspProfile/:lsp_id">
                <LSPProfile />
              </Route>
              <Route path="/aspProfile/:asp_id">
                <ASPProfile />
              </Route>
              <Route path="/login">
                {loggedIn ? <Redirect to="/" /> : <LoginPage />}
              </Route>
            </Switch>
          </Router>
        </div>
      </loginContext.Provider>
    </ProSidebarProvider>
  );
}
