import React, { useEffect, useState } from "react";
import "./ConsultationDashboard.css";
import PieChart from "./PieChart/PieChart.js";
import axios from "axios";
import {
  Tabs,
  Form,
  Table,
  Input,
  Button,
  Spin,
  Select,
  DatePicker,
} from "antd";
import baseUrl from "../../config.js";
import authHeader from "../Authentication/authHeader.js";
import { DateRangePicker } from "rsuite";
import Swal from "sweetalert2";
import BarChart from "./BarChart/BarChart.js";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import TicketUpdateModal from "./ConsultationModals/TicketUpdateModal.js";

export default function ConsultationDashboard() {
  const [form] = Form.useForm();
  const [consultantForm] = Form.useForm();
  const [issueTypeForm] = Form.useForm();
  const { Search } = Input;
  const { Option } = Select;
  const [tabValue, setTabValue] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [issueTypeLoading, setIssueTypeLoading] = useState(false);
  const [consultantLoading, setConsultantLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [consultantList, setConsultantList] = useState([]);
  const [issueTypeList, setIssueTypeList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");
  const [analyticsData, setAnalyticsData] = useState({});

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [analyticsDataDateRange, setAnalyticsDataDateRange] = useState([]);

  const [ticketUpdateModal, setTicketUpdateModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const today = new Date();
  const todaysDate = new Date(today);
  todaysDate.setDate(today.getDate());
  const ticketCreationDate = todaysDate.toISOString().slice(0, 10);

  useEffect(() => {
    setConsultantLoading(true);
    axios
      .get(`${baseUrl}/consultation/consultantList/`, {
        headers: authHeader(),
      })
      .then((data) => {
        setConsultantLoading(false);
        setConsultantList(data?.data);
      })
      .catch((error) => {
        setConsultantLoading(false);
        console.log(error);
      });
  }, [employeeList]);

  const fetchConsultationEmployeeList = () => {
    setConsultantLoading(true);
    axios
      .get(`${baseUrl}/consultation/consultationEmployeeList`, {
        headers: authHeader(),
      })
      .then((results) => {
        setEmployeeList(results?.data);
        setConsultantLoading(false);
      })
      .catch((error) => {
        setConsultantLoading(false);
        console.error("Error fetching consultationEmployeeList:", error);
      });
  };

  const fetchIssueTypeList = () => {
    setIssueTypeLoading(true);
    axios
      .get(`${baseUrl}/consultation/issueTypeList/`, {
        headers: authHeader(),
      })
      .then((data) => {
        setIssueTypeLoading(false);
        setIssueTypeList(data?.data);
      })
      .catch((error) => {
        setIssueTypeLoading(false);
        console.log(error);
      });
  };

  const fetchTicketList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}/consultation/ticketList/`, {
        headers: authHeader(),
        params: {
          page: currentPage,
          filter: filter,
        },
      })
      .then((data) => {
        setLoading(false);
        setTotalItems(data?.data.count || 0);
        setTicketList(data?.data?.results);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/consultation/analyticsData/`, {
        headers: authHeader(),
        params: {
          consultation_type: selectedType,
          status: selectedStatus,
          district: selectedDistrict,
          date_range: analyticsDataDateRange?.join(","),
        },
      })
      .then((data) => {
        setLoading(false);
        setAnalyticsData(data?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [
    selectedStatus,
    selectedType,
    selectedDistrict,
    analyticsDataDateRange,
    ticketList,
  ]);

  useEffect(() => {
    fetchConsultationEmployeeList();
  }, []);

  useEffect(() => {
    fetchIssueTypeList();
  }, []);

  useEffect(() => {
    fetchTicketList();
  }, [currentPage, filter]);

  const onChange = (value) => {
    setTabValue(value);
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district_name: data?.data?.district?.district_name,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleStatusSelect = (value) => {
    setSelectedStatus(value);
  };
  const handleTypeSelect = (value) => {
    setSelectedType(value);
  };
  const handleDistrictSelect = (value) => {
    setSelectedDistrict(value);
  };

  const handleDateRangeChange = (value) => {
    setAnalyticsDataDateRange(
      value?.map((date) => new Date(date).toISOString().split("T")[0])
    );
  };

  const handleTicketUpdateModal = (record) => {
    setTicketUpdateModal(true);
    setSelectedData(record);
  };
  const handleTicketModalUpdateCancel = () => {
    setTicketUpdateModal(false);
  };

  const consultantCreate = (data) => {
    setConsultantLoading(true);
    axios
      .post(
        `${baseUrl}/consultation/createConsultant/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        setConsultantLoading(false);
        if (data?.status === 201) {
          Swal.fire({
            title: "Success",
            text: data?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            consultantForm.resetFields();
            fetchConsultationEmployeeList();
          });
        }
      })
      .catch((error) => {
        setConsultantLoading(false);
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  const issueTypeCreate = (data) => {
    setIssueTypeLoading(true);
    axios
      .post(
        `${baseUrl}/consultation/createIssueType/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        setIssueTypeLoading(false);
        if (data?.status === 201) {
          Swal.fire({
            title: "Success",
            text: data?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            issueTypeForm.resetFields();
            fetchIssueTypeList();
          });
        }
      })
      .catch((error) => {
        setIssueTypeLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const onFinish = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/consultation/ticketCreate/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        setLoading(false);
        if (data?.status === 201) {
          Swal.fire({
            title: "Success",
            text: data?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            form.resetFields();
            fetchTicketList();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  const distinctTicketDistrict = [
    ...new Set(ticketList?.map((item) => item?.district)),
  ];

  const handleTicketDelete = (ticket_id) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to delete ${ticket_id} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/consultation/ticketDelete/${ticket_id}`, {
            headers: authHeader(),
          })
          .then((data) => {
            if (data?.status === 204) {
              Swal.fire({
                title: "Success",
                text: `${ticket_id} deleted successfully`,
                icon: "success",
                confirmButtonText: "Ok",
              }).then(() => {
                fetchTicketList();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error,
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };
  const issueColumns = [
    {
      title: "Issue ID",
      dataIndex: "issue_type_id",
    },
    {
      title: "Issue Type",
      dataIndex: "issue_type",
    },
  ];
  const consultantColumns = [
    {
      title: "Consultant ID",
      dataIndex: "consultant_id",
    },
    {
      title: "Consultant Name",
      render: (record) =>
        record?.employee?.user_info?.first_name +
        " " +
        record?.employee?.user_info?.last_name,
    },
  ];
  const ticketTableColumns = [
    {
      title: "Date",
      width: "10%",
      dataIndex: "opening_date",
    },
    {
      title: "Ticket ID",
      width: "10%",
      dataIndex: "ticket_id",
    },
    {
      title: "Location",
      width: "10%",
      dataIndex: "district",
    },
    {
      title: "Farmer",
      width: "12%",
      render: (record) => record?.farmer?.name,
    },
    {
      title: "Type",
      width: "8%",
      dataIndex: "consultation_type",
    },
    {
      title: "Issue Type",
      width: "10%",
      render: (record) => record?.issue_type?.issue_type,
    },
    {
      title: "Consultant",
      width: "10%",
      render: (record) =>
        record?.consultant?.employee?.user_info?.first_name +
        " " +
        record?.consultant?.employee?.user_info?.last_name,
    },
    {
      title: "FO",
      width: "10%",
      render: (record) =>
        record?.employee?.user_info?.first_name +
        " " +
        record?.employee?.user_info?.last_name,
    },
    {
      title: "Actions",
      render: (record) => (
        <div>
          <Button>
            <EditOutlined onClick={() => handleTicketUpdateModal(record)} />
          </Button>
          <Button onClick={() => handleTicketDelete(record?.ticket_id)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
      width: "10%",
    },
  ];

  const staticTableColumn = [
    {
      title: "Disrict",
      dataIndex: "district",
    },
    {
      title: "Ticket Count",
      dataIndex: "total_tickets",
    },
    {
      title: "Livestock",
      dataIndex: "livestock_tickets",
    },
    {
      title: "Crop",
      dataIndex: "crop_tickets",
    },
  ];
  const items = [
    {
      key: "ticketCreate",
      label: "Ticket Create",
      children: (
        <div
          className="border pe-2 pb-3"
          style={{ overflow: "auto", height: "420px" }}
        >
          <Form
            className="mt-2"
            onFinish={onFinish}
            form={form}
            layout="horizontal"
          >
            <Form.Item
              hasFeedback
              label="Ticket Date"
              name="ticket_date"
              initialValue={ticketCreationDate}
              labelCol={{ span: 6 }}
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Please select ticket date",
                },
              ]}
            >
              <Input placeholder="Ticket Date" readOnly />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Farmer Phone Number"
              name="phoneNumber"
              onChange={handleFarmerInfo}
              labelCol={{ span: 6 }}
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Please enter farmer's phone number",
                },
                {
                  pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <Input placeholder="Phone Number" type="tel" />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Farmer Name"
              name="farmer_name"
              labelCol={{ span: 6 }}
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Farmer name required",
                },
              ]}
            >
              <Input placeholder="Farmer Name" readOnly />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Farmer ID"
              name="farmer_id"
              labelCol={{ span: 6 }}
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Farmer ID required",
                },
              ]}
            >
              <Input placeholder="Farmer ID" readOnly />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="District"
              name="district_name"
              labelCol={{ span: 6 }}
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "District name required",
                },
              ]}
            >
              <Input placeholder="District Name" readOnly />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 6 }}
              hasFeedback
              label="Consultation Type"
              name="consultation_type"
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Consultation type required",
                },
              ]}
            >
              <Select placeholder="Select consultation type" showSearch>
                <Option value="Livestock">Livestock</Option>
                <Option value="Crop">Crop</Option>
              </Select>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 6 }}
              hasFeedback
              label="Crop/Livestock Name"
              name="crop_or_livestock_name"
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Crop/Livestock name required",
                },
              ]}
            >
              <Input placeholder="Crop/Livestock Name" />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 6 }}
              hasFeedback
              label="Issue Type"
              name="issue_type_id"
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Issue type required",
                },
              ]}
            >
              <Select placeholder="Select issue" showSearch>
                {issueTypeList?.map((issueType, index) => (
                  <Option value={issueType?.issue_type_id} key={index}>
                    {issueType?.issue_type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 6 }}
              hasFeedback
              label="Consultant Assign"
              name="consultant_id"
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: true,
                  message: "Please select consultant",
                },
              ]}
            >
              <Select placeholder="Select consultant" showSearch>
                {consultantList?.map((consultant, index) => (
                  <Option value={consultant?.consultant_id} key={index}>
                    {consultant?.employee?.user_info?.first_name +
                      " " +
                      consultant?.employee?.user_info?.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 6 }}
              hasFeedback
              label="FO Assign"
              name="employee_id"
              style={{ marginBottom: 5 }}
            >
              <Select placeholder="Select FO" showSearch>
                {employeeList?.map((employee, index) => (
                  <Option key={index} value={employee?.employee_id}>
                    {employee?.user_info?.first_name +
                      " " +
                      employee?.user_info?.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ textAlign: "right", margin: 0 }}>
              <Button
                style={{ backgroundColor: "#38512e", color: "#ffffff" }}
                htmlType="submit"
                disabled={loading}
              >
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "issueCreate",
      label: "Issue Create",
      children: (
        <div
          className="pe-2 pb-3"
          style={{ overflow: "auto", height: "420px" }}
        >
          <Form
            className="mt-2 border p-3"
            onFinish={issueTypeCreate}
            form={issueTypeForm}
            layout="horizontal"
          >
            <Form.Item
              hasFeedback
              label="Issue Type"
              name="create_issue_type"
              labelCol={{ span: 6 }}
              style={{ marginBottom: 8 }}
              rules={[
                {
                  required: true,
                  message: "Please enter issue type",
                },
                {
                  pattern: /^[a-zA-Z\s\-.]+(\s-\s\d+(\.\d+)?)?$/,
                  message: "Only english characters",
                },
              ]}
            >
              <Input placeholder="Issue Type" />
            </Form.Item>
            <Form.Item style={{ textAlign: "right", margin: 0 }}>
              <Button
                style={{ backgroundColor: "#38512e", color: "#ffffff" }}
                htmlType="submit"
                disabled={issueTypeLoading}
              >
                Submit
                {issueTypeLoading && (
                  <Spin size="small" style={{ marginLeft: 8 }} />
                )}
              </Button>
            </Form.Item>
          </Form>
          <Table
            className="mt-4 border"
            columns={issueColumns}
            dataSource={issueTypeList}
            scroll={{ y: 140 }}
          />
        </div>
      ),
    },
    {
      key: "consultantCreate",
      label: "Consultant Create",
      children: (
        <div
          className="pe-2 pb-3"
          style={{ overflow: "auto", height: "420px" }}
        >
          <Form
            className="mt-2 border p-3"
            onFinish={consultantCreate}
            form={consultantForm}
            layout="horizontal"
          >
            <Form.Item
              hasFeedback
              label="Consultant"
              name="consultant_employee_id"
              labelCol={{ span: 6 }}
              style={{ marginBottom: 8 }}
              rules={[
                {
                  required: true,
                  message: "Please select employee to make consultant",
                },
              ]}
            >
              <Select placeholder="Select employee">
                {employeeList?.map((employee, index) => (
                  <Option key={index} value={employee?.employee_id}>
                    {employee?.user_info?.first_name +
                      " " +
                      employee?.user_info?.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ textAlign: "right", margin: 0 }}>
              <Button
                style={{ backgroundColor: "#38512e", color: "#ffffff" }}
                htmlType="submit"
                disabled={consultantLoading}
              >
                Submit
                {consultantLoading && (
                  <Spin size="small" style={{ marginLeft: 8 }} />
                )}
              </Button>
            </Form.Item>
          </Form>
          <Table
            className="mt-4 border"
            columns={consultantColumns}
            dataSource={consultantList}
            loading={consultantLoading}
            scroll={{ y: 140 }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {" "}
      <TicketUpdateModal
        visible={ticketUpdateModal}
        onCancel={handleTicketModalUpdateCancel}
        data={selectedData}
        issueTypeList={issueTypeList}
        consultantList={consultantList}
        employeeList={employeeList}
        fetchTicketList={fetchTicketList}
      />
      <div className="consultationDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>CONSULTATION DASHBOARD</h3>
        </div>
        <div className="consultationDashboardContainer shadow-sm rounded">
          <div className="mainView px-3">
            <div className="tabsAndTable">
              <div className="tabs mt-4">
                <p
                  className="text-center py-2 border"
                  style={{ backgroundColor: "lightgray" }}
                >
                  Ticket & Issue
                </p>
                <Tabs
                  defaultActiveKey="projects"
                  items={items}
                  onChange={onChange}
                />
              </div>
              <div className="ticketTable border mt-2">
                <div className="m-2 mt-4 d-flex justify-content-end align-items-center">
                  <Search
                    className="w-25"
                    placeholder="search here..."
                    allowClear
                    onSearch={handleFilterChange}
                    enterButton={
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#38512e",
                          borderColor: "#38512e",
                        }}
                      >
                        Search
                      </Button>
                    }
                  />
                </div>
                <Table
                  columns={ticketTableColumns}
                  dataSource={ticketList}
                  loading={loading}
                  rowKey={(record) => record.ticket_id}
                  scroll={{ y: 340 }}
                  pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize: 10, // Set the number of items per page
                    onChange: handlePageChange,
                  }}
                  size="small"
                />
              </div>
            </div>

            <div className="totalStats">
              <div className="stats mt-4 ms-2">
                <p
                  className="text-center py-2 border"
                  style={{ backgroundColor: "lightgray" }}
                >
                  Stats
                </p>
                <div className="d-flex justify-content-end mb-2">
                  <div>
                    <p className="m-0">Date: </p>
                    <DateRangePicker
                      onChange={handleDateRangeChange}
                      showOneCalendar
                    />
                  </div>
                  <div className="ms-3">
                    <p className="m-0">District: </p>
                    <Select
                      style={{ width: "140px" }}
                      placeholder="Select District"
                      onChange={handleDistrictSelect}
                    >
                      <Option value="">All Districts</Option>
                      {distinctTicketDistrict?.map((district, index) => (
                        <Option value={district} key={index}>
                          {district}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="ms-3">
                    <p className="m-0">Type: </p>
                    <Select
                      style={{ width: "140px" }}
                      placeholder="Select Type"
                      onChange={handleTypeSelect}
                    >
                      <Option value="">All Types</Option>
                      <Option value="Crop">Crop</Option>
                      <Option value="Livestock">Livestock</Option>
                    </Select>
                  </div>
                  <div className="mx-3">
                    <p className="m-0">Status: </p>
                    <Select
                      placeholder="Select Status"
                      onChange={handleStatusSelect}
                      style={{ width: "140px" }}
                    >
                      <Option value="">All Status</Option>
                      <Option value="Open">Open</Option>
                      <Option value="Close">Close</Option>
                    </Select>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="w-50 border">
                    <PieChart
                      title="Status"
                      data={analyticsData?.status_pie_chart}
                    ></PieChart>
                  </div>
                  <div className="w-50 border">
                    <PieChart
                      title="LS vs Crop"
                      data={analyticsData?.consultation_type_pie_chart}
                    ></PieChart>
                  </div>
                </div>
              </div>
              <div className="statsTable my-2 ms-2 border ">
                <Table
                  className="mt-3"
                  columns={staticTableColumn}
                  dataSource={analyticsData?.table_data}
                  loading={loading}
                  scroll={{ y: 165 }}
                  rowKey={(record, index) => index}
                />
              </div>
              <div className="consultationBarChart mt-2 ms-2 border">
                <BarChart
                  districtBarChartData={analyticsData?.district_bar_chart}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
