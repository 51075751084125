import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChart = ({ districtBarChartData }) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'column',
      height: 250,
    },
    title: {
      text: 'District wise Ticket Count',
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    series: [
      {
        name: 'Ticket Count',
        data: [],
      },
    ],
    colors: ['#c2d26d'],
  });

  useEffect(() => {
    if (districtBarChartData) {
      const categories = districtBarChartData.map(entry => entry.name);
      const data = districtBarChartData.map(entry => entry.y);
      setChartOptions({
        ...chartOptions,
        xAxis: {
          categories: categories,
        },
        series: [
          {
            name: 'Ticket Count',
            data: data,
          },
        ],
      });
    }
  }, [districtBarChartData]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default BarChart;
