import "./RouteMap.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import retailerList from "./retailerData";

import { Icon } from "leaflet";
import { useEffect, useState, useRef } from "react";
import { Input, Select } from "antd";

function RouteMap() {
  const { Option } = Select;
  const [districtFilter, setDistrictFilter] = useState("All Districts");
  const [unionFilter, setUnionFilter] = useState("All Unions");
  const [phoneSearchInput, setPhoneSearchInput] = useState("");
  const [visibleRetailers, setVisibleRetailers] = useState([]);
  const [selectedRetailerIndex, setSelectedRetailerIndex] = useState(null);
  const [uniqueUnions, setUniqueUnions] = useState([]);
  const markers = useRef([]);
  const mapRef = useRef(null);

  const uniqueDistricts = [
    "All Districts",
    ...new Set(retailerList.map((shop) => shop.District_Name)),
  ];

  useEffect(() => {
    handleFiltering();
    // Update unique unions when district filter changes
    if (districtFilter !== "All Districts") {
      const unions = [
        ...new Set(
          retailerList
            .filter((shop) => shop.District_Name === districtFilter)
            .map((shop) => shop.Union_Name)
        ),
      ];
      setUniqueUnions(["All Unions", ...unions]);
    } else {
      setUniqueUnions([]);
    }
  }, [districtFilter, unionFilter, phoneSearchInput]);

  const handleFiltering = () => {
    let filteredRetailers = [...retailerList];

    // Filter by district
    if (districtFilter !== "All Districts") {
      filteredRetailers = filteredRetailers.filter(
        (shop) => shop.District_Name === districtFilter
      );
    }

    // Filter by union
    if (unionFilter !== "All Unions") {
      filteredRetailers = filteredRetailers.filter(
        (shop) => shop.Union_Name === unionFilter
      );
    }

    // Filter by phone number
    if (phoneSearchInput !== "") {
      filteredRetailers = filteredRetailers.filter(
        (shop) => String(shop.Retailer_Phone).indexOf(phoneSearchInput) !== -1
      );
    }

    setVisibleRetailers(filteredRetailers);
  };

  const handleDistrictFilter = (value) => {
    setDistrictFilter(value);
    setUnionFilter("All Unions");
  };


  const handleUnionFilter = (value) => {
    setUnionFilter(value);
  };

  const handlePhoneSearch = (event) => {
    setPhoneSearchInput(event.target.value.trim().toLowerCase());
  };

  // Create custom icon
  const customIcon = new Icon({
    iconUrl: require("./icons/placeholder.png"),
    iconSize: [30, 40],
  });

  const handleRetailerSelection = (index) => {
    setSelectedRetailerIndex(index);
    const selectedRetailer = visibleRetailers[index];
    if (selectedRetailer && mapRef.current) {
      const { Retailer_ID, Latitude, Longitude } = selectedRetailer;
      const marker = markers.current.find(
        (marker) =>
          marker.getLatLng().lat === Latitude &&
          marker.getLatLng().lng === Longitude &&
          marker.leafletId === Retailer_ID
      );
      console.log(marker);

      if (marker) {
        // Close any open popups before flying to the location
        mapRef.current.closePopup();

        // Fly to the location
        mapRef.current.flyTo([Latitude, Longitude], 18, {
          animate: true,
          duration: 1,
        });

        // Once the map finishes flying to the location, open the popup
        mapRef.current.on("zoomend", () => {
          marker.openPopup();
          // Remove the event listener to avoid multiple popups opening
          mapRef.current.off("zoomend");
        });
      }
    }
  };

  return (
    <div>
      <main>
        <div className="retailer_list">
          <div className="heading">
            <h2>Agrigate Retailer List</h2>
            {/* District Filter */}
            <Select
              className="me-2"
              id="districtFilter"
              onChange={handleDistrictFilter}
              value={districtFilter}
              style={{ width: "180px" }}
            >
              {uniqueDistricts.map((district) => (
                <Option key={district} value={district}>
                  {district}
                </Option>
              ))}
            </Select>
            {/* Union Filter */}
            <Select
              id="unionFilter"
              onChange={handleUnionFilter}
              value={unionFilter}
              style={{ width: "180px" }}
            >
              {uniqueUnions.map((union) => (
                <Option key={union} value={union}>
                  {union}
                </Option>
              ))}
            </Select>
            {/* Phone Search */}
            <Input
              type="text"
              id="phoneSearchInput"
              placeholder="Search by phone number"
              onBlur={handlePhoneSearch}
              value={phoneSearchInput}
              onChange={(e) => setPhoneSearchInput(e.target.value)}
            />
            {/* Total Retailers */}
            <p id="totalRetailersPlaceholder">
              Total Retailers: {visibleRetailers.length}
            </p>
          </div>
          {/* Retailer List */}
          <ul className="list">
            {visibleRetailers.map((shop, index) => (
              <li key={index} onClick={() => handleRetailerSelection(index)}>
                <div className="retailer-data">
                  <a href="#">{shop.Retailer_Name}</a>
                  <p>RID: {shop.Retailer_ID}</p>
                  <p>Shop Name: {shop.Traders_Name}</p>
                  <p>Union: {shop.Union_Name}</p>
                  <p>Phone: {shop.Retailer_Phone}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* Map */}
        <MapContainer center={[23.7902, 90.4096]} zoom={7.5} ref={mapRef}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* Markers */}
          <MarkerClusterGroup>
            {visibleRetailers.map((retailer, index) => (
              <Marker
                key={index}
                position={[retailer.Latitude, retailer.Longitude]}
                icon={customIcon}
                ref={(marker) => {
                  if (marker) {
                    marker.leafletId = retailer.Retailer_ID;
                    markers.current.push(marker);
                  }
                }}
              >
                <Popup>
                  <div>
                    <h3>Retailer Info</h3>
                    {/* Render Popup Content */}
                    <p>Retailer Name: {retailer.Retailer_Name}</p>
                    <p>Retailer ID: {retailer.Retailer_ID}</p>
                    <p>Phone: {retailer.Retailer_Phone}</p>
                    <p>Traders Name: {retailer.Traders_Name}</p>
                    <p>Bazar Name: {retailer.Bazar_Name}</p>
                    <p>District: {retailer.District_Name}</p>
                    <p>Upazila: {retailer.Upazila_Name}</p>
                    <p>Union: {retailer.Union_Name}</p>
                    <p>Last Visited: {retailer.Last_visited}</p>
                    <p>Route Name: {retailer.Route_name}</p>
                    <p>Assigned To: {retailer.Assigned_to}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      </main>
    </div>
  );
}

export default RouteMap;
