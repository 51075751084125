import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChart = ({ data }) => {
  const chartRef = useRef(null);
  const [options, setOptions] = useState();

  useEffect(() => {
    if (chartRef.current) {
      const groupedData = groupDataByDate(data);
      const categories = Object.keys(groupedData).sort(); // Sort the dates
      const seriesData = categories.map(date => groupedData[date].length);
      const options = {
        chart: {
          type: 'column',
          height: 260,
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: categories,
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Number of Orders'
          }
        },
        series: [
          {
            name: 'Orders Fulfilled',
            data: seriesData
          }
        ]
      };

      setOptions(options);
    }
  }, [data]);

  const groupDataByDate = data => {
    const groupedData = {};

    data.forEach(item => {
      const date = item.fulfillment_date;
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
      groupedData[date].push(item);
    });

    return groupedData;
  };

  return <HighchartsReact  highcharts={Highcharts} options={options} ref={chartRef} />;
};

export default BarChart;
