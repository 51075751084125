import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  Modal,
  DatePicker,
  Upload,
} from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { UploadOutlined } from "@ant-design/icons";
import awsS3 from "aws-sdk/clients/s3";

export default function WorkOrderCreateModal({
  visible,
  handleWorkOrderModalClose,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [workOrderDate, setWorkOrderDate] = useState(null);
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [ipInfo, setIPInfo] = useState([]);
  const [sourcingProductsList, setSourcingProductsList] = useState([]);
  const [WOImageUrl, setWOImageUrl] = useState("");
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );

  const handleWorkOrderDateChange = (date, dateString) => {
    setWorkOrderDate(dateString);
  };

  const handleDeadlineDateChange = (date, dateString) => {
    setDeadlineDate(dateString);
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/ip/ip_info`, {
        headers: authHeader(),
      })
      .then((data) => setIPInfo(data?.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/sourcingProductList`, {
        headers: authHeader(),
      })
      .then((data) => setSourcingProductsList(data?.data));
  }, []);

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };
    const s3 = new awsS3({
      accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });
      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (data) => {
    data.work_order_date = workOrderDate;
    data.deadline_date = deadlineDate;
    data.employee_id = userProfile.employee_id;
    data.wo_image = WOImageUrl;
    axios
      .post(
        `${baseUrl}/ip/work_order_create/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            form.resetFields();
            setWorkOrderDate(null);
            setDeadlineDate(null);
            handleWorkOrderModalClose();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  return (
    <Modal
      title="Work Order Create"
      open={visible}
      onCancel={handleWorkOrderModalClose}
      footer={false}
    >
      <Form
        className="register-form"
        form={form}
        onFinish={handleSubmit}
        layout="horizontal"
      >
        <Form.Item
          hasFeedback
          label="Work Order Date"
          name="work_order_date"
          labelCol={{ span: 8 }}
          rules={[{ required: true, message: "Please enter work order date" }]}
        >
          <DatePicker
            format="YYYY-MM-DD"
            onChange={handleWorkOrderDateChange}
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Company Name"
          name="company_id"
          labelCol={{ span: 8 }}
          rules={[{ required: true, message: "Please select company" }]}
        >
          <Select
            placeholder="Select company"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {ipInfo?.map((ip) => (
              <Option key={ip.company_id} value={ip.company_id}>
                {ip.company_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="PO(Purchase Order)"
          name="purchase_order"
          labelCol={{ span: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter PO",
            },
            {
              pattern: /^[a-zA-Z]?|[0-9]+(\.[0-9]+)+$/,
              message: "Only English characters",
            },
          ]}
        >
          <Input placeholder="Purchase order" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Product List"
          name="sourcing_product_id"
          labelCol={{ span: 8 }}
          rules={[{ required: true, message: "Please select product" }]}
        >
          <Select
            placeholder="Select product"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {sourcingProductsList?.map((ip) => (
              <Option
                key={ip.sourcing_product_id}
                value={ip.sourcing_product_id}
              >
                {ip.product_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Work Order Quantity"
          name="work_order_qty"
          labelCol={{ span: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter ordered quantity",
            },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only english number",
            },
          ]}
        >
          <Input placeholder="Work Order Quantity" type="tel" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Rate (Tk/Kg)"
          name="rate_tk_per_kg"
          labelCol={{ span: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter rate",
            },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only english number",
            },
          ]}
        >
          <Input placeholder="Rate (Tk/Kg)" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Deadline"
          name="deadline_date"
          labelCol={{ span: 8 }}
        >
          <DatePicker format="YYYY-MM-DD" onChange={handleDeadlineDateChange} />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Remarks"
          name="remarks"
          initialValue=""
          labelCol={{ span: 8 }}
        >
          <Input placeholder="Remarks" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="WO Image"
          name="wo_image"
          valuePropName="fileList"
          labelCol={{ span: 8 }}
          getValueFromEvent={(e) => e.fileList}
          rules={[
            {
              required: true,
              message: "Please upload WO image",
            },
            {
              validator: (_, value) => {
                if (value && value.length > 0) {
                  const file = value[0];
                  const fileType = file.type;
                  const acceptedFormats = [
                    "image/jpeg",
                    "image/png",
                    "image/jpg",
                    "image/heic",
                  ];
                  if (acceptedFormats.includes(fileType)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                    );
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(info) => handleImageUpload(info, setWOImageUrl)}
            listType="picture"
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#38512e",
                color: "#ffffff",
              }}
              icon={<UploadOutlined />}
            >
              Upload WO Image
            </Button>
          </Upload>
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              style={{
                backgroundColor: "#38512e",
                color: "#ffffff",
              }}
            >
              Create
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
