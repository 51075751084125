import { Button, DatePicker, Form, Input, Modal, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";

export default function DueUpdateModal({
  visible,
  data,
  handleClose,
  fetchData,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [duePayAmount, setDuePayAmount] = useState(0);
  const [selectedDate, setSelectedDate] = useState()

  useEffect(() => {
    form.setFieldsValue({
      due: data?.due,
    });
  }, [data?.due, form]);

  const modalClose = () => {
    form.setFieldsValue({
      due_pay: null,
      due_payment_date: null,
    });
    handleClose();
  };
  const handleDuePayDate = (date, dateString) => {
    setSelectedDate(dateString);
  }

  const handleDuePay = (value) => {
    const currentDue = data?.due;
    if (value > currentDue) {
      form.setFields([
        {
          name: "due",
          errors: ["Entered amount cannot be greater than the due."],
        },
      ]);
    } else {
      form.setFieldsValue({
        due: currentDue - value,
      });
    }
    setDuePayAmount(value);
  };

  const handleUpdate = (value) => {
    value.total_paid = parseFloat(data?.total_paid) + parseFloat(duePayAmount);
    value.due_payment_date = selectedDate;
    axios
      .patch(`${baseUrl}/inventory/sales_due_update/${data?.sales_id}/`, value, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Due Updated Successful",
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            fetchData();
            modalClose();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  return (
    <Modal open={visible} onCancel={modalClose} centered footer={false}>
      <h4 className="text-center" style={{ color: "#38512e" }}>
        <strong>Agrigate Network Limited</strong>
      </h4>
      <h6 className="text-center" style={{ color: "#38512e" }}>
        Center - {data?.center?.center_name}
      </h6>
      <div
        className="d-flex justify-content-around my-4 pt-3 fw-medium"
        style={{
          color: "#38512e",
          fontSize: "16px",
          border: "solid 1px #38512e",
        }}
      >
        <div className="w-50 ms-3">
          <p className="mb-0">Invoice:</p>
          <p>Date:</p>

          <p className="mb-0">Customer Name:</p>
          <p>Customer Number:</p>
        </div>
        <div className="w-50">
          <p className="mb-0">
            <strong>{data?.sales_id}</strong>
          </p>
          <p>{new Date(data?.created_at).toLocaleString()}</p>
          <p className="mb-0">
            <strong>{data?.farmer?.name || data?.retailer?.name || data?.institutional?.institutional_buyer_name}</strong>
          </p>
          <p> {"0" + (data?.farmer?.phone || data?.retailer?.phone || data?.institutional?.institutional_buyer_phone)}</p>
        </div>
      </div>
      {parseFloat(data?.due) > 0 ? (
        <div
          className="d-flex justify-content-around my-4 pt-3 fw-medium"
          style={{
            color: "#38512e",
            fontSize: "16px",
            border: "solid 1px #38512e",
          }}
        >
          <Form form={form} layout="horizontal" onFinish={handleUpdate}>
            <Form.Item
              label="Due"
              labelCol={{ span: 9 }}
              name="due"
              rules={[
                {
                  required: true,
                  message: "Due required",
                },
              ]}
            >
              <Input placeholder="Due" readOnly />
            </Form.Item>
            <Form.Item
              label="Due Pay Amount"
              name="due_pay"
              rules={[
                {
                  required: true,
                  message: "Please enter due pay amount",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Only english number",
                },
              ]}
            >
              <Input
                placeholder="Due pay amount"
                onChange={(e) => handleDuePay(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Due Pay Date"
              name="due_payment_date"
              labelCol={{ span: 9 }}
              rules={[
                {
                  required: true,
                  message: "Please select due pay date",
                },
              ]}
            >
              <DatePicker format="YYYY-MM-DD" onChange={handleDuePayDate}/>
            </Form.Item>
            <Form.Item className="d-flex justify-content-end ">
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                style={{ color: "#ffffff", backgroundColor: "#203509" }}
              >
                Update
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
}
