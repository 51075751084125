import { Button, Input, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../assets/Agrigate.png";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import DueDetailsModal from "./DueDetailsModal";

export default function ContractFarmingHistory() {
  const { Option } = Select;
  const [projectDetails, setProjectDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Search } = Input;
  const [searchedPhone, setSearchedPhone] = useState("");

  const [selectedProjectAnalyticsName, setSelectedProjectAnalyticsName] =
    useState("");
  const [CFDistinctData, setCFDistinctData] = useState({});
  const [selectedProjectAnalyticsType, setSelectedProjectAnalyticsType] =
    useState("");
  const [selectedProjectAnalyticsYear, setSelectedProjectAnalyticsYear] =
    useState("");
  const [dueDetailsProjectId, setDueDetailsProjectId] = useState("");
  const [dueDetailsModal, setDueDetailsModal] = useState(false);
  const [CFAnalyticsLoading, setCFAnalyticsLoading] = useState(false);

  const [CFAnalyticsData, setCFAnalyticsData] = useState([]);

  const handleDueDetailsModalClose = () => {
    setDueDetailsModal(false);
    setDueDetailsProjectId(null);
    setProjectDetails(null);

  };
  const handleProjectAnalyticsName = (value) => {
    setSelectedProjectAnalyticsName(value);
  };
  const handleProjectAnalyticsType = (value) => {
    setSelectedProjectAnalyticsType(value);
  };
  const handleProjectAnalyticsYear = (value) => {
    setSelectedProjectAnalyticsYear(value);
  };

  const handleDueDetails = (id) => {
    setDueDetailsProjectId(id);
    setDueDetailsModal(true);
  };

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbySUkP6ME6OdtNLcu4_CkxbiEf74EBBOOqbs7xzjZTRNufti-w2ZHi7ysc6mNKGBXCTXg/exec`
      )
      .then((response) => {
        setCFDistinctData(response?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setCFAnalyticsLoading(true);
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbz7PVjGnw0CMxI1mT5A2rgcvdmWZjC_iqGNyO7TxArc4qOA1Wiu00RcZniO5G6QMtZK/exec?projectName=${selectedProjectAnalyticsName}&&projectType=${selectedProjectAnalyticsType}&&projectYear=${selectedProjectAnalyticsYear}`
      )
      .then((response) => {
        setCFAnalyticsLoading(false);
        setCFAnalyticsData(response?.data?.data);
      })
      .catch((error) => {
        setCFAnalyticsLoading(false);
        console.log(error);
      });
  }, [
    selectedProjectAnalyticsName,
    selectedProjectAnalyticsType,
    selectedProjectAnalyticsYear,
  ]);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://script.google.com/macros/s/AKfycbyEHYUK2lkO6RQg6mHOHfEydWoc2E1sOuDnVUdykdPymIHgdJNwV9xC3yK6R0Gkn2lS/exec?projectId=${dueDetailsProjectId}&&phone=${searchedPhone}`
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setProjectDetails(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [dueDetailsProjectId, searchedPhone]);

  const handlePhoneNumber = (value) => {
    setSearchedPhone(value);
  };

  const projectAnalyticsColumn = [
    {
      title: "Project",
      dataIndex: "Project_ID",
    },
    {
      title: "Location",
      dataIndex: "Location",
    },
    {
      title: "Total Farmer",
      dataIndex: "Total_Farmer",
    },
    {
      title: "Input",
      dataIndex: "Input",
    },
    {
      title: "Output",
      dataIndex: "Output",
    },
    {
      title: "Due",
      dataIndex: "Due",
    },
    {
      title: "Details",
      render: (record) => (
        <div>
          <Button
            onClick={() => {
              handleDueDetails(record?.Project_ID);
            }}
          >
            Details
          </Button>
        </div>
      ),
    },
  ];
  const projectDetailsColumn = [
    {
      title: "Project Name",
      render: (record) => record?.project_name,
    },
    {
      title: "Project Year",
      render: (record) => record?.project_year,
    },
    {
      title: "Project Type",
      render: (record) => record?.project_type,
    },
    {
      title: "Center",
      render: (record) => record?.center,
    },
    {
      title: "Customer Id",
      render: (record) => record?.customer_id,
    },
    {
      title: "Customer Name",
      render: (record) => record?.customer_name,
    },
    {
      title: "Number",
      render: (record) => "0" + record?.customer_number,
    },
    {
      title: "Credit",
      render: (record) => parseFloat(record?.credit)?.toLocaleString(),
    },
    {
      title: "Paid",
      render: (record) => parseFloat(record?.paid)?.toLocaleString(),
    },
    {
      title: "Due",
      render: (record) => parseFloat(record?.due)?.toLocaleString(),
    },
    {
      title: "Last Payment",
      render: (record) => {
        const lastPaymentDate = record?.last_payment_date;
        if (!lastPaymentDate) {
          return "";
        }
        return new Date(lastPaymentDate)?.toLocaleDateString() ?? "";
      }
    },
  ];
  return (
    <>
      <div className="p-5">
        <section className="headSection d-flex align-items-center">
          <div>
            <img
              src={logo}
              style={{ width: "100px", height: "100px" }}
              alt="agrigate"
            />
          </div>
          <div>
            <h4>Contract Farming History</h4>
          </div>
        </section>

        <div className=" pe-3">
          <p
            className="text-center py-2 border"
            style={{ backgroundColor: "lightgray" }}
          >
            Project Analytics
          </p>
          <div className="my-2 border">
            <div className="d-flex">
              <div className="ms-3 mt-3">
                <p className="m-0">Project: </p>
                <Select
                  placeholder="Select District"
                  onChange={handleProjectAnalyticsName}
                  value={selectedProjectAnalyticsName}
                  style={{ width: "200px" }}
                >
                  <Option value="">All Projects</Option>
                  {CFDistinctData?.distinctProject?.map((project, index) => (
                    <Option key={index} value={project}>
                      {project}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Type: </p>
                <Select
                  placeholder="Select Type"
                  onChange={handleProjectAnalyticsType}
                  style={{ width: "200px" }}
                  value={selectedProjectAnalyticsType}
                >
                  <Option value="">All Types</Option>
                  <Option value="Crop">Crop</Option>
                  <Option value="Livestock">Livestock</Option>
                </Select>
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Year: </p>
                <Select
                  placeholder="Select Year"
                  onChange={handleProjectAnalyticsYear}
                  style={{ width: "200px" }}
                  value={selectedProjectAnalyticsYear}
                >
                  <Option value="">All Years</Option>
                  {CFDistinctData?.distinctYear?.map((project, index) => (
                    <Option key={index} value={project}>
                      {project}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <Table
              className="mt-3"
              columns={projectAnalyticsColumn}
              dataSource={CFAnalyticsData}
              loading={CFAnalyticsLoading}
              size="small"
              scroll={{ y: 180 }}
              rowKey={(record) => record.Project_ID}
            />
          </div>
        </div>
      </div>

      <Modal
        open={dueDetailsModal}
        footer={false}
        onCancel={handleDueDetailsModalClose}
        width="70%"
      >
        <div className="m-2">
          <div className="d-flex flex-end">
            <Search
              className="mb-2"
              placeholder="Search number"
              style={{ width: "400px" }}
              onSearch={handlePhoneNumber}
              allowClear
            />
          </div>
          <Table
            dataSource={projectDetails}
            columns={projectDetailsColumn}
            loading={loading}
            rowKey={(record) =>record?.customer_id}
          />
        </div>
      </Modal>
    </>
  );
}
