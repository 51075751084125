import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Modal, Spin } from "antd";
import axios from "axios";
import authHeader from "../Authentication/authHeader";
import baseUrl from "../../config";
import Swal from "sweetalert2";

export default function WarehouseStockOutUpdateModal({
  viewModal,
  handleWarehouseUpdateModalClose,
  data,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      driver_name: data?.driver_name,
      driver_number: "0" + data?.driver_number,
      truck_no: data?.truck_no,
      transport_cost_tk: data?.transport_cost_tk,
      transport_rent_status: data?.transport_rent_status,
    });
  }, [data, form]);

  const handleUpdate = (value) => {
    setLoading(true);
    axios
      .patch(`${baseUrl}/sourcing/warehouse_stock_out_update/${data?.gdn_number}/`, value, {
        headers: authHeader(),
      })
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Transportation Updated Successful",
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            
            handleWarehouseUpdateModalClose();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  return (
    <Modal
      open={viewModal}
      onCancel={handleWarehouseUpdateModalClose}
      width="50%"
      footer={false}
    >
      <p className="text-center" style={{ color: "#203509", fontSize: 16 }}>
        <strong>Transportation Info </strong>
      </p>
      <div className="mb-4">
        <Form
          form={form}
          color="#203509"
          onFinish={handleUpdate}
          layout="vertical"
        >
          <div className="d-flex">
            <div className="d-flex justify-content-center">
              <Form.Item className="ms-2">
                <Input
                  className="text-center custom-placeholder-color"
                  placeholder="Truck Driver Name"
                  disabled
                  style={{ color: "#203509", borderColor: "#203509" }}
                />
              </Form.Item>

              <Form.Item
                className="ms-2"
                name="driver_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter driver name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only English characters",
                  },
                ]}
              >
                <Input
                  className="text-center"
                  placeholder="Truck Driver Name"
                  style={{ color: "#203509", borderColor: "#203509" }}
                  readOnly
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-content-center">
              <Form.Item className="ms-2">
                <Input
                  className="text-center custom-placeholder-color"
                  placeholder="Truck Driver Phone"
                  disabled
                  style={{ color: "#203509", borderColor: "#203509" }}
                />
              </Form.Item>
              <Form.Item
                className="ms-2"
                name="driver_number"
                rules={[
                  {
                    required: true,
                    message: "Driver's phone number required",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input
                  className="text-center"
                  placeholder="Truck Driver Phone"
                  style={{ color: "#203509", borderColor: "#203509" }}
                  readOnly
                />
              </Form.Item>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex justify-content-center">
              <Form.Item className="ms-2">
                <Input
                  className="text-center custom-placeholder-color"
                  placeholder="Truck No"
                  disabled
                  style={{ color: "#203509", borderColor: "#203509" }}
                />
              </Form.Item>
              <Form.Item className="ms-2" name="truck_no">
                <Input
                  className="text-center"
                  placeholder="Truck No"
                  style={{ color: "#203509", borderColor: "#203509" }}
                  readOnly
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-content-center">
              <Form.Item className="ms-2">
                <Input
                  className="text-center custom-placeholder-color"
                  placeholder="Transport Cost"
                  disabled
                  style={{ color: "#203509", borderColor: "#203509" }}
                />
              </Form.Item>
              <Form.Item
                className="ms-2"
                name="transport_cost_tk"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/, // Regular expression to match English characters and spaces
                    message: "Only English numbers",
                  },
                ]}
              >
                <Input
                  className="text-center"
                  placeholder="Transportation Cost"
                  style={{ color: "#203509", borderColor: "#203509" }}
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-center">
              <Form.Item className="ms-2">
                <Input
                  className="text-center custom-placeholder-color"
                  placeholder="Transport Rent Status"
                  disabled
                  style={{ color: "#203509", borderColor: "#203509" }}
                />
              </Form.Item>
              <Form.Item className="ms-2" name="transport_rent_status">
                <Select
                  placeholder="Select Rent Status"
                  style={{ width: "200px" }}
                >
                  <Option value="N/A">N/A</Option>
                  <Option value="Paid">Paid</Option>
                  <Option value="Pending">Pending</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item className="d-flex justify-content-end ">
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              style={{ color: "#ffffff", backgroundColor: "#203509" }}
            >
              Update
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
