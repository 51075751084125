import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./LoginPage.css";
import agrigate from "../../assets/Agrigate.png";
import { loginContext } from "../../App";
import Swal from "sweetalert2";
import authService from "../Authentication/authService";
import axios from "axios";
import authHeader from "../Authentication/authHeader";
import baseUrl from "../../config";

const LoginPage = () => {
  const { loggedIn, userPermissions, setLoggedIn, setUserPermissions } =
    useContext(loginContext);
  const history = useHistory();
  const location = useLocation();
  let { from } = location.state || { from: { pathname: "/home" } };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function setCookie(name, value, days, domain) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
      name + "=" + (value || "") + expires + "; domain=" + domain + "; path=/";
  }
  const onSubmit = async (data) => {
    const loginData = {
      loginUsername: data.loginUsername,
      loginPassword: data.loginPassword,
    };
    authService
      .login(loginData)
      .then((response) => {
        const results = response?.data;
        if (results.access) {
          // Retrieve the employee profile information
          axios
            .get(`${baseUrl}/employee/profile/`, {
              headers: authHeader(),
            })
            .then((response) => {
              const employeeProfile = response?.data;
              const user_info = employeeProfile.user_info;

              axios
                .post(
                  `https://onethread-backend.agrigate.network/api/v1/accounts/login/`,
                  {
                    email: user_info.email,
                    password: data.loginPassword,
                    pushSubscription: user_info.email,
                  }
                )
                .then((response) => {
                  console.log(response);

                  // Extracting tokens from the response
                  const accessToken = response?.data?.accessToken;
                  const refreshToken = response?.data?.refreshToken;

                  // Setting cookies
                  const domainName = ".agrigate.network"; 
                  setCookie(
                    "ot_access_id",
                    accessToken,
                    365,
                    domainName
                  );
                  setCookie(
                    "ot_refresh_id",
                    refreshToken,
                    365,
                    domainName
                  );
                  setCookie(
                    "REACT_APP_COOKIE_DOMAIN",
                    domainName,
                    365,
                    domainName
                  );
                });

              user_info["employee_id"] = employeeProfile.employee_id;
              setUserPermissions(employeeProfile?.user_permissions);

              localStorage.setItem("userProfile", JSON.stringify(user_info));
              localStorage.setItem(
                "userGroups",
                JSON.stringify(employeeProfile.user_groups)
              );
              localStorage.setItem(
                "user_permissions",
                JSON.stringify(employeeProfile.user_permissions)
              );

              setLoggedIn(results);
              history.replace(from);
            })
            .catch((error) => {
              console.error("Error1:", error);
              Swal.fire({
                title: "Wrong",
                text: error?.message,
                icon: "error",
                confirmButtonText: "Retry",
              });
              history.push("/login");
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Wrong",
          text: "Username or Password",
          icon: "error",
          confirmButtonText: "Retry",
        });
        history.push("/login");
      });
  };
  useEffect(() => {
    localStorage.setItem("access_token", JSON.stringify(loggedIn?.access));
    localStorage.setItem("refresh_token", JSON.stringify(loggedIn?.refresh));
  }, [loggedIn]);

  return (
    <>
      <div></div>
      <div className="loginDisplayMain">
        <div className="shadow-sm loginDisplay">
          <div className="leftHandBox ">
            <h3 className="mb-5">LOGIN</h3>
            <form className="w-75" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  {...register("loginUsername")}
                  className="form-control"
                  placeholder="Enter username"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  {...register("loginPassword")}
                  className="form-control"
                  placeholder="Password"
                  required
                />
              </div>
              {/* <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                </div> */}

              <div></div>
              <button
                type="submit"
                className="btn mt-5"
                style={{ backgroundColor: "#5f8b3d", color: "white" }}
              >
                Login
              </button>
            </form>
          </div>
          <div className="rightHandBox">
            <img className="img-fluid w-50" src={agrigate} alt="" />
            <h5 className="text-center" style={{ color: "#38512e" }}>
              Agrigate
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
