import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import { Button, Select, Table, Tooltip } from "antd";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./OrderFulfillment.css";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import OrderFulfillmentModal from "./OrderFulfillmentModal";
import BarChart from "../BarChart/BarChart";
import OrderFulfillmentUpdateModal from "./OrderFulfillmentUpdateModal";

export default function OrderFulfillment() {
  const { Option } = Select;
  const [fulfillmentList, setFulfillmentList] = useState([]);
  const [countData, setCountData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [foID, setFoID] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [updateModalData, setUpdateModalData] = useState();
  const [viewUpdateModal, setViewUpdateModal] = useState(false);
  const [orderFulfillmentTimeline, setOrderFulfillmentTimeline] = useState([]);

  const [revenueSelectedDateRange, setRevenueSelectedDateRange] =
    useState(null);
  const [revenueSelectedDistrict, setRevenueSelectedDistrict] = useState("");

  const [timelineSelectedDateRange, setTimelineSelectedDateRange] =
    useState(null);
  const [timelineSelectedDistrict, setTimelineSelectedDistrict] = useState("");
  const [timelineSelectedType, setTimelineSelectedType] = useState("");

  const [totalDataSelectedDateRange, setTotalDataSelectedDateRange] =
    useState(null);
  const [totalDataSelectedDistrict, setTotalDataSelectedDistrict] =
    useState("");
  const [totalDataSelectedType, setTotalDataSelectedType] = useState("");
  const [totalDataSelectedFO, setTotalDataSelectedFO] = useState("");

  const [countDataSelectedDateRange, setCountDataSelectedDateRange] =
    useState(null);
  const [countDataSelectedDistrict, setCountDataSelectedDistrict] =
    useState("");
  const [countDataSelectedType, setCountDataSelectedType] = useState("");
  const [totalDataSelectedStatus, setTotalDataSelectedStatus] = useState("");

  const handleTotalDataDateRage = (value) => {
    setTotalDataSelectedDateRange(value);
  };
  const handleTotalDataDistrict = (value) => {
    setTotalDataSelectedDistrict(value);
  };
  const handleTotalDataType = (value) => {
    setTotalDataSelectedType(value);
  };
  const handleTotalDataFO = (value) => {
    const output = fulfillmentList?.find(
      (fo) => fo.employee.employee_name === value
    );
    setFoID(output?.employee?.employee_id);
    setTotalDataSelectedFO(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCountDataDateRage = (value) => {
    setCountDataSelectedDateRange(value);
  };
  const handleCountDataDistrict = (value) => {
    setCountDataSelectedDistrict(value);
  };
  const handleCountDataType = (value) => {
    setCountDataSelectedType(value);
  };

  const handleRevenueDate = (value) => {
    setRevenueSelectedDateRange(value);
  };
  const handleRevenueDistrict = (value) => {
    setRevenueSelectedDistrict(value);
  };

  const handleTimelineDate = (value) => {
    setTimelineSelectedDateRange(value);
  };
  const handleTimelineDistrict = (value) => {
    setTimelineSelectedDistrict(value);
  };
  const handleTimelineType = (value) => {
    setTimelineSelectedType(value);
  };
  const handleFulfillmentStatus = (value) => {
    setTotalDataSelectedStatus(value);
  };

  const fetchOrderFulfillmentList = () => {
    axios
      .get(
        `${baseUrl}/retailer/orderFulfillmentList?date=${totalDataSelectedDateRange}`,
        {
          headers: authHeader(),
          params: {
            page: currentPage,
            district: totalDataSelectedDistrict,
            orderType: totalDataSelectedType,
            foId: foID,
            status: totalDataSelectedStatus,
          },
        }
      )
      .then((response) => {
        setFulfillmentList(response?.data?.results || []);
        setTotalItems(response?.data?.count || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCountData = () => {
    axios
      .get(
        `${baseUrl}/retailer/orderFulfillmentCounts?district=${countDataSelectedDistrict}&date=${countDataSelectedDateRange}&order_type=${countDataSelectedType}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => setCountData(response?.data))
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/retailer/orderFulfillmentTimeline?district=${timelineSelectedDistrict}&date=${timelineSelectedDateRange}&order_type=${timelineSelectedType}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setOrderFulfillmentTimeline(response?.data);
        console.log(response);
      })
      .catch((error) => console.log(error));
  }, [
    timelineSelectedDateRange,
    timelineSelectedDistrict,
    timelineSelectedType,
  ]);

  useEffect(() => {
    fetchOrderFulfillmentList();
  }, [
    currentPage,
    foID,
    totalDataSelectedDateRange,
    totalDataSelectedDistrict,
    totalDataSelectedType,
    totalDataSelectedStatus,
  ]);

  const distinctDistrict = [
    ...new Set(fulfillmentList?.map((item) => item?.order?.district)),
  ];
  const distinctOrderType = [
    ...new Set(fulfillmentList?.map((item) => item?.order?.order_type)),
  ];
  const distinctFO = [
    ...new Set(fulfillmentList?.map((item) => item?.employee.employee_name)),
  ];

  useEffect(() => {
    fetchCountData();
  }, [
    countDataSelectedDateRange,
    countDataSelectedDistrict,
    countDataSelectedType,
  ]);

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/retailer/orderFulfillmentRevenueData?district=${revenueSelectedDistrict}&date=${revenueSelectedDateRange}`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => setRevenueData(response?.data));
  }, [revenueSelectedDateRange, revenueSelectedDistrict]);

  const handleViewModal = (record) => {
    setLoading(true);
    setViewModal(true);
    setModalData(record);
  };
  const handleModalClose = () => {
    setViewModal(false);
    setModalData(null);
    fetchOrderFulfillmentList();
    fetchCountData();
  };

  const handleUpdateModal = (record) => {
    setViewUpdateModal(true);
    setUpdateModalData(record);
  };
  const handleUpdateModalClose = () => {
    setViewUpdateModal(false);
    setUpdateModalData(null);
    fetchOrderFulfillmentList();
  };

  const fulfillmentColumn = [
    {
      title: "Fulfillment Date",
      dataIndex: "fulfillment_date",
      width: "10%",
    },
    {
      title: "Order ID",
      render: (record) => record?.order?.order_id,
      width: "10%",
    },
    {
      title: "Fulfillment ID",
      dataIndex: "fulfillment_id",
      width: "10%",
    },
    {
      title: "District",
      render: (record) => record?.order?.district,
      width: "10%",
    },
    {
      title: "Order Type",
      render: (record) => record?.order?.order_type,
      width: "10%",
    },
    {
      title: "Sale ID",
      dataIndex: "sale_id",
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "fulfillment_status",
      width: "10%",
    },
    {
      title: "FO Name",
      render: (record) => record?.employee?.employee_name,
      width: "10%",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) =>
        record?.sale_id ? (
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Update">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleUpdateModal(record)}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
        ),
    },
  ];

  return (
    <>
      {modalData && (
        <OrderFulfillmentModal
          visible={viewModal}
          onCancel={handleModalClose}
          modalData={modalData}
        />
      )}
      {updateModalData && (
        <OrderFulfillmentUpdateModal
          visible={viewUpdateModal}
          onCancel={handleUpdateModalClose}
          updateModalData={updateModalData}
        />
      )}
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>ORDER FULFILLMENT DASHBOARD</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="farmerBoxSection">
            <div className="orderFulfillmentCardDesign">
              <p className="m-3" style={{ fontSize: "20px" }}>
                Revenue (BDT)
              </p>
              <div className="d-flex">
                <div className="ms-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    onChange={handleRevenueDate}
                    value={revenueSelectedDateRange}
                    showOneCalendar
                  />
                </div>
                <div className="ms-1">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    onChange={handleRevenueDistrict}
                    value={revenueSelectedDistrict}
                  >
                    <Option value="">All Districts</Option>
                    {distinctDistrict?.map((district, index) => (
                      <Option key={index} value={district}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex align-items-center text-center mx-4 mb-1">
                  <div
                    className="w-50 py-3 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Farmer
                  </div>
                  <div className="w-50 py-3 border">
                    {revenueData?.farmer_total_price_sum?.toLocaleString()}
                  </div>
                </div>
                <div className="d-flex align-items-center text-center mx-4 mb-1">
                  <div
                    className="w-50 py-3 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Retailer
                  </div>
                  <div className="w-50 py-3 border">
                    {revenueData?.retailer_total_price_sum?.toLocaleString()}
                  </div>
                </div>
                <div className="d-flex align-items-center text-center mx-4 mb-1">
                  <div
                    className="w-50 py-3 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Total Revenue
                  </div>
                  <div className="w-50 py-3 border">
                    {revenueData?.total_price_sum?.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
            <div className="orderFulfillmentCardDesign">
              <p className="m-3" style={{ fontSize: "20px" }}>
                Order Fulfillment Timeline
              </p>
              <div className="d-flex">
                <div className="ms-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    showOneCalendar
                    value={timelineSelectedDateRange}
                    onChange={handleTimelineDate}
                  />
                </div>
                <div className="ms-1">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    value={timelineSelectedDistrict}
                    onChange={handleTimelineDistrict}
                  >
                    <Option value="">All Districts</Option>
                    {distinctDistrict?.map((district, index) => (
                      <Option key={index} value={district}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="ms-1">
                  <p className="m-0">Order Type: </p>
                  <Select
                    style={{ width: "120px" }}
                    showSearch
                    placeholder="Select Type"
                    value={timelineSelectedType}
                    onChange={handleTimelineType}
                  >
                    <Option value="">All Types</Option>
                    {distinctOrderType?.map((type, index) => (
                      <Option key={index} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="m-1">
                <BarChart data={orderFulfillmentTimeline} />
              </div>
            </div>
            <div className="orderFulfillmentCardDesign">
              <p className="m-3" style={{ fontSize: "20px" }}>
                Total Orders
              </p>
              <div className="d-flex">
                <div className="ms-3">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    onChange={handleCountDataDateRage}
                    value={countDataSelectedDateRange}
                    showOneCalendar
                  />
                </div>
                <div className="ms-1">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    onChange={handleCountDataDistrict}
                    value={countDataSelectedDistrict}
                  >
                    <Option value="">All Districts</Option>
                    {distinctDistrict?.map((district, index) => (
                      <Option key={index} value={district}>
                        {district}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="ms-1">
                  <p className="m-0"> Order Type: </p>
                  <Select
                    style={{ width: "120px" }}
                    showSearch
                    placeholder="Select Type"
                    onChange={handleCountDataType}
                    value={countDataSelectedType}
                  >
                    <Option value="">All Types</Option>
                    {distinctOrderType?.map((type, index) => (
                      <Option key={index} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex align-items-center text-center mx-4 mb-1">
                  <div
                    className="w-50 py-3 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Total Order Taken
                  </div>
                  <div className="w-50 py-3 border">
                    {countData?.total_orders}
                  </div>
                </div>
                <div className="d-flex align-items-center text-center mx-4 mb-1">
                  <div
                    className="w-50 py-3 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Total In Progress
                  </div>
                  <div className="w-50 py-3 border">
                    {countData?.in_progress}
                  </div>
                </div>
                <div className="d-flex align-items-center text-center mx-4 mb-1">
                  <div
                    className="w-50 py-3 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Total Due
                  </div>
                  <div className="w-50 py-3 border">{countData?.due}</div>
                </div>
                <div className="d-flex align-items-center text-center mx-4 mb-1">
                  <div
                    className="w-50 py-3 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Total Completed
                  </div>
                  <div className="w-50 py-3 border">{countData?.completed}</div>
                </div>
              </div>
            </div>
          </div>
          <section className="priceAppTotalTable mx-2 shadow">
            <h5 className="text-center pt-4 pb-2 border-bottom">
              Fulfillment List
            </h5>
            <div className="d-flex">
              <div className="ms-3 mt-3">
                <p className="m-0">Date: </p>
                <DateRangePicker
                  style={{ width: "250px" }}
                  showOneCalendar
                  onChange={handleTotalDataDateRage}
                  value={totalDataSelectedDateRange}
                />
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Location: </p>
                <Select
                  style={{ width: "160px" }}
                  placeholder="Select District"
                  onChange={handleTotalDataDistrict}
                  value={totalDataSelectedDistrict}
                  showSearch
                >
                  <Option value="">All Districts</Option>
                  {distinctDistrict?.map((district, index) => (
                    <Option key={index} value={district}>
                      {district}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Order Type: </p>
                <Select
                  style={{ width: "160px" }}
                  showSearch
                  onChange={handleTotalDataType}
                  value={totalDataSelectedType}
                  placeholder="Select Type"
                >
                  <Option value="">All Types</Option>
                  {distinctOrderType?.map((type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Field Officer:</p>
                <Select
                  style={{ width: "160px" }}
                  onChange={handleTotalDataFO}
                  value={totalDataSelectedFO}
                  placeholder="Select FO"
                  showSearch
                >
                  <Option value="">All FO</Option>
                  {distinctFO?.map((fo, index) => (
                    <Option key={index} value={fo}>
                      {fo}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Status:</p>
                <Select
                  style={{ width: "160px" }}
                  onChange={handleFulfillmentStatus}
                  value={totalDataSelectedStatus}
                  placeholder="Select Status"
                  showSearch
                >
                  <Option value="">All</Option>
                  <Option value="In progress">In progress</Option>
                  <Option value="Completed">Completed</Option>
                  <Option value="Due">Due</Option>
                </Select>
              </div>
            </div>
            <Table
              className="mx-2"
              columns={fulfillmentColumn}
              dataSource={fulfillmentList}
              rowKey={(record) => record.fulfillment_id}
              pagination={{
                current: currentPage,
                total: totalItems,
                pageSize: 10,
                onChange: handlePageChange,
              }}
            />
          </section>
        </div>
      </div>
    </>
  );
}
