const retailerList = [
  {
    "Retailer_ID": "RNU-RAN-PRM-0000100-R",
    "Retailer_Name": "Rohern chindro mohandro",
    "Retailer_Phone": 1724511580,
    "Traders_Name": "Sri horen shondro mohonto",
    "Bazar_Name": "Lalbag",
    "Latitude": 25.724061,
    "Longitude": 89.2542671,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Dorshona",
    "Last_visited": "11/11/2023",
    "Route_name": "Dorshona",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-DRA-0000100-R",
    "Retailer_Name": "Abdur rob",
    "Retailer_Phone": 1888113381,
    "Traders_Name": "Rangpur Chaci Ghor",
    "Bazar_Name": "Lalbag",
    "Latitude": 25.7251208,
    "Longitude": 89.2563724,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Dorshona",
    "Last_visited": "11/11/2023",
    "Route_name": "Dorshona",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000100-R",
    "Retailer_Name": "Biz Mela",
    "Retailer_Phone": 1773130129,
    "Traders_Name": "Habib Hedayet",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9125519,
    "Longitude": 89.4478702,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000101-R",
    "Retailer_Name": "Rahat",
    "Retailer_Phone": 1873557121,
    "Traders_Name": "Rahat agro",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9126336,
    "Longitude": 89.447735,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-DRA-0000101-R",
    "Retailer_Name": "Rohul amin Ronjo",
    "Retailer_Phone": 1712514606,
    "Traders_Name": "Ronjo Enterprise",
    "Bazar_Name": "Lalbag",
    "Latitude": 25.7243055,
    "Longitude": 89.2546369,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Dorshona",
    "Last_visited": "11/11/2023",
    "Route_name": "Dorshona",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-DRA-0000103-R",
    "Retailer_Name": "Bitul",
    "Retailer_Phone": 1719822596,
    "Traders_Name": "Krisi betan",
    "Bazar_Name": "Lalbag",
    "Latitude": 25.7240432,
    "Longitude": 89.2542579,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Dorshona",
    "Last_visited": "11/11/2023",
    "Route_name": "Dorshona",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000102-R",
    "Retailer_Name": "Abul kasem",
    "Retailer_Phone": 1717545130,
    "Traders_Name": "A L khan seed store",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9142119,
    "Longitude": 89.4470516,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-RAN-DRA-0000104-R",
    "Retailer_Name": "Sahadat hossion",
    "Retailer_Phone": 1717292872,
    "Traders_Name": "Rupaion feed",
    "Bazar_Name": "Lalbag",
    "Latitude": 25.7248676,
    "Longitude": 89.2540603,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Dorshona",
    "Last_visited": "11/11/2023",
    "Route_name": "Dorshona",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000002-R",
    "Retailer_Name": "Sahadat",
    "Retailer_Phone": 1708410618,
    "Traders_Name": "Sahadat store",
    "Bazar_Name": "Lalbag",
    "Latitude": 25.7249677,
    "Longitude": 89.2539588,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/1/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000003-R",
    "Retailer_Name": "Akter",
    "Retailer_Phone": 1786959614,
    "Traders_Name": "Akter store",
    "Bazar_Name": "Railway station Bazar",
    "Latitude": 25.7296136,
    "Longitude": 89.2636246,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/2/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000103-R",
    "Retailer_Name": "Rahat",
    "Retailer_Phone": 1712949307,
    "Traders_Name": "Khan seeds",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9125937,
    "Longitude": 89.4476385,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/3/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000004-R",
    "Retailer_Name": "Jabend",
    "Retailer_Phone": 1750107430,
    "Traders_Name": "Ashad feed store",
    "Bazar_Name": "Railway station Bazar",
    "Latitude": 25.729859,
    "Longitude": 89.2629498,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/4/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000005-R",
    "Retailer_Name": "Kamran",
    "Retailer_Phone": 1768885268,
    "Traders_Name": "Nishan Enterprise",
    "Bazar_Name": "Railway station Bazar",
    "Latitude": 25.7295815,
    "Longitude": 89.2662679,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/5/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000104-R",
    "Retailer_Name": "Rezaul korim",
    "Retailer_Phone": 1922685555,
    "Traders_Name": "Mesers ma tedars and poltry food",
    "Bazar_Name": "Puraton bazar",
    "Latitude": 25.9197658,
    "Longitude": 89.444664,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/6/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000006-R",
    "Retailer_Name": "Sagot",
    "Retailer_Phone": 1718722020,
    "Traders_Name": "Fish feed",
    "Bazar_Name": "Railway station Bazar",
    "Latitude": 25.7289245,
    "Longitude": 89.2668976,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/7/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000007-R",
    "Retailer_Name": "Baccu",
    "Retailer_Phone": 1712149070,
    "Traders_Name": "New torov dasTedars",
    "Bazar_Name": "Railway station Bazar",
    "Latitude": 25.7289162,
    "Longitude": 89.266905,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/8/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000105-R",
    "Retailer_Name": "Nasir uddin",
    "Retailer_Phone": 1712933738,
    "Traders_Name": "Massers nasir traders",
    "Bazar_Name": "Rail bazar",
    "Latitude": 25.9164249,
    "Longitude": 89.4464623,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/9/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000106-R",
    "Retailer_Name": "Sekendar Ali",
    "Retailer_Phone": 1712208047,
    "Traders_Name": "Massers sekender Ali",
    "Bazar_Name": "Puraton bazar Lalmonirhat",
    "Latitude": 25.9184042,
    "Longitude": 89.4454878,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/10/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000107-R",
    "Retailer_Name": "Omar ali",
    "Retailer_Phone": 1303417696,
    "Traders_Name": "Massers fatema traders",
    "Bazar_Name": "Puraton bazar",
    "Latitude": 25.9183963,
    "Longitude": 89.4453816,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000108-R",
    "Retailer_Name": "Protik",
    "Retailer_Phone": 1754136884,
    "Traders_Name": "Protik dairy farm",
    "Bazar_Name": "BDR gate",
    "Latitude": 25.9190857,
    "Longitude": 89.4451491,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/12/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000008-R",
    "Retailer_Name": "Imran",
    "Retailer_Phone": 1756110005,
    "Traders_Name": "Imran tedars",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7289162,
    "Longitude": 89.266905,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/13/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000009-R",
    "Retailer_Name": "Arman",
    "Retailer_Phone": 1723181119,
    "Traders_Name": "Feha enterprise",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7288485,
    "Longitude": 89.2669602,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/14/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000010-R",
    "Retailer_Name": "Sabbir",
    "Retailer_Phone": 1722667587,
    "Traders_Name": "Sabbir tedars",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7284699,
    "Longitude": 89.2672792,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000109-R",
    "Retailer_Name": "Fahid",
    "Retailer_Phone": 1713764779,
    "Traders_Name": "Massers fahid dairy store",
    "Bazar_Name": "Puraton bazar",
    "Latitude": 25.9197539,
    "Longitude": 89.4446736,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000011-R",
    "Retailer_Name": "Ovi",
    "Retailer_Phone": 1764902709,
    "Traders_Name": "MR teding",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7284027,
    "Longitude": 89.2673532,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000012-R",
    "Retailer_Name": "Rasel",
    "Retailer_Phone": 1609957786,
    "Traders_Name": "Kholil dedars",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7283816,
    "Longitude": 89.2673571,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000013-R",
    "Retailer_Name": "Milon",
    "Retailer_Phone": 1724034070,
    "Traders_Name": "Milon tedars",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7283513,
    "Longitude": 89.2674079,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000014-R",
    "Retailer_Name": "Samim",
    "Retailer_Phone": 1743468084,
    "Traders_Name": "Hujaifa tedars",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7287664,
    "Longitude": 89.2670399,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000110-R",
    "Retailer_Name": "Mosiur Rahman",
    "Retailer_Phone": 1720505427,
    "Traders_Name": "Massers mr Trading",
    "Bazar_Name": "Puran bazar",
    "Latitude": 25.9142214,
    "Longitude": 89.4470586,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000015-R",
    "Retailer_Name": "Faysal",
    "Retailer_Phone": 1785237830,
    "Traders_Name": "Faysal tedars",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7292137,
    "Longitude": 89.2667813,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000016-R",
    "Retailer_Name": "Aanamyl",
    "Retailer_Phone": 1715039842,
    "Traders_Name": "Anamul tedars",
    "Bazar_Name": "Alomnagar",
    "Latitude": 25.7302255,
    "Longitude": 89.2659891,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000100-R",
    "Retailer_Name": "Dulu store",
    "Retailer_Phone": 1796763043,
    "Traders_Name": "Dulu store",
    "Bazar_Name": "Borobari bazar",
    "Latitude": 25.8710265,
    "Longitude": 89.5093136,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/11/2023",
    "Route_name": "Barobari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000101-R",
    "Retailer_Name": "Raju",
    "Retailer_Phone": 1728841265,
    "Traders_Name": "L enterprise",
    "Bazar_Name": "Birobari bazar",
    "Latitude": 25.8712,
    "Longitude": 89.5091437,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/11/2023",
    "Route_name": "Barobari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000102-R",
    "Retailer_Name": "Mohammad Lablu mia",
    "Retailer_Phone": 1767486830,
    "Traders_Name": "L S enterprise",
    "Bazar_Name": "Birobari Bazar",
    "Latitude": 25.8716416,
    "Longitude": 89.5091499,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/11/2023",
    "Route_name": "Barobari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000017-R",
    "Retailer_Name": "Robiyul alom",
    "Retailer_Phone": 1755488178,
    "Traders_Name": "New monirom store",
    "Bazar_Name": "Arshad mor Rangpur",
    "Latitude": 25.7409095,
    "Longitude": 89.2493417,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000103-R",
    "Retailer_Name": "Mojun",
    "Retailer_Phone": 1712162057,
    "Traders_Name": "Mukul fertilizer",
    "Bazar_Name": "Borobari Bazar",
    "Latitude": 25.8707081,
    "Longitude": 89.5099325,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/11/2023",
    "Route_name": "Barobari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000104-R",
    "Retailer_Name": "Asadul Ali",
    "Retailer_Phone": 1718324811,
    "Traders_Name": "Massers Shamim fertilizer",
    "Bazar_Name": "Birobari bazar",
    "Latitude": 25.8716902,
    "Longitude": 89.5089501,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/11/2023",
    "Route_name": "Barobari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000105-R",
    "Retailer_Name": "Nazrul islam",
    "Retailer_Phone": 1714699843,
    "Traders_Name": "Nazrul fertilizer",
    "Bazar_Name": "Borobari Bazar",
    "Latitude": 25.8714717,
    "Longitude": 89.5093129,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/1/2023",
    "Route_name": "Barobari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000106-R",
    "Retailer_Name": "Moniruzzaman",
    "Retailer_Phone": 1779648796,
    "Traders_Name": "Bismillah dairy and poltry",
    "Bazar_Name": "Borobari bazar",
    "Latitude": 25.8722152,
    "Longitude": 89.5081854,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/2/2023",
    "Route_name": "Barobari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000107-R",
    "Retailer_Name": "Ashraf",
    "Retailer_Phone": 1818995644,
    "Traders_Name": "Momin traders",
    "Bazar_Name": "Borobari bazar",
    "Latitude": 25.8706365,
    "Longitude": 89.5099557,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/3/2023",
    "Route_name": "Barobari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000108-R",
    "Retailer_Name": "Nimai",
    "Retailer_Phone": 1402656462,
    "Traders_Name": "Nimai feeds",
    "Bazar_Name": "Borobari bazar",
    "Latitude": 25.8706365,
    "Longitude": 89.5099557,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/4/2023",
    "Route_name": "Barobari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000018-R",
    "Retailer_Name": "Abul shem",
    "Retailer_Phone": 1745229515,
    "Traders_Name": "Pingki tedars",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7344543,
    "Longitude": 89.2876548,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/5/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000109-R",
    "Retailer_Name": "Mizanur Rahman",
    "Retailer_Phone": 1715270677,
    "Traders_Name": "Mizan traders",
    "Bazar_Name": "Borobari bazar",
    "Latitude": 25.8717016,
    "Longitude": 89.5089617,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/6/2023",
    "Route_name": "Barobari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-BRI-0000110-R",
    "Retailer_Name": "Rasel",
    "Retailer_Phone": 1728721150,
    "Traders_Name": "Rasel",
    "Bazar_Name": "Borobari bazar",
    "Latitude": 25.8703354,
    "Longitude": 89.5101479,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Barobari",
    "Last_visited": "11/7/2023",
    "Route_name": "Barobari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000019-R",
    "Retailer_Name": "Jamal hossion",
    "Retailer_Phone": 1925646340,
    "Traders_Name": "Jamal Tedars",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7344694,
    "Longitude": 89.2876683,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/8/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000020-R",
    "Retailer_Name": "Afjal",
    "Retailer_Phone": 1796746239,
    "Traders_Name": "Afjal tedars",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7345054,
    "Longitude": 89.2879183,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/9/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000021-R",
    "Retailer_Name": "Dalim",
    "Retailer_Phone": 1784010382,
    "Traders_Name": "Dalim krishi Ghor",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7341049,
    "Longitude": 89.2876303,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/10/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000022-R",
    "Retailer_Name": "Ranjit",
    "Retailer_Phone": 1718800298,
    "Traders_Name": "Ranjit",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7339454,
    "Longitude": 89.2874277,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000023-R",
    "Retailer_Name": "Sumon",
    "Retailer_Phone": 1773490542,
    "Traders_Name": "Sumaiya tedars",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7338229,
    "Longitude": 89.2872336,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000024-R",
    "Retailer_Name": "Sujon",
    "Retailer_Phone": 1994261249,
    "Traders_Name": "Poli chura store",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7340431,
    "Longitude": 89.2874658,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000025-R",
    "Retailer_Name": "Alom",
    "Retailer_Phone": 1925481753,
    "Traders_Name": "Sawon tedars",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7339334,
    "Longitude": 89.2874286,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000026-R",
    "Retailer_Name": "Nazmul",
    "Retailer_Phone": 1904528761,
    "Traders_Name": "Nazmul tedars",
    "Bazar_Name": "Sath matha Bazar",
    "Latitude": 25.7339534,
    "Longitude": 89.287434,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000027-R",
    "Retailer_Name": "Nur Nobbi",
    "Retailer_Phone": 1718840748,
    "Traders_Name": "Bhuiyan Beej Tedars",
    "Bazar_Name": "Mahigonge",
    "Latitude": 25.7287747,
    "Longitude": 89.2917793,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000028-R",
    "Retailer_Name": "Majharul",
    "Retailer_Phone": 1715816278,
    "Traders_Name": "Majharul Tedars",
    "Bazar_Name": "Mahigonge",
    "Latitude": 25.7284074,
    "Longitude": 89.2920273,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000111-R",
    "Retailer_Name": "Haris Uddin",
    "Retailer_Phone": 1719513883,
    "Traders_Name": "Nahid fertilizer shop",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9142861,
    "Longitude": 89.4470995,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000029-R",
    "Retailer_Name": "Monir",
    "Retailer_Phone": 1959306264,
    "Traders_Name": "Iren tedars",
    "Bazar_Name": "Mahigonge",
    "Latitude": 25.7290731,
    "Longitude": 89.2919865,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/1/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000030-R",
    "Retailer_Name": "Juwel",
    "Retailer_Phone": 1824321214,
    "Traders_Name": "Mahigonge feed",
    "Bazar_Name": "Mahigonge",
    "Latitude": 25.728512,
    "Longitude": 89.2919105,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/2/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000031-R",
    "Retailer_Name": "Jubyer hossen",
    "Retailer_Phone": 1927904027,
    "Traders_Name": "Abdulla tedars",
    "Bazar_Name": "Mahigonge",
    "Latitude": 25.7285803,
    "Longitude": 89.2918367,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/3/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000033-R",
    "Retailer_Name": "Raihan islam",
    "Retailer_Phone": 1738381416,
    "Traders_Name": "Mayer dua tedars",
    "Bazar_Name": "Mahigonge",
    "Latitude": 25.7284975,
    "Longitude": 89.2919502,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/4/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000032-R",
    "Retailer_Name": "Sohidul islam",
    "Retailer_Phone": 1717510609,
    "Traders_Name": "Agata feed mills",
    "Bazar_Name": "Mahigonge",
    "Latitude": 25.7284317,
    "Longitude": 89.2917558,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/5/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000112-R",
    "Retailer_Name": "Sahinur alom",
    "Retailer_Phone": 1761372237,
    "Traders_Name": "Bijoy seeds",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9125918,
    "Longitude": 89.4476348,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/6/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000113-R",
    "Retailer_Name": "Titas mia",
    "Retailer_Phone": 1724778733,
    "Traders_Name": "Titas seed store",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9125916,
    "Longitude": 89.4476271,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/7/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000100-R",
    "Retailer_Name": "Joynal Abedin",
    "Retailer_Phone": 1774360574,
    "Traders_Name": "Massers Joynal traders",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8740873,
    "Longitude": 89.3685439,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/8/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000101-R",
    "Retailer_Name": "Mominur islam",
    "Retailer_Phone": 1751206821,
    "Traders_Name": "Vai vai kitnasok and veriets",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8740875,
    "Longitude": 89.3685427,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/9/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000102-R",
    "Retailer_Name": "Sopon",
    "Retailer_Phone": 1460464825,
    "Traders_Name": "Soniya traders",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8740875,
    "Longitude": 89.3685433,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/10/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000103-R",
    "Retailer_Name": "Moksud Ali",
    "Retailer_Phone": 1714333837,
    "Traders_Name": "Massers krisi sar bitan",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8745381,
    "Longitude": 89.367006,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000104-R",
    "Retailer_Name": "Nozrul",
    "Retailer_Phone": 1730193061,
    "Traders_Name": "Keya traders",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8745888,
    "Longitude": 89.3669896,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/12/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000105-R",
    "Retailer_Name": "Bipul",
    "Retailer_Phone": 1773230969,
    "Traders_Name": "Bokul fertilizer shop",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8745889,
    "Longitude": 89.3669895,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/13/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000101-R",
    "Retailer_Name": "Abul bashar",
    "Retailer_Phone": 1729706177,
    "Traders_Name": "Monira tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4409296,
    "Longitude": 89.3009247,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/14/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000100-R",
    "Retailer_Name": "Mohaiminul",
    "Retailer_Phone": 1717316274,
    "Traders_Name": "Akando tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4409544,
    "Longitude": 89.3009734,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000107-R",
    "Retailer_Name": "Rofikul islam",
    "Retailer_Phone": 1326017543,
    "Traders_Name": "Rupali fartilizer shop",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8745374,
    "Longitude": 89.3665869,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000102-R",
    "Retailer_Name": "Mithu mia",
    "Retailer_Phone": 1722614273,
    "Traders_Name": "Mitu tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4485585,
    "Longitude": 89.3000065,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000108-R",
    "Retailer_Name": "Osman goni",
    "Retailer_Phone": 1731134328,
    "Traders_Name": "Osman goni store",
    "Bazar_Name": "Anondobazar",
    "Latitude": 25.8813134,
    "Longitude": 89.3699596,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000103-R",
    "Retailer_Name": "Rakib",
    "Retailer_Phone": 1773585749,
    "Traders_Name": "Bhi bhi tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4412346,
    "Longitude": 89.3010286,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000109-R",
    "Retailer_Name": "Liton",
    "Retailer_Phone": 1735181268,
    "Traders_Name": "AL imran fertilizer",
    "Bazar_Name": "Trimohoni setu bazar",
    "Latitude": 25.878211,
    "Longitude": 89.3777051,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000110-R",
    "Retailer_Name": "Ashikur Rahman",
    "Retailer_Phone": 1794946984,
    "Traders_Name": "Romjan fertilizer",
    "Bazar_Name": "Shetu Bazar",
    "Latitude": 25.8782168,
    "Longitude": 89.3777018,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000111-R",
    "Retailer_Name": "Obaidul seam",
    "Retailer_Phone": 1798980911,
    "Traders_Name": "Obaidul graders",
    "Bazar_Name": "Shetu bazar",
    "Latitude": 25.8781947,
    "Longitude": 89.377721,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000112-R",
    "Retailer_Name": "Jahangir alom",
    "Retailer_Phone": 1924801301,
    "Traders_Name": "Rifat traders",
    "Bazar_Name": "Shetu bazar",
    "Latitude": 25.8781904,
    "Longitude": 89.3776793,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000104-R",
    "Retailer_Name": "Mamun",
    "Retailer_Phone": 1785086376,
    "Traders_Name": "Nafiz niyaz tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4412589,
    "Longitude": 89.3011161,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000113-R",
    "Retailer_Name": "Ashik",
    "Retailer_Phone": 1728159301,
    "Traders_Name": "Vai vai traders",
    "Bazar_Name": "Shetu Bazar",
    "Latitude": 25.8781206,
    "Longitude": 89.3779293,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000105-R",
    "Retailer_Name": "Safi alom",
    "Retailer_Phone": 1761183800,
    "Traders_Name": "Nafi fashion",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4413673,
    "Longitude": 89.3005264,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-KUH-0000114-R",
    "Retailer_Name": "Atakur Rohman",
    "Retailer_Phone": 1981757883,
    "Traders_Name": "Vai vai fertilizer",
    "Bazar_Name": "Shetu Bazar",
    "Latitude": 25.8976711,
    "Longitude": 89.3961043,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Khuniagachh",
    "Last_visited": "11/11/2023",
    "Route_name": "Khuniagachh",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000106-R",
    "Retailer_Name": "Mamun",
    "Retailer_Phone": 1737415468,
    "Traders_Name": "Mamun tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.441369,
    "Longitude": 89.3004944,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-ADI-SPI-0000100-R",
    "Retailer_Name": "Motiur Rahman",
    "Retailer_Phone": 1723537558,
    "Traders_Name": "Belal bizagar",
    "Bazar_Name": "Saptibari",
    "Latitude": 25.8976597,
    "Longitude": 89.3961482,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Aditmari",
    "Union_Name": "Saptibari",
    "Last_visited": "11/11/2023",
    "Route_name": "Saptibari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-ADI-SPI-0000101-R",
    "Retailer_Name": "Atik",
    "Retailer_Phone": 1744848119,
    "Traders_Name": "Agriculture store",
    "Bazar_Name": "Saptibari",
    "Latitude": 25.8978822,
    "Longitude": 89.3962998,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Aditmari",
    "Union_Name": "Saptibari",
    "Last_visited": "11/1/2023",
    "Route_name": "Saptibari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-ADI-SPI-0000102-R",
    "Retailer_Name": "Sofikul islam",
    "Retailer_Phone": 1716386493,
    "Traders_Name": "Sofikul Alom traders",
    "Bazar_Name": "Saptibari",
    "Latitude": 25.8978838,
    "Longitude": 89.3963006,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Aditmari",
    "Union_Name": "Saptibari",
    "Last_visited": "11/2/2023",
    "Route_name": "Saptibari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-ADI-SPI-0000103-R",
    "Retailer_Name": "Abdus Salam",
    "Retailer_Phone": 1721858734,
    "Traders_Name": "Massers A salam Traders",
    "Bazar_Name": "Saptibari",
    "Latitude": 25.9064278,
    "Longitude": 89.3738886,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Aditmari",
    "Union_Name": "Saptibari",
    "Last_visited": "11/3/2023",
    "Route_name": "Saptibari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-ADI-SPI-0000104-R",
    "Retailer_Name": "Sumon",
    "Retailer_Phone": 1723459391,
    "Traders_Name": "Alom traders",
    "Bazar_Name": "Saptibari",
    "Latitude": 25.906427,
    "Longitude": 89.3738851,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Aditmari",
    "Union_Name": "Saptibari",
    "Last_visited": "11/4/2023",
    "Route_name": "Saptibari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-ADI-SPI-0000105-R",
    "Retailer_Name": "Jahangir Alam",
    "Retailer_Phone": 1718429592,
    "Traders_Name": "Jahangir fertilizer shop",
    "Bazar_Name": "Saptibari",
    "Latitude": 25.8983634,
    "Longitude": 89.3967435,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Aditmari",
    "Union_Name": "Saptibari",
    "Last_visited": "11/5/2023",
    "Route_name": "Saptibari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000107-R",
    "Retailer_Name": "Sagor",
    "Retailer_Phone": 1710432346,
    "Traders_Name": "Sagor tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4415352,
    "Longitude": 89.3011145,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/6/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000108-R",
    "Retailer_Name": "Alom",
    "Retailer_Phone": 1786752243,
    "Traders_Name": "Ferija tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4400999,
    "Longitude": 89.3003731,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/7/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000109-R",
    "Retailer_Name": "Abdul mannab",
    "Retailer_Phone": 1762870375,
    "Traders_Name": "Maisha tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4381216,
    "Longitude": 89.3012375,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/8/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000110-R",
    "Retailer_Name": "Rajjak",
    "Retailer_Phone": 1751577699,
    "Traders_Name": "Guda ghor tredars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.440566,
    "Longitude": 89.3002472,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/9/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000111-R",
    "Retailer_Name": "Monuyar",
    "Retailer_Phone": 1717292870,
    "Traders_Name": "Nuha tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4405395,
    "Longitude": 89.3002596,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/10/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000112-R",
    "Retailer_Name": "Full mia",
    "Retailer_Phone": 1713735247,
    "Traders_Name": "Full mia trades",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4416576,
    "Longitude": 89.3000023,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000113-R",
    "Retailer_Name": "Hasan",
    "Retailer_Phone": 1781008024,
    "Traders_Name": "Hasan tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4413699,
    "Longitude": 89.300517,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000114-R",
    "Retailer_Name": "Anuwar",
    "Retailer_Phone": 1763982308,
    "Traders_Name": "Mama bagina tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4413659,
    "Longitude": 89.3005257,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000115-R",
    "Retailer_Name": "Assadujaman",
    "Retailer_Phone": 1729641895,
    "Traders_Name": "Shopner krishi ghor",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4413726,
    "Longitude": 89.3005113,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000116-R",
    "Retailer_Name": "Asmaul",
    "Retailer_Phone": 1300774130,
    "Traders_Name": "Krisi ghor",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4413708,
    "Longitude": 89.3005023,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000118-R",
    "Retailer_Name": "Masud rana",
    "Retailer_Phone": 1797887985,
    "Traders_Name": "Masudrana tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4413713,
    "Longitude": 89.3005134,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000117-R",
    "Retailer_Name": "Samsul",
    "Retailer_Phone": 1722076879,
    "Traders_Name": "Sadib tedars",
    "Bazar_Name": "Laldighir bazar",
    "Latitude": 25.4413702,
    "Longitude": 89.3005018,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000119-R",
    "Retailer_Name": "Nomun",
    "Retailer_Phone": 1717766870,
    "Traders_Name": "Anisa tedars",
    "Bazar_Name": "Lal",
    "Latitude": 25.4413715,
    "Longitude": 89.3005122,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000120-R",
    "Retailer_Name": "Milon",
    "Retailer_Phone": 1718703830,
    "Traders_Name": "Milon tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.456242,
    "Longitude": 89.297115,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/1/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000121-R",
    "Retailer_Name": "Suhanur Rahman",
    "Retailer_Phone": 1887855778,
    "Traders_Name": "Suhanur tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4562663,
    "Longitude": 89.2972004,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/2/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000122-R",
    "Retailer_Name": "Sohel",
    "Retailer_Phone": 1761008128,
    "Traders_Name": "Sohel tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4562657,
    "Longitude": 89.2971936,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/3/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000123-R",
    "Retailer_Name": "Rukon",
    "Retailer_Phone": 1737896772,
    "Traders_Name": "Rukon tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4551966,
    "Longitude": 89.294666,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/4/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000100-R",
    "Retailer_Name": "Gulam mustofa",
    "Retailer_Phone": 1725022976,
    "Traders_Name": "Massers G M Traders",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9254258,
    "Longitude": 89.4985791,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/5/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000124-R",
    "Retailer_Name": "Juwel",
    "Retailer_Phone": 1305161257,
    "Traders_Name": "Juwel tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4551966,
    "Longitude": 89.294666,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/6/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000101-R",
    "Retailer_Name": "Mohammad Arshadul Hoque",
    "Retailer_Phone": 1949728571,
    "Traders_Name": "Arshadul Fertilizers",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.926299,
    "Longitude": 89.4957601,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/7/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000125-R",
    "Retailer_Name": "Safikul",
    "Retailer_Phone": 1737485276,
    "Traders_Name": "Sofikul tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4562665,
    "Longitude": 89.2971933,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/8/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000102-R",
    "Retailer_Name": "Abdul kader",
    "Retailer_Phone": 1713636674,
    "Traders_Name": "Mahi bij o sar ghor",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9261969,
    "Longitude": 89.4953731,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/9/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000126-R",
    "Retailer_Name": "Mutaleb",
    "Retailer_Phone": 1740087790,
    "Traders_Name": "Habiba tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4556508,
    "Longitude": 89.2967635,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/10/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000103-R",
    "Retailer_Name": "Mitu mia",
    "Retailer_Phone": 1739949166,
    "Traders_Name": "Mitu fertilizer",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9263438,
    "Longitude": 89.4960059,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000127-R",
    "Retailer_Name": "Gowtom chondro",
    "Retailer_Phone": 1712080142,
    "Traders_Name": "Saja store",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.456002,
    "Longitude": 89.2965285,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/12/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000128-R",
    "Retailer_Name": "Sowrob",
    "Retailer_Phone": 1751490074,
    "Traders_Name": "Sowrob tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4560101,
    "Longitude": 89.2965263,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/13/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000129-R",
    "Retailer_Name": "Rubel mia",
    "Retailer_Phone": 1772048330,
    "Traders_Name": "Rubel tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4551966,
    "Longitude": 89.294666,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/14/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000104-R",
    "Retailer_Name": "Asadul Hoque",
    "Retailer_Phone": 1714596599,
    "Traders_Name": "Massers salman Tradings",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9244384,
    "Longitude": 89.4964992,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000105-R",
    "Retailer_Name": "Sofikul",
    "Retailer_Phone": 1902574714,
    "Traders_Name": "Sofikul traders",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9245979,
    "Longitude": 89.4965528,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000130-R",
    "Retailer_Name": "Sopon",
    "Retailer_Phone": 1711114328,
    "Traders_Name": "Sopon tedars",
    "Bazar_Name": "Sukancauki",
    "Latitude": 25.4562599,
    "Longitude": 89.2971846,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000106-R",
    "Retailer_Name": "Kurban Ali",
    "Retailer_Phone": 1744750523,
    "Traders_Name": "Vai vai traders",
    "Bazar_Name": "Tiktik bazar",
    "Latitude": 25.9244875,
    "Longitude": 89.4965126,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000107-R",
    "Retailer_Name": "Mizanur",
    "Retailer_Phone": 1718909993,
    "Traders_Name": "Mizan traders",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9252606,
    "Longitude": 89.497093,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000108-R",
    "Retailer_Name": "Kafuyan",
    "Retailer_Phone": 1718909992,
    "Traders_Name": "Kafuyan",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9252639,
    "Longitude": 89.4970889,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000109-R",
    "Retailer_Name": "Abul kalam Azad",
    "Retailer_Phone": 1750320145,
    "Traders_Name": "Massrs dewan traders",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9253047,
    "Longitude": 89.4971372,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-KLT-0000110-R",
    "Retailer_Name": "Abdus Sattar mia",
    "Retailer_Phone": 1843814199,
    "Traders_Name": "Vai vai fertilizer",
    "Bazar_Name": "Kulaghat bazar",
    "Latitude": 25.9252389,
    "Longitude": 89.4971206,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Kulaghat",
    "Last_visited": "11/11/2023",
    "Route_name": "Kulaghat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000001-R",
    "Retailer_Name": "Anonto",
    "Retailer_Phone": 1319188002,
    "Traders_Name": "Onu dairy and poltry feed",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.9207191,
    "Longitude": 89.4698865,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000002-R",
    "Retailer_Name": "Milon",
    "Retailer_Phone": 1734128519,
    "Traders_Name": "Ma babar ashirbad dairy and poltry",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.9207248,
    "Longitude": 89.469872,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000003-R",
    "Retailer_Name": "Mustak Ahammed",
    "Retailer_Phone": 1714966236,
    "Traders_Name": "Mithila fertilizer shop",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.9206583,
    "Longitude": 89.4690072,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000004-R",
    "Retailer_Name": "Murshedul Hoque",
    "Retailer_Phone": 1783037567,
    "Traders_Name": "Murshedul Hoque",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.9206633,
    "Longitude": 89.4690012,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000005-R",
    "Retailer_Name": "Asraful islam",
    "Retailer_Phone": 1719701247,
    "Traders_Name": "Asraful traders",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.9207135,
    "Longitude": 89.4698909,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000006-R",
    "Retailer_Name": "Sofikul",
    "Retailer_Phone": 1717505838,
    "Traders_Name": "Mohammad traders",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.9204974,
    "Longitude": 89.4679485,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000114-R",
    "Retailer_Name": "Saiful Islam",
    "Retailer_Phone": 1714926201,
    "Traders_Name": "Krishi bitan",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.9188316,
    "Longitude": 89.4485168,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000007-R",
    "Retailer_Name": "Monjur alom",
    "Retailer_Phone": 1712014384,
    "Traders_Name": "Massera dhrubo Enterpriser",
    "Bazar_Name": "Upazila bazar",
    "Latitude": 25.912931,
    "Longitude": 89.4466457,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/1/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000008-R",
    "Retailer_Name": "Rakibul islam",
    "Retailer_Phone": 1719708482,
    "Traders_Name": "Massers Krishi bitan",
    "Bazar_Name": "Noyahat Bazar",
    "Latitude": 25.912931,
    "Longitude": 89.4466457,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/2/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000100-R",
    "Retailer_Name": "Jiyaur Rahman",
    "Retailer_Phone": 1722981638,
    "Traders_Name": "Jusna tedars",
    "Bazar_Name": "Kauapukur bazar",
    "Latitude": 25.4711158,
    "Longitude": 89.2949628,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/3/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000131-R",
    "Retailer_Name": "Jamal mia",
    "Retailer_Phone": 1710727336,
    "Traders_Name": "Evan tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5044152,
    "Longitude": 89.2919956,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/4/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000132-R",
    "Retailer_Name": "Ripon prodan",
    "Retailer_Phone": 1774241437,
    "Traders_Name": "Sinha store",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5056275,
    "Longitude": 89.2894334,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/5/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000133-R",
    "Retailer_Name": "Anuwar",
    "Retailer_Phone": 1726636667,
    "Traders_Name": "Allaher dan tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5055805,
    "Longitude": 89.2898238,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/6/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000134-R",
    "Retailer_Name": "Sahin",
    "Retailer_Phone": 1762751129,
    "Traders_Name": "Aisha tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5103201,
    "Longitude": 89.2884347,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/7/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000135-R",
    "Retailer_Name": "Jahid",
    "Retailer_Phone": 1750545674,
    "Traders_Name": "Jahid tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5103201,
    "Longitude": 89.2884347,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/8/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000136-R",
    "Retailer_Name": "Dipu",
    "Retailer_Phone": 1719208280,
    "Traders_Name": "Fatema beej bander",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5062687,
    "Longitude": 89.2892039,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/9/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000137-R",
    "Retailer_Name": "Ashraful",
    "Retailer_Phone": 1845340857,
    "Traders_Name": "Sumaiya tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5103201,
    "Longitude": 89.2884347,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/10/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000138-R",
    "Retailer_Name": "Nur alom",
    "Retailer_Phone": 1908171780,
    "Traders_Name": "Nur alom tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5103201,
    "Longitude": 89.2884347,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000139-R",
    "Retailer_Name": "Rejayul",
    "Retailer_Phone": 1760555395,
    "Traders_Name": "Rejayul tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5057394,
    "Longitude": 89.2894753,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000140-R",
    "Retailer_Name": "Ashraful",
    "Retailer_Phone": 1706942710,
    "Traders_Name": "Bhi bhi tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5057393,
    "Longitude": 89.2894736,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000141-R",
    "Retailer_Name": "Badsha",
    "Retailer_Phone": 1718877340,
    "Traders_Name": "Ma babar dua",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5103201,
    "Longitude": 89.2884347,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000142-R",
    "Retailer_Name": "Rahmat ali",
    "Retailer_Phone": 1980217545,
    "Traders_Name": "Rimi telecom",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5103201,
    "Longitude": 89.2884347,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000143-R",
    "Retailer_Name": "Dipol kumar de",
    "Retailer_Phone": 1723846031,
    "Traders_Name": "Mollika tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5056974,
    "Longitude": 89.289793,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000144-R",
    "Retailer_Name": "Motiyar Rahman",
    "Retailer_Phone": 1724425671,
    "Traders_Name": "Bokul tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5055846,
    "Longitude": 89.2893426,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000145-R",
    "Retailer_Name": "Nitai pal",
    "Retailer_Phone": 1721512776,
    "Traders_Name": "Jonnoni tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5073605,
    "Longitude": 89.2887314,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000146-R",
    "Retailer_Name": "Samsul islam",
    "Retailer_Phone": 1786825442,
    "Traders_Name": "Samsul tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5055822,
    "Longitude": 89.2893326,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/1/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000147-R",
    "Retailer_Name": "Alom",
    "Retailer_Phone": 1719359928,
    "Traders_Name": "Alom tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5084565,
    "Longitude": 89.288388,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/2/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000149-R",
    "Retailer_Name": "Assadujaman",
    "Retailer_Phone": 1767537226,
    "Traders_Name": "Assadujaman traders",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5084565,
    "Longitude": 89.288388,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/3/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000148-R",
    "Retailer_Name": "Sohidul islam",
    "Retailer_Phone": 1704357698,
    "Traders_Name": "Sohidul islam Tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.518827,
    "Longitude": 89.2872476,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/4/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000115-R",
    "Retailer_Name": "Mosiur Rahman",
    "Retailer_Phone": 1316350394,
    "Traders_Name": "Hridoy fertilizer shop",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746404,
    "Longitude": 89.4460335,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/5/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000116-R",
    "Retailer_Name": "Rohim badsha",
    "Retailer_Phone": 1927108714,
    "Traders_Name": "Mosiur Traders",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8753359,
    "Longitude": 89.4454076,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/6/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000117-R",
    "Retailer_Name": "Rohim",
    "Retailer_Phone": 1780044920,
    "Traders_Name": "Fertilizer shop",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746769,
    "Longitude": 89.446398,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/7/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000150-R",
    "Retailer_Name": "Sumon",
    "Retailer_Phone": 1751013837,
    "Traders_Name": "Sumon tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.506756,
    "Longitude": 89.2889291,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/8/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000118-R",
    "Retailer_Name": "Mohammad Anuwar Hossain",
    "Retailer_Phone": 1736269058,
    "Traders_Name": "Massers Monjur Alom shop",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8727472,
    "Longitude": 89.445112,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/9/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000119-R",
    "Retailer_Name": "Asaduzzaman Asad",
    "Retailer_Phone": 1318117374,
    "Traders_Name": "Massers A fertilizer shop",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.874633,
    "Longitude": 89.4460287,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/10/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000120-R",
    "Retailer_Name": "Monsur Ali",
    "Retailer_Phone": 1719709053,
    "Traders_Name": "Massers Nazma bij ghor",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746642,
    "Longitude": 89.4462537,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000121-R",
    "Retailer_Name": "Mohammad momin khan",
    "Retailer_Phone": 1723256981,
    "Traders_Name": "Massers maruf tradres",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746458,
    "Longitude": 89.4462017,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/12/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000122-R",
    "Retailer_Name": "Mohammad Ashraf Alom",
    "Retailer_Phone": 1744350596,
    "Traders_Name": "Massers sadi enterprise",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746921,
    "Longitude": 89.4463828,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/13/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000123-R",
    "Retailer_Name": "Harun sorkar",
    "Retailer_Phone": 1714604762,
    "Traders_Name": "Siam Fertilizer",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746981,
    "Longitude": 89.4464195,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/14/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000124-R",
    "Retailer_Name": "Soriful Islam",
    "Retailer_Phone": 1739834894,
    "Traders_Name": "Shefa dairy and poltry",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746706,
    "Longitude": 89.4460357,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000125-R",
    "Retailer_Name": "Rafikul sorkar",
    "Retailer_Phone": 1739018976,
    "Traders_Name": "Sorkar traders",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746391,
    "Longitude": 89.4460407,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000126-R",
    "Retailer_Name": "Abul kalam",
    "Retailer_Phone": 1722667082,
    "Traders_Name": "Fahim dairy food store",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8748212,
    "Longitude": 89.4460333,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000127-R",
    "Retailer_Name": "Jahid",
    "Retailer_Phone": 1730654719,
    "Traders_Name": "Al fatun dairy and poltry store",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8746382,
    "Longitude": 89.4460358,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000101-R",
    "Retailer_Name": "Mohonto",
    "Retailer_Phone": 1909886975,
    "Traders_Name": "Mohonto tedars",
    "Bazar_Name": "SAnerhat",
    "Latitude": 25.4842338,
    "Longitude": 89.3281296,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000102-R",
    "Retailer_Name": "Nishat",
    "Retailer_Phone": 1997741403,
    "Traders_Name": "Nishat tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4839387,
    "Longitude": 89.328469,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000128-R",
    "Retailer_Name": "Toyob ali",
    "Retailer_Phone": 1712932631,
    "Traders_Name": "Toyob Ali fertilizer",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8743249,
    "Longitude": 89.4451195,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000129-R",
    "Retailer_Name": "Khan fertilizer",
    "Retailer_Phone": 1717677287,
    "Traders_Name": "Toyob",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8743254,
    "Longitude": 89.4451235,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000103-R",
    "Retailer_Name": "Dr Tajul khan",
    "Retailer_Phone": 1737078171,
    "Traders_Name": "Tajul tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4841436,
    "Longitude": 89.3282252,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000130-R",
    "Retailer_Name": "Azizur Rahman",
    "Retailer_Phone": 1721541839,
    "Traders_Name": "Vai vai feed and vusi ghor",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8727472,
    "Longitude": 89.445112,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000104-R",
    "Retailer_Name": "Motutur",
    "Retailer_Phone": 1726927638,
    "Traders_Name": "Motiur tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4834342,
    "Longitude": 89.3289808,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000131-R",
    "Retailer_Name": "Ayub ali kha",
    "Retailer_Phone": 1741460583,
    "Traders_Name": "Massers jaman seed store",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8743104,
    "Longitude": 89.4451143,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000133-R",
    "Retailer_Name": "Mohammad ibrahim Ali",
    "Retailer_Phone": 1713781066,
    "Traders_Name": "Imran Poltry and dairy feed",
    "Bazar_Name": "Mohrndranagar Bazar",
    "Latitude": 25.8743219,
    "Longitude": 89.445122,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000105-R",
    "Retailer_Name": "Sajahan",
    "Retailer_Phone": 1725344218,
    "Traders_Name": "Sajahan tedars",
    "Bazar_Name": "Sh",
    "Latitude": 25.4842308,
    "Longitude": 89.3281215,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000106-R",
    "Retailer_Name": "Mamun",
    "Retailer_Phone": 1949705714,
    "Traders_Name": "Mamun tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.484971,
    "Longitude": 89.3290696,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000107-R",
    "Retailer_Name": "Ekramul",
    "Retailer_Phone": 1744707893,
    "Traders_Name": "Ekramul tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.484971,
    "Longitude": 89.3290696,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/1/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000134-R",
    "Retailer_Name": "Bulu mia",
    "Retailer_Phone": 1750366241,
    "Traders_Name": "Bithi traders",
    "Bazar_Name": "Foringir dighi Bazar",
    "Latitude": 25.8749012,
    "Longitude": 89.4285551,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/2/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000135-R",
    "Retailer_Name": "Alom sarkar",
    "Retailer_Phone": 1713775541,
    "Traders_Name": "Sarkar traders",
    "Bazar_Name": "Foringir dighi Bazar",
    "Latitude": 25.8751552,
    "Longitude": 89.4274024,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/3/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000136-R",
    "Retailer_Name": "Abdul malek helu",
    "Retailer_Phone": 1729935236,
    "Traders_Name": "Malek traders",
    "Bazar_Name": "Foringir dighi Bazar",
    "Latitude": 25.8751552,
    "Longitude": 89.4273967,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/4/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000137-R",
    "Retailer_Name": "Harunur Roshid",
    "Retailer_Phone": 1701954149,
    "Traders_Name": "Krisok fartilizer",
    "Bazar_Name": "Foringir dighi Bazar",
    "Latitude": 25.8747016,
    "Longitude": 89.4278702,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/5/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000138-R",
    "Retailer_Name": "Saju mondol",
    "Retailer_Phone": 1793917919,
    "Traders_Name": "Krishi bitan",
    "Bazar_Name": "Foringir dighi Bazar",
    "Latitude": 25.8748977,
    "Longitude": 89.4276679,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/6/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000139-R",
    "Retailer_Name": "Sekendar ali sorkar",
    "Retailer_Phone": 1731192433,
    "Traders_Name": "Massers lalmoni seed store",
    "Bazar_Name": "Foringir dighi Bazar",
    "Latitude": 25.875303,
    "Longitude": 89.4368344,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/7/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000112-R",
    "Retailer_Name": "Tuwfik",
    "Retailer_Phone": 1781320804,
    "Traders_Name": "Towfik tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4842367,
    "Longitude": 89.3281328,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/8/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000111-R",
    "Retailer_Name": "Barek",
    "Retailer_Phone": 1919566292,
    "Traders_Name": "Mb tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4842401,
    "Longitude": 89.3281298,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/9/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000113-R",
    "Retailer_Name": "Sohel khan",
    "Retailer_Phone": 1710439410,
    "Traders_Name": "Bhi bhi Tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4842377,
    "Longitude": 89.3281353,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/10/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000114-R",
    "Retailer_Name": "Nahid",
    "Retailer_Phone": 1744675705,
    "Traders_Name": "Nahid store",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4840633,
    "Longitude": 89.3281215,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000110-R",
    "Retailer_Name": "Masud",
    "Retailer_Phone": 1767345840,
    "Traders_Name": "Mila tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4842305,
    "Longitude": 89.3281129,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000109-R",
    "Retailer_Name": "Gulap mondol",
    "Retailer_Phone": 1737476174,
    "Traders_Name": "Gulap mondol tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4842417,
    "Longitude": 89.3281102,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000108-R",
    "Retailer_Name": "Sofikul",
    "Retailer_Phone": 1734807816,
    "Traders_Name": "Sofikul tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4842338,
    "Longitude": 89.3281296,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000115-R",
    "Retailer_Name": "Sauth mia",
    "Retailer_Phone": 1765976075,
    "Traders_Name": "Ruman Tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4842014,
    "Longitude": 89.3281315,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-SAT-0000116-R",
    "Retailer_Name": "Rashedul islam",
    "Retailer_Phone": 1709459828,
    "Traders_Name": "Rashu tedars",
    "Bazar_Name": "Shanerhat bazar",
    "Latitude": 25.4848099,
    "Longitude": 89.3270777,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Shanerhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Shanerhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000151-R",
    "Retailer_Name": "Sujon",
    "Retailer_Phone": 1770992717,
    "Traders_Name": "Sujon tredars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5055907,
    "Longitude": 89.289349,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000152-R",
    "Retailer_Name": "Robin",
    "Retailer_Phone": 1755311189,
    "Traders_Name": "Robin tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5062005,
    "Longitude": 89.2894284,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000153-R",
    "Retailer_Name": "Saidul",
    "Retailer_Phone": 1827090366,
    "Traders_Name": "Sidul tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5061536,
    "Longitude": 89.2891622,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/1/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000009-R",
    "Retailer_Name": "Tarshad khan",
    "Retailer_Phone": 1716986327,
    "Traders_Name": "Sobuj bangla seeds",
    "Bazar_Name": "Gushala",
    "Latitude": 25.914238,
    "Longitude": 89.447083,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/2/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-PRJ-0000154-R",
    "Retailer_Name": "Firoze",
    "Retailer_Phone": 1723607273,
    "Traders_Name": "Fariya tedars",
    "Bazar_Name": "Boro durga",
    "Latitude": 25.5103201,
    "Longitude": 89.2884347,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Pirgonj",
    "Last_visited": "11/3/2023",
    "Route_name": "Pirgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-RAN-RPS-0000034-R",
    "Retailer_Name": "Kamrul hasan",
    "Retailer_Phone": 1925115514,
    "Traders_Name": "Nahar tedars",
    "Bazar_Name": "Morden er mor",
    "Latitude": 25.7096627,
    "Longitude": 89.2610981,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Rangpur Sadar",
    "Union_Name": "Rangpur Sadar",
    "Last_visited": "11/4/2023",
    "Route_name": "Rangpur Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000010-R",
    "Retailer_Name": "Tuhin",
    "Retailer_Phone": 1745080166,
    "Traders_Name": "Messers krishi Ghor",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9178864,
    "Longitude": 89.441278,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/5/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000011-R",
    "Retailer_Name": "Masum parvej",
    "Retailer_Phone": 1711054415,
    "Traders_Name": "Masum fertilizer",
    "Bazar_Name": "BDR hat",
    "Latitude": 25.9178864,
    "Longitude": 89.441278,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/6/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000012-R",
    "Retailer_Name": "Mukter Ali",
    "Retailer_Phone": 1761489488,
    "Traders_Name": "Massers mukter Ali",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9170887,
    "Longitude": 89.4417433,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/7/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000013-R",
    "Retailer_Name": "Bijoy",
    "Retailer_Phone": 1737960527,
    "Traders_Name": "Ali fertilizer shop",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9178941,
    "Longitude": 89.4412762,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/8/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000014-R",
    "Retailer_Name": "Monjur ali",
    "Retailer_Phone": 1792793633,
    "Traders_Name": "Jonab Ali fertilizer Shop",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9170969,
    "Longitude": 89.4417548,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/9/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000015-R",
    "Retailer_Name": "Sirajul",
    "Retailer_Phone": 1726081111,
    "Traders_Name": "Chasi ghor",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9171051,
    "Longitude": 89.4416856,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/10/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000016-R",
    "Retailer_Name": "Boitre",
    "Retailer_Phone": 1712133923,
    "Traders_Name": "Boitre",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9170926,
    "Longitude": 89.4417662,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000100-R",
    "Retailer_Name": "Atawur rahman",
    "Retailer_Phone": 1729876581,
    "Traders_Name": "Aawur Rahman",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4808851,
    "Longitude": 89.2785246,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/12/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000017-R",
    "Retailer_Name": "Masud rana",
    "Retailer_Phone": 1724674221,
    "Traders_Name": "Massers masud fertilizer shop",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9170269,
    "Longitude": 89.441804,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/13/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000018-R",
    "Retailer_Name": "Rased",
    "Retailer_Phone": 1717775643,
    "Traders_Name": "Rased fertilizer",
    "Bazar_Name": "BDR Hat",
    "Latitude": 25.9170104,
    "Longitude": 89.4418135,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/14/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000101-R",
    "Retailer_Name": "Sujon",
    "Retailer_Phone": 1619813417,
    "Traders_Name": "Sojib argu tedars",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4808506,
    "Longitude": 89.278724,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000102-R",
    "Retailer_Name": "Rashed khan",
    "Retailer_Phone": 1738215666,
    "Traders_Name": "Krisi Bij betan",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4808483,
    "Longitude": 89.2785888,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000019-R",
    "Retailer_Name": "Tarshad khan",
    "Retailer_Phone": 1716986328,
    "Traders_Name": "Sobuj bangla seeds",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9126083,
    "Longitude": 89.4478874,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000020-R",
    "Retailer_Name": "Ullas sheak",
    "Retailer_Phone": 1719208962,
    "Traders_Name": "Lalmoni seed",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9126001,
    "Longitude": 89.4479646,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000140-R",
    "Retailer_Name": "Atik",
    "Retailer_Phone": 1775600996,
    "Traders_Name": "Tangail seed store",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9126439,
    "Longitude": 89.4479654,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-MHR-0000141-R",
    "Retailer_Name": "Asraf",
    "Retailer_Phone": 1716273954,
    "Traders_Name": "Asraf traders",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9126117,
    "Longitude": 89.4479315,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000021-R",
    "Retailer_Name": "Bondhon",
    "Retailer_Phone": 1830886998,
    "Traders_Name": "Bondhon seeds shop",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9126122,
    "Longitude": 89.4476647,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000110-R",
    "Retailer_Name": "Ranjo",
    "Retailer_Phone": 1724763787,
    "Traders_Name": "Ranjo tedars",
    "Bazar_Name": "Gurzipara bow Bazar",
    "Latitude": 25.4812948,
    "Longitude": 89.2744522,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000111-R",
    "Retailer_Name": "Ismail",
    "Retailer_Phone": 1307414151,
    "Traders_Name": "Ma tedars",
    "Bazar_Name": "Gurzipara bow Bazar",
    "Latitude": 25.4812661,
    "Longitude": 89.2741774,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000113-R",
    "Retailer_Name": "Rukunni jaman",
    "Retailer_Phone": 1797631453,
    "Traders_Name": "Ruknujamman tedars",
    "Bazar_Name": "Gurzipara bow Bazar",
    "Latitude": 25.4813631,
    "Longitude": 89.2747317,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000112-R",
    "Retailer_Name": "Saddam",
    "Retailer_Phone": 1740211962,
    "Traders_Name": "Saddam tedars",
    "Bazar_Name": "Gurzipara bow Bazar",
    "Latitude": 25.4813878,
    "Longitude": 89.2747846,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000114-R",
    "Retailer_Name": "Meherul islam",
    "Retailer_Phone": 1616709110,
    "Traders_Name": "Miha tedars",
    "Bazar_Name": "Gurzipara bow Bazar",
    "Latitude": 25.4813875,
    "Longitude": 89.2748002,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000115-R",
    "Retailer_Name": "Towhidul islam",
    "Retailer_Phone": 1788266188,
    "Traders_Name": "Bhi bhi tedars",
    "Bazar_Name": "Gurzipara bow Bazar",
    "Latitude": 25.4811554,
    "Longitude": 89.2754751,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000117-R",
    "Retailer_Name": "Sajib",
    "Retailer_Phone": 1748202027,
    "Traders_Name": "Osman goni tedars",
    "Bazar_Name": "Gurzipara bow Bazar",
    "Latitude": 25.4811925,
    "Longitude": 89.2754181,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/11/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000022-R",
    "Retailer_Name": "Akbor ali",
    "Retailer_Phone": 1315230940,
    "Traders_Name": "Akbor traders",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9129385,
    "Longitude": 89.4466646,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Mohendranagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohendranagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000116-R",
    "Retailer_Name": "Khukon",
    "Retailer_Phone": 1723631760,
    "Traders_Name": "Khukon tedars",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4808531,
    "Longitude": 89.2771293,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/1/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000023-R",
    "Retailer_Name": "Meraj Hossain Ahad",
    "Retailer_Phone": 1757068184,
    "Traders_Name": "Massers Akhi store",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9129468,
    "Longitude": 89.4466623,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/2/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000107-R",
    "Retailer_Name": "Safikul",
    "Retailer_Phone": 1301192399,
    "Traders_Name": "Safikul tedars",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4811706,
    "Longitude": 89.2777636,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/3/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000108-R",
    "Retailer_Name": "Muksed",
    "Retailer_Phone": 1722666936,
    "Traders_Name": "Moksud tedars",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4808387,
    "Longitude": 89.2770834,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/4/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000109-R",
    "Retailer_Name": "Saddam",
    "Retailer_Phone": 1318968717,
    "Traders_Name": "K and s tedars",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4811131,
    "Longitude": 89.2781739,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/5/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000103-R",
    "Retailer_Name": "Sohidul",
    "Retailer_Phone": 1718484339,
    "Traders_Name": "Sohidul islam Tedars",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4809436,
    "Longitude": 89.2777036,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/6/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000104-R",
    "Retailer_Name": "Kamrul",
    "Retailer_Phone": 1757601947,
    "Traders_Name": "Kamrul stor",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.480935,
    "Longitude": 89.2778755,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/7/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000105-R",
    "Retailer_Name": "Saidul islam",
    "Retailer_Phone": 1735024617,
    "Traders_Name": "Minha tedars",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4809579,
    "Longitude": 89.2776411,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/8/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000118-R",
    "Retailer_Name": "Ramjan",
    "Retailer_Phone": 1737144605,
    "Traders_Name": "Ramjan store",
    "Bazar_Name": "Gurzipara",
    "Latitude": 25.4808652,
    "Longitude": 89.2785347,
    "Business_Type": "livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/9/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-BRH-0000119-R",
    "Retailer_Name": "Sirahul islam",
    "Retailer_Phone": 1761262081,
    "Traders_Name": "Nishat tedars",
    "Bazar_Name": "Bismail",
    "Latitude": 25.4711158,
    "Longitude": 89.2949628,
    "Business_Type": "crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgonj",
    "Union_Name": "Borodargah",
    "Last_visited": "11/10/2023",
    "Route_name": "Borodargah",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000024-R",
    "Retailer_Name": "Aminul islam",
    "Retailer_Phone": 1734283415,
    "Traders_Name": "Rofik poltry and dairy feeds",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9142329,
    "Longitude": 89.4470242,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000025-R",
    "Retailer_Name": "Shek raihan",
    "Retailer_Phone": 1610551952,
    "Traders_Name": "Scg",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9142329,
    "Longitude": 89.4470242,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000026-R",
    "Retailer_Name": "Lukman Hossain liton",
    "Retailer_Phone": 1763054335,
    "Traders_Name": "Shek feed ghor",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9142329,
    "Longitude": 89.4470242,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000027-R",
    "Retailer_Name": "Haider ali khan badol",
    "Retailer_Phone": 1712949309,
    "Traders_Name": "Khan seeds",
    "Bazar_Name": "Gushala",
    "Latitude": 25.9142329,
    "Longitude": 89.4470242,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Lalmonirhat Sadar",
    "Union_Name": "Lalmonirhat Sadar",
    "Last_visited": "11/11/2023",
    "Route_name": "Lalmonirhat Sadar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000100-R",
    "Retailer_Name": "Moharajpur Bij Ghar",
    "Retailer_Phone": 1921102586,
    "Traders_Name": "Moharajpur Bij Ghar",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6249573,
    "Longitude": 88.6346032,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000101-R",
    "Retailer_Name": "New Popular Bij Bhander",
    "Retailer_Phone": 1712225096,
    "Traders_Name": "New Popular Bij Bhander",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6250408,
    "Longitude": 88.6346921,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000103-R",
    "Retailer_Name": "Krishi Bij Bhander",
    "Retailer_Phone": 1761154870,
    "Traders_Name": "Krishi Bij Bhander",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.624735,
    "Longitude": 88.6353494,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000104-R",
    "Retailer_Name": "Dinajpur Bij Bhander",
    "Retailer_Phone": 1714941817,
    "Traders_Name": "Dinajpur Bij Bhander",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6247274,
    "Longitude": 88.6353835,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000106-R",
    "Retailer_Name": "Bhai Bhai Bij Bhander",
    "Retailer_Phone": 1712552073,
    "Traders_Name": "Bhai Bhai Bij Bhander",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.624731,
    "Longitude": 88.6354505,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/1/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000107-R",
    "Retailer_Name": "Al Madina Seed Store",
    "Retailer_Phone": 1911187300,
    "Traders_Name": "Al Madina Seed Store",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6247062,
    "Longitude": 88.6354812,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/2/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000108-R",
    "Retailer_Name": "Maa Bij Bhander",
    "Retailer_Phone": 1310964924,
    "Traders_Name": "Maa Bij Bhnader",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6247491,
    "Longitude": 88.6353893,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/3/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000109-R",
    "Retailer_Name": "Shahjalal Bij Bhander",
    "Retailer_Phone": 1796710189,
    "Traders_Name": "Shahjalal Bij Bhander",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6247409,
    "Longitude": 88.6353138,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/4/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000110-R",
    "Retailer_Name": "New Sabuj Bij Bhander",
    "Retailer_Phone": 1750705774,
    "Traders_Name": "New Sabuj Bij Bhander",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6248476,
    "Longitude": 88.6350429,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/5/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000111-R",
    "Retailer_Name": "Bismillah Bij Bhander",
    "Retailer_Phone": 1786310316,
    "Traders_Name": "Bismillah Bij Bhander",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6248805,
    "Longitude": 88.6350208,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/6/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-FZR-0000112-R",
    "Retailer_Name": "Krishok Seed Store",
    "Retailer_Phone": 1744780689,
    "Traders_Name": "Krishok Seed Store",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.6249414,
    "Longitude": 88.6348227,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Fazilpur",
    "Last_visited": "11/7/2023",
    "Route_name": "Fazilpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000100-R",
    "Retailer_Name": "Nur Banijalloy",
    "Retailer_Phone": 1723889679,
    "Traders_Name": "Nur Bij Bhander",
    "Bazar_Name": "Bhabai Nagar",
    "Latitude": 25.6072507,
    "Longitude": 88.669152,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/8/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000100-R",
    "Retailer_Name": "Gumalm Mostafa",
    "Retailer_Phone": 1795299179,
    "Traders_Name": "Mukta Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5412752,
    "Longitude": 88.7715639,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/9/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000101-R",
    "Retailer_Name": "Moshiur Rahman",
    "Retailer_Phone": 1722759540,
    "Traders_Name": "Madina Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5412531,
    "Longitude": 88.7715227,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/10/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000102-R",
    "Retailer_Name": "Abu Huraira Rifat",
    "Retailer_Phone": 1821859759,
    "Traders_Name": "Dui Bhai Store",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5419456,
    "Longitude": 88.7722696,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000103-R",
    "Retailer_Name": "Md Delwar",
    "Retailer_Phone": 1755208693,
    "Traders_Name": "Haque Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5420387,
    "Longitude": 88.7722979,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/12/2023",
    "Route_name": "Punotti",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000104-R",
    "Retailer_Name": "Anwar Hossen",
    "Retailer_Phone": 1734059791,
    "Traders_Name": "Anwar Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5418043,
    "Longitude": 88.7725595,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/13/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000105-R",
    "Retailer_Name": "Md Sujon",
    "Retailer_Phone": 1784284140,
    "Traders_Name": "Sujon Store",
    "Bazar_Name": "Amtoli bazar",
    "Latitude": 25.5420504,
    "Longitude": 88.7726579,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/14/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000106-R",
    "Retailer_Name": "Mohammad Mohizur Rahman",
    "Retailer_Phone": 1834124523,
    "Traders_Name": "Mohizur Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5420748,
    "Longitude": 88.7726902,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000107-R",
    "Retailer_Name": "Abdur Rahim",
    "Retailer_Phone": 1862525256,
    "Traders_Name": "Rahim Store",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5413523,
    "Longitude": 88.7717801,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000108-R",
    "Retailer_Name": "Nayeem Hasan",
    "Retailer_Phone": 1701561127,
    "Traders_Name": "Mayer Doa",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5414901,
    "Longitude": 88.7724268,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000109-R",
    "Retailer_Name": "Ariful Islam",
    "Retailer_Phone": 1736175550,
    "Traders_Name": "Rifat Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5414603,
    "Longitude": 88.7723792,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000110-R",
    "Retailer_Name": "Abdul Malek",
    "Retailer_Phone": 1729115431,
    "Traders_Name": "Maa Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5414498,
    "Longitude": 88.7725034,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000112-R",
    "Retailer_Name": "Mominul",
    "Retailer_Phone": 1718139071,
    "Traders_Name": "Momin Traders",
    "Bazar_Name": "Mcha Shah Bazar",
    "Latitude": 25.5393909,
    "Longitude": 88.7952553,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000113-R",
    "Retailer_Name": "Nur Alam",
    "Retailer_Phone": 1740341134,
    "Traders_Name": "Mondol Traders",
    "Bazar_Name": "Uchitpur Bazar",
    "Latitude": 25.539854,
    "Longitude": 88.8134155,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000114-R",
    "Retailer_Name": "Emdadul Rahman",
    "Retailer_Phone": 1726964301,
    "Traders_Name": "Maa Traders",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5414704,
    "Longitude": 88.7725093,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000116-R",
    "Retailer_Name": "Md Rashidul",
    "Retailer_Phone": 1722885911,
    "Traders_Name": "Nur Store",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5414715,
    "Longitude": 88.772509,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000117-R",
    "Retailer_Name": "Md Imtiaj",
    "Retailer_Phone": 1884683818,
    "Traders_Name": "Sani Store",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5414152,
    "Longitude": 88.7722513,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-CHI-PNI-0000118-R",
    "Retailer_Name": "Raizul islam",
    "Retailer_Phone": 1710396233,
    "Traders_Name": "Dada Nati Paan Store",
    "Bazar_Name": "Amtoli Bazar",
    "Latitude": 25.5414858,
    "Longitude": 88.7725069,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Chirirbandar",
    "Union_Name": "Punotti",
    "Last_visited": "11/11/2023",
    "Route_name": "Punotti",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-SNR-0000100-R",
    "Retailer_Name": "Md Kawser",
    "Retailer_Phone": 1716264757,
    "Traders_Name": "Dui Bhai Store",
    "Bazar_Name": "Lakkhitola Bazar",
    "Latitude": 25.5527714,
    "Longitude": 88.749646,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Sankarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sankarpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-SNR-0000101-R",
    "Retailer_Name": "Al Amin",
    "Retailer_Phone": 1712926901,
    "Traders_Name": "Al Mamun Traders",
    "Bazar_Name": "Lakkhitola Bazar",
    "Latitude": 25.5528233,
    "Longitude": 88.7495359,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Sankarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sankarpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-SNR-0000102-R",
    "Retailer_Name": "Rayhan Kabir",
    "Retailer_Phone": 1761319186,
    "Traders_Name": "Kabir Traders",
    "Bazar_Name": "Lakkhitola Bazar",
    "Latitude": 25.5527228,
    "Longitude": 88.7494686,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Sankarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sankarpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-SNR-0000103-R",
    "Retailer_Name": "Maybe Hammad Ajmol Hossen",
    "Retailer_Phone": 1731918307,
    "Traders_Name": "Sarker Krishi Bitan",
    "Bazar_Name": "Lakkhitola Bazar",
    "Latitude": 25.5528589,
    "Longitude": 88.7495402,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Sankarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sankarpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000100-R",
    "Retailer_Name": "Jakir Hossain",
    "Retailer_Phone": 1719135483,
    "Traders_Name": "Maa Traders",
    "Bazar_Name": "Gowalhat Bazar",
    "Latitude": 25.5682637,
    "Longitude": 88.7351028,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/1/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000101-R",
    "Retailer_Name": "Jahangir",
    "Retailer_Phone": 1973740706,
    "Traders_Name": "Jahangir Store",
    "Bazar_Name": "Gowalhat Bazar",
    "Latitude": 25.5682409,
    "Longitude": 88.7351101,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/2/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000102-R",
    "Retailer_Name": "Ratan Rai",
    "Retailer_Phone": 1930319950,
    "Traders_Name": "Ratan Traders",
    "Bazar_Name": "Gowalhat Bazar",
    "Latitude": 25.5683985,
    "Longitude": 88.7350688,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/3/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000103-R",
    "Retailer_Name": "Masud",
    "Retailer_Phone": 1728501793,
    "Traders_Name": "Masud Traders",
    "Bazar_Name": "Gowalhat Bazar",
    "Latitude": 25.5683903,
    "Longitude": 88.7350593,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/4/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000104-R",
    "Retailer_Name": "Tapon",
    "Retailer_Phone": 1798930463,
    "Traders_Name": "Maa Traders",
    "Bazar_Name": "Chakrampur Bazar",
    "Latitude": 25.5805189,
    "Longitude": 88.7236846,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/5/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000105-R",
    "Retailer_Name": "Ragunath",
    "Retailer_Phone": 1783878187,
    "Traders_Name": "Ribo Traders",
    "Bazar_Name": "Chakrampur Bazar",
    "Latitude": 25.585831,
    "Longitude": 88.7390253,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/6/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000106-R",
    "Retailer_Name": "Mojaffar Hossen",
    "Retailer_Phone": 1712407091,
    "Traders_Name": "Drivar Store",
    "Bazar_Name": "Chakrampur Bazar",
    "Latitude": 25.5805189,
    "Longitude": 88.7236846,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/7/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000107-R",
    "Retailer_Name": "Majedul",
    "Retailer_Phone": 1773482974,
    "Traders_Name": "Majedur Store",
    "Bazar_Name": "Chakrampur Bazar",
    "Latitude": 25.5775849,
    "Longitude": 88.7282922,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/8/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000102-R",
    "Retailer_Name": "Minhaj",
    "Retailer_Phone": 1794885957,
    "Traders_Name": "Minhaj Traders",
    "Bazar_Name": "Pachbari Bazar",
    "Latitude": 25.585562,
    "Longitude": 88.7080373,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/9/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000103-R",
    "Retailer_Name": "Mohammad Shahin Ali",
    "Retailer_Phone": 1719254765,
    "Traders_Name": "Jihad Traders",
    "Bazar_Name": "Kawga Moar",
    "Latitude": 25.6071119,
    "Longitude": 88.7004072,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/10/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000104-R",
    "Retailer_Name": "Md Alam",
    "Retailer_Phone": 1723994276,
    "Traders_Name": "Alam Store",
    "Bazar_Name": "Chuniapara Bazar",
    "Latitude": 25.6083438,
    "Longitude": 88.6820226,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000105-R",
    "Retailer_Name": "Mohammad Anwar",
    "Retailer_Phone": 1785519154,
    "Traders_Name": "Afzal Store Fall",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5835311,
    "Longitude": 88.669069,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000106-R",
    "Retailer_Name": "Motiur Rahman",
    "Retailer_Phone": 1774656957,
    "Traders_Name": "Motiur Poultry",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5843892,
    "Longitude": 88.6676134,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000107-R",
    "Retailer_Name": "Kadimul Islam",
    "Retailer_Phone": 1735128594,
    "Traders_Name": "Kadimul Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5843374,
    "Longitude": 88.668027,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000108-R",
    "Retailer_Name": "Mohammad Hasan Ali",
    "Retailer_Phone": 1728484558,
    "Traders_Name": "Hasan Ali Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5843035,
    "Longitude": 88.6679858,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000109-R",
    "Retailer_Name": "Hafizur Rahman",
    "Retailer_Phone": 1721504594,
    "Traders_Name": "Bhai Bon Store",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5843059,
    "Longitude": 88.6679861,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000110-R",
    "Retailer_Name": "Shahjan",
    "Retailer_Phone": 1713866983,
    "Traders_Name": "Sadia varieties store",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5843173,
    "Longitude": 88.6679491,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000111-R",
    "Retailer_Name": "Delwar",
    "Retailer_Phone": 1788269574,
    "Traders_Name": "Delwar Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5843217,
    "Longitude": 88.6679428,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000112-R",
    "Retailer_Name": "Selim Rana",
    "Retailer_Phone": 1783095260,
    "Traders_Name": "Maa Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5830612,
    "Longitude": 88.6681176,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/1/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000113-R",
    "Retailer_Name": "Aktaruzzaman",
    "Retailer_Phone": 1725235478,
    "Traders_Name": "Aktaruzzaman Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5837967,
    "Longitude": 88.6679431,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/2/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000114-R",
    "Retailer_Name": "Tanvir",
    "Retailer_Phone": 1301163282,
    "Traders_Name": "Tanvir Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.584379,
    "Longitude": 88.667521,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/3/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000115-R",
    "Retailer_Name": "Abdur Rouf",
    "Retailer_Phone": 1730172802,
    "Traders_Name": "Rayhan Krishi Ghar",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5843732,
    "Longitude": 88.6675434,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/4/2023",
    "Route_name": "Shashora",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000116-R",
    "Retailer_Name": "Mustafa Kamal",
    "Retailer_Phone": 1737486983,
    "Traders_Name": "Atik Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5830148,
    "Longitude": 88.6681342,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/5/2023",
    "Route_name": "Shashora",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000117-R",
    "Retailer_Name": "Badsha",
    "Retailer_Phone": 1729346835,
    "Traders_Name": "Anisa Traders",
    "Bazar_Name": "Fultola Bazar",
    "Latitude": 25.5830638,
    "Longitude": 88.6680976,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/6/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000108-R",
    "Retailer_Name": "Kurban Ali",
    "Retailer_Phone": 1763400735,
    "Traders_Name": "Krishi Ghar",
    "Bazar_Name": "Godagari bazar",
    "Latitude": 25.5612423,
    "Longitude": 88.6762283,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/7/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000109-R",
    "Retailer_Name": "Hazi",
    "Retailer_Phone": 1733134091,
    "Traders_Name": "Hazi Traders",
    "Bazar_Name": "Godagari Bazar",
    "Latitude": 25.5611381,
    "Longitude": 88.6762609,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/8/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000110-R",
    "Retailer_Name": "Aktarul",
    "Retailer_Phone": 1738755848,
    "Traders_Name": "Aktarul Traders",
    "Bazar_Name": "Godagari Bazar",
    "Latitude": 25.560703,
    "Longitude": 88.6763219,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/9/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-UHL-0000111-R",
    "Retailer_Name": "Mustafizur Rahman Mantu",
    "Retailer_Phone": 1755170558,
    "Traders_Name": "Mantu Traders",
    "Bazar_Name": "Godagari bazar",
    "Latitude": 25.560714,
    "Longitude": 88.6761413,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Uthrail",
    "Last_visited": "11/10/2023",
    "Route_name": "Uthrail",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000118-R",
    "Retailer_Name": "Saidul",
    "Retailer_Phone": 1729895407,
    "Traders_Name": "Saidul Store",
    "Bazar_Name": "Pachbari Bazar",
    "Latitude": 25.5879105,
    "Longitude": 88.7122769,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/11/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000119-R",
    "Retailer_Name": "Mohammad Gulam mostafa",
    "Retailer_Phone": 1717101565,
    "Traders_Name": "Gulam Mostafa Traders",
    "Bazar_Name": "Pachbari Bazar",
    "Latitude": 25.587914,
    "Longitude": 88.7127079,
    "Business_Type": "crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/12/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-SAA-0000120-R",
    "Retailer_Name": "Badsha",
    "Retailer_Phone": 1761170554,
    "Traders_Name": "Alif Traders",
    "Bazar_Name": "Pachbari Bazar",
    "Latitude": 25.5877828,
    "Longitude": 88.7122154,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Shashora",
    "Last_visited": "11/13/2023",
    "Route_name": "Shashora",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000100-R",
    "Retailer_Name": "Sushanto",
    "Retailer_Phone": 1735190310,
    "Traders_Name": "Sushanto store",
    "Bazar_Name": "Pulhat bazar",
    "Latitude": 25.6047302,
    "Longitude": 88.6353203,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/14/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000101-R",
    "Retailer_Name": "Abdullah al amin",
    "Retailer_Phone": 1770860471,
    "Traders_Name": "Abdullah alamn store",
    "Bazar_Name": "Pulhat bazar",
    "Latitude": 25.6052102,
    "Longitude": 88.6356953,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000103-R",
    "Retailer_Name": "Kartik",
    "Retailer_Phone": 1712778452,
    "Traders_Name": "Bhusi vandar",
    "Bazar_Name": "Rail Bazar",
    "Latitude": 25.6356625,
    "Longitude": 88.6449145,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000104-R",
    "Retailer_Name": "Nimai",
    "Retailer_Phone": 1722707738,
    "Traders_Name": "Nirob Go khaddo vandar",
    "Bazar_Name": "Sher shah market",
    "Latitude": 25.6337643,
    "Longitude": 88.648461,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000105-R",
    "Retailer_Name": "Mamun",
    "Retailer_Phone": 1736627411,
    "Traders_Name": "Mamun Traders",
    "Bazar_Name": "Sher shah market",
    "Latitude": 25.6342281,
    "Longitude": 88.6488554,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000106-R",
    "Retailer_Name": "Ibrahim",
    "Retailer_Phone": 1714691879,
    "Traders_Name": "Ibrahim traders",
    "Bazar_Name": "Bottoli",
    "Latitude": 25.6341287,
    "Longitude": 88.6495997,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000107-R",
    "Retailer_Name": "Robin",
    "Retailer_Phone": 1744296589,
    "Traders_Name": "Ria Traders",
    "Bazar_Name": "Bottoli",
    "Latitude": 25.6341978,
    "Longitude": 88.649469,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000109-R",
    "Retailer_Name": "Abbas Ali",
    "Retailer_Phone": 1760567515,
    "Traders_Name": "Ma Traders",
    "Bazar_Name": "Modina masjid",
    "Latitude": 25.6442029,
    "Longitude": 88.6337137,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000110-R",
    "Retailer_Name": "MD akash",
    "Retailer_Phone": 1762733453,
    "Traders_Name": "Vai vai store",
    "Bazar_Name": "Ramnogor",
    "Latitude": 25.6395453,
    "Longitude": 88.6323669,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000111-R",
    "Retailer_Name": "Anser Ali",
    "Retailer_Phone": 1719858116,
    "Traders_Name": "Vai Vai tradars",
    "Bazar_Name": "Ramnogor",
    "Latitude": 25.6391745,
    "Longitude": 88.6318561,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-DIN-ALR-0000112-R",
    "Retailer_Name": "Md Babu",
    "Retailer_Phone": 1731362708,
    "Traders_Name": "Babu Tradars",
    "Bazar_Name": "Ramnogor",
    "Latitude": 25.6391745,
    "Longitude": 88.6318561,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Dinajpur Sadar",
    "Union_Name": "Auliapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Auliapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000100-R",
    "Retailer_Name": "Md Fozlu Rahaman",
    "Retailer_Phone": 1773668132,
    "Traders_Name": "Vi vi readers",
    "Bazar_Name": "Boattola",
    "Latitude": 26.1144715,
    "Longitude": 89.1798201,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/11/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000101-R",
    "Retailer_Name": "Md Babu",
    "Retailer_Phone": 1775014780,
    "Traders_Name": "Mamata teaders",
    "Bazar_Name": "telpump",
    "Latitude": 26.1237904,
    "Longitude": 89.1433661,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/11/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000100-R",
    "Retailer_Name": "Md Abu motaleb",
    "Retailer_Phone": 1743877388,
    "Traders_Name": "madhurjo teaders",
    "Bazar_Name": "Dighirhat",
    "Latitude": 26.1426869,
    "Longitude": 89.1357728,
    "Business_Type": "crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/11/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000101-R",
    "Retailer_Name": "Md Milon",
    "Retailer_Phone": 1747545974,
    "Traders_Name": "Milon khaddo ghor",
    "Bazar_Name": "Rampur",
    "Latitude": 25.8187052,
    "Longitude": 88.6653118,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000102-R",
    "Retailer_Name": "Nurul islam",
    "Retailer_Phone": 1784935745,
    "Traders_Name": "Nurul tadus",
    "Bazar_Name": "Chourasats",
    "Latitude": 25.8120783,
    "Longitude": 88.6144467,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000102-R",
    "Retailer_Name": "Md Rabiul Islam",
    "Retailer_Phone": 1762890991,
    "Traders_Name": "Ma veterinary",
    "Bazar_Name": "parulia",
    "Latitude": 26.075108,
    "Longitude": 89.1370681,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/1/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000101-R",
    "Retailer_Name": "Md Ahosan",
    "Retailer_Phone": 1725743178,
    "Traders_Name": "R Islam Bradars",
    "Bazar_Name": "Bolea Bazar",
    "Latitude": 25.8566314,
    "Longitude": 88.5661394,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/2/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000001-R",
    "Retailer_Name": "Gobindo Roy",
    "Retailer_Phone": 1750313154,
    "Traders_Name": "Gobindo vetenary stor",
    "Bazar_Name": "Bort office",
    "Latitude": 26.026066,
    "Longitude": 88.6259022,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Polashbari",
    "Last_visited": "11/3/2023",
    "Route_name": "Polashbari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000102-R",
    "Retailer_Name": "Md Babul",
    "Retailer_Phone": 1773940187,
    "Traders_Name": "Babul store",
    "Bazar_Name": "Bolaka mor",
    "Latitude": 25.8600435,
    "Longitude": 88.6489043,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/4/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KHA-AOI-0000100-R",
    "Retailer_Name": "Md Manjum hossain",
    "Retailer_Phone": 1767292574,
    "Traders_Name": "Manjum store",
    "Bazar_Name": "Talmatia bazar",
    "Latitude": 25.9306969,
    "Longitude": 88.7260913,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Alokjhari",
    "Last_visited": "11/5/2023",
    "Route_name": "Alokjhari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KHA-AOI-0000101-R",
    "Retailer_Name": "Md Rejaul",
    "Retailer_Phone": 1311894530,
    "Traders_Name": "Rejaul Store",
    "Bazar_Name": "Khansama bazar",
    "Latitude": 25.926602,
    "Longitude": 88.7277269,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Alokjhari",
    "Last_visited": "11/6/2023",
    "Route_name": "Alokjhari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000100-R",
    "Retailer_Name": "Md nur nabi",
    "Retailer_Phone": 1540187241,
    "Traders_Name": "Ms shahitto palli",
    "Bazar_Name": "Dighirhat",
    "Latitude": 26.1429415,
    "Longitude": 89.1343385,
    "Business_Type": "livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/7/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-DBR-0000100-R",
    "Retailer_Name": "Md Asraful",
    "Retailer_Phone": 1794992482,
    "Traders_Name": "Tin vay khaddo ghor",
    "Bazar_Name": "Rampur",
    "Latitude": 25.8163768,
    "Longitude": 88.6716047,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Dabor",
    "Last_visited": "11/8/2023",
    "Route_name": "Dabor",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000060-R",
    "Retailer_Name": "Md. Safiul Islam",
    "Retailer_Phone": 1988670874,
    "Traders_Name": "Messrs Shubho Traders and Veterinaries",
    "Bazar_Name": "Barokhata Bazaar",
    "Latitude": 26.203831,
    "Longitude": 89.109882,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/9/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000059-R",
    "Retailer_Name": "Md. Rubiul Islam",
    "Retailer_Phone": 1921972981,
    "Traders_Name": "Messrs Tohid Veterinary",
    "Bazar_Name": "Milan Bazaar",
    "Latitude": 26.202061,
    "Longitude": 89.109095,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/10/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000058-R",
    "Retailer_Name": "Md. Abdur Razzak",
    "Retailer_Phone": 1857974364,
    "Traders_Name": "Veterinary and Feed Sales",
    "Bazar_Name": "Barokhata Bazaar",
    "Latitude": 26.124072,
    "Longitude": 89.139528,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000057-R",
    "Retailer_Name": "Mo. Abid Ali",
    "Retailer_Phone": 1721230426,
    "Traders_Name": "Bhai Bhai Food House",
    "Bazar_Name": "Barokhata Bazar",
    "Latitude": 26.124007,
    "Longitude": 89.139533,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000056-R",
    "Retailer_Name": "Mo. Ukil Hossain",
    "Retailer_Phone": 1796843965,
    "Traders_Name": "Rokeya Pharmacy and Feed House",
    "Bazar_Name": "Barokhata Bazar",
    "Latitude": 26.203658,
    "Longitude": 89.112302,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000055-R",
    "Retailer_Name": "Mo. Jahidul Islam",
    "Retailer_Phone": 1786282650,
    "Traders_Name": "Dadima Traders and Vatenaries and Battery House",
    "Bazar_Name": "Barokhata Bazar",
    "Latitude": 26.124137,
    "Longitude": 89.139327,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000054-R",
    "Retailer_Name": "Mo. Robiul Islam",
    "Retailer_Phone": 1318104726,
    "Traders_Name": "Mayer Doa Basta and Feed House",
    "Bazar_Name": "Barokhata Bazar",
    "Latitude": 26.202321,
    "Longitude": 89.110903,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000053-R",
    "Retailer_Name": "Mo. Mehedi Hasan",
    "Retailer_Phone": 1992494457,
    "Traders_Name": "Mehedi Veterinary and Feed",
    "Bazar_Name": "B.D.R. Market",
    "Latitude": 26.2025826,
    "Longitude": 89.11060154,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000052-R",
    "Retailer_Name": "Mo. Sojib Hossain",
    "Retailer_Phone": 1310112455,
    "Traders_Name": "Sojib Dairy and Feed",
    "Bazar_Name": "B.D.R. Market",
    "Latitude": 26.20271135,
    "Longitude": 89.11048486,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000051-R",
    "Retailer_Name": "Mo. Sabuj",
    "Retailer_Phone": 1775444018,
    "Traders_Name": "Madina Veterinary",
    "Bazar_Name": "Milan Bazar",
    "Latitude": 26.173144,
    "Longitude": 89.124224,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/11/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-LAL-LMS-0000050-R",
    "Retailer_Name": "Md. Fahim Foyat Hossain",
    "Retailer_Phone": 1744696301,
    "Traders_Name": "Delwar Chawl and Feed House",
    "Bazar_Name": "Barokhata Bazar",
    "Latitude": 26.20321623,
    "Longitude": 89.11021635,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Barokhata",
    "Last_visited": "11/1/2023",
    "Route_name": "Barokhata",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000158-R",
    "Retailer_Name": "Md. Naium Islam",
    "Retailer_Phone": 1723780374,
    "Traders_Name": "Saikat Feed Corner and Traders",
    "Bazar_Name": "Dai Khao Road",
    "Latitude": 26.119063,
    "Longitude": 89.14333,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/2/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000157-R",
    "Retailer_Name": "Md.Ripon Mia",
    "Retailer_Phone": 1888457595,
    "Traders_Name": "Messrs Tuba Traders",
    "Bazar_Name": "Dai Khao Road",
    "Latitude": 26.119073,
    "Longitude": 89.143625,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/3/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000156-R",
    "Retailer_Name": "Mo.Shipan Mia",
    "Retailer_Phone": 1716226184,
    "Traders_Name": "Messrs Sinho and Ahi Traders",
    "Bazar_Name": "Dakalibondha Hat",
    "Latitude": 26.126854,
    "Longitude": 89.1395,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/4/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000155-R",
    "Retailer_Name": "Mo.Moklesur Rahman",
    "Retailer_Phone": 1717236472,
    "Traders_Name": "Mehemot Enter Prize",
    "Bazar_Name": "Dakalibondha Hat",
    "Latitude": 26.125692,
    "Longitude": 89.137063,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/5/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000154-R",
    "Retailer_Name": "Sri Sukumar Chandra Barmorn",
    "Retailer_Phone": 1763122266,
    "Traders_Name": "Sukumar Store",
    "Bazar_Name": "Dakalibondha Hat",
    "Latitude": 26.125638,
    "Longitude": 89.13705,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/6/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000153-R",
    "Retailer_Name": "Mo. Nur Hakim",
    "Retailer_Phone": 1538328691,
    "Traders_Name": "Nurhakim Varieties Store",
    "Bazar_Name": "Shahidul Moor",
    "Latitude": 26.113517,
    "Longitude": 89.162714,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/7/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000152-R",
    "Retailer_Name": "Mohammad Nurul Amin Talukdar",
    "Retailer_Phone": 1744378916,
    "Traders_Name": "Labib Chawl Art",
    "Bazar_Name": "Gendukuri Bazar",
    "Latitude": 26.110336,
    "Longitude": 89.187286,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/8/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000055-R",
    "Retailer_Name": "Md. Ashrafujjaman Lebu",
    "Retailer_Phone": 1723537752,
    "Traders_Name": "Bismillah Traders",
    "Bazar_Name": "Crop Godown",
    "Latitude": 26.137986,
    "Longitude": 89.136508,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/9/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000054-R",
    "Retailer_Name": "Md. Nur Nabi",
    "Retailer_Phone": 1305109900,
    "Traders_Name": "Sahitya Poultry Media",
    "Bazar_Name": "Dighir Hat",
    "Latitude": 26.122514,
    "Longitude": 89.136787,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/10/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000053-R",
    "Retailer_Name": "Sri: Bishwanath Bormon",
    "Retailer_Phone": 1722612424,
    "Traders_Name": "Medha Veterinary",
    "Bazar_Name": "Auditorium Hall Bazar",
    "Latitude": 26.13188495,
    "Longitude": 89.13817993,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/11/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000052-R",
    "Retailer_Name": "Mo. Abdus Salam",
    "Retailer_Phone": 1733075430,
    "Traders_Name": "Sakhi Traders",
    "Bazar_Name": "Dighir Hat",
    "Latitude": 26.14271161,
    "Longitude": 89.13497467,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/12/2023",
    "Route_name": "Singimari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000051-R",
    "Retailer_Name": "Mo. Rani",
    "Retailer_Phone": 1717382102,
    "Traders_Name": "Messrs Setu Protein Corner",
    "Bazar_Name": "Upazila Road Bazar",
    "Latitude": 26.13458853,
    "Longitude": 89.13739837,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/13/2023",
    "Route_name": "Singimari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-SNA-0000051-R",
    "Retailer_Name": "Md. Shariful Islam Babu",
    "Retailer_Phone": 1750814780,
    "Traders_Name": "Messrs Momotamayee Traders",
    "Bazar_Name": "Purba Sindurna Mor",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Sindurna",
    "Last_visited": "11/14/2023",
    "Route_name": "Sindurna",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000152-R",
    "Retailer_Name": "Mo: Jamal Uddin",
    "Retailer_Phone": 1768943609,
    "Traders_Name": "Nuruzzaman Rice House",
    "Bazar_Name": "Paruliya Bazar",
    "Latitude": 26.07720985,
    "Longitude": 89.13610481,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000057-R",
    "Retailer_Name": "Md. Azmat Ali",
    "Retailer_Phone": 1764972348,
    "Traders_Name": "Alo Money Traders",
    "Bazar_Name": "Dai Khao Bazaar",
    "Latitude": 26.090384,
    "Longitude": 89.22145,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000056-R",
    "Retailer_Name": "Mohammad Alamgir Hosen",
    "Retailer_Phone": 1733071231,
    "Traders_Name": "Bhaibhai Store",
    "Bazar_Name": "Nowdabas Bazar",
    "Latitude": 26.113522,
    "Longitude": 89.162703,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000055-R",
    "Retailer_Name": "Mo. Jaynal Abedin",
    "Retailer_Phone": 1722773400,
    "Traders_Name": "Jaynal Veterinary",
    "Bazar_Name": "Nowdabas Bazar",
    "Latitude": 26.096744,
    "Longitude": 89.18995,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000054-R",
    "Retailer_Name": "Mo.Sirajul Jamal Sithil",
    "Retailer_Phone": 1791813210,
    "Traders_Name": "Surabhi Store",
    "Bazar_Name": "Ketkibari Market",
    "Latitude": 26.076294,
    "Longitude": 89.172994,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000053-R",
    "Retailer_Name": "Mo. Shahidar Rahman",
    "Retailer_Phone": 1728886373,
    "Traders_Name": "",
    "Bazar_Name": "",
    "Latitude": 26.075953,
    "Longitude": 89.172719,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000052-R",
    "Retailer_Name": "Sri Sukhen Chandra Roy",
    "Retailer_Phone": 1787942782,
    "Traders_Name": "",
    "Bazar_Name": "",
    "Latitude": 26.09052771,
    "Longitude": 89.22214976,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000051-R",
    "Retailer_Name": "Mohammad Rokonuzzaman",
    "Retailer_Phone": 1763050208,
    "Traders_Name": "",
    "Bazar_Name": "",
    "Latitude": 26.09060359,
    "Longitude": 89.22183326,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000050-R",
    "Retailer_Name": "Mo. Abdur Razzak",
    "Retailer_Phone": 1722256416,
    "Traders_Name": "Rozb Enterprise",
    "Bazar_Name": "Doikhawa Bazar",
    "Latitude": 26.09061202,
    "Longitude": 89.22295308,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/11/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000155-R",
    "Retailer_Name": "Md. Kajal Mia",
    "Retailer_Phone": 1794948260,
    "Traders_Name": "Kajal Traders",
    "Bazar_Name": "Dai Khao Bazaar",
    "Latitude": 26.090636,
    "Longitude": 89.22283,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Gotamari",
    "Last_visited": "11/11/2023",
    "Route_name": "Gotamari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000154-R",
    "Retailer_Name": "Mohammad Abu Saeed",
    "Retailer_Phone": 1748228045,
    "Traders_Name": "",
    "Bazar_Name": "Dai Khao Bazaar",
    "Latitude": 26.090556,
    "Longitude": 89.22246,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Gotamari",
    "Last_visited": "11/11/2023",
    "Route_name": "Gotamari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000153-R",
    "Retailer_Name": "Md. Latif Mia",
    "Retailer_Phone": 1740473034,
    "Traders_Name": "Latif Store",
    "Bazar_Name": "Dai Khao Bazaar",
    "Latitude": 26.123597,
    "Longitude": 89.138593,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Gotamari",
    "Last_visited": "11/11/2023",
    "Route_name": "Gotamari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000152-R",
    "Retailer_Name": "Mo. Mozaffar Ali",
    "Retailer_Phone": 1745829356,
    "Traders_Name": "",
    "Bazar_Name": "Doikhawa Bazar",
    "Latitude": 26.122511,
    "Longitude": 89.136792,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Gotamari",
    "Last_visited": "11/11/2023",
    "Route_name": "Gotamari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000151-R",
    "Retailer_Name": "Sri Anukul Chandra",
    "Retailer_Phone": 1740148554,
    "Traders_Name": "",
    "Bazar_Name": "Doikhawa Bazar",
    "Latitude": 26.093007,
    "Longitude": 89.214475,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Gotamari",
    "Last_visited": "11/11/2023",
    "Route_name": "Gotamari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-DWI-0000050-R",
    "Retailer_Name": "Md. Jaynal Abedin",
    "Retailer_Phone": 1751028460,
    "Traders_Name": "Messrs Absar Traders",
    "Bazar_Name": "Ghuni Bazaar",
    "Latitude": 26.052049,
    "Longitude": 89.154701,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Dawabari",
    "Last_visited": "11/11/2023",
    "Route_name": "Dawabari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000057-R",
    "Retailer_Name": "Md Harun",
    "Retailer_Phone": 1631818374,
    "Traders_Name": "Harun tredars",
    "Bazar_Name": "Jomidar hat",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/1/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000056-R",
    "Retailer_Name": "Md.Alauddin",
    "Retailer_Phone": 1852438676,
    "Traders_Name": "M/s Bradars poultry &feeds",
    "Bazar_Name": "Ashram Bazar",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/2/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000055-R",
    "Retailer_Name": "Md.Hasan",
    "Retailer_Phone": 1622061243,
    "Traders_Name": "M/s Hasan Tradara",
    "Bazar_Name": "Jomidar Hat Bazar",
    "Latitude": 22.69843908,
    "Longitude": 90.91102395,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/3/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000054-R",
    "Retailer_Name": "Md.Rasel",
    "Retailer_Phone": 1777752551,
    "Traders_Name": "Al Modina Tredars",
    "Bazar_Name": "Jomidar Hat Bazar",
    "Latitude": 22.69804789,
    "Longitude": 90.91215168,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/4/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000053-R",
    "Retailer_Name": "Salil Majumdar",
    "Retailer_Phone": 1827522343,
    "Traders_Name": "M/s Majumdar Bradars Poultry",
    "Bazar_Name": "Jomidar Hat Bazar",
    "Latitude": 22.69861802,
    "Longitude": 90.91172765,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/5/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000052-R",
    "Retailer_Name": "Md.Khabir Uddin",
    "Retailer_Phone": 1732316836,
    "Traders_Name": "M/s Tanvir Vetenarian pharmacy",
    "Bazar_Name": "Jomidar Hat Bazar",
    "Latitude": 22.697774,
    "Longitude": 90.911673,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/6/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000051-R",
    "Retailer_Name": "Prohar Mazumdar",
    "Retailer_Phone": 1716419744,
    "Traders_Name": "M/s Majumdar Poultry & Feed",
    "Bazar_Name": "Karunanagar Bazar",
    "Latitude": 22.696631,
    "Longitude": 90.911639,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/7/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-LAK-KSI-0000054-R",
    "Retailer_Name": "Md Javad",
    "Retailer_Phone": 1726675908,
    "Traders_Name": "Haji Stor",
    "Bazar_Name": "Saheber Hat",
    "Latitude": 22.77557291,
    "Longitude": 90.80121577,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Shaheberhat",
    "Last_visited": "11/8/2023",
    "Route_name": "Shaheberhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-LAK-KSI-0000053-R",
    "Retailer_Name": "Md Shamsu Uddin",
    "Retailer_Phone": 1717804543,
    "Traders_Name": "M/ S Faraz Stor",
    "Bazar_Name": "Saheber Hat",
    "Latitude": 22.77528095,
    "Longitude": 90.79974667,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Shaheberhat",
    "Last_visited": "11/9/2023",
    "Route_name": "Shaheberhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-LAK-KSI-0000052-R",
    "Retailer_Name": "Md. Ibrahim",
    "Retailer_Phone": 1714370253,
    "Traders_Name": "Raju Store",
    "Bazar_Name": "Matirhat Bazar",
    "Latitude": 22.73803289,
    "Longitude": 90.86800135,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charkalkini",
    "Last_visited": "11/10/2023",
    "Route_name": "Charkalkini",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-LAK-KSI-0000051-R",
    "Retailer_Name": "Hasan",
    "Retailer_Phone": 1823815293,
    "Traders_Name": "Allahr Dan Store",
    "Bazar_Name": "Matirhat Bazar",
    "Latitude": 22.73817681,
    "Longitude": 90.86807138,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charkalkini",
    "Last_visited": "11/11/2023",
    "Route_name": "Charkalkini",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-LAK-KSI-0000050-R",
    "Retailer_Name": "Md. Nur Saleman",
    "Retailer_Phone": 1616529936,
    "Traders_Name": "Mesad",
    "Bazar_Name": "Matirhat Bazar",
    "Latitude": 22.738217,
    "Longitude": 90.868564,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charkalkini",
    "Last_visited": "11/11/2023",
    "Route_name": "Charkalkini",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000167-R",
    "Retailer_Name": "Md.Faruq",
    "Retailer_Phone": 1830347402,
    "Traders_Name": "Vai Vai Store",
    "Bazar_Name": "Char Martin Munshigonj Bazar",
    "Latitude": 22.816893,
    "Longitude": 90.831253,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Martin",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Martin",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000166-R",
    "Retailer_Name": "Anower Hossain",
    "Retailer_Phone": 1835479573,
    "Traders_Name": "Anower Store",
    "Bazar_Name": "Char Martin Munshigonj Bazar",
    "Latitude": 22.817358,
    "Longitude": 90.830952,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Martin",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Martin",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000165-R",
    "Retailer_Name": "Md.Noman",
    "Retailer_Phone": 1685661941,
    "Traders_Name": "Rashed Tredars",
    "Bazar_Name": "Char Martin Munshigonj Bazar",
    "Latitude": 22.817366,
    "Longitude": 90.830976,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Martin",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Martin",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000164-R",
    "Retailer_Name": "Nurul amin",
    "Retailer_Phone": 1726941008,
    "Traders_Name": "Hossain Store",
    "Bazar_Name": "Koraitola Bazar",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/11/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000163-R",
    "Retailer_Name": "Md Sumon",
    "Retailer_Phone": 1635011088,
    "Traders_Name": "Messrs Rashed Traders and Rashed Rent A Car",
    "Bazar_Name": "Koraitola Bazar",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/11/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000162-R",
    "Retailer_Name": "Md saipul Islam",
    "Retailer_Phone": 1916281007,
    "Traders_Name": "Messrs Rahmaniya Store",
    "Bazar_Name": "Charlorench Main Road Mid Bazar",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/11/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000161-R",
    "Retailer_Name": "Mohammad",
    "Retailer_Phone": 1715174170,
    "Traders_Name": "Mohammadia store",
    "Bazar_Name": "Charlorench Main Road Mid Bazar",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/1/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000160-R",
    "Retailer_Name": "Md Tuhin",
    "Retailer_Phone": 1755755488,
    "Traders_Name": "Shopiq comandar store",
    "Bazar_Name": "Charlorench Main Road Mid Bazar",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/2/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000159-R",
    "Retailer_Name": "Bahar Hossain",
    "Retailer_Phone": 1716940067,
    "Traders_Name": "Haji Bahar Hossain Store",
    "Bazar_Name": "Charlorench Main Road Mid Bazar",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/3/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000158-R",
    "Retailer_Name": "Jahidul Islam",
    "Retailer_Phone": 1748010418,
    "Traders_Name": "Roni Store",
    "Bazar_Name": "Charlorench Main Road Mid Bazar",
    "Latitude": 22.768719,
    "Longitude": 90.865778,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/4/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000157-R",
    "Retailer_Name": "Sirajul Islam",
    "Retailer_Phone": 1728271877,
    "Traders_Name": "Jihad Store",
    "Bazar_Name": "Charlorench Main Road Mid Bazar",
    "Latitude": 22.769533,
    "Longitude": 90.865661,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/5/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000156-R",
    "Retailer_Name": "Mahbub Alam",
    "Retailer_Phone": 1761902213,
    "Traders_Name": "Mahbub Veterinary and Pyara Mahal",
    "Bazar_Name": "Koraitola Bazar",
    "Latitude": 22.79356575,
    "Longitude": 90.86644444,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/6/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000153-R",
    "Retailer_Name": "Joynal abedin",
    "Retailer_Phone": 1827192665,
    "Traders_Name": "Joinal abedin tredars",
    "Bazar_Name": "Koraitola Bazar",
    "Latitude": 22.793258,
    "Longitude": 90.866175,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Torabgonj",
    "Last_visited": "11/7/2023",
    "Route_name": "Torabgonj",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000152-R",
    "Retailer_Name": "md Riaz Uddin",
    "Retailer_Phone": 1705461257,
    "Traders_Name": "Mayer Doa Store",
    "Bazar_Name": "Torabgonj Bazar",
    "Latitude": 22.81729049,
    "Longitude": 90.82237202,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Torabgonj",
    "Last_visited": "11/8/2023",
    "Route_name": "Torabgonj",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000151-R",
    "Retailer_Name": "Haji Md. Shahabuddin",
    "Retailer_Phone": 1715264462,
    "Traders_Name": "Haji Hashem Traders",
    "Bazar_Name": "Torabgonj Bazar",
    "Latitude": 22.816927,
    "Longitude": 90.823873,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Torabgonj",
    "Last_visited": "11/9/2023",
    "Route_name": "Torabgonj",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000150-R",
    "Retailer_Name": "Md. Abdul Rahman Mohan",
    "Retailer_Phone": 1721442139,
    "Traders_Name": "Messrs Aktar Enterprise",
    "Bazar_Name": "Torabgonj Bazar",
    "Latitude": 22.81730484,
    "Longitude": 90.82243758,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Torabgonj",
    "Last_visited": "11/10/2023",
    "Route_name": "Torabgonj",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-PTT-0000054-R",
    "Retailer_Name": "Md shamim",
    "Retailer_Phone": 1738518862,
    "Traders_Name": "Monir store",
    "Bazar_Name": "Matabr hat",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Patarirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Patarirhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-PTT-0000053-R",
    "Retailer_Name": "Redowan Patowary",
    "Retailer_Phone": 1833588250,
    "Traders_Name": "Vai vai store",
    "Bazar_Name": "Pataririhat",
    "Latitude": 22.70645954,
    "Longitude": 90.86416363,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Patarirhat",
    "Last_visited": "11/12/2023",
    "Route_name": "Patarirhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-PTT-0000052-R",
    "Retailer_Name": "Md Tarek",
    "Retailer_Phone": 1825756507,
    "Traders_Name": "M/S Sayud Traders",
    "Bazar_Name": "Khayerhat Bazar",
    "Latitude": 22.710308,
    "Longitude": 90.87694,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Patarirhat",
    "Last_visited": "11/13/2023",
    "Route_name": "Patarirhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-PTT-0000051-R",
    "Retailer_Name": "Md Alomgir Hossain",
    "Retailer_Phone": 1716889698,
    "Traders_Name": "M/S Vai Vai Store",
    "Bazar_Name": "Pataririhat",
    "Latitude": 22.723322,
    "Longitude": 90.911894,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Patarirhat",
    "Last_visited": "11/14/2023",
    "Route_name": "Patarirhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000061-R",
    "Retailer_Name": "Md Mainuddin",
    "Retailer_Phone": 1813617638,
    "Traders_Name": "Mainuddin Stor",
    "Bazar_Name": "Karunanagar Bazar",
    "Latitude": 22.72143309,
    "Longitude": 90.9107169,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000060-R",
    "Retailer_Name": "Md.Belal Hossain",
    "Retailer_Phone": 1710087015,
    "Traders_Name": "Kashfull Agrovet",
    "Bazar_Name": "Karunanagar Bazar",
    "Latitude": 22.722492,
    "Longitude": 90.910792,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000059-R",
    "Retailer_Name": "Liton Chandra Das",
    "Retailer_Phone": 1831381119,
    "Traders_Name": "M/s Meghna Poultry & Feeds",
    "Bazar_Name": "Karunanagar Bazar",
    "Latitude": 22.72337274,
    "Longitude": 90.91232622,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000058-R",
    "Retailer_Name": "Md.Osman Gani",
    "Retailer_Phone": 1825299815,
    "Traders_Name": "Jihad Poultry & Pharmacy",
    "Bazar_Name": "Karunanagar Bazar",
    "Latitude": 22.723148,
    "Longitude": 90.911143,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000057-R",
    "Retailer_Name": "Md Azad",
    "Retailer_Phone": 1842788012,
    "Traders_Name": "Azad Store",
    "Bazar_Name": "Baro Mazir Tek",
    "Latitude": 22.723973,
    "Longitude": 90.878969,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000056-R",
    "Retailer_Name": "Samim Hossain",
    "Retailer_Phone": 1608630360,
    "Traders_Name": "Johir Store",
    "Bazar_Name": "Ludhua Bazar",
    "Latitude": 22.72171863,
    "Longitude": 90.84180564,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000055-R",
    "Retailer_Name": "Abdur Rashid",
    "Retailer_Phone": 1881424881,
    "Traders_Name": "Rashid Store",
    "Bazar_Name": "Ludhua Bazar",
    "Latitude": 22.72188507,
    "Longitude": 90.84231537,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000054-R",
    "Retailer_Name": "Md. Millad Hossain",
    "Retailer_Phone": 1610171114,
    "Traders_Name": "Salahuddin Store",
    "Bazar_Name": "Boaliya",
    "Latitude": 22.72146981,
    "Longitude": 90.8663596,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000053-R",
    "Retailer_Name": "Nozrul Islam",
    "Retailer_Phone": 1859466024,
    "Traders_Name": "Nozrul Store",
    "Bazar_Name": "Jajira Bazar",
    "Latitude": 22.74815912,
    "Longitude": 90.86002423,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000052-R",
    "Retailer_Name": "Saddam Hussen",
    "Retailer_Phone": 1821657847,
    "Traders_Name": "Hawlader Store",
    "Bazar_Name": "Karunanagar Bazar",
    "Latitude": 22.72355087,
    "Longitude": 90.90876425,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000051-R",
    "Retailer_Name": "Md Selim",
    "Retailer_Phone": 1626022397,
    "Traders_Name": "Selim Store",
    "Bazar_Name": "Khayerhat Bazar",
    "Latitude": 22.70910337,
    "Longitude": 90.87644194,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000057-R",
    "Retailer_Name": "Rakibul Islam Raju",
    "Retailer_Phone": 1768854229,
    "Traders_Name": "Raju Store",
    "Bazar_Name": "Fajumiyar Hat",
    "Latitude": 22.751601,
    "Longitude": 90.901637,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000056-R",
    "Retailer_Name": "Md Shahjahan",
    "Retailer_Phone": 1818369690,
    "Traders_Name": "M/S Shahjahan Traders",
    "Bazar_Name": "Fajumiyar Hat",
    "Latitude": 22.73954081,
    "Longitude": 90.96160874,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000055-R",
    "Retailer_Name": "Md Yousuf",
    "Retailer_Phone": 1762066404,
    "Traders_Name": "Yousuf Traders",
    "Bazar_Name": "Fajumiyar Hat",
    "Latitude": 22.74004005,
    "Longitude": 90.96057123,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000054-R",
    "Retailer_Name": "Md Melon",
    "Retailer_Phone": 1741921495,
    "Traders_Name": "Md Melon Traders",
    "Bazar_Name": "Fajumiyar Hat",
    "Latitude": 22.73985699,
    "Longitude": 90.95942545,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000053-R",
    "Retailer_Name": "Md Sakait Ullah patowary",
    "Retailer_Phone": 1874596252,
    "Traders_Name": "M/ S Sakait Traders",
    "Bazar_Name": "Fajumiyar Hat",
    "Latitude": 22.73923295,
    "Longitude": 90.96088699,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/1/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KHA-AOI-0000105-R",
    "Retailer_Name": "Mo: Haydar Ali",
    "Retailer_Phone": 1721899326,
    "Traders_Name": "Messrs Haydar Store",
    "Bazar_Name": "Khansama Bazar",
    "Latitude": 25.929753,
    "Longitude": 88.720391,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Alokjhari",
    "Last_visited": "11/2/2023",
    "Route_name": "Alokjhari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000074-R",
    "Retailer_Name": "Md. Shah Alam",
    "Retailer_Phone": 1740873542,
    "Traders_Name": "M/S Shah Alam Traders",
    "Bazar_Name": "Kanto nogor bazar",
    "Latitude": 25.784987,
    "Longitude": 88.675197,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/3/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000073-R",
    "Retailer_Name": "Fozlur Rohoman",
    "Retailer_Phone": 1737380075,
    "Traders_Name": "Fozlur Teders",
    "Bazar_Name": "Kanto Nogor Bazar",
    "Latitude": 25.7852974,
    "Longitude": 88.67538466,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/4/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000072-R",
    "Retailer_Name": "Al-Mamun",
    "Retailer_Phone": 1723531820,
    "Traders_Name": "Ma Khaddo Gor",
    "Bazar_Name": "13 Mile Garia Bazar",
    "Latitude": 25.80274229,
    "Longitude": 88.67678711,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/5/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000071-R",
    "Retailer_Name": "Ibrahim",
    "Retailer_Phone": 1757829680,
    "Traders_Name": "Ekota Tedas",
    "Bazar_Name": "10 Mile Bazar",
    "Latitude": 25.75640938,
    "Longitude": 88.67353183,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/6/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000070-R",
    "Retailer_Name": "Lakhi Kanta Ram",
    "Retailer_Phone": 1725302442,
    "Traders_Name": "Nipa Rice Mill",
    "Bazar_Name": "10 Mile Bazar",
    "Latitude": 25.75646857,
    "Longitude": 88.67352512,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/7/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000069-R",
    "Retailer_Name": "Mo Samim Reza",
    "Retailer_Phone": 1705913382,
    "Traders_Name": "Vai Vai Food & Grocery",
    "Bazar_Name": "13 Mile Garia Bazar",
    "Latitude": 25.80343765,
    "Longitude": 88.67655941,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/8/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000068-R",
    "Retailer_Name": "Md:Nasir uddin",
    "Retailer_Phone": 1735128137,
    "Traders_Name": "Khaddo gor",
    "Bazar_Name": "13 Mile Garia Bazar",
    "Latitude": 25.80244118,
    "Longitude": 88.676502,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/9/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000067-R",
    "Retailer_Name": "Mehedi",
    "Retailer_Phone": 1788085487,
    "Traders_Name": "Mehedi Tedas",
    "Bazar_Name": "Kaharol Bazar",
    "Latitude": 25.79199354,
    "Longitude": 88.59911684,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/10/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000066-R",
    "Retailer_Name": "Kazi Atiqur Rahman",
    "Retailer_Phone": 1760967112,
    "Traders_Name": "Azim Poultry & Fish Feed",
    "Bazar_Name": "Kaharol Bazar",
    "Latitude": 25.79321441,
    "Longitude": 88.59939095,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000065-R",
    "Retailer_Name": "Aminul Islam Amin",
    "Retailer_Phone": 1740919929,
    "Traders_Name": "Mahim Poultry & Fish Feed, Tahsin Feed & Chicks",
    "Bazar_Name": "Kaharol Bazar",
    "Latitude": 25.79255665,
    "Longitude": 88.59948567,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000064-R",
    "Retailer_Name": "Lion",
    "Retailer_Phone": 1761779732,
    "Traders_Name": "Lion Tedas",
    "Bazar_Name": "Kaharol Bazar",
    "Latitude": 25.79249363,
    "Longitude": 88.5993592,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000063-R",
    "Retailer_Name": "Nazirul Islam",
    "Retailer_Phone": 1718072116,
    "Traders_Name": "Tamanna Tedas",
    "Bazar_Name": "Kaharol Bazar",
    "Latitude": 25.79331454,
    "Longitude": 88.59918577,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000062-R",
    "Retailer_Name": "Md: Jahidul Islam",
    "Retailer_Phone": 1745700222,
    "Traders_Name": "Jahidul Tedas",
    "Bazar_Name": "Kaharol Bazar",
    "Latitude": 25.7929606,
    "Longitude": 88.59951063,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000061-R",
    "Retailer_Name": "Md.Hamidul Islam",
    "Retailer_Phone": 1737989799,
    "Traders_Name": "Masum Store",
    "Bazar_Name": "Nagori Sagori Hat",
    "Latitude": 25.96683237,
    "Longitude": 88.65662702,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Moricha",
    "Last_visited": "11/11/2023",
    "Route_name": "Moricha",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000060-R",
    "Retailer_Name": "Md. Jahangir Alam",
    "Retailer_Phone": 1736335124,
    "Traders_Name": "Alamin Store",
    "Bazar_Name": "14 Hathkhola Hat",
    "Latitude": 25.94580807,
    "Longitude": 88.63886744,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Moricha",
    "Last_visited": "11/11/2023",
    "Route_name": "Moricha",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000059-R",
    "Retailer_Name": "Md. Al Amin",
    "Retailer_Phone": 1797760544,
    "Traders_Name": "Al Amin Variety",
    "Bazar_Name": "14 Hathkhola Hat",
    "Latitude": 25.94585872,
    "Longitude": 88.63886476,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Moricha",
    "Last_visited": "11/11/2023",
    "Route_name": "Moricha",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000058-R",
    "Retailer_Name": "Md.Jahangir Alom",
    "Retailer_Phone": 1734301745,
    "Traders_Name": "Vai Bon Store",
    "Bazar_Name": "14 Hathkhola Hat",
    "Latitude": 25.94582918,
    "Longitude": 88.63892176,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Moricha",
    "Last_visited": "11/1/2023",
    "Route_name": "Moricha",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000057-R",
    "Retailer_Name": "Md.Tofazzal Hossain",
    "Retailer_Phone": 1744765838,
    "Traders_Name": "Nur stor",
    "Bazar_Name": "Zindapir Market",
    "Latitude": 25.949507,
    "Longitude": 88.576275,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/2/2023",
    "Route_name": "Sator",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000056-R",
    "Retailer_Name": "Md. Ali Imran",
    "Retailer_Phone": 1716796607,
    "Traders_Name": "Sorkar Traders",
    "Bazar_Name": "Zindapir Market",
    "Latitude": 25.94942,
    "Longitude": 88.576156,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/3/2023",
    "Route_name": "Sator",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000055-R",
    "Retailer_Name": "Md.Abdullah Al Mamun",
    "Retailer_Phone": 1755567692,
    "Traders_Name": "Mamun pharmacy",
    "Bazar_Name": "Zindapir Market",
    "Latitude": 25.949424,
    "Longitude": 88.576218,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/4/2023",
    "Route_name": "Sator",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000054-R",
    "Retailer_Name": "Md.Shahidul Islam",
    "Retailer_Phone": 1748949468,
    "Traders_Name": "Shahidul Store",
    "Bazar_Name": "Bottali Hat",
    "Latitude": 25.91083192,
    "Longitude": 88.57060447,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/5/2023",
    "Route_name": "Sator",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000184-R",
    "Retailer_Name": "Mo Nurul Islam",
    "Retailer_Phone": 1722491348,
    "Traders_Name": "Islam Store",
    "Bazar_Name": "City Market",
    "Latitude": 25.854585,
    "Longitude": 88.654648,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/6/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000183-R",
    "Retailer_Name": "Mo Saiful Islam",
    "Retailer_Phone": 1741513006,
    "Traders_Name": "Saiful Store",
    "Bazar_Name": "Hablurhat",
    "Latitude": 25.884725,
    "Longitude": 88.628206,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/7/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000182-R",
    "Retailer_Name": "Mo Omor Ali",
    "Retailer_Phone": 1743665346,
    "Traders_Name": "Karimul Store",
    "Bazar_Name": "Hablurhat",
    "Latitude": 25.88462174,
    "Longitude": 88.62831248,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/8/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000181-R",
    "Retailer_Name": "Mithu Rai",
    "Retailer_Phone": 1738345986,
    "Traders_Name": "Mithu Store",
    "Bazar_Name": "Jadumor",
    "Latitude": 25.87734166,
    "Longitude": 88.63733974,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/9/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000180-R",
    "Retailer_Name": "Md. Chan Mia",
    "Retailer_Phone": 1729344809,
    "Traders_Name": "Bondhon Poultry Feed",
    "Bazar_Name": "Bolaka Bazar",
    "Latitude": 25.87895465,
    "Longitude": 88.63525849,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/10/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000179-R",
    "Retailer_Name": "Mo Faruk Hossain",
    "Retailer_Phone": 1723655457,
    "Traders_Name": "Faruk Traders",
    "Bazar_Name": "Pour Bazar",
    "Latitude": 25.85465523,
    "Longitude": 88.65504934,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000178-R",
    "Retailer_Name": "Mo Monnash Islam (Sumon)",
    "Retailer_Phone": 1764441331,
    "Traders_Name": "Sumon Pharmacy",
    "Bazar_Name": "Ullash Bazar",
    "Latitude": 25.86559909,
    "Longitude": 88.64867295,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/12/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000177-R",
    "Retailer_Name": "Manik Debnath",
    "Retailer_Phone": 1737763975,
    "Traders_Name": "Manik Rice Mill",
    "Bazar_Name": "Bolaka Bazar",
    "Latitude": 25.86006504,
    "Longitude": 88.65017915,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/13/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000176-R",
    "Retailer_Name": "Toilko Daa",
    "Retailer_Phone": 1762199568,
    "Traders_Name": "Toilko Store",
    "Bazar_Name": "Jagdal Bazar",
    "Latitude": 25.83855354,
    "Longitude": 88.64419542,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/14/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000175-R",
    "Retailer_Name": "Khokon Chondro Sen",
    "Retailer_Phone": 1737455505,
    "Traders_Name": "Sen Traders",
    "Bazar_Name": "Bolaka Bazar",
    "Latitude": 25.85994342,
    "Longitude": 88.64912896,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SIR-0000052-R",
    "Retailer_Name": "Santo roy",
    "Retailer_Phone": 1740229295,
    "Traders_Name": "Manob traders",
    "Bazar_Name": "Dhanagaon Chaurasta Mod",
    "Latitude": 25.980981,
    "Longitude": 88.567319,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shibrampur",
    "Last_visited": "11/11/2023",
    "Route_name": "Shibrampur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SIR-0000051-R",
    "Retailer_Name": "Md.Rony Ahmed",
    "Retailer_Phone": 1757866466,
    "Traders_Name": "Joynal Traders",
    "Bazar_Name": "Rath Bazar Hat",
    "Latitude": 26.00545153,
    "Longitude": 88.55548662,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shibrampur",
    "Last_visited": "11/11/2023",
    "Route_name": "Shibrampur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SIR-0000050-R",
    "Retailer_Name": "Shupen Bormon",
    "Retailer_Phone": 1737823455,
    "Traders_Name": "Shupen Store",
    "Bazar_Name": "Barolia Hat",
    "Latitude": 25.97206976,
    "Longitude": 88.57565339,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shibrampur",
    "Last_visited": "11/11/2023",
    "Route_name": "Shibrampur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-PLR-0000054-R",
    "Retailer_Name": "Md. Mohoshin Ali",
    "Retailer_Phone": 1727959803,
    "Traders_Name": "Beauty Variety Store",
    "Bazar_Name": "Latter Hat",
    "Latitude": 25.92813112,
    "Longitude": 88.69313933,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mohonpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohonpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-PLR-0000053-R",
    "Retailer_Name": "Md.Razu",
    "Retailer_Phone": 1796168289,
    "Traders_Name": "Bismillah Variety Store",
    "Bazar_Name": "Latter Hat",
    "Latitude": 25.92872779,
    "Longitude": 88.6927757,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mohonpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohonpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-PLR-0000052-R",
    "Retailer_Name": "Shanto roy",
    "Retailer_Phone": 1312890011,
    "Traders_Name": "Shourov farmesy",
    "Bazar_Name": "Mahanpur Market",
    "Latitude": 25.911598,
    "Longitude": 88.534143,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mohammadpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mohammadpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-PLR-0000051-R",
    "Retailer_Name": "Md Monirul Islam",
    "Retailer_Phone": 1744889114,
    "Traders_Name": "Vai Vai Store",
    "Bazar_Name": "Modhubonpur Bazar",
    "Latitude": 25.86700876,
    "Longitude": 88.71117656,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Paltapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Paltapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000057-R",
    "Retailer_Name": "Montajul Ahmed",
    "Retailer_Phone": 1751430251,
    "Traders_Name": "Natasa Animal Clinic",
    "Bazar_Name": "Jharbadi Market",
    "Latitude": 25.999897,
    "Longitude": 88.691678,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shatagram",
    "Last_visited": "11/11/2023",
    "Route_name": "Shatagram",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000056-R",
    "Retailer_Name": "Md.Asif",
    "Retailer_Phone": 1723893165,
    "Traders_Name": "Asif Rice Store",
    "Bazar_Name": "Jharbari Hat",
    "Latitude": 25.99949289,
    "Longitude": 88.69168468,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shatagram",
    "Last_visited": "11/11/2023",
    "Route_name": "Shatagram",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000055-R",
    "Retailer_Name": "Md.Moznur Rahman",
    "Retailer_Phone": 1629398890,
    "Traders_Name": "Master Dal Seller",
    "Bazar_Name": "Jharbari Hat",
    "Latitude": 25.99955316,
    "Longitude": 88.69171552,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shatagram",
    "Last_visited": "11/11/2023",
    "Route_name": "Shatagram",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000053-R",
    "Retailer_Name": "Md Babul",
    "Retailer_Phone": 1724394204,
    "Traders_Name": "Babul stor",
    "Bazar_Name": "Bhawanipur Bajar",
    "Latitude": 25.976572,
    "Longitude": 88.626124,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Polashbari",
    "Last_visited": "11/11/2023",
    "Route_name": "Polashbari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000052-R",
    "Retailer_Name": "Md.Forhad hossen",
    "Retailer_Phone": 1756492110,
    "Traders_Name": "M/S Forhad traders",
    "Bazar_Name": "Bhawanipur Market",
    "Latitude": 25.977114,
    "Longitude": 88.626181,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Polashbari",
    "Last_visited": "11/11/2023",
    "Route_name": "Polashbari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000051-R",
    "Retailer_Name": "Md.Afizar Rahman",
    "Retailer_Phone": 1722485167,
    "Traders_Name": "Riyad Pharmacy",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.62626176,
    "Longitude": 88.63195019,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Polashbari",
    "Last_visited": "11/11/2023",
    "Route_name": "Polashbari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000050-R",
    "Retailer_Name": "Md.Tosirul Islam",
    "Retailer_Phone": 1733106825,
    "Traders_Name": "Tosirul Store",
    "Bazar_Name": "Bahadur Bazar",
    "Latitude": 25.62628921,
    "Longitude": 88.63190283,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Polashbari",
    "Last_visited": "11/11/2023",
    "Route_name": "Polashbari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000057-R",
    "Retailer_Name": "Md Mahatab Ali",
    "Retailer_Phone": 1774924479,
    "Traders_Name": "MRS Mahatab Traders",
    "Bazar_Name": "Chowdhury hat",
    "Latitude": 25.90719,
    "Longitude": 88.711433,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/1/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000056-R",
    "Retailer_Name": "Md Abdul Haque Sobuj",
    "Retailer_Phone": 1724108304,
    "Traders_Name": "Mohona Traders",
    "Bazar_Name": "Burir Hat",
    "Latitude": 25.86728683,
    "Longitude": 88.66929305,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/2/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000055-R",
    "Retailer_Name": "Md. Afsar Ali",
    "Retailer_Phone": 1709415147,
    "Traders_Name": "Kamal Traders",
    "Bazar_Name": "Golapgonj Hat",
    "Latitude": 25.92474383,
    "Longitude": 88.64301302,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/3/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000054-R",
    "Retailer_Name": "Sucitra bosak",
    "Retailer_Phone": 1717330408,
    "Traders_Name": "Sworup store",
    "Bazar_Name": "Golapgonj Hat",
    "Latitude": 25.9248385,
    "Longitude": 88.64285845,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/4/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000160-R",
    "Retailer_Name": "Haydar Ali",
    "Retailer_Phone": 1722960469,
    "Traders_Name": "Faima Feed House",
    "Bazar_Name": "Kabiraj Hat",
    "Latitude": 25.903016,
    "Longitude": 88.599692,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/5/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000159-R",
    "Retailer_Name": "Sri Foni Chandra Bormon",
    "Retailer_Phone": 1767067103,
    "Traders_Name": "Foni Store",
    "Bazar_Name": "Machboaal",
    "Latitude": 25.92454,
    "Longitude": 88.609355,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/6/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000151-R",
    "Retailer_Name": "Mr. Mosharaf Hossain",
    "Retailer_Phone": 1571266218,
    "Traders_Name": "Rumi Sar Ghor",
    "Bazar_Name": "Rumi Sar Ghor",
    "Latitude": 26.116242,
    "Longitude": 89.173947,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/7/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000150-R",
    "Retailer_Name": "Mr. Alamgir Hossain",
    "Retailer_Phone": 1740490226,
    "Traders_Name": "Ma Sar Ghor",
    "Bazar_Name": "Ma Sar Ghor",
    "Latitude": 26.11025,
    "Longitude": 89.186994,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/8/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000050-R",
    "Retailer_Name": "Mr. Manjurul Islam Bosunia",
    "Retailer_Phone": 1710158819,
    "Traders_Name": "Messers Manjurul Islam Bosunia",
    "Bazar_Name": "Messers Manjurul Islam Bosunia",
    "Latitude": 26.141373,
    "Longitude": 89.134883,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/9/2023",
    "Route_name": "Singimari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-SNA-0000050-R",
    "Retailer_Name": "Mr. Aolad Hossain",
    "Retailer_Phone": 1728331034,
    "Traders_Name": "Messers Fuyad Sar Ghor",
    "Bazar_Name": "Messers Fuyad Sar Ghor",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Sindurna",
    "Last_visited": "11/10/2023",
    "Route_name": "Sindurna",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000151-R",
    "Retailer_Name": "Mr. Ekramul Haque",
    "Retailer_Phone": 1997549313,
    "Traders_Name": "Bhai Bhai Traders",
    "Bazar_Name": "Bhai Bhai Traders",
    "Latitude": 26.075275,
    "Longitude": 89.137101,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000150-R",
    "Retailer_Name": "Mr. Helal Uddin",
    "Retailer_Phone": 1750244682,
    "Traders_Name": "Labbai Traders",
    "Bazar_Name": "Labbai Traders",
    "Latitude": 26.07567,
    "Longitude": 89.137298,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000150-R",
    "Retailer_Name": "Mr. Abu Saeed",
    "Retailer_Phone": 1722677075,
    "Traders_Name": "Messers",
    "Bazar_Name": "Messers",
    "Latitude": 26.090383,
    "Longitude": 89.221692,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Gotamari",
    "Last_visited": "11/11/2023",
    "Route_name": "Gotamari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-GDI-0000050-R",
    "Retailer_Name": "Mr. Atiar Rahman",
    "Retailer_Phone": 1723581042,
    "Traders_Name": "Milan Bazar",
    "Bazar_Name": "A. Jalil Traders",
    "Latitude": 26.172657,
    "Longitude": 89.124043,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Goddimari",
    "Last_visited": "11/11/2023",
    "Route_name": "Goddimari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000050-R",
    "Retailer_Name": "Anayet Ullah",
    "Retailer_Phone": 1714438344,
    "Traders_Name": "Faysal Tredars",
    "Bazar_Name": "Faysal Tredars",
    "Latitude": 22.723481,
    "Longitude": 90.912757,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/11/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000155-R",
    "Retailer_Name": "Abdur Rahman",
    "Retailer_Phone": 1751050908,
    "Traders_Name": "M/S Mohammadiya Store",
    "Bazar_Name": "M/S Mohammadiya Store",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/11/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-TRJ-0000154-R",
    "Retailer_Name": "Md Kamal Hossain",
    "Retailer_Phone": 1717856173,
    "Traders_Name": "M/S Swity Banijjho Bitan",
    "Bazar_Name": "M/S Swity Banijjho Bitan",
    "Latitude": 22.771611,
    "Longitude": 90.866461,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Charlorench",
    "Last_visited": "11/11/2023",
    "Route_name": "Charlorench",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-PTT-0000050-R",
    "Retailer_Name": "Md Salah Uddin",
    "Retailer_Phone": 1840772912,
    "Traders_Name": "Khayerhat",
    "Bazar_Name": "Habib Traders",
    "Latitude": 22.710396,
    "Longitude": 90.876894,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Patarirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Patarirhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000057-R",
    "Retailer_Name": "Nahidul Islam",
    "Retailer_Phone": 1715572503,
    "Traders_Name": "Nahid Tredars",
    "Bazar_Name": "Nahid Tredars",
    "Latitude": 22.767963,
    "Longitude": 90.865728,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/1/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000056-R",
    "Retailer_Name": "Nurul Amin",
    "Retailer_Phone": 1715101195,
    "Traders_Name": "M/s Nurul Amin & Bradars",
    "Bazar_Name": "M/s Nurul Amin & Bradars",
    "Latitude": 22.723265,
    "Longitude": 90.910106,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/2/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000055-R",
    "Retailer_Name": "Yeakub Nuri",
    "Retailer_Phone": 1981017329,
    "Traders_Name": "Rayhan Tredars",
    "Bazar_Name": "Rayhan Tredars",
    "Latitude": 22.736305,
    "Longitude": 90.868155,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/3/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000054-R",
    "Retailer_Name": "Helal Uddin",
    "Retailer_Phone": 1718113957,
    "Traders_Name": "Helal & Bradars",
    "Bazar_Name": "Helal & Bradars",
    "Latitude": 22.735265,
    "Longitude": 90.869299,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/4/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000053-R",
    "Retailer_Name": "Suparanjan Das",
    "Retailer_Phone": 1822837617,
    "Traders_Name": "M/s Suparanjan Das",
    "Bazar_Name": "M/s Suparanjan Das",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/5/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000052-R",
    "Retailer_Name": "Md Ashraful jaman Hossain",
    "Retailer_Phone": 1739725225,
    "Traders_Name": "M/ S Matabur Traders",
    "Bazar_Name": "M/ S Matabur Traders",
    "Latitude": 22.73625,
    "Longitude": 90.868119,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/6/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000051-R",
    "Retailer_Name": "Md Nurul Alom Chowdhury",
    "Retailer_Phone": 1711279801,
    "Traders_Name": "M/s Chowdhury store",
    "Bazar_Name": "M/s Chowdhury store",
    "Latitude": 22.737001,
    "Longitude": 90.868465,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/7/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000050-R",
    "Retailer_Name": "Md.Ahsaah",
    "Retailer_Phone": 1716957815,
    "Traders_Name": "Vai vai Banijja Bitan",
    "Bazar_Name": "Vai vai Banijja Bitan",
    "Latitude": 22.735402,
    "Longitude": 90.86787,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/8/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000050-R",
    "Retailer_Name": "Md Bayajid",
    "Retailer_Phone": 1827588699,
    "Traders_Name": "Mojahid Traders",
    "Bazar_Name": "Mojahid Traders",
    "Latitude": 22.73419,
    "Longitude": 90.866965,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/9/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000052-R",
    "Retailer_Name": "Md Rakibul Hasan",
    "Retailer_Phone": 1823825239,
    "Traders_Name": "M/s Sahajan traders",
    "Bazar_Name": "M/s Sahajan traders",
    "Latitude": 22.750985,
    "Longitude": 90.903681,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/10/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000051-R",
    "Retailer_Name": "Md Nasim",
    "Retailer_Phone": 1705659545,
    "Traders_Name": "M/S Miya Traders",
    "Bazar_Name": "M/S Miya Traders",
    "Latitude": 22.751163,
    "Longitude": 90.904655,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000050-R",
    "Retailer_Name": "Md Monir",
    "Retailer_Phone": 1832309859,
    "Traders_Name": "M/s Nahar traders",
    "Bazar_Name": "M/s Nahar traders",
    "Latitude": 22.751127,
    "Longitude": 90.904683,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/12/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KHA-BEI-0000101-R",
    "Retailer_Name": "Md Anawor Hossain",
    "Retailer_Phone": 1723606872,
    "Traders_Name": "M/S Roni traders",
    "Bazar_Name": "M/S Roni traders",
    "Latitude": 25.860206,
    "Longitude": 88.781793,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Angarpara",
    "Last_visited": "11/13/2023",
    "Route_name": "Angarpara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KHA-AOI-0000104-R",
    "Retailer_Name": "Badal Debnath",
    "Retailer_Phone": 1750515245,
    "Traders_Name": "Messers Badal Agro",
    "Bazar_Name": "Messers Badal Agro",
    "Latitude": 25.925651,
    "Longitude": 88.728516,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Alokjhari",
    "Last_visited": "11/14/2023",
    "Route_name": "Alokjhari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KHA-AOI-0000103-R",
    "Retailer_Name": "Md. Moktadir Islam",
    "Retailer_Phone": 1717525754,
    "Traders_Name": "Messers Krishibitan",
    "Bazar_Name": "Messers Krishibitan",
    "Latitude": 25.925673,
    "Longitude": 88.72835,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Alokjhari",
    "Last_visited": "11/11/2023",
    "Route_name": "Alokjhari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KHA-AOI-0000102-R",
    "Retailer_Name": "Ramanath Sen",
    "Retailer_Phone": 1773781174,
    "Traders_Name": "Messers Bhai Bhai Traders",
    "Bazar_Name": "Messers Bhai Bhai Traders",
    "Latitude": 25.925506,
    "Longitude": 88.728544,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Alokjhari",
    "Last_visited": "11/11/2023",
    "Route_name": "Alokjhari",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-SNR-0000051-R",
    "Retailer_Name": "Mr. Ziaur Rahman Zia",
    "Retailer_Phone": 1729449980,
    "Traders_Name": "M/S Ma Traders",
    "Bazar_Name": "M/S Ma Traders",
    "Latitude": 25.818575,
    "Longitude": 88.665381,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundopur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundopur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-SNR-0000050-R",
    "Retailer_Name": "Mr. Rubel Islam",
    "Retailer_Phone": 1744560310,
    "Traders_Name": "M/S Rafia Traders",
    "Bazar_Name": "M/S Rafia Traders",
    "Latitude": 25.836055,
    "Longitude": 88.660838,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundopur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundopur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000191-R",
    "Retailer_Name": "Md. Abul Basher",
    "Retailer_Phone": 1712401494,
    "Traders_Name": "Messers Basher Traders",
    "Bazar_Name": "Messers Basher Traders",
    "Latitude": 25.802766,
    "Longitude": 88.676358,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000190-R",
    "Retailer_Name": "Md. Zulfikar Ali",
    "Retailer_Phone": 1767421371,
    "Traders_Name": "Messers Zulfikar Traders",
    "Bazar_Name": "Messers Zulfikar Traders",
    "Latitude": 25.803076,
    "Longitude": 88.676451,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000189-R",
    "Retailer_Name": "Md. Zahangir Alam",
    "Retailer_Phone": 1737503312,
    "Traders_Name": "Messers Sihab Traders",
    "Bazar_Name": "Messers Sihab Traders",
    "Latitude": 25.803457,
    "Longitude": 88.67645,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000188-R",
    "Retailer_Name": "Md. Ruhel Alam",
    "Retailer_Phone": 1740086996,
    "Traders_Name": "Messers Ruhel Traders",
    "Bazar_Name": "Messers Ruhel Traders",
    "Latitude": 25.799048,
    "Longitude": 88.676725,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000187-R",
    "Retailer_Name": "Md. Rezu Ahmed",
    "Retailer_Phone": 1568453078,
    "Traders_Name": "Messers Bhai Bhai Traders",
    "Bazar_Name": "Messers Bhai Bhai Traders",
    "Latitude": 25.802078,
    "Longitude": 88.676826,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000186-R",
    "Retailer_Name": "Md. Abdul Samad",
    "Retailer_Phone": 1712274979,
    "Traders_Name": "Messers Salam Traders",
    "Bazar_Name": "Messers Salam Traders",
    "Latitude": 25.801623,
    "Longitude": 88.676827,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000185-R",
    "Retailer_Name": "Md. Ekramul Haque",
    "Retailer_Phone": 1767453447,
    "Traders_Name": "Messers Ekramul Traders",
    "Bazar_Name": "Messers Ekramul Traders",
    "Latitude": 25.801623,
    "Longitude": 88.676827,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000184-R",
    "Retailer_Name": "Md. Mizanur Rahman",
    "Retailer_Phone": 1762775360,
    "Traders_Name": "Messers Mithila Traders",
    "Bazar_Name": "Messers Mithila Traders",
    "Latitude": 25.801621,
    "Longitude": 88.676827,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000183-R",
    "Retailer_Name": "Md. Rakibul Islam",
    "Retailer_Phone": 1740236875,
    "Traders_Name": "Messers Kabby Traders",
    "Bazar_Name": "Messers Kabby Traders",
    "Latitude": 25.802435,
    "Longitude": 88.676777,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000182-R",
    "Retailer_Name": "Santosh Kumar Das",
    "Retailer_Phone": 1718708558,
    "Traders_Name": "Messers Tamal Traders",
    "Bazar_Name": "Messers Tamal Traders",
    "Latitude": 25.803017,
    "Longitude": 88.676513,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000181-R",
    "Retailer_Name": "Md. Abu Sayem Ali",
    "Retailer_Phone": 1733132154,
    "Traders_Name": "Messers Labib Traders",
    "Bazar_Name": "Messers Labib Traders",
    "Latitude": 25.803017,
    "Longitude": 88.676718,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000180-R",
    "Retailer_Name": "Md. Rashidul Islam",
    "Retailer_Phone": 1745324895,
    "Traders_Name": "Messers Bhai Bhai Traders",
    "Bazar_Name": "Messers Bhai Bhai Traders",
    "Latitude": 25.784509,
    "Longitude": 88.675051,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/1/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000179-R",
    "Retailer_Name": "Mostofa",
    "Retailer_Phone": 1713793342,
    "Traders_Name": "Roni tedres",
    "Bazar_Name": "Roni tedres",
    "Latitude": 25.782007,
    "Longitude": 88.67281,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/2/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000178-R",
    "Retailer_Name": "Mr. Salim Islam",
    "Retailer_Phone": 1748944985,
    "Traders_Name": "M/S Ali Traders",
    "Bazar_Name": "M/S Ali Traders",
    "Latitude": 25.784967,
    "Longitude": 88.67542,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/3/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000177-R",
    "Retailer_Name": "Md. Mamun Islam",
    "Retailer_Phone": 1740541443,
    "Traders_Name": "Messers Mahi Traders",
    "Bazar_Name": "Messers Mahi Traders",
    "Latitude": 25.783514,
    "Longitude": 88.628211,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Ramchandrapur",
    "Last_visited": "11/4/2023",
    "Route_name": "Ramchandrapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000176-R",
    "Retailer_Name": "Md. Riyazur Islam",
    "Retailer_Phone": 1733145503,
    "Traders_Name": "Messers Riya Traders",
    "Bazar_Name": "Messers Riya Traders",
    "Latitude": 25.783525,
    "Longitude": 88.628187,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Ramchandrapur",
    "Last_visited": "11/5/2023",
    "Route_name": "Ramchandrapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000175-R",
    "Retailer_Name": "Md. Ismail Hossain",
    "Retailer_Phone": 1727219915,
    "Traders_Name": "Messers Sonia Traders",
    "Bazar_Name": "Messers Sonia Traders",
    "Latitude": 25.783571,
    "Longitude": 88.628079,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Ramchandrapur",
    "Last_visited": "11/6/2023",
    "Route_name": "Ramchandrapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000174-R",
    "Retailer_Name": "Md. Abu Said",
    "Retailer_Phone": 1719666251,
    "Traders_Name": "Messers Rumar Traders",
    "Bazar_Name": "Messers Rumar Traders",
    "Latitude": 25.812295,
    "Longitude": 88.614629,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/7/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000173-R",
    "Retailer_Name": "Md. Safikul Islam",
    "Retailer_Phone": 1784007013,
    "Traders_Name": "Messers Asad Traders",
    "Bazar_Name": "Messers Asad Traders",
    "Latitude": 25.812295,
    "Longitude": 88.614629,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/8/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000172-R",
    "Retailer_Name": "Md. Sohel Rana (Baboo)",
    "Retailer_Phone": 1734106239,
    "Traders_Name": "Messers Azim Traders",
    "Bazar_Name": "Messers Azim Traders",
    "Latitude": 25.812295,
    "Longitude": 88.614629,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/9/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000171-R",
    "Retailer_Name": "Md. Nur Islam Nuh",
    "Retailer_Phone": 1772849082,
    "Traders_Name": "Messers Nuri Traders",
    "Bazar_Name": "Messers Nuri Traders",
    "Latitude": 25.825702,
    "Longitude": 88.625804,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/10/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000170-R",
    "Retailer_Name": "Md. Zahirul Islam",
    "Retailer_Phone": 1787920993,
    "Traders_Name": "Messers Zahirul Traders",
    "Bazar_Name": "Messers Zahirul Traders",
    "Latitude": 25.825779,
    "Longitude": 88.625819,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000169-R",
    "Retailer_Name": "Md. Soher Rana",
    "Retailer_Phone": 1713717905,
    "Traders_Name": "Messers Rana Traders",
    "Bazar_Name": "Messers Rana Traders",
    "Latitude": 25.798242,
    "Longitude": 88.605445,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000168-R",
    "Retailer_Name": "Md. Osman Gani",
    "Retailer_Phone": 1740875279,
    "Traders_Name": "Sonarli Seed Store",
    "Bazar_Name": "Sonarli Seed Store",
    "Latitude": 25.793641,
    "Longitude": 88.59999,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000167-R",
    "Retailer_Name": "Md. Anowar Hossain",
    "Retailer_Phone": 1718087725,
    "Traders_Name": "Messers Hena Traders",
    "Bazar_Name": "Messers Hena Traders",
    "Latitude": 25.794636,
    "Longitude": 88.598657,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000166-R",
    "Retailer_Name": "Md. Abdul Malek",
    "Retailer_Phone": 1727246280,
    "Traders_Name": "Messers Mosharraf Traders",
    "Bazar_Name": "Messers Mosharraf Traders",
    "Latitude": 25.795065,
    "Longitude": 88.599432,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000165-R",
    "Retailer_Name": "Md. Abdur Rahman",
    "Retailer_Phone": 1724045821,
    "Traders_Name": "Hassan Seed Store",
    "Bazar_Name": "Hassan Seed Store",
    "Latitude": 25.795282,
    "Longitude": 88.599267,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000164-R",
    "Retailer_Name": "Md. Aliur Rahman",
    "Retailer_Phone": 1717849935,
    "Traders_Name": "Messers Arin Traders",
    "Bazar_Name": "Messers Arin Traders",
    "Latitude": 25.796037,
    "Longitude": 88.599276,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000163-R",
    "Retailer_Name": "Md. Ansarul Islam",
    "Retailer_Phone": 1739624100,
    "Traders_Name": "Messers Kushan Traders",
    "Bazar_Name": "Messers Kushan Traders",
    "Latitude": 25.796103,
    "Longitude": 88.599016,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000162-R",
    "Retailer_Name": "Md. Fozle Rabbi",
    "Retailer_Phone": 1722968925,
    "Traders_Name": "Dhansidi Traders",
    "Bazar_Name": "Dhansidi Traders",
    "Latitude": 25.794198,
    "Longitude": 88.599417,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/1/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000161-R",
    "Retailer_Name": "Md. Ainul Haque",
    "Retailer_Phone": 1721012094,
    "Traders_Name": "Messers Jahangir Traders",
    "Bazar_Name": "Messers Jahangir Traders",
    "Latitude": 25.796284,
    "Longitude": 88.604066,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/2/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000160-R",
    "Retailer_Name": "Md. Saiful Islam",
    "Retailer_Phone": 1797874580,
    "Traders_Name": "Messers Apan Traders",
    "Bazar_Name": "Messers Apan Traders",
    "Latitude": 25.795479,
    "Longitude": 88.603552,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/3/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000159-R",
    "Retailer_Name": "Md. Wed Ali",
    "Retailer_Phone": 1717228123,
    "Traders_Name": "Messers Fahad Traders",
    "Bazar_Name": "Messers Fahad Traders",
    "Latitude": 25.795457,
    "Longitude": 88.603618,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/4/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000158-R",
    "Retailer_Name": "Md. Manirul Islam",
    "Retailer_Phone": 1773046550,
    "Traders_Name": "Messers Sharmin Traders",
    "Bazar_Name": "Messers Sharmin Traders",
    "Latitude": 25.818912,
    "Longitude": 88.665881,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/5/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000157-R",
    "Retailer_Name": "Md. Samirul Islam",
    "Retailer_Phone": 1734755024,
    "Traders_Name": "Messers Samirul Traders",
    "Bazar_Name": "Messers Samirul Traders",
    "Latitude": 25.817344,
    "Longitude": 88.665497,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000156-R",
    "Retailer_Name": "Md. Limon Hossain (Nur)",
    "Retailer_Phone": 1314225058,
    "Traders_Name": "Messers Abul Traders",
    "Bazar_Name": "Messers Abul Traders",
    "Latitude": 25.794676,
    "Longitude": 88.660401,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000155-R",
    "Retailer_Name": "Md. Tarikul Islam",
    "Retailer_Phone": 1718674013,
    "Traders_Name": "Messers Chashi Traders",
    "Bazar_Name": "Messers Chashi Traders",
    "Latitude": 25.827798,
    "Longitude": 88.661578,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000154-R",
    "Retailer_Name": "Md Rafiqul Islam",
    "Retailer_Phone": 1730943690,
    "Traders_Name": "M/S Rafik Traders",
    "Bazar_Name": "M/S Rafik Traders",
    "Latitude": 25.817522,
    "Longitude": 88.665347,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000153-R",
    "Retailer_Name": "Md Emdadul Hok",
    "Retailer_Phone": 1713462063,
    "Traders_Name": "Jui Traders",
    "Bazar_Name": "Jui Traders",
    "Latitude": 25.817455,
    "Longitude": 88.665437,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000152-R",
    "Retailer_Name": "Md: kasem ali",
    "Retailer_Phone": 1764905255,
    "Traders_Name": "Golden crop",
    "Bazar_Name": "Golden crop",
    "Latitude": 25.827688,
    "Longitude": 88.662044,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000151-R",
    "Retailer_Name": "Riyajul Islam",
    "Retailer_Phone": 1922013819,
    "Traders_Name": "M/S Maeyer Doa Tedas",
    "Bazar_Name": "M/S Maeyer Doa Tedas",
    "Latitude": 25.809585,
    "Longitude": 88.612043,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000150-R",
    "Retailer_Name": "Robiyul Islam",
    "Retailer_Phone": 1774130130,
    "Traders_Name": "Robi Tedas",
    "Bazar_Name": "Robi Tedas",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000053-R",
    "Retailer_Name": "Mr. Gopal Chandra Ray",
    "Retailer_Phone": 1780623006,
    "Traders_Name": "Agricultural Trading",
    "Bazar_Name": "Agricultural Trading",
    "Latitude": 25.911161,
    "Longitude": 88.570511,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/1/2023",
    "Route_name": "Sator",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000052-R",
    "Retailer_Name": "Md. Mahfuzur Rahman",
    "Retailer_Phone": 1749939051,
    "Traders_Name": "Messers Nabit Traders",
    "Bazar_Name": "Messers Nabit Traders",
    "Latitude": 25.904705,
    "Longitude": 88.599556,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/2/2023",
    "Route_name": "Sator",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000051-R",
    "Retailer_Name": "Md Abdul Hossain",
    "Retailer_Phone": 1719472395,
    "Traders_Name": "M/S Towfique Traders",
    "Bazar_Name": "M/S Towfique Traders",
    "Latitude": 25.909714,
    "Longitude": 88.575103,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/3/2023",
    "Route_name": "Sator",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000050-R",
    "Retailer_Name": "Md Mokhlesur Rahman",
    "Retailer_Phone": 1784937931,
    "Traders_Name": "M/S Mowsumi Traders",
    "Bazar_Name": "M/S Mowsumi Traders",
    "Latitude": 25.917659,
    "Longitude": 88.55638,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/4/2023",
    "Route_name": "Sator",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000174-R",
    "Retailer_Name": "Talibur Rahman",
    "Retailer_Phone": 1796791641,
    "Traders_Name": "Messers Tasim Traders",
    "Bazar_Name": "Messers Tasim Traders",
    "Latitude": 25.860011,
    "Longitude": 88.649887,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/5/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000173-R",
    "Retailer_Name": "Mr. Nawab Ali",
    "Retailer_Phone": 1717290640,
    "Traders_Name": "M/S Nawab Traders",
    "Bazar_Name": "M/S Nawab Traders",
    "Latitude": 25.863772,
    "Longitude": 88.655088,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/6/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000172-R",
    "Retailer_Name": "Mr. Rashedu Nabi (Babu)",
    "Retailer_Phone": 1710044637,
    "Traders_Name": "M/S Rashed Agro",
    "Bazar_Name": "M/S Rashed Agro",
    "Latitude": 25.864158,
    "Longitude": 88.655061,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/7/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000171-R",
    "Retailer_Name": "Mr. Nobirul Islam",
    "Retailer_Phone": 1718704405,
    "Traders_Name": "M/S Karim Traders",
    "Bazar_Name": "M/S Karim Traders",
    "Latitude": 25.864938,
    "Longitude": 88.64947,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/8/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000170-R",
    "Retailer_Name": "Bishtu Chandra Ray",
    "Retailer_Phone": 1735373291,
    "Traders_Name": "Niyan Traders",
    "Bazar_Name": "Niyan Traders",
    "Latitude": 25.883829,
    "Longitude": 88.628863,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/9/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000169-R",
    "Retailer_Name": "Mr. Jahangir Alam",
    "Retailer_Phone": 1773104280,
    "Traders_Name": "M/S Jahangir Alam Traders",
    "Bazar_Name": "M/S Jahangir Alam Traders",
    "Latitude": 25.866179,
    "Longitude": 88.648137,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/10/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000168-R",
    "Retailer_Name": "Mr. Suman Chandra Roy",
    "Retailer_Phone": 1750823910,
    "Traders_Name": "M/S Ma Barber Ashibo Traders",
    "Bazar_Name": "M/S Ma Barber Ashibo Traders",
    "Latitude": 25.877405,
    "Longitude": 88.637751,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000167-R",
    "Retailer_Name": "Mr. Shahidul Islam",
    "Retailer_Phone": 1797827401,
    "Traders_Name": "M/S Shahidul Traders",
    "Bazar_Name": "M/S Shahidul Traders",
    "Latitude": 25.836642,
    "Longitude": 88.660707,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/12/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000166-R",
    "Retailer_Name": "Mr. Ashrafujjaman Arif",
    "Retailer_Phone": 1714624263,
    "Traders_Name": "Bhuiya Tedres",
    "Bazar_Name": "Bhuiya Tedres",
    "Latitude": 25.87658,
    "Longitude": 88.63774,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/13/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000165-R",
    "Retailer_Name": "Mr. Abdul Kader",
    "Retailer_Phone": 1776863527,
    "Traders_Name": "Bhai Bhai Traders",
    "Bazar_Name": "Bhai Bhai Traders",
    "Latitude": 25.890829,
    "Longitude": 88.647945,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/14/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000164-R",
    "Retailer_Name": "Mr. Sofikul Islam",
    "Retailer_Phone": 1710631461,
    "Traders_Name": "Ma Traders",
    "Bazar_Name": "Ma Traders",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000163-R",
    "Retailer_Name": "Mr. Enamul Hak",
    "Retailer_Phone": 1850651817,
    "Traders_Name": "Mominul Traders",
    "Bazar_Name": "Mominul Traders",
    "Latitude": 25.89083,
    "Longitude": 88.647944,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000162-R",
    "Retailer_Name": "Mr. Ali Hossain",
    "Retailer_Phone": 1727959934,
    "Traders_Name": "M/S Mujahid Traders",
    "Bazar_Name": "M/S Mujahid Traders",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000161-R",
    "Retailer_Name": "Mr. Mostafizur Rahman (Fizar)",
    "Retailer_Phone": 1723742668,
    "Traders_Name": "M/S Ma Baba Traders",
    "Bazar_Name": "M/S Ma Baba Traders",
    "Latitude": 25.877373,
    "Longitude": 88.637631,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000160-R",
    "Retailer_Name": "Mr. Meer Sayaf",
    "Retailer_Phone": 1755459513,
    "Traders_Name": "M/S Mahi Traders",
    "Bazar_Name": "M/S Mahi Traders",
    "Latitude": 25.877371,
    "Longitude": 88.637606,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000159-R",
    "Retailer_Name": "Mr. Robiul Islam",
    "Retailer_Phone": 1737568194,
    "Traders_Name": "M/S Arman Traders",
    "Bazar_Name": "M/S Arman Traders",
    "Latitude": 25.83845,
    "Longitude": 88.643815,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000158-R",
    "Retailer_Name": "Mr. Animigh Roy",
    "Retailer_Phone": 1750845699,
    "Traders_Name": "M/S Tin Bhai Traders",
    "Bazar_Name": "M/S Tin Bhai Traders",
    "Latitude": 25.860339,
    "Longitude": 88.648487,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000157-R",
    "Retailer_Name": "Mr. Manirul Islam (Milan)",
    "Retailer_Phone": 1738593277,
    "Traders_Name": "M/S Ma Traders",
    "Bazar_Name": "M/S Ma Traders",
    "Latitude": 25.859925,
    "Longitude": 88.650145,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000156-R",
    "Retailer_Name": "Mr. Jalal Uddin",
    "Retailer_Phone": 1717675196,
    "Traders_Name": "Morsalin Traders",
    "Bazar_Name": "Morsalin Traders",
    "Latitude": 25.869093,
    "Longitude": 88.65102,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000155-R",
    "Retailer_Name": "Mr. Malek Ostar",
    "Retailer_Phone": 1719001681,
    "Traders_Name": "M/S Nasim Traders",
    "Bazar_Name": "M/S Nasim Traders",
    "Latitude": 25.88403,
    "Longitude": 88.628843,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000154-R",
    "Retailer_Name": "Mr. Kabul Islam",
    "Retailer_Phone": 1761656056,
    "Traders_Name": "M/S Bhai Bhai Traders",
    "Bazar_Name": "M/S Bhai Bhai Traders",
    "Latitude": 25.883911,
    "Longitude": 88.628974,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000153-R",
    "Retailer_Name": "Mr. Mejer Ali",
    "Retailer_Phone": 1751367662,
    "Traders_Name": "M/S Abdullah Al Mahmud Traders",
    "Bazar_Name": "M/S Abdullah Al Mahmud Traders",
    "Latitude": 25.873797,
    "Longitude": 88.651281,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000152-R",
    "Retailer_Name": "Mr. Aminul Islam",
    "Retailer_Phone": 1741457684,
    "Traders_Name": "M/S Ma Traders",
    "Bazar_Name": "M/S Ma Traders",
    "Latitude": "",
    "Longitude": "",
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000151-R",
    "Retailer_Name": "Mr. Duluraj",
    "Retailer_Phone": 1737519788,
    "Traders_Name": "Nur Traders",
    "Bazar_Name": "Nur Traders",
    "Latitude": 25.838445,
    "Longitude": 88.643981,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SJR-0000150-R",
    "Retailer_Name": "Md. Sohel Rana",
    "Retailer_Phone": 1790510492,
    "Traders_Name": "M/S Ma Baba Dowa Traders",
    "Bazar_Name": "M/S Ma Baba Dowa Traders",
    "Latitude": 25.838186,
    "Longitude": 88.644085,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sujalpur",
    "Last_visited": "11/11/2023",
    "Route_name": "Sujalpur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-PLR-0000050-R",
    "Retailer_Name": "Horipodo Ray",
    "Retailer_Phone": 1719419865,
    "Traders_Name": "M/S Sowrav Traders",
    "Bazar_Name": "M/S Sowrav Traders",
    "Latitude": 25.866609,
    "Longitude": 88.686397,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Paltapur",
    "Last_visited": "11/1/2023",
    "Route_name": "Paltapur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000054-R",
    "Retailer_Name": "Mr. Hossain Faruk",
    "Retailer_Phone": 1722994028,
    "Traders_Name": "M/S Haji Traders",
    "Bazar_Name": "M/S Haji Traders",
    "Latitude": 25.998112,
    "Longitude": 88.694517,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shatagram",
    "Last_visited": "11/2/2023",
    "Route_name": "Shatagram",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000053-R",
    "Retailer_Name": "Md. Aher Ali",
    "Retailer_Phone": 1791868172,
    "Traders_Name": "Messers Sraboni Traders",
    "Bazar_Name": "Messers Sraboni Traders",
    "Latitude": 25.876243,
    "Longitude": 88.669497,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/3/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000052-R",
    "Retailer_Name": "Md Johir Rayhan",
    "Retailer_Phone": 1761047794,
    "Traders_Name": "Notunhat",
    "Bazar_Name": "M/S JRJ Trades",
    "Latitude": 25.898663,
    "Longitude": 88.668464,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/4/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000051-R",
    "Retailer_Name": "Md Anamul Haque",
    "Retailer_Phone": 1608172109,
    "Traders_Name": "M/S Ahan Traders",
    "Bazar_Name": "M/S Ahan Traders",
    "Latitude": 25.907045,
    "Longitude": 88.711592,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/5/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000050-R",
    "Retailer_Name": "Mr. Abdul Malek",
    "Retailer_Phone": 1737731328,
    "Traders_Name": "M/S Malek Traders",
    "Bazar_Name": "M/S Malek Traders",
    "Latitude": 25.898643,
    "Longitude": 88.668148,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/6/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000158-R",
    "Retailer_Name": "Md Ahsanul Haque",
    "Retailer_Phone": 1724309049,
    "Traders_Name": "Messers Farzana Traders",
    "Bazar_Name": "Messers Farzana Traders",
    "Latitude": 25.863708,
    "Longitude": 88.654816,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/7/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000157-R",
    "Retailer_Name": "Mr. Rakib Islam",
    "Retailer_Phone": 1744349289,
    "Traders_Name": "M/S Siman Traders",
    "Bazar_Name": "M/S Siman Traders",
    "Latitude": 25.924531,
    "Longitude": 88.609105,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/8/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000156-R",
    "Retailer_Name": "Mr. Al Amin",
    "Retailer_Phone": 1784049418,
    "Traders_Name": "M/S Al Amin Traders",
    "Bazar_Name": "M/S Al Amin Traders",
    "Latitude": 25.883923,
    "Longitude": 88.628867,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/9/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000155-R",
    "Retailer_Name": "Mr. Ruhel Amin",
    "Retailer_Phone": 1723374640,
    "Traders_Name": "M/S Shawon Traders",
    "Bazar_Name": "M/S Shawon Traders",
    "Latitude": 25.904193,
    "Longitude": 88.602221,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/10/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000154-R",
    "Retailer_Name": "Mr. Milan Islam",
    "Retailer_Phone": 1723856574,
    "Traders_Name": "M/S Montasir Traders",
    "Bazar_Name": "M/S Montasir Traders",
    "Latitude": 25.92451,
    "Longitude": 88.608895,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000153-R",
    "Retailer_Name": "Mr. Sohag Hossain",
    "Retailer_Phone": 1713721575,
    "Traders_Name": "M/S Krishibeej Ghar",
    "Bazar_Name": "M/S Krishibeej Ghar",
    "Latitude": 25.904564,
    "Longitude": 88.599678,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000152-R",
    "Retailer_Name": "Md. Humayan Ali",
    "Retailer_Phone": 1722885574,
    "Traders_Name": "M/S Humayan Traders",
    "Bazar_Name": "M/S Humayan Traders",
    "Latitude": 25.92444,
    "Longitude": 88.608951,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000151-R",
    "Retailer_Name": "Suboth chandra Roy",
    "Retailer_Phone": 1737621369,
    "Traders_Name": "Joy Krisan Traders",
    "Bazar_Name": "Joy Krisan Traders",
    "Latitude": 25.92444,
    "Longitude": 88.608943,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000150-R",
    "Retailer_Name": "Md. Abdul Karim",
    "Retailer_Phone": 1737908776,
    "Traders_Name": "M/S Asmain Adil Trader's",
    "Bazar_Name": "M/S Asmain Adil Trader's",
    "Latitude": 25.903357,
    "Longitude": 88.599879,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000101-R",
    "Retailer_Name": "Jogodis roy",
    "Retailer_Phone": 1713792958,
    "Traders_Name": "Bijoy store",
    "Bazar_Name": "Golapganj",
    "Latitude": 25.92292,
    "Longitude": 88.6423757,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/11/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-KHA-BEI-0000101-R",
    "Retailer_Name": "Vivi feed gor",
    "Retailer_Phone": 1780894501,
    "Traders_Name": "Ohedul islam",
    "Bazar_Name": "Tanguya",
    "Latitude": 25.9119035,
    "Longitude": 88.7650431,
    "Business_Type": "livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Khansama",
    "Union_Name": "Bherbheri",
    "Last_visited": "11/11/2023",
    "Route_name": "Bherbheri",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-SUA-0000001-R",
    "Retailer_Name": "Md. Isaqul Islam",
    "Retailer_Phone": 1315275314,
    "Traders_Name": "Ma babar doa traders",
    "Bazar_Name": "Shibdeb boldarer matha",
    "Latitude": 25.6732,
    "Longitude": 89.503889,
    "Business_Type": "Livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Saula",
    "Last_visited": "11/11/2023",
    "Route_name": "Saula",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-PIR-TMR-0000001-R",
    "Retailer_Name": "Razu Mia",
    "Retailer_Phone": 1722387622,
    "Traders_Name": "Razu traders",
    "Bazar_Name": "Thumbul bazar",
    "Latitude": 25.643114,
    "Longitude": 89.491203,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Tambulpur",
    "Last_visited": "11/1/2023",
    "Route_name": "Tambulpur",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000001-R",
    "Retailer_Name": "Mojibur Rahman",
    "Retailer_Phone": 1780507213,
    "Traders_Name": "M/S Ma baba Traders",
    "Bazar_Name": "",
    "Latitude": 25.904131,
    "Longitude": 88.601071,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/2/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000001-R",
    "Retailer_Name": "Gopal Ghosh",
    "Retailer_Phone": 1716728090,
    "Traders_Name": "Ghosh Dairy Farm",
    "Bazar_Name": "",
    "Latitude": 25.911246,
    "Longitude": 88.570564,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/3/2023",
    "Route_name": "Sator",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000002-R",
    "Retailer_Name": "Abdullah Mamun",
    "Retailer_Phone": 1713726031,
    "Traders_Name": "M/S Mamun Pharmacy",
    "Bazar_Name": "",
    "Latitude": 25.949453,
    "Longitude": 88.576304,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/4/2023",
    "Route_name": "Sator",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-TMR-0000002-R",
    "Retailer_Name": "Md Mezinur Rahman",
    "Retailer_Phone": 1732083217,
    "Traders_Name": "Shahin Store",
    "Bazar_Name": "Shibdeb boldarer matha",
    "Latitude": 25.627603,
    "Longitude": 89.505745,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Tambulpur",
    "Last_visited": "11/5/2023",
    "Route_name": "Tambulpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-MRA-0000001-R",
    "Retailer_Name": "Mubarak Islam",
    "Retailer_Phone": 1737270612,
    "Traders_Name": "M/S Mobarok Traders",
    "Bazar_Name": "",
    "Latitude": 25.967055,
    "Longitude": 88.598979,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Moricha",
    "Last_visited": "11/6/2023",
    "Route_name": "Moricha",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SIR-0000001-R",
    "Retailer_Name": "Saddam Hossain",
    "Retailer_Phone": 1761100005,
    "Traders_Name": "M/S Salma Animal Feed Store",
    "Bazar_Name": "Muraripur bazar",
    "Latitude": 25.994704,
    "Longitude": 88.591017,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shibrampur",
    "Last_visited": "11/7/2023",
    "Route_name": "Shibrampur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-TMR-0000003-R",
    "Retailer_Name": "Abdur Zazzak",
    "Retailer_Phone": 1734564508,
    "Traders_Name": "M/S Asha Traders",
    "Bazar_Name": "Rahamoter cor proton bazar",
    "Latitude": 25.628933,
    "Longitude": 89.505147,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Tambulpur",
    "Last_visited": "11/8/2023",
    "Route_name": "Tambulpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "RNU-PIR-SUA-0000002-R",
    "Retailer_Name": "Md. Ashraful Islam",
    "Retailer_Phone": 1723887615,
    "Traders_Name": "M/S Sohan Traders",
    "Bazar_Name": "",
    "Latitude": 25.672887,
    "Longitude": 89.486514,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Saula",
    "Last_visited": "11/9/2023",
    "Route_name": "Saula",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-MHR-0000001-R",
    "Retailer_Name": "Tarek Islam",
    "Retailer_Phone": 1773787754,
    "Traders_Name": "M/S Towha Animal Health",
    "Bazar_Name": "",
    "Latitude": 25.907816,
    "Longitude": 88.711883,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Mohonpur",
    "Last_visited": "11/10/2023",
    "Route_name": "Mohonpur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-DWI-0000001-R",
    "Retailer_Name": "Md. Ainuddin",
    "Retailer_Phone": 1717239123,
    "Traders_Name": "Ainuddin chal ghar",
    "Bazar_Name": "",
    "Latitude": 26.051562,
    "Longitude": 89.153565,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Dawabari",
    "Last_visited": "11/11/2023",
    "Route_name": "Dawabari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-IAI-0000001-R",
    "Retailer_Name": "Samsul Islam",
    "Retailer_Phone": 1723151169,
    "Traders_Name": "Samsul store",
    "Bazar_Name": "Damur chakla",
    "Latitude": 25.700098,
    "Longitude": 89.384598,
    "Business_Type": "Livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Itakumari",
    "Last_visited": "11/12/2023",
    "Route_name": "Itakumari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-GDI-0000001-R",
    "Retailer_Name": "Md. Mofizul Islam",
    "Retailer_Phone": 1750250732,
    "Traders_Name": "Ma Babar Doa Variety Store",
    "Bazar_Name": "",
    "Latitude": 26.163805,
    "Longitude": 89.126663,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Goddimari",
    "Last_visited": "11/13/2023",
    "Route_name": "Goddimari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000001-R",
    "Retailer_Name": "Md. Fazlul Haq",
    "Retailer_Phone": 1773668132,
    "Traders_Name": "Bhai Bhai Traders",
    "Bazar_Name": "",
    "Latitude": 26.11468,
    "Longitude": 89.179459,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/14/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-SIR-0000002-R",
    "Retailer_Name": "Minarul Islam",
    "Retailer_Phone": 1714074769,
    "Traders_Name": "M/S Bhai Bhai Poshu Sastho kendro",
    "Bazar_Name": "",
    "Latitude": 25.971866,
    "Longitude": 88.575787,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shibrampur",
    "Last_visited": "11/11/2023",
    "Route_name": "Shibrampur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-STR-0000003-R",
    "Retailer_Name": "Prodip Ghosh",
    "Retailer_Phone": 1755476074,
    "Traders_Name": "M/S Maloni Traders",
    "Bazar_Name": "Dakeswari bazar",
    "Latitude": 25.94542,
    "Longitude": 88.591167,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Sator",
    "Last_visited": "11/11/2023",
    "Route_name": "Sator",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000002-R",
    "Retailer_Name": "Moklesur Rahman",
    "Retailer_Phone": 1737567261,
    "Traders_Name": "M/S Poshu Khaddo Vandar",
    "Bazar_Name": "",
    "Latitude": 25.904221,
    "Longitude": 88.601093,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-KLI-0000001-R",
    "Retailer_Name": "Firoz",
    "Retailer_Phone": 1767565368,
    "Traders_Name": "Dildar Traders",
    "Bazar_Name": "",
    "Latitude": 25.711057,
    "Longitude": 89.345616,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Kollyani",
    "Last_visited": "11/11/2023",
    "Route_name": "Kollyani",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-SNA-0000001-R",
    "Retailer_Name": "Md. Rezaul Karim",
    "Retailer_Phone": 1744601317,
    "Traders_Name": "Jerin Pharmacy",
    "Bazar_Name": "",
    "Latitude": 26.119259,
    "Longitude": 89.134782,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Sindurna",
    "Last_visited": "11/11/2023",
    "Route_name": "Sindurna",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "RNU-PIR-PRA-0000001-R",
    "Retailer_Name": "Md. Majnu Mia",
    "Retailer_Phone": 1751152036,
    "Traders_Name": "Ma Pharmacy",
    "Bazar_Name": "",
    "Latitude": 25.663352,
    "Longitude": 89.383497,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Pirgacha",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgacha",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000001-R",
    "Retailer_Name": "Md Mizanur Rahman Limon",
    "Retailer_Phone": 1722307039,
    "Traders_Name": "M/S Limon Traders",
    "Bazar_Name": "Notunhat",
    "Latitude": 25.898683,
    "Longitude": 88.668015,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/11/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "DNU-BIR-SIR-0000003-R",
    "Retailer_Name": "Krishna Barman",
    "Retailer_Phone": 1717289121,
    "Traders_Name": "Krishna Chandra barman Retailer",
    "Bazar_Name": "",
    "Latitude": 26.013246,
    "Longitude": 88.553896,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shibrampur",
    "Last_visited": "11/11/2023",
    "Route_name": "Shibrampur",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-ANR-0000001-R",
    "Retailer_Name": "Md. Abu Rayhan",
    "Retailer_Phone": 1716160585,
    "Traders_Name": "Soikot Traders",
    "Bazar_Name": "",
    "Latitude": 25.692969,
    "Longitude": 89.44652,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Annodanagar",
    "Last_visited": "11/11/2023",
    "Route_name": "Annodanagar",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-MRA-0000002-R",
    "Retailer_Name": "Saddam Hossain",
    "Retailer_Phone": 1753273076,
    "Traders_Name": "Md. Saddam Hossain",
    "Bazar_Name": "",
    "Latitude": 25.923847,
    "Longitude": 88.64261,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Moricha",
    "Last_visited": "11/11/2023",
    "Route_name": "Moricha",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-PLR-0000001-R",
    "Retailer_Name": "Md Omor Faruk",
    "Retailer_Phone": 1762612067,
    "Traders_Name": "Md Omor Faruk",
    "Bazar_Name": "Sonka Bazar",
    "Latitude": 25.838492,
    "Longitude": 88.712908,
    "Business_Type": "Crop",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Paltapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Paltapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-KAU-BLA-0000001-R",
    "Retailer_Name": "Md. Julfikar Haider",
    "Retailer_Phone": 1719165349,
    "Traders_Name": "Sadik Dairy",
    "Bazar_Name": "",
    "Latitude": 25.768735,
    "Longitude": 89.413012,
    "Business_Type": "Livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Kaunia",
    "Union_Name": "Balapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Balapara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-PRA-0000002-R",
    "Retailer_Name": "Raju Mia",
    "Retailer_Phone": 1714537021,
    "Traders_Name": "Barakat Store",
    "Bazar_Name": "",
    "Latitude": 25.657584,
    "Longitude": 89.407294,
    "Business_Type": "Livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Pirgacha",
    "Last_visited": "11/11/2023",
    "Route_name": "Pirgacha",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-KAU-TPR-0000001-R",
    "Retailer_Name": "Martuza Ali Ahmed Manik",
    "Retailer_Phone": 1725672915,
    "Traders_Name": "Niloy Pharmacy",
    "Bazar_Name": "",
    "Latitude": 25.732673,
    "Longitude": 89.469256,
    "Business_Type": "Crop",
    "District_Name": "Rangpur",
    "Upazila_Name": "Kaunia",
    "Union_Name": "Tepamodhupur",
    "Last_visited": "11/11/2023",
    "Route_name": "Tepamodhupur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000001-R",
    "Retailer_Name": "Md. Sofiar Rahman",
    "Retailer_Phone": 1796006252,
    "Traders_Name": "Ma Babar Doa Varieties Store",
    "Bazar_Name": "",
    "Latitude": 26.076548,
    "Longitude": 89.136425,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-BIR-PLI-0000002-R",
    "Retailer_Name": "Saidul Islam",
    "Retailer_Phone": 1725232222,
    "Traders_Name": "Saidul Islam Retailer",
    "Bazar_Name": "",
    "Latitude": 25.978833,
    "Longitude": 88.625811,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Polashbari",
    "Last_visited": "11/1/2023",
    "Route_name": "Polashbari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "RNU-KAU-KRK-0000001-R",
    "Retailer_Name": "Md. Abdul Motaleb Hossain",
    "Retailer_Phone": 1735976346,
    "Traders_Name": "Back Artificial insemination enterprise",
    "Bazar_Name": "",
    "Latitude": 25.757977,
    "Longitude": 89.339527,
    "Business_Type": "Livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Kaunia",
    "Union_Name": "Kurshaupk",
    "Last_visited": "11/2/2023",
    "Route_name": "Kurshaupk",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "RNU-PIR-ANR-0000002-R",
    "Retailer_Name": "Md. Manik Mia",
    "Retailer_Phone": 1957207708,
    "Traders_Name": "Manik Traders",
    "Bazar_Name": "",
    "Latitude": 25.731455,
    "Longitude": 89.411957,
    "Business_Type": "Livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Annodanagar",
    "Last_visited": "11/3/2023",
    "Route_name": "Annodanagar",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "RNU-PIR-KLI-0000002-R",
    "Retailer_Name": "Abu Taher",
    "Retailer_Phone": 1312283596,
    "Traders_Name": "Tamim Traders",
    "Bazar_Name": "",
    "Latitude": 25.71128,
    "Longitude": 89.345127,
    "Business_Type": "Livestock",
    "District_Name": "Rangpur",
    "Upazila_Name": "Pirgacha",
    "Union_Name": "Kollyani",
    "Last_visited": "11/4/2023",
    "Route_name": "Kollyani",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-SIR-0000004-R",
    "Retailer_Name": "Md. Islam",
    "Retailer_Phone": 1729941659,
    "Traders_Name": "Md. Islam Trader",
    "Bazar_Name": "Velapukur Babur hat",
    "Latitude": 25.983573,
    "Longitude": 88.608681,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Shibrampur",
    "Last_visited": "11/5/2023",
    "Route_name": "Shibrampur",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000002-R",
    "Retailer_Name": "Md Abid Hasan Badol",
    "Retailer_Phone": 1730865102,
    "Traders_Name": "M/s Raha traders",
    "Bazar_Name": "",
    "Latitude": 26.073057,
    "Longitude": 89.138836,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/6/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000001-R",
    "Retailer_Name": "Hamid",
    "Retailer_Phone": 1725858152,
    "Traders_Name": "Ma Veterinary",
    "Bazar_Name": "",
    "Latitude": 26.102022,
    "Longitude": 89.1771,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/7/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000003-R",
    "Retailer_Name": "Md. Rashidul Islam",
    "Retailer_Phone": 1740471168,
    "Traders_Name": "M/s Riva traders",
    "Bazar_Name": "",
    "Latitude": 26.073019,
    "Longitude": 89.138313,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/8/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "DNU-KAH-SNR-0000001-R",
    "Retailer_Name": "Md. Alamgir",
    "Retailer_Phone": 1779679223,
    "Traders_Name": "M/S Alamgir Traders",
    "Bazar_Name": "12 mail, Kantanogor, Kaharol, Dinajpur",
    "Latitude": 25.785002,
    "Longitude": 88.675215,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Sundarpur",
    "Last_visited": "11/9/2023",
    "Route_name": "Sundarpur",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "DNU-BIR-BOR-0000003-R",
    "Retailer_Name": "Md. Billal Hossain",
    "Retailer_Phone": 1799840649,
    "Traders_Name": "M/S Billal Traders",
    "Bazar_Name": "",
    "Latitude": 25.904436,
    "Longitude": 88.599046,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Bhognagar",
    "Last_visited": "11/10/2023",
    "Route_name": "Bhognagar",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "DNU-KAH-MKR-0000001-R",
    "Retailer_Name": "Md. Ashraful Islam",
    "Retailer_Phone": 1734234486,
    "Traders_Name": "Teen Bhai Khaddo Ghar",
    "Bazar_Name": "Rampur Bazar",
    "Latitude": 25.818446,
    "Longitude": 88.665345,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Kaharol",
    "Union_Name": "Mukundapur",
    "Last_visited": "11/11/2023",
    "Route_name": "Mukundapur",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000001-R",
    "Retailer_Name": "Md Foysal",
    "Retailer_Phone": 1945205467,
    "Traders_Name": "M/s Foysal Traders",
    "Bazar_Name": "",
    "Latitude": 22.751592,
    "Longitude": 90.902674,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000004-R",
    "Retailer_Name": "Md Halim Hossen",
    "Retailer_Phone": 1742638320,
    "Traders_Name": "Kawsar Traders",
    "Bazar_Name": "",
    "Latitude": 26.075765,
    "Longitude": 89.136788,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000002-R",
    "Retailer_Name": "Md Mohammad Rokonuzzaman",
    "Retailer_Phone": 1721377577,
    "Traders_Name": "Sohan Traders",
    "Bazar_Name": "",
    "Latitude": 26.119139,
    "Longitude": 89.143474,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/11/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-SNA-0000002-R",
    "Retailer_Name": "Md Mohammad Liton Rana",
    "Retailer_Phone": 1728862345,
    "Traders_Name": "M/s Liton Seed store",
    "Bazar_Name": "",
    "Latitude": 26.12034,
    "Longitude": 89.135649,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Sindurna",
    "Last_visited": "11/11/2023",
    "Route_name": "Sindurna",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000003-R",
    "Retailer_Name": "Md Sohel Rana",
    "Retailer_Phone": 1728169331,
    "Traders_Name": "Nafis Traders",
    "Bazar_Name": "",
    "Latitude": 26.110303,
    "Longitude": 89.187024,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/11/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-DWI-0000002-R",
    "Retailer_Name": "Md Azizul Islam",
    "Retailer_Phone": 1766147402,
    "Traders_Name": "M/S Azizul Teders",
    "Bazar_Name": "",
    "Latitude": 26.050558,
    "Longitude": 89.153483,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Dawabari",
    "Last_visited": "11/11/2023",
    "Route_name": "Dawabari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000005-R",
    "Retailer_Name": "Md Mahbub Alam",
    "Retailer_Phone": 1755203762,
    "Traders_Name": "Mahabub Veterinary",
    "Bazar_Name": "",
    "Latitude": 26.100713,
    "Longitude": 89.140678,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-GTI-0000001-R",
    "Retailer_Name": "Md Tapan Ray",
    "Retailer_Phone": 1737186146,
    "Traders_Name": "M/S Prapti Traders",
    "Bazar_Name": "",
    "Latitude": 26.090614,
    "Longitude": 89.225522,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Gotamari",
    "Last_visited": "11/1/2023",
    "Route_name": "Gotamari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000006-R",
    "Retailer_Name": "Md Sagar Hossen",
    "Retailer_Phone": 1773600312,
    "Traders_Name": "M/S Sagar Traders",
    "Bazar_Name": "",
    "Latitude": 26.078175,
    "Longitude": 89.135605,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/2/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-CAA-0000002-R",
    "Retailer_Name": "Md Abul Kalam",
    "Retailer_Phone": 1971650559,
    "Traders_Name": "M/s Fayez Traders",
    "Bazar_Name": "",
    "Latitude": 22.751471,
    "Longitude": 90.903177,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Kadira",
    "Last_visited": "11/3/2023",
    "Route_name": "Char Kadira",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000007-R",
    "Retailer_Name": "Md Shakul Islam",
    "Retailer_Phone": 1764885264,
    "Traders_Name": "Shoyon Shar Ghar",
    "Bazar_Name": "",
    "Latitude": 26.051692,
    "Longitude": 89.153903,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/4/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-NWS-0000002-R",
    "Retailer_Name": "Polash Chandra",
    "Retailer_Phone": 1737565751,
    "Traders_Name": "Parboti Shar Ghar",
    "Bazar_Name": "",
    "Latitude": 26.089647,
    "Longitude": 89.207934,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Nowdabas",
    "Last_visited": "11/5/2023",
    "Route_name": "Nowdabas",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000001-R",
    "Retailer_Name": "Dr. Md. Mithul Rana",
    "Retailer_Phone": 1745406789,
    "Traders_Name": "Chittagong Veterinary",
    "Bazar_Name": "",
    "Latitude": 26.152099,
    "Longitude": 89.131452,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/6/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000008-R",
    "Retailer_Name": "Md Asaduzzaman Babu",
    "Retailer_Phone": 1774078638,
    "Traders_Name": "M/s Hajera Traders",
    "Bazar_Name": "",
    "Latitude": 26.076063,
    "Longitude": 89.135485,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/7/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000009-R",
    "Retailer_Name": "Md Saiful Islam",
    "Retailer_Phone": 1735033148,
    "Traders_Name": "Sagar Shar Ghar",
    "Bazar_Name": "",
    "Latitude": 26.075496,
    "Longitude": 89.136979,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/8/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "DNU-BIR-NJA-0000002-R",
    "Retailer_Name": "Md Amullo Ray",
    "Retailer_Phone": 1747113401,
    "Traders_Name": "M/S Apurbo Traders",
    "Bazar_Name": "",
    "Latitude": 25.894141,
    "Longitude": 88.694603,
    "Business_Type": "Livestock",
    "District_Name": "Dinajpur",
    "Upazila_Name": "Birganj",
    "Union_Name": "Nijpara",
    "Last_visited": "11/9/2023",
    "Route_name": "Nijpara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000004-R",
    "Retailer_Name": "Md Atowar Mia",
    "Retailer_Phone": 1737033407,
    "Traders_Name": "M/S Ma- Baba Veterinari",
    "Bazar_Name": "",
    "Latitude": 26.116404,
    "Longitude": 89.173962,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/10/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-SNA-0000003-R",
    "Retailer_Name": "Md Nozrul Islam",
    "Retailer_Phone": 1713768573,
    "Traders_Name": "M/s Nazmul Shar & Biz Ghar",
    "Bazar_Name": "",
    "Latitude": 26.118954,
    "Longitude": 89.134745,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Sindurna",
    "Last_visited": "11/11/2023",
    "Route_name": "Sindurna",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-SNA-0000004-R",
    "Retailer_Name": "Md Shariful Islam",
    "Retailer_Phone": 1863075827,
    "Traders_Name": "M/s Ma babar doa shar ghar",
    "Bazar_Name": "",
    "Latitude": 26.119184,
    "Longitude": 89.134588,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Sindurna",
    "Last_visited": "11/12/2023",
    "Route_name": "Sindurna",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-TNA-0000005-R",
    "Retailer_Name": "Md Saidur Islam",
    "Retailer_Phone": 1717175707,
    "Traders_Name": "Sin Traders",
    "Bazar_Name": "",
    "Latitude": 26.11046,
    "Longitude": 89.18649,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Tongvhanga",
    "Last_visited": "11/13/2023",
    "Route_name": "Tongvhanga",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000010-R",
    "Retailer_Name": "Md Anwarul Islam",
    "Retailer_Phone": 1930992082,
    "Traders_Name": "Millat Traders",
    "Bazar_Name": "",
    "Latitude": 26.072992,
    "Longitude": 89.138892,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/14/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000001-R",
    "Retailer_Name": "Balayet Hossain",
    "Retailer_Phone": 1616309969,
    "Traders_Name": "Nirab Store",
    "Bazar_Name": "Falcon board Bazar",
    "Latitude": 22.726512,
    "Longitude": 90.858109,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-CAN-0000002-R",
    "Retailer_Name": "Md. Akter Dawan",
    "Retailer_Phone": 1881522077,
    "Traders_Name": "M/S Akter Dawn Traders",
    "Bazar_Name": "",
    "Latitude": 22.748796,
    "Longitude": 90.859808,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Char Folcon",
    "Last_visited": "11/11/2023",
    "Route_name": "Char Folcon",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-SNA-0000005-R",
    "Retailer_Name": "Md Nur Islam",
    "Retailer_Phone": 1723271102,
    "Traders_Name": "M/S Manik Shar & Biz Ghar",
    "Bazar_Name": "",
    "Latitude": 26.120393,
    "Longitude": 89.135362,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Sindurna",
    "Last_visited": "11/11/2023",
    "Route_name": "Sindurna",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000002-R",
    "Retailer_Name": "Md Rafiqul Islam",
    "Retailer_Phone": 1812039258,
    "Traders_Name": "M/S Jion Traders",
    "Bazar_Name": "",
    "Latitude": 26.12156,
    "Longitude": 89.137022,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/11/2023",
    "Route_name": "Singimari",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000011-R",
    "Retailer_Name": "Md Maynal Haque",
    "Retailer_Phone": 1719247560,
    "Traders_Name": "M/S Shadharan Shar Ghar",
    "Bazar_Name": "",
    "Latitude": 26.094573,
    "Longitude": 89.138491,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000012-R",
    "Retailer_Name": "Md Sirajul Islam",
    "Retailer_Phone": 1723465297,
    "Traders_Name": "M/S Krishi Ghar",
    "Bazar_Name": "",
    "Latitude": 26.0752,
    "Longitude": 89.13687,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000013-R",
    "Retailer_Name": "Md Ashraf Ali",
    "Retailer_Phone": 1737425451,
    "Traders_Name": "M/S Urmi Shar Ghar",
    "Bazar_Name": "",
    "Latitude": 26.078212,
    "Longitude": 89.135452,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000014-R",
    "Retailer_Name": "Md Mahedul Islam",
    "Retailer_Phone": 1723997849,
    "Traders_Name": "M/S Sajib Shar & Biz Ghar",
    "Bazar_Name": "",
    "Latitude": 26.076217,
    "Longitude": 89.136558,
    "Business_Type": "Crop",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/11/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-LAK-KSI-0000001-R",
    "Retailer_Name": "Md Bahar Chowdhury",
    "Retailer_Phone": 1872904464,
    "Traders_Name": "Ali Hossen Traders",
    "Bazar_Name": "",
    "Latitude": 22.866721,
    "Longitude": 90.923751,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Lakshmipur Sadar",
    "Union_Name": "Kusakhali",
    "Last_visited": "11/11/2023",
    "Route_name": "Kusakhali",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000001-R",
    "Retailer_Name": "Shohedeb Poddar",
    "Retailer_Phone": 1762173756,
    "Traders_Name": "M/s Badhon oil Mill",
    "Bazar_Name": "Koronanogor bazar",
    "Latitude": 22.722974,
    "Longitude": 90.911811,
    "Business_Type": "Livestock",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000003-R",
    "Retailer_Name": "Saiful Islam",
    "Retailer_Phone": 1798760656,
    "Traders_Name": "Saidul Store",
    "Bazar_Name": "",
    "Latitude": 26.14259,
    "Longitude": 89.134298,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/11/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000002-R",
    "Retailer_Name": "Md. Abbas Uddin",
    "Retailer_Phone": 1823922738,
    "Traders_Name": "Abbas store",
    "Bazar_Name": "Koronanogor bazar",
    "Latitude": 22.723494,
    "Longitude": 90.911109,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-AER-0000001-R",
    "Retailer_Name": "Md Mahbub Alam",
    "Retailer_Phone": 1731861278,
    "Traders_Name": "Mahbub Store",
    "Bazar_Name": "",
    "Latitude": 22.708059,
    "Longitude": 90.875639,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-AER-0000002-R",
    "Retailer_Name": "Md Mashud Alam",
    "Retailer_Phone": 1631493390,
    "Traders_Name": "Maksud Store",
    "Bazar_Name": "",
    "Latitude": 22.708319,
    "Longitude": 90.875571,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000003-R",
    "Retailer_Name": "Md Abul Kashem",
    "Retailer_Phone": 1715080072,
    "Traders_Name": "Kashem Store",
    "Bazar_Name": "Koronanogor bazar",
    "Latitude": 22.723025,
    "Longitude": 90.910331,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/11/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000004-R",
    "Retailer_Name": "Md Bahar",
    "Retailer_Phone": 1879076303,
    "Traders_Name": "Bahar Store",
    "Bazar_Name": "Koronanogor bazar",
    "Latitude": 22.723015,
    "Longitude": 90.910324,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/1/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000001-R",
    "Retailer_Name": "Md Khalil",
    "Retailer_Phone": 1751336473,
    "Traders_Name": "Khalil Store",
    "Bazar_Name": "",
    "Latitude": 22.698395,
    "Longitude": 90.911585,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/2/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-RAM-CAM-0000002-R",
    "Retailer_Name": "Jaynal Abedin",
    "Retailer_Phone": 1612930894,
    "Traders_Name": "Binimoy Store",
    "Bazar_Name": "",
    "Latitude": 22.69858,
    "Longitude": 90.911426,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Ramgati",
    "Union_Name": "Charbadam",
    "Last_visited": "11/3/2023",
    "Route_name": "Charbadam",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000005-R",
    "Retailer_Name": "Md Rubel",
    "Retailer_Phone": 1759854736,
    "Traders_Name": "Rubel Store",
    "Bazar_Name": "Koronanogor bazar",
    "Latitude": 22.723374,
    "Longitude": 90.911301,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/4/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "hehe"
  },
  {
    "Retailer_ID": "LLA-HAT-SNI-0000004-R",
    "Retailer_Name": "Md Roni",
    "Retailer_Phone": 1717382902,
    "Traders_Name": "M/S Shetu Protin Corner",
    "Bazar_Name": "",
    "Latitude": 26.140693,
    "Longitude": 89.135287,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Singimari",
    "Last_visited": "11/5/2023",
    "Route_name": "Singimari",
    "Assigned_to": "Teredus"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000006-R",
    "Retailer_Name": "Akram Hossain",
    "Retailer_Phone": 1689522767,
    "Traders_Name": "Mostafa and Song",
    "Bazar_Name": "Koronanogor bazar",
    "Latitude": 22.722914,
    "Longitude": 90.910765,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/6/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "Tradus"
  },
  {
    "Retailer_ID": "LKU-KAM-PTT-0000001-R",
    "Retailer_Name": "Md Mamunur Rahman",
    "Retailer_Phone": 1838338223,
    "Traders_Name": "M/S Mamun Store",
    "Bazar_Name": "",
    "Latitude": 22.737096,
    "Longitude": 90.8616,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Patarirhat",
    "Last_visited": "11/7/2023",
    "Route_name": "Patarirhat",
    "Assigned_to": "Terdar"
  },
  {
    "Retailer_ID": "LKU-KAM-HJT-0000007-R",
    "Retailer_Name": "Md Selim",
    "Retailer_Phone": 1863043514,
    "Traders_Name": "Selim Store",
    "Bazar_Name": "",
    "Latitude": 22.723322,
    "Longitude": 90.879229,
    "Business_Type": "Crop",
    "District_Name": "Lakshmipur",
    "Upazila_Name": "Kamalnagar",
    "Union_Name": "Hajirhat",
    "Last_visited": "11/8/2023",
    "Route_name": "Hajirhat",
    "Assigned_to": "sir"
  },
  {
    "Retailer_ID": "LLA-HAT-PTA-0000015-R",
    "Retailer_Name": "Md Hasanujjaman Hasan",
    "Retailer_Phone": 1301315335,
    "Traders_Name": "Rohan Veterinary",
    "Bazar_Name": "",
    "Latitude": 26.073114,
    "Longitude": 89.138598,
    "Business_Type": "Livestock",
    "District_Name": "Lalmonirhat",
    "Upazila_Name": "Hatibandha",
    "Union_Name": "Paticapara",
    "Last_visited": "11/9/2023",
    "Route_name": "Paticapara",
    "Assigned_to": "hehe"
  }
];
export default retailerList;