import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Divider, Input, Modal, Select, Table, Tooltip } from "antd";
import { DateRangePicker } from "rsuite";
import {
  PrinterOutlined,
  EditOutlined,
  RollbackOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import DueUpdateModal from "./DueUpdateModal";

export default function ManageSales() {
  const { Option } = Select;
  const { Search } = Input;
  const [invoiceLists, setInvoiceLists] = useState([]);
  const [salesLoading, setSalesLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [distinctValues, setDistinctSalesValue] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [viewDueUpdateModal, setViewDueUpdateModal] = useState(false);
  const [data, setData] = useState(null);
  const [dueUpdateData, setDueUpdateData] = useState(null);
  const [search, setSearch] = useState("");
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );

  const [selectedDateRange, setSalesSelectedDateRange] = useState([]);
  const [selectedType, setSalesSelectedType] = useState("");

  const handleSalesDateRage = (value) => {
    setSalesSelectedDateRange(value);
  };

  const handleSalesType = (value) => {
    setSalesSelectedType(value);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleSearch = (value) => {
    setSearch(value);
  };
  const handleViewModal = (value) => {
    setViewModal(true);
    setData(value);
  };
  const handleInvoiceModalClose = () => {
    setViewModal(false);
  };

  const handleDueUpdateModal = (value) => {
    setViewDueUpdateModal(true);
    setDueUpdateData(value);
  };
  const handleDueUpdateModalClose = () => {
    setViewDueUpdateModal(false);
  };

  const handleInvoiceDelete = (record) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to delete ${record?.sales_id} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/inventory/invoice_delete/${record?.sales_id}`, {
            headers: authHeader(),
          })
          .then((data) => {
            if (data?.status === 204) {
              Swal.fire({
                title: "Success",
                text: `${record?.sales_id} deleted successfully`,
                icon: "success",
                confirmButtonText: "Ok",
              }).then(() => {
                fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Error",
              text: error?.response?.data?.detail,
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };

  const handleReturn = (record) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to return ${record?.sales_id} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Return",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${baseUrl}/inventory/sales_return_update/${record?.sales_id}/`,
            record,
            {
              headers: authHeader(),
            }
          )
          .then((data) => {
            console.log(data);
            if (data?.status === 200) {
              Swal.fire({
                title: "Success",
                text: `${record?.sales_id} returned successfully`,
                icon: "success",
                confirmButtonText: "Ok",
              }).then(() => {
                fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Error",
              text: error?.response?.data?.error,
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };

  const fetchData = async () => {
    try {
      setSalesLoading(true);
      const response = await axios.get(
        `${baseUrl}/inventory/sales_list_center/${userProfile?.employee_id}/`,
        {
          headers: authHeader(),
          params: {
            type: selectedType,
            dateRange: selectedDateRange,
            filter: search,
            page: currentPage,
          },
        }
      );
      setTotalItems(response?.data?.count);
      setInvoiceLists(response?.data?.results);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setSalesLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${baseUrl}/inventory/sales_distinct_data/${userProfile.employee_id}/`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setDistinctSalesValue(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [userProfile.employee_id]);

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    selectedType,
    selectedDateRange,
    search,
    userProfile.employee_id,
  ]);

  const modalRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
    onAfterPrint: () => {
      handleInvoiceModalClose();
    },
  });

  function calculateNetTotal(data) {
    let netTotal = 0;

    data?.invoice_products?.forEach((product) => {
      const discount = parseInt(product.discount) || 0;
      const productTotalPrice = parseInt(product.product_total_price);
      const productNetTotal = productTotalPrice + discount;
      netTotal += productNetTotal;
    });

    return parseInt(netTotal).toLocaleString();
  }

  function calculateTotalDiscount(data) {
    let totalDiscount = 0;

    data?.invoice_products?.forEach((product) => {
      const discount = parseInt(product.discount) || 0;
      totalDiscount += discount;
    });

    return parseInt(totalDiscount).toLocaleString();
  }

  const salesColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.created_at).toISOString().slice(0, 10)
          : "",
    },
    {
      title: "Location",
      render: (record) => record?.center?.center_name,
    },
    {
      title: "Sale ID",
      dataIndex: "sales_id",
    },
    {
      title: "Customer",
      render: (record) => record?.farmer?.name || record?.retailer?.name || record?.institutional.institutional_buyer_name,
    },
    {
      title: "Phone",
      render: (record) =>
        "0" + (record?.retailer?.phone || record?.farmer?.phone || record?.institutional?.institutional_buyer_phone),
    },
    {
      title: "Customer Type",
      dataIndex: "customer_type",
    },
    {
      title: "Total Paid",
      dataIndex: "total_paid",
    },
    {
      title: "Due",
      dataIndex: "due",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="Print">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Due Update">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleDueUpdateModal(record)}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Return">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleReturn(record)}
            >
              <RollbackOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleInvoiceDelete(record)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];
  const productTableColumn = [
    {
      title: "Product",
      render: (record) => record?.product?.product_name,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
    },
    {
      title: "MRP",
      dataIndex: "sale_rate",
    },
    {
      title: "DISCOUNT",
      render: (record) => record?.discount || 0,
    },
    {
      title: "AMOUNT",
      dataIndex: "product_total_price",
    },
  ];

  return (
    <>
      <DueUpdateModal
        visible={viewDueUpdateModal}
        handleClose={handleDueUpdateModalClose}
        data={dueUpdateData}
        fetchData={fetchData}
      />
      <Modal
        className="mb-5"
        style={{ marginTop: "80px" }}
        open={viewModal}
        onCancel={handleInvoiceModalClose}
        footer={false}
        centered
      >
        <div ref={modalRef} className="p-3">
          <h4 className="text-center" style={{ color: "#000000" }}>
            <strong>Agrigate Network Limited</strong>
          </h4>
          <h6 className="text-center" style={{ color: "#000000" }}>
            Center - {data?.center?.center_name}
          </h6>
          <div
            className="d-flex justify-content-around my-4 pt-3 fw-medium"
            style={{
              color: "#000000",
              fontSize: "16px",
              border: "solid 1px #000000",
            }}
          >
            <div className="w-50 ms-3">
              <p className="mb-0">Invoice:</p>
              <p>Date:</p>

              <p className="mb-0">Customer Name:</p>
              <p>Customer Number:</p>
            </div>
            <div className="w-50">
              <p className="mb-0">
                <strong>{data?.sales_id}</strong>
              </p>
              <p>{new Date(data?.created_at).toLocaleString()}</p>
              <p className="mb-0">
                <strong>{data?.farmer?.name || data?.retailer?.name || data?.institutional?.institutional_buyer_name}</strong>
              </p>
              <p>
                {"0" + (data?.farmer?.phone || data?.retailer?.phone || data?.institutional?.institutional_buyer_phone)}
              </p>
            </div>
          </div>
          <Divider
            style={{ background: "transparent", border: "1px dashed #000000" }}
          />
          <Table
            columns={productTableColumn}
            dataSource={data?.invoice_products}
            pagination={false}
            rowKey={(record) => record?.batch_id}
            size="small"
          />
          <div className="text-end me-2 mt-3">
            <p>Total MRP: {calculateNetTotal(data)} BDT</p>
            <p>Total Discount: {calculateTotalDiscount(data)} BDT</p>
            <p className="fw-bold">
              Net Payable: {parseInt(data?.total_price)} BDT
            </p>
            <p className="fw-bold">
              Total Paid: {parseInt(data?.total_paid)} BDT
            </p>
            <p>Due: {parseInt(data?.due)} BDT</p>
          </div>
          <Divider
            style={{ background: "transparent", border: "1px dashed #000000" }}
          />
          <p className="text-center">
            যে কোন পরামর্শ এবং সহায়তার জন্য এগ্রিগেট নেটওয়ার্ক লিমিটেড এর
            হটলাইনে যোগাযোগ করুনঃ ০১৩২৪৭৪১৬১৯,০১৩২৪৭৪১৬২০
          </p>
          <Divider
            style={{ background: "transparent", border: "1px dashed #000000" }}
          />
          <p className="text-center">
            Powered by <strong>Agrigate Network Limited</strong>
          </p>
        </div>
        <Button
          className="d-flex align-items-center fw-bold"
          style={{ backgroundColor: "#38512e", color: "#ffffff" }}
          onClick={handlePrint}
        >
          <PrinterOutlined />
          Print
        </Button>
      </Modal>

      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>MANAGE SALES</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="d-flex justify-content-between border-bottom ">
                <div className="d-flex mb-4">
                  <div className="ms-3">
                    <p className="m-0">Date: </p>
                    <DateRangePicker
                      onChange={handleSalesDateRage}
                      value={selectedDateRange}
                      style={{ width: "190px" }}
                      showOneCalendar
                    />
                  </div>
                  {/* <div className="ms-2 mb-1">
                    <p className="m-0">Location: </p>
                    <Select
                      style={{ width: "200px" }}
                      placeholder="Select location"
                      showSearch
                      onChange={handleTotalSalesDistrict}
                      value={totalSalesSelectedDistrict}
                    >
                      <Option value="">All Locations</Option>
                      {distinctValues?.distinctCenter?.map(
                        (district, index) => (
                          <Option value={district} key={index}>
                            {district}
                          </Option>
                        )
                      )}
                    </Select>
                  </div> */}
                  <div className="ms-1 mb-1">
                    <p className="m-0">Type: </p>
                    <Select
                      style={{ width: "200px" }}
                      showSearch
                      placeholder="Select Type"
                      onChange={handleSalesType}
                      value={selectedType}
                    >
                      <Option value="">All Types</Option>
                      {distinctValues?.customer_types?.map((type, index) => (
                        <Option value={type} key={index}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="pt-4">
                  <Search
                    className="w-100"
                    placeholder="search here..."
                    allowClear
                    onSearch={handleSearch}
                    enterButton={
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#38512e",
                          borderColor: "#38512e",
                        }}
                      >
                        Search
                      </Button>
                    }
                  />
                </div>
              </div>
              <Table
                columns={salesColumn}
                dataSource={invoiceLists}
                loading={salesLoading}
                rowKey={(record) => record?.sales_id}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
