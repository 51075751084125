import React from "react";

export default function StockTableComponent({ data }) {
  return (
    <div>
      <p
        className="text-center shadow-sm mb-1"
        style={{ backgroundColor: "#d9d8d8" }}
      >
        <strong>
          <small>Stock Sheet</small>
        </strong>
      </p>
      <table
        className="table table-bordered"
        style={{ fontSize: "11px", lineHeight: "10px" }}
      >
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Product Name</th>
            <th scope="col">Company</th>
            <th scope="col">Pack Size(kg/L)</th>
            <th scope="col">Total(Qty)</th>
            <th scope="col">Distributed (Qty)</th>
            <th scope="col">Left (Qty)</th>
            <th scope="col">Recent Sold (Qty)</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{item.key1}</td>
              <td>{item.key2}</td>
              <td>{item.key3}</td>
              <td>{item.key4}</td>
              <td>{item.key5}</td>
              <td>{item.key6}</td>
              <td>{item.key8}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
