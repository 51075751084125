import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin, Select, DatePicker } from "antd";
import axios from "axios";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import Swal from "sweetalert2";
import PrescriptionModal from "./PrescriptionModal";

export default function LivestockTicketModel({
  visible,
  onCancel,
  data,
  fetchLivestockTicket,
  fetchFollowupLivestockList,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [viewPrescription, setViewPrescription] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      opening_date: data?.opening_date,
      ticket_id: data?.ticket_id,
      district: data?.district,
      name: data?.farmer?.name,
      phone: "0" + data?.farmer?.phone,
      farmer_id: data?.farmer?.farmer_id,
      issue_type: data?.issue_type?.issue_type,
      ticket_status: data?.status,
      crop_or_livestock_name: data?.crop_or_livestock_name,
    });
  }, [data, form]);

  const handleCancel = () => {
    form.setFieldsValue({
      advice: null,
      followup_date: null,
    });
    onCancel();
  };

  const handlePrescriptionModal = () => {
    setViewPrescription(true);
  };
  const handlePrescriptionModalClose = () => {
    setViewPrescription(false);
  };
  const onFinish = (value) => {
    const convertDate = new Date(value.followup_date);
    console.log(convertDate);
    const followup_date = convertDate.toISOString().split("T")[0];
    value.followup_date = followup_date;
    axios
      .post(
        `${baseUrl}/consultation/livestockFollowupCreate/`,
        {
          data: value,
        },
        {
          headers: authHeader(),
        }
      )
      .then((result) => {
        if (result?.status === 201) {
          Swal.fire({
            title: "Success",
            text: result?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            handleCancel();
            fetchLivestockTicket();
            fetchFollowupLivestockList();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <Modal
      title="Livestock Followup"
      centered
      open={visible}
      onCancel={handleCancel}
      footer={null}
      width="40%"
    >
      <PrescriptionModal
        viewModal={viewPrescription}
        closeModal={handlePrescriptionModalClose}
        data={data}
      />
      <Form
        className="mt-4"
        onFinish={onFinish}
        form={form}
        layout="horizontal"
      >
        <Form.Item
          hasFeedback
          label="Date"
          name="opening_date"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please select ticket date",
            },
          ]}
        >
          <Input placeholder="Date" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Ticket"
          name="ticket_id"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Ticket required",
            },
          ]}
        >
          <Input placeholder="Ticket ID" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Location"
          name="district"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Location required",
            },
          ]}
        >
          <Input placeholder="Location" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer Name"
          name="name"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer name required",
            },
          ]}
        >
          <Input placeholder="Farmer Name" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer Contact"
          name="phone"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer Contact",
            },
          ]}
        >
          <Input placeholder="Farmer Contact" type="tel" />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer ID"
          name="farmer_id"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer Contact",
            },
          ]}
        >
          <Input placeholder="Farmer ID" readOnly />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 6 }}
          hasFeedback
          label="Crop/Livestock Name"
          name="crop_or_livestock_name"
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Crop/Livestock name required",
            },
          ]}
        >
          <Input placeholder="Crop/Livestock Name" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Issue Type"
          name="issue_type"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Issue type required",
            },
          ]}
        >
          <Input placeholder="Issue Type" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Advice"
          name="advice"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please input advice",
            },
            {
              pattern: /^[A-Za-z0-9\s,._'"\/;-]+$/,
              message: "Only English characters",
            },
          ]}
        >
          <TextArea rows={3} placeholder="Advice" />
        </Form.Item>
        <Form.Item style={{ textAlign: "right", margin: 0 }}>
          <Button
            style={{ backgroundColor: "#38512e", color: "#ffffff" }}
            onClick={handlePrescriptionModal}
          >
            Add Prescription
          </Button>
        </Form.Item>

        <Form.Item
          label="Followup Date"
          name="followup_date"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Followup date required",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Ticket Status"
          name="ticket_status"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Ticket status required",
            },
          ]}
        >
          <Select placeholder="Select ticket status">
            <Option value="Open">Open</Option>
            <Option value="Close">Close</Option>
          </Select>
        </Form.Item>
        <Form.Item style={{ textAlign: "right", margin: 0 }}>
          <Button
            style={{ backgroundColor: "#38512e", color: "#ffffff" }}
            htmlType="submit"
            disabled={loading}
          >
            Submit
            {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
