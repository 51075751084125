import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { DateRangePicker } from "rsuite";
import {
  PlusOutlined,
  MinusCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Space,
  Modal,
  Table,
  Tooltip,
} from "antd";
import WarehouseStockDetailsModal from "./WarehouseStockDetailsModal";

export default function WarehouseStockIn() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [stockList, setStockList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [stockLoading, setStockLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [viewStockInModal, setViewStockInModal] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [gdnList, setGdnList] = useState([]);
  const [selectedGDNs, setSelectedGDNs] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleSalesDateRage = (value) => {
    setSelectedDateRange(value);
  };

  const handleWarehouse = (value) => {
    setSelectedWarehouse(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleStockInModal = () => {
    setViewStockInModal(true);
  };

  const handleGRNDetailsModal = (record) => {
    setViewModal(true);
    setModalData(record);
  };
  const handleGRNDetailsModalClose = () => {
    setViewModal(false);
  };
  const handleStockInModalClose = () => {
    setViewStockInModal(false);
    form.resetFields();
    setSelectedGDNs([]);
  };

  const fetchGDNList = () => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/sourcing/gdn_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setStockLoading(false);
        setGdnList(response?.data);
      })
      .catch((error) => {
        setStockLoading(false);
        console.log(error);
      });
  };

  const fetchStockList = () => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/sourcing/warehouse_stock_list/`, {
        headers: authHeader(),
        params: {
          searched_warehouse_id: selectedWarehouse,
          dateRange: selectedDateRange,
          page: currentPage,
        },
      })
      .then((response) => {
        setStockLoading(false);
        setStockList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setStockLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchStockList();
  }, [currentPage, selectedWarehouse, selectedDateRange]);

  useEffect(() => {
    fetchGDNList();
  }, []);

  useEffect(() => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/sourcing/warehouse_info/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setStockLoading(false);
        setWarehouseList(response?.data);
      })
      .catch((error) => {
        setStockLoading(true);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  const handleGDNSelection = (value, index) => {
    const updatedSelectedGDNs = selectedGDNs.filter(
      (gdn, gdnIndex) => gdnIndex !== index
    );
    updatedSelectedGDNs.push(value);
    setSelectedGDNs(updatedSelectedGDNs);

    const selectedGDNData = gdnList.find((gdn) => gdn.gdn_number === value);
    console.log(selectedGDNData);
    const newValues = { ...form.getFieldsValue() };
    if (selectedGDNData) {
      newValues.products[index].number_of_sack = selectedGDNData.number_of_sac;
      newValues.products[index].product_name = selectedGDNData.product_name;
      newValues.products[index].quantity = selectedGDNData.purchase_quantity_kg;
      newValues.products[index].purchase_cost =
        parseFloat(selectedGDNData.purchase_quantity_kg) *
        parseFloat(selectedGDNData.purchase_rate_tk_per_kg);
    } else {
      newValues.products[index].number_of_sack = undefined;
      newValues.products[index].product_name = undefined;
      newValues.products[index].quantity = undefined;
      newValues.products[index].purchase_cost = undefined;
    }
    form.setFieldsValue({ ...newValues });
    calculateTotalSack();
    calculateTotalQuantity();
    calculateTotalPurchaseCost();
  };

  const calculateTotalSack = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalSackSum = products.reduce((sum, product) => {
      return sum + (product.number_of_sack || 0);
    }, 0);

    form.setFieldValue("total_sack", newTotalSackSum);
  };

  const calculateTotalQuantity = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalQuantitySum = products.reduce((sum, product) => {
      return sum + (product.quantity || 0);
    }, 0);

    form.setFieldValue("total_quantity", newTotalQuantitySum);
  };
  const calculateTotalPurchaseCost = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalPurchaseCostSum = products.reduce((sum, product) => {
      return sum + (product.purchase_cost || 0);
    }, 0);

    form.setFieldValue("total_purchase_cost", newTotalPurchaseCostSum);
  };

  const handleRemoveGDN = (index) => {
    const updatedSelectedGDNs = [...selectedGDNs];
    updatedSelectedGDNs.splice(index, 1); // Remove the GDN at the specified index
    setSelectedGDNs(updatedSelectedGDNs); // Update the selected GDNs state
  };

  const handleSubmit = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/sourcing/warehouse_stock_in/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            fetchGDNList();
            handleStockInModalClose();
            fetchStockList();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  const stockColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.created_at).toISOString().slice(0, 10)
          : "",
      width: "8%",
    },
    {
      title: "Warehouse",
      render: (record) => record?.warehouse?.warehouse_name,
      width: "12%",
    },
    {
      title: "GRN",
      dataIndex: "grn",
      width: "8%",
    },
    {
      title: "Stock In (Kg)",
      render:(record) => (record?.stock_in_kg).toLocaleString(),
      width: "9%",
    },
    {
      title: "Stock In (Sack)",
      dataIndex: "stock_in_sack",
      width: "9%",
    },
    {
      title: "Stock Out (Sack)",
      dataIndex: "stock_out_sack",
      width: "9%",
    },
    {
      title: "Stock Out (Kg)",
      render:(record) => (record?.stock_out_kg).toLocaleString(),
      width: "9%",
    },
    {
      title: "Remaining Stock (Sack)",
      dataIndex: "remaining_stock_sack",
      width: "13%",
    },
    {
      title: "Remaining Stock (Kg)",
      // dataIndex: "remaining_stock_kg",
      render:(record) => (record?.remaining_stock_kg).toLocaleString(),
      width: "12%",
    },
    {
      title: "Stock Value",
      render: (record) =>
        ((record?.total_purchase_cost / record?.stock_in_kg) *
        record?.remaining_stock_kg).toLocaleString(),
      width: "12%",
    },
    {
      title: "Action",
      render: (record) => (
        <Tooltip title="Details">
          <Button onClick={() => handleGRNDetailsModal(record)}>
            <EyeOutlined />
          </Button>
        </Tooltip>
      ),
      width: "10%",
    },
  ];
  return (
    <>
      {viewModal ? (
        <WarehouseStockDetailsModal
          viewModal={viewModal}
          stockDetailsModalClose={handleGRNDetailsModalClose}
          data={modalData}
        />
      ) : null}

      <Modal
        open={viewStockInModal}
        onCancel={handleStockInModalClose}
        width="60%"
        footer={false}
        title="Warehouse Stock In"
      >
        <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
          <div
            className="shadow-sm p-3"
            style={{ border: "1px solid #203509" }}
          >
            <Form
              form={form}
              color="#203509"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <div className="d-flex justify-content-between">
                <Col style={{ width: "250px" }}>
                  <Form.Item
                    hasFeedback
                    name="warehouse_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select warehouse",
                      },
                    ]}
                  >
                    <Select placeholder="Select Warehouse">
                      {warehouseList?.map((warehouse) => (
                        <Option
                          value={warehouse?.warehouse_id}
                          key={warehouse?.warehouse_id}
                        >
                          {warehouse?.warehouse_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="grn"
                    rules={[
                      {
                        required: true,
                        message: "Please insert GRN",
                      },
                    ]}
                  >
                    <Input placeholder="GRN" />
                  </Form.Item>
                </Col>
              </div>

              <Form.List name="products" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <div
                    className="p-3 mb-5"
                    style={{ color: "#203509", border: "1px solid #203509" }}
                  >
                    <Col lg={4}>
                      <Form.Item>
                        <Button
                          type="default"
                          onClick={() => add()}
                          block
                          size="small"
                          icon={<PlusOutlined />}
                          style={{ color: "#000000" }}
                        >
                          Add product
                        </Button>
                      </Form.Item>
                    </Col>
                    {fields.length === 0 && (
                      <Space key="initial" align="baseline">
                        <Col style={{ width: "250px" }}>
                          <Form.Item
                            label="GDN"
                            name={[0, `gdn_number`]}
                            rules={[
                              {
                                required: true,
                                message: "GDN is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select GDN"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => handleGDNSelection(value, 0)}
                            >
                              {gdnList
                                .filter(
                                  (gdn) =>
                                    !selectedGDNs.includes(gdn.gdn_number)
                                ) // Filter out selected GDNs
                                .map((gdn) => (
                                  <Option
                                    key={gdn?.gdn_number}
                                    value={gdn?.gdn_number}
                                  >
                                    {gdn?.gdn_number}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="Number of Sack"
                            name={[0, `number_of_sack`]}
                            rules={[
                              {
                                required: true,
                                message: "Number of sack required",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input placeholder="Number of sack" />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="Quantity (kg)"
                            name={[0, `quantity`]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity required",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input placeholder="Quantity (kg)" />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="Product Name"
                            name={[0, `product_name`]}
                            rules={[
                              {
                                required: true,
                                message: "Product name required",
                              },
                            ]}
                          >
                            <Input placeholder="Product Name" />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="Purchase Cost"
                            name={[0, `purchase_cost`]}
                            rules={[
                              {
                                required: true,
                                message: "Purchase cost required",
                              },
                            ]}
                          >
                            <Input placeholder="Purchase Cost" />
                          </Form.Item>
                        </Col>
                      </Space>
                    )}
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space key={key} align="baseline">
                        <Row gutter={[8, 16, 24, 32]}>
                          <Col style={{ width: "250px" }}>
                            <Form.Item
                              {...restField}
                              label="GDN"
                              name={[name, `gdn_number`]}
                              rules={[
                                {
                                  required: true,
                                  message: "GDN is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select GDN"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) =>
                                  handleGDNSelection(value, index)
                                }
                              >
                                {gdnList
                                  .filter(
                                    (gdn) =>
                                      !selectedGDNs.includes(gdn.gdn_number)
                                  )
                                  .map((gdn) => (
                                    <Option
                                      key={gdn?.gdn_number}
                                      value={gdn?.gdn_number}
                                    >
                                      {gdn?.gdn_number}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="Number of Sack"
                              name={[name, `number_of_sack`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Number of Sack required",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input placeholder="Number of Sack" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="Quantity (kg)"
                              name={[name, `quantity`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Quantity required",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input placeholder="Quantity (kg)" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="Product Name"
                              name={[name, `product_name`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product name required",
                                },
                              ]}
                            >
                              <Input placeholder="Product Name" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="Purchase Cost"
                              name={[name, `purchase_cost`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Purchase cost required",
                                },
                              ]}
                            >
                              <Input placeholder="Purchase Cost" />
                            </Form.Item>
                          </Col>

                          {fields.length > 1 ? (
                            <Button
                              className="align-self-center"
                              onClick={() => {
                                handleRemoveGDN(index);
                                form.setFieldsValue({
                                  total_price: undefined,
                                });
                                remove(name);
                                calculateTotalSack();
                                calculateTotalQuantity();
                                calculateTotalPurchaseCost();
                              }}
                              style={{
                                backgroundColor: "#c21807",
                                color: "#ffffff",
                              }}
                            >
                              <MinusCircleOutlined />
                            </Button>
                          ) : null}
                        </Row>
                      </Space>
                    ))}
                  </div>
                )}
              </Form.List>

              <div className="d-flex shadow-sm mb-4">
                <div
                  className="w-50 pt-4"
                  style={{ color: "#203509", border: "1px solid #203509" }}
                >
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Total Number of Sack"
                        disabled
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="total_sack"
                      rules={[
                        {
                          required: true,
                          message: "Total number of sack missing",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Total number of sack"
                        readOnly
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div
                  className="w-50 pt-4"
                  style={{ color: "#203509", border: "1px solid #203509" }}
                >
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Total Quantity"
                        disabled
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="total_quantity"
                      rules={[
                        {
                          required: true,
                          message: "Total quantity missing",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Total Quantity"
                        readOnly
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Total Purchase Cost"
                        disabled
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="total_purchase_cost"
                      rules={[
                        {
                          required: true,
                          message: "Total purchase cost missing",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Total Purchase Cost"
                        readOnly
                        style={{ color: "#203509", borderColor: "#203509" }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Form.Item className="d-flex justify-content-end ">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  style={{ color: "#ffffff", backgroundColor: "#203509" }}
                >
                  Submit
                  {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>WAREHOUSE STOCK IN DETAILS</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                <div>
                  <Select
                    placeholder="Select Warehouse"
                    className="me-3"
                    onChange={handleWarehouse}
                    style={{ width: "300px" }}
                  >
                    <Option value="">All Warehouse</Option>
                    {warehouseList?.map((warehouse) => (
                      <Option
                        value={warehouse?.warehouse_id}
                        key={warehouse?.warehouse_id}
                      >
                        {warehouse?.warehouse_name}
                      </Option>
                    ))}
                  </Select>

                  <DateRangePicker
                    onChange={handleSalesDateRage}
                    value={selectedDateRange}
                    style={{ width: "250px" }}
                    showOneCalendar
                  />
                </div>

                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#38512e",
                    borderColor: "#38512e",
                  }}
                  onClick={handleStockInModal}
                >
                  Stock In
                </Button>
              </div>
              <Table
                className="border"
                columns={stockColumn}
                dataSource={stockList}
                loading={stockLoading}
                rowKey={(record, index) => index}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
