import { Button, Image, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { DateRangePicker } from "rsuite";
import Swal from "sweetalert2";
import axios from "axios";

export default function DairySurvey() {
  const { Option } = Select;
  const [dairySurvey, setDairySurvey] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [viewImageModal, setViewImageModal] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedUpazila, setSelectedUpazila] = useState("");
  const [selectedUnion, setSelectedUnion] = useState("");
  const [surveyDistinctValues, setSurveyDistinctValues] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbzNkxrwS1DhCeKBKzFLyiyc7YRqWnhYX5X4pGrIKV-Ln_CkG8P_TP3DJVJewuRJ7LCBEA/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setSurveyDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handleUpazila = (value) => {
    setSelectedUpazila(value);
  };
  const handleUnion = (value) => {
    setSelectedUnion(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbxK7kmBit4O2arekrTGxuhEge7XybrFyEF439hg6k5eoh6ymQ53rQhpEid0AANC07cHIw/exec?page=${currentPage}&district=${selectedDistrict}&upazila=${selectedUpazila}&union=${selectedUnion}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setDairySurvey(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    selectedDateRange,
    selectedDistrict,
    selectedUnion,
    selectedUpazila,
  ]);

  const dairyColumns = [
    {
      title: "Survey Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Date);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Farmer ID",
      width: "11%",
      dataIndex: "Farmer_ID",
    },
    {
      title: "Farmer Name",
      width: "12%",
      dataIndex: "Farmer_Name",
    },
    {
      title: "Farmer Phone",
      width: "12%",
      render: (record) => {
        return "0" + record?.Phone_Number; // Add '0' to the beginning of the phone number
      },
    },

    {
      title: "District",
      dataIndex: "District_Name",
      width: "10%",
    },
    {
      title: "Pregnant Cow",
      dataIndex: "Number_of_Pregnant_Cow",
      width: "10%",
    },
    {
      title: "Cross Breed Milking Cow",
      dataIndex: "Cross_breed_number_for_Milking_Cow",
      width: "12%",
    },
    {
      title: "Local Milking Cow",
      dataIndex: "Local_number_for_Milking_Cow",
      width: "12%",
    },
    {
      title: "Heifer",
      dataIndex: "Number_of_Heifer",
      width: "10%",
    },
    {
      title: "Cow Calf",
      dataIndex: "Number_of_Cow_Calf",
      width: "10%",
    },
    {
      title: "Bull Calf",
      dataIndex: "Number_of_Bull_Calf",
      width: "10%",
    },
    {
      title: "Bull",
      dataIndex: "Number_of_Bull",
      width: "10%",
    },
    {
      title: "FO Name",
      dataIndex: "Field_Officer_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  const handleViewModal = (record) => {
    setViewModal(true);
    setModalData(record);
  };
  const handleImageClick = (url) => {
    setViewImageModal(true);
    setImageUrl(url);
  };

  return (
    <>
      <Modal
        title="Farm Photo"
        centered
        visible={viewImageModal} // Use 'visible' to control the visibility of the Modal
        footer={null}
        onCancel={() => {
          setViewImageModal(false);
        }}
      >
        <Image width={200} src={imageUrl} alt="Farm Photo" />
      </Modal>
      <Modal
        title="Dairy Survey"
        centered
        open={viewModal}
        onCancel={() => setViewModal(false)}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Farmer Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Farmer ID</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farmer_ID}
                </td>
              </tr>
              <tr>
                <th className="p-2">Farmer Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farmer_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Farmer Phone</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Phone_Number}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.District_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Upazila}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Union}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Dairy Farm Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Cattle Info
                </th>
              </tr>
              <tr>
                <td className="p-2 w-50">Cross Milking Cow</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Cross_breed_number_for_Milking_Cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Local Milking Cow</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Local_number_for_Milking_Cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Pregnant Cow</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_Pregnant_Cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Heifer</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_Heifer}
                </td>
              </tr>
              <tr>
                <td className="p-2">Cow Calf</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_Cow_Calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">Bull Calf</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_Bull_Calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">Bull</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_Bull}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Farm Info
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Farm Size (sqft)</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farm_Size_sqft}
                </td>
              </tr>
              <tr>
                <td className="p-2">Farm Photo</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  <Button
                    // style={{backgroundColor: "unset" }}
                    onClick={() => handleImageClick(modalData?.Farm_Photo)}
                  >
                    {" "}
                    click
                  </Button>
                </td>
              </tr>
              <tr>
                <th
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#203509", color: "#ffffff" }}
                >
                  Waste Management
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Waste Management Methods</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Waste_Management_Method}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Sales Cow Dung</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Do_you_sell_cow_dung}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Revenue (year)</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.cow_dung_selling_price_per_year}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="d-flex justify-content-between my-3"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          <div className="w-50 border text-center py-1">Feed Buying Info</div>
          <div className="w-50 border text-center py-1">Deworming Info</div>
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-50">Feed Buying Place</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Feed_Buying_place}
                </td>
              </tr>
              <tr>
                <th className="p-2">Cash or Due</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Cash_or_Due}
                </td>
              </tr>
              <tr>
                <th className="p-2">Payment variance for Due</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Due_feed_buy_price_variance}
                </td>
              </tr>
              <tr>
                <th className="p-2">Duration for due payment (Day)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Due_paying_days}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-50">Does Deworming</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Deworming_Status}
                </td>
              </tr>
              <tr>
                <th className="p-2">First Deworming Age (month)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Age_month_of_first_deworming}
                </td>
              </tr>
              <tr>
                <th className="p-2">Month Interval of Deworming</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Time_interval_of_deworming}
                </td>
              </tr>
              <tr>
                <th className="p-2">Cost of Deworming</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Cost_of_deworming}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Feed Prices
        </div>
        <div
          className="mb-3 text-center py-1"
          style={{ backgroundColor: "#607D43", color: "#ffffff" }}
        >
          Concentrate Info
        </div>
        <div className="d-flex">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <thead className="m-5">
              <tr>
                <th className="p-2 text-center">Product</th>
                <th className="p-2 text-center">kg_bst</th>
                <th className="p-2 text-center">Price_bst</th>
                <th className="p-2 text-center">Price_kg</th>
              </tr>
            </thead>
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-25">Wheat Bran</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Wheat_bran_kg_per_bst}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Wheat_bran_price_per_bst}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Wheat_bran_price_per_kg}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Rice Polish</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Rice_polish_kg_per_bst}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Rice_polish_price_per_bst}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Rice_polish_price_per_kg}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Oil Cake</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Til_oil_cake_kg_per_bst}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Til_oil_cake_price_per_bst}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Til_oil_cake_price_per_kg}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-50">
            <table
              className="table-bordered w-100"
              style={{ border: "0.3px solid lightgray ", color: "#203509" }}
            >
              <tbody className="m-5">
                <tr>
                  <td className="p-2 text-center">Given Concentrates Types</td>
                </tr>
                <tr>
                  <td
                    className="p-2 text-center"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    {modalData?.Concentrate_types}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className="table-bordered w-100"
              style={{ border: "0.3px solid lightgray ", color: "#203509" }}
            >
              <tbody className="m-5">
                <tr>
                  <th
                    className="p-2 text-center"
                    colSpan={2}
                    style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                  >
                    Hay Info
                  </th>
                </tr>
                <tr>
                  <td className="p-2 text-center w-50">Cost per kg</td>
                  <td
                    className="p-2 text-center"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    {modalData?.Hay_purchase_price_per_kg}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Cow Calf related Info
        </div>
        <div className="d-flex">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  Cow Calf Food Info
                </td>
              </tr>
              <tr>
                <td className="p-2 w-50">First Concentrate Given Age (year)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Age_of_concentrate_given_month_for_calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Concentrate Amount (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_Concentrate_Amount_kg_for_calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Concentrate buying Cost</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_Concentrate_cost}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Cost of Feed</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_Cost_of_feed_per_Calf_tk}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  Cow Calf Vaccine Info
                </td>
              </tr>
              <tr>
                <td className="p-2 w-50">Vaccine Names</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Vaccine_Name_for_calf}
                </td>
              </tr>
              <tr>
                <th className="p-2" colSpan={2}>
                  Vaccine Cost
                </th>
              </tr>
              <tr>
                <td className="p-2">BQ</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.BQ_Vaccine_cost_for_calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">Anthrax</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Anthrax_vaccine_cost_for_calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">FMD</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.FMD_vaccine_cost_for_calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">HS</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.HS_vaccine_cost_for_calf}
                </td>
              </tr>
              <tr>
                <td className="p-2">LSD</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.LSD_vaccine_cost_for_calf}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Pregnant Cow related Info
        </div>
        <div className="d-flex">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  Pregnant Cow Food Info
                </td>
              </tr>
              <tr>
                <td className="p-2 w-50">Daily Concentrate Amount (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Pregnant_cow_Concentrate_amount_kg}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Ready Feed Amount (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Ready_feed_amount_for_pregnant_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Hay Amount (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_hay_amount_for_pregnant_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Silage Amount (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_feeding_amount_of_silage_for_pregnant_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Total Feed Cost</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_cost_to_feed_a_pregnant_cow}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  Insemination Info
                </td>
              </tr>
              <tr>
                <td className="p-2 w-50">Cow Insemination Process</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Cow_Insemination_Process}
                </td>
              </tr>
              <tr>
                <td className="p-2">First Insemination Age (Year)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.First_Insemination_Year}
                </td>
              </tr>
              <tr>
                <td className="p-2">Insemination Cost</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Insemination_cost}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Milking Cow Info
        </div>
        <div className="d-flex">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  Milking Cow Food Info
                </td>
              </tr>
              <tr>
                <td className="p-2 w-50">Daily Concentrate Consumption (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_Concentrate_amount_for_milking_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Ready Feed Consumption (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_ready_feed_amount_for_milking_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Hay Consumption (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_hay_consumption_for_milking_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Silage Consumption (kg)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Average_silage_consumption_for_milking_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Water Consumption</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_water_consumption_Liter_for_milking_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily Total Feed Cost</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_feed_cost_for_a_milking_cow}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  Milk Info
                </td>
              </tr>
              <tr>
                <td className="p-2 w-50">Number of Milk producing cow</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Number_of_Milk_producing_cow}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily AVG Milk Production (liter)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Avg_milk_production_per_day}
                </td>
              </tr>
              <tr>
                <td className="p-2">Daily MAX Milk Production (liter)</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Daily_maximum_milk_production}
                </td>
              </tr>
              <tr>
                <td className="p-2">Milk selling place</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Milk_selling_place}
                </td>
              </tr>
              <tr>
                <td className="p-2">Milk selling price per liter</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Milk_selling_price_per_liter}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Organizational Information
        </div>
        <div className="d-flex">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-75" colSpan={2}>
                  Involved with any organization
                </td>
                <td className="p-2">
                  {modalData?.Involve_With_another_organization}
                </td>
              </tr>
              <tr>
                <th className="p-2 w-50">Stakeholder</th>
                <th className="p-2 w-25">Name</th>
                <th className="p-2 w-25">Number</th>
              </tr>
              <tr>
                <td className="p-2">Organization</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Organization_Name_Company_NGO_Distributor}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Organization_Number}
                </td>
              </tr>
              <tr>
                <td className="p-2">Vet</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Vet_name}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Vet_Phone}
                </td>
              </tr>
              <tr>
                <td className="p-2">Vaccine provider</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Vaccine_provider_name}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Vaccine_provider_Phone}
                </td>
              </tr>
              <tr>
                <td className="p-2">AI Provider</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.AI_Provider_Name}
                </td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.AI_Provider_Phone}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td
                  className="p-2 text-center"
                  colSpan={2}
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  Loan Info
                </td>
              </tr>
              <tr>
                <td className="p-2 w-50">Take loan from NGO</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Taken_a_loan_from_any_NGO}
                </td>
              </tr>
              <tr>
                <td className="p-2">Amount of loan</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Loan_amount}
                </td>
              </tr>
              <tr>
                <td className="p-2">Purpose</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Purpose_of_Loan}
                </td>
              </tr>
              <tr>
                <td className="p-2">Weekly installment</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Weekly_installment}
                </td>
              </tr>
              <tr>
                <td className="p-2">Weekly Installments left</td>
                <td
                  className="p-2 text-center"
                  style={{ backgroundColor: "#E6E6E6" }}
                >
                  {modalData?.Weekly_installemnts_left}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table
          className="w-50 table-bordered"
          style={{ border: "0.3px solid lightgray ", color: "#203509" }}
        >
          <tbody className="m-5">
            <tr>
              <td
                className="p-2 text-center"
                colSpan={2}
                style={{ backgroundColor: "#607D43", color: "#ffffff" }}
              >
                Agrigate Interest
              </td>
            </tr>
            <tr>
              <td className="p-2 w-50">Interested to work with Agrigate</td>
              <td
                className="p-2 text-center"
                style={{ backgroundColor: "#E6E6E6" }}
              >
                {modalData?.Interested_to_work_with_agrigate}
              </td>
            </tr>
            <tr>
              <td className="p-2">Attend regular meetings</td>
              <td
                className="p-2 text-center"
                style={{ backgroundColor: "#E6E6E6" }}
              >
                {modalData?.Attend_regular_meetings}
              </td>
            </tr>
            <tr>
              <td className="p-2">Pay method</td>
              <td
                className="p-2 text-center"
                style={{ backgroundColor: "#E6E6E6" }}
              >
                {modalData?.How_do_you_intend_to_pay}
              </td>
            </tr>
            <tr>
              <td className="p-2">Pay dues on time</td>
              <td
                className="p-2 text-center"
                style={{ backgroundColor: "#E6E6E6" }}
              >
                {modalData?.Pay_dues_on_time}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>DAIRY SURVEY DETAILS</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {surveyDistinctValues?.distinctDistrict?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">Upazila: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select upazila"
                showSearch
                onChange={handleUpazila}
                value={selectedUpazila}
              >
                <Option value="">All Upazilas</Option>
                {surveyDistinctValues?.distinctUpazila?.map(
                  (upazila, index) => (
                    <Option value={upazila} key={index}>
                      {upazila}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">Union: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select union"
                showSearch
                onChange={handleUnion}
                value={selectedUnion}
              >
                <Option value="">All Unions</Option>
                {surveyDistinctValues?.distinctUnion?.map((union, index) => (
                  <Option value={union} key={index}>
                    {union}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={dairyColumns}
            dataSource={dairySurvey}
            loading={tableLoading}
            rowKey={(record) => record.Farmer_ID}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
