import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Spin, Table, Tooltip } from "antd";
import { DateRangePicker } from "rsuite";
import Swal from "sweetalert2";
import axios from "axios";

export default function AgroProcessorSurvey() {
  const { Option } = Select;
  const [agroProcessorSurvey, setAgroProcessorSurvey] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [productDetails, setProductDetails] = useState([]);

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [surveyDistinctValues, setSurveyDistinctValues] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbziBziD9UulTDgAnEVi64gqPFOBsPHD1K_Yr0Mj6diGjmp-0F_N0zV-VPsMuvmtUd0IeA/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setSurveyDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };
  const handleViewModal = (record) => {
    setLoading(true);
    setViewModal(true);
    setModalData(record);
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbxI6QH-vSVMTBQlMqHD3hU7tGu36bDnZhzuxKf6RShQBvCHHTUENhFBVFZlET9f8aAM/exec?managerPhone=${record?.Manager_Phone_Number}`
      )
      .then((data) => {
        console.log(data?.data?.resultArray);
        setProductDetails(data?.data?.resultArray);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbzRIk42vkXUn6kHhNYiO39u2MV75Mp74WTlCMrTfbX3Gv_sgkvsBhDvvpizMCEIxaWABw/exec?page=${currentPage}&district=${selectedDistrict}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setAgroProcessorSurvey(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [currentPage, selectedDateRange, selectedDistrict]);

  const agroProcessorColumns = [
    {
      title: "Survey Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Date);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Mill Name",
      width: "12%",
      dataIndex: "Mill_name",
    },
    {
      title: "Mill Type",
      width: "12%",
      dataIndex: "Mill_Type",
    },
    {
      title: "Proprietor",
      width: "12%",
      dataIndex: "Proprietor",
    },

    {
      title: "Manager Phonr Number",
      width: "12%",
      render: (record) => {
        return "0" + record?.Manager_Phone_Number; // Add '0' to the beginning of the phone number
      },
    },
    {
      title: "Size of Mill",
      dataIndex: "Size_of_Mill",
      width: "10%",
    },
    {
      title: "Automation Type",
      dataIndex: "Automation_Type",
      width: "10%",
    },
    {
      title: "FO Name",
      dataIndex: "Field_Officer_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Agro Processor Survey"
        centered
        open={viewModal}
        onCancel={() => {
          setViewModal(false);
          setProductDetails([]);
        }}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Agro Processor Mill Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Mill Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Mill_name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Proprietor</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Proprietor}
                </td>
              </tr>
              <tr>
                <th className="p-2">Manager's Phone Number</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Manager_Phone_Number}
                </td>
              </tr>
              <tr>
                <th className="p-2">Size of Mill</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Size_of_Mill}
                </td>
              </tr>
              <tr>
                <th className="p-2">Automation Type</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Automation_Type}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {loading ? <Spin /> : productDetails[0]?.District_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {loading ? <Spin /> : productDetails[0]?.Upazila_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {loading ? <Spin /> : productDetails[0]?.Union_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">GPS</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Latitude},{modalData?.Longitude}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Product Information
        </div>

        <table
          className="w-100 table-bordered text-center"
          style={{ border: "0.3px solid lightgray ", color: "#203509" }}
        >
          <tbody className="m-5">
            <tr>
              <th>Product Name</th>
              <th>Product Quantity (TON)</th>
              <th>Product Price (Per KG)</th>
              <th>Byproduct Name</th>
              <th>Byproduct Quantity (TON)</th>
              <th>Product Price (Per KG)</th>
            </tr>
            {loading ? (
              <tr className="w-100">
                <td colSpan={6}>
                  <Spin />
                </td>
              </tr>
            ) : (
              productDetails?.map((product, index) => (
                <tr key={index}>
                  <td className="py-2">{product.Product_Name}</td>
                  <td>{product.Product_Quantity_TON}</td>
                  <td>{product.Product_Price_Per_KG}</td>
                  <td>{product.Byproduct_Name}</td>
                  <td>{product.Byproduct_Quantity_TON}</td>
                  <td>{product.Byproduct_Price_Per_KG}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>
            AGRO PROCESSOR SURVEY DETAILS
          </h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {surveyDistinctValues?.distinctDistrict?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </div>
          <Table
            columns={agroProcessorColumns}
            dataSource={agroProcessorSurvey}
            loading={tableLoading}
            rowKey={(record) => record.Date}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
