import { Button, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import Swal from "sweetalert2";

export default function LSPKyc() {
  const { Option } = Select;
  const [lspKYC, setLSPKYC] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [KYCDistinctValues, setKYCDistinctValues] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxQdqpID3fT5k0_6qqUU2s6xi9Ut2Tn44ClV4oCc_jBW4DMqo9N-kwPjHVVuevHKF-04Q/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setKYCDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [lspKYC]);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbzz5onQftI_BqiECOjrjciJOn4TpuKZRuEsdMLqZhsbDQoEUOluK0VtpQbCzZJT4aXw/exec?page=${currentPage}&district=${selectedDistrict}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setLSPKYC(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [currentPage, selectedDateRange, selectedDistrict]);

  const lspKycColumns = [
    {
      title: "KYC Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Date);
        return date.toLocaleDateString();
      },
    },
    {
      title: "LSP ID",
      width: "11%",
      dataIndex: "LSP_ID",
    },
    {
      title: "LSP Name",
      width: "12%",
      dataIndex: "LSP_Name",
    },
    {
      title: "LSP Phone",
      width: "12%",
      render: (record) => {
        return "0" + record?.Phone_Number;
      },
    },
    {
      title: "District",
      dataIndex: "District_Name",
      width: "10%",
    },
    {
      title: "FO Name",
      dataIndex: "Field_Officer_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  const handleViewModal = (record) => {
    setViewModal(true);
    setModalData(record);
  };
  return (
    <>
      <Modal
        title="LSP KYC"
        centered
        open={viewModal}
        onCancel={() => setViewModal(false)}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          LSP Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">LSP ID</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.LSP_ID}
                </td>
              </tr>
              <tr>
                <th className="p-2">LSP Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.LSP_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">LSP Phone</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Phone_Number}
                </td>
              </tr>
              <tr>
                <th className="p-2">Experience(Years)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Working_Experience}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.District_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Upazila}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Union}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="d-flex justify-content-between my-3"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          <div className="w-50 border text-center py-1">
            Official service provider Info
          </div>
          <div className="w-50 border text-center py-1">
            Unofficial service provider Info
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-25">Institution Name</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Official_Service_Provider_Institution_Name}
                </td>
              </tr>
              <tr>
                <td className="p-2">Designation</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Official_Service_Provider_Designation}
                </td>
              </tr>
              <tr>
                <td className="p-2">Service Type</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Official_Service_Provider_Service_Type}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-25">Service Type</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Unofficial_Service_Provider_Service_Type}
                </td>
              </tr>
              <tr>
                <td className="p-2">Interested to work with agrigate?</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Interested_to_work_with_agrigate}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 text-center py-1"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Service Info
        </div>
        <div className="d-flex">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Vaccine
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Vaccine Source</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Vaccine_Source}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Storing Method</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Vaccine_Storing_Method}
                </td>
              </tr>
              <tr>
                <th className="p-2" colSpan={2}>
                  Vaccination Service Fee
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Fee for Cow</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Fee_for_Cow}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Fee for Goat</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Fee_for_Goat}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Fee for Buffalo</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Fee_for_Buffalo}
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Fee for Duck & Chicken</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Fee_for_Duck_and_Chickens}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Semen
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Semen Type</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Semen_Type}
                </td>
              </tr>
              <tr>
                <th className="p-2" colSpan={2}>
                  Semen Service Fee (Including Cost)
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Non-governmental employees</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {
                    modalData?.How_much_fee_do_governmental_employees_charge_for_semen_including_its_cost
                  }
                </td>
              </tr>
              <tr>
                <td className="p-2 w-25">Governmental employees</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {
                    modalData?.How_much_fee_do_non_governmental_employees_charge_for_semen_including_its_cost
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h3 style={{ color: "#38512e" }}>LSP KYC DETAILS</h3>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {KYCDistinctValues?.distinctDistrict?.map((district, index) => (
                  <Option value={district} key={index}>
                    {district}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={lspKycColumns}
            dataSource={lspKYC}
            loading={tableLoading}
            rowKey={(record) => record.LSP_ID}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
