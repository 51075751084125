import React from 'react'

export default function RegistrationTableComponent({data}) {
  return (
    <>
            <p className="text-center border shadow-sm mb-1 p-1">
          <strong>
            <small>Total</small>
          </strong>
        </p>
        <div className="registrationTotalDataSection">
          <div className="shadow-sm border">
            <h5 className="ms-4 mt-4" style={{ color: "gray" }}>
              Total Registration Count
            </h5>
            <h4 className="ms-4">{data?.totalCount}</h4>
          </div>
          <div className="shadow-sm border" style={{overflow:"auto"}}>
            <table
              className="table"
              style={{ fontSize: "12px", lineHeight: "10px" }}
            >
              <thead>
                <tr style={{backgroundColor:"lightgray"}}>
                  <th scope="col">#</th>
                  <th scope="col">District</th>
                  <th scope="col">Record Count</th>
                </tr>
              </thead>
              <tbody>
                {data?.allocationWiseData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index+1}.</td>
                    <td>{item.location}</td>
                    <td>{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="border shadow-sm" style={{overflow:"auto"}}>
          <table
              className="table"
              style={{ fontSize: "12px", lineHeight: "10px" }}
            >
              <thead>
                <tr style={{backgroundColor:"lightgray"}}>
                  <th scope="col">#</th>
                  <th scope="col">FO Name</th>
                  <th scope="col">Record Count</th>
                </tr>
              </thead>
              <tbody>
                {data?.AllFieldOfficerWiseCount?.map((item, index) => (
                  <tr key={index}>
                    <td>{index+1}.</td>
                    <td>{item.foName}</td>
                    <td>{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </div>
        <p className="text-center border shadow-sm mb-1 p-1 mt-4">
          <strong>
            <small>Daily Survey Info</small>
          </strong>
        </p>
        <div className="registrationDateWiseDataSection">
          <div className="shadow-sm border ">
            <h5 className="ms-4 mt-4" style={{ color: "gray" }}>
              Daily Registration Count
            </h5>
            <h4 className="ms-4">{data?.todaysCount}</h4>
          </div>
          <div className="border shadow-sm" style={{overflow:"auto"}}>
            <table
              className="table"
              style={{ fontSize: "12px", lineHeight: "10px" }}
            >
              <thead>
                <tr style={{backgroundColor:"lightgray"}}>
                  <th scope="col">#</th>
                  <th scope="col">District</th>
                  <th scope="col">Record Count</th>
                </tr>
              </thead>
              <tbody>
                {data?.todaysLocationWiseData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index+1}.</td>
                    <td>{item.location}</td>
                    <td>{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="border shadow-sm" style={{overflow:"auto"}}>
          <table
              className="table"
              style={{ fontSize: "12px", lineHeight: "10px" }}
            >
              <thead>
                <tr style={{backgroundColor:"lightgray"}}>
                  <th scope="col">#</th>
                  <th scope="col">FO Name</th>
                  <th scope="col">Record Count</th>
                </tr>
              </thead>
              <tbody>
                {data?.FieldOfficerWiseCount?.map((item, index) => (
                  <tr key={index}>
                    <td>{index+1}.</td>
                    <td>{item.foName}</td>
                    <td>{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </div>
    </>
  )
}

