import axios from "axios";
import baseUrl from "../../config";
class AuthService {
  async login(payload) {
    const response = await axios.post(`${baseUrl}/api/token/`, {
      username: payload.loginUsername,
      password: payload.loginPassword,
    });
    const results = response.data;
    if (results.access) {
      localStorage.setItem("access_token", JSON.stringify(results.access));
      localStorage.setItem("refresh_token", JSON.stringify(results.refresh));
      localStorage.setItem("isLoggedIn", "true");
    }
    return await Promise.resolve(response);
  }

  logout() {
    localStorage.clear();
    const cookieNames = [
      "ot_access_id",
      "ot_refresh_id",
      "REACT_APP_COOKIE_DOMAIN",
    ];
    cookieNames.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.agrigate.network; path=/;`;
    });
  }

  async refreshToken() {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      const response = await axios.post(`${baseUrl}/api/token/refresh/`, {
        refresh: refresh_token,
      });

      const { access } = response.data;

      if (access) {
        localStorage.setItem("access_token", access);
      }

      return response;
    } catch (error) {
      // If refresh token fails, log out the user
      this.logout();
      return Promise.reject(error);
    }
  }
}
export default new AuthService();
