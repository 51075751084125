import { Select, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import sourcingPreviousData from "./previousSourcingData";

export default function SourcingDashboard() {
  const { Option } = Select;
  const [sourcingInfo, setSourcingInfo] = useState(null);
  const [todaysData, setTodaysData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warehouseCountData, setWarehouseCountData] = useState(null);
  const [previousSourcingSelectedProduct, setPreviousSourcingSelectedProduct] =
    useState("");
  const [previousSourcingSelectedYear, setPreviousSourcingSelectedYear] =
    useState("");
  const [test, setTest] = useState("");

  const handleProductSelect = (value) => {
    setPreviousSourcingSelectedProduct(value);
  };
  const handleYearSelect = (value) => {
    setPreviousSourcingSelectedYear(value);
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/api/price_app/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setLoading(false);
        setTest(response?.data);
        dailyProductPrice(
          response?.data?.filter((item) => item?.created_at !== null)
        );
      });
  }, []);

  const dailyProductPrice = (data) => {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });

    const distinctProductData = {};

    filteredData.forEach((item) => {
      const productName = item.product_name;

      if (!distinctProductData[productName]) {
        distinctProductData[productName] = {
          product_name: productName,
          total_spot_price: item.spot_price,
          count: 1,
          total_yesterday_price: item.yesterday_price,
        };
      } else {
        distinctProductData[productName].total_spot_price += item.spot_price;
        distinctProductData[productName].total_yesterday_price +=
          item.yesterday_price;
        distinctProductData[productName].count++;
      }
    });

    // Calculate the average spot_price for each product
    const averageProductData = Object.values(distinctProductData).map(
      (productData) => ({
        ...productData,
        avg_spot_price: (
          productData.total_spot_price / productData.count
        ).toFixed(2),
        avg_yesterday_price: (
          productData.total_yesterday_price / productData.count
        ).toFixed(2),
      })
    );

    setTodaysData(averageProductData);
  };

  const sourcingPreviousFilteredData = sourcingPreviousData?.filter((item) => {
    const isProductMatched =
      previousSourcingSelectedProduct === "" ||
      previousSourcingSelectedProduct === item?.Product;
    const isYearMatched =
      previousSourcingSelectedYear === "" ||
      previousSourcingSelectedYear === item?.Year;
    return isProductMatched && isYearMatched;
  });

  useEffect(() => {
    axios
      .get(`${baseUrl}/sourcing/sourcingInfo`, {
        headers: authHeader(),
      })
      .then((response) => {
        setSourcingInfo(response?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/sourcing/warehouse_count_data`, {
        headers: authHeader(),
      })
      .then((response) => {
        setWarehouseCountData(response?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const dailyPriceDataColumns = [
    {
      title: "Product",
      width: "40%",
      dataIndex: "product_name",
    },
    {
      title: "Today",
      width: "30%",
      dataIndex: "avg_spot_price",
    },
    {
      title: "Yesterday",
      width: "30%",
      dataIndex: "avg_yesterday_price",
    },
  ];

  const previousSourcingColumn = [
    {
      title: "Year",
      dataIndex: "Year",
    },
    {
      title: "Month",
      dataIndex: "Month",
    },
    {
      title: "Product",
      dataIndex: "Product",
    },
    {
      title: "Party Name",
      dataIndex: "Party_Name",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
    },
    {
      title: "Purchase",
      dataIndex: "Purchase",
    },
    {
      title: "Sales",
      dataIndex: "Sales",
    },
    {
      title: "Profit",
      dataIndex: "Profit",
    },
  ];
  return (
    <div className="consultationDashboard">
      <div className="dashboardNameSection">
        <h3 style={{ color: "#38512e" }}>SOURCING DASHBOARD</h3>
      </div>
      <div className="consultationDashboardContainer shadow-sm rounded">
        <div className="gridView px-3 pt-5">
          <div className="gridViewLeft shadow-sm border text-center mx-2">
            <div>
              <p
                className="py-1 m-0"
                style={{ backgroundColor: "#203509", color: "#ffffff" }}
              >
                Sourcing
              </p>
              <div className="sourcingGridUpper px-2 mb-4">
                <div className="border mt-4">
                  <p
                    className="py-2 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Purchase Qty
                  </p>
                  <p className="py-2">
                    {sourcingInfo?.total_purchase_quantity?.toLocaleString() ||
                      0}
                  </p>
                </div>
                <div className="border mt-4">
                  <p
                    className="py-2 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Sales Quantity
                  </p>
                  <p className="py-2">
                    {sourcingInfo?.total_sale_quantity?.toLocaleString() || 0}
                  </p>
                </div>
                <div className="border mt-4">
                  <p
                    className="py-2 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Purchase Amount
                  </p>
                  <p className="py-2">
                    {sourcingInfo?.total_purchase_amount?.toLocaleString() || 0}
                  </p>
                </div>
                <div className="border mt-4">
                  <p
                    className="py-1 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Sales Amount
                  </p>
                  <p className="py-2">
                    {sourcingInfo?.total_sale_amount?.toLocaleString() || 0}
                  </p>
                </div>
              </div>

              <p className="mb-5">
                <Link
                  className="farmerCashSalesDashboardBtn"
                  to="/productSourcing"
                >
                  See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                </Link>
              </p>
            </div>
            <div>
              <p
                className="py-1 m-0"
                style={{ backgroundColor: "#203509", color: "#ffffff" }}
              >
                Warehouse
              </p>
              <div className="sourcingGridUpper px-2 mb-4">
                <div className="border mt-4">
                  <p
                    className="py-2 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Remaining Stock (Sack)
                  </p>
                  <p className="py-2">
                    {warehouseCountData?.total_remaining_stock_sack?.toLocaleString() ||
                      0}
                  </p>
                </div>
                <div className="border mt-4">
                  <p
                    className="py-2 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Remaining Stock (Kg)
                  </p>
                  <p className="py-2">
                    {warehouseCountData?.total_remaining_stock_kg?.toLocaleString() ||
                      0}
                  </p>
                </div>
                <div className="border mt-4">
                  <p
                    className="py-2 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Stock Out (Sack)
                  </p>
                  <p className="py-2">
                    {warehouseCountData?.total_stock_out_sack?.toLocaleString() ||
                      0}
                  </p>
                </div>
                <div className="border mt-4">
                  <p
                    className="py-1 m-0"
                    style={{ backgroundColor: "lightgray", color: "#203509" }}
                  >
                    Stock Out (Kg)
                  </p>
                  <p className="py-2">
                    {warehouseCountData?.total_stock_out_kg?.toLocaleString() ||
                      0}
                  </p>
                </div>
              </div>
              <p className="mb-5">
                <Link
                  className="farmerCashSalesDashboardBtn"
                  to="/warehouseStockIn"
                >
                  See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                </Link>
              </p>
            </div>
          </div>

          <div className="gridViewRight shadow-sm border text-center mx-2">
            <div>
              <p
                className="py-1 m-0"
                style={{ backgroundColor: "#203509", color: "#ffffff" }}
              >
                Product Price
              </p>
              <Table
                className="mt-2 border mx-2"
                columns={dailyPriceDataColumns}
                dataSource={test}
                loading={loading}
                rowKey={(record) => record.id}
                size="small"
                scroll={{ y: 180 }}
              />
              <p className="mt-4 mb-4">
                <Link className="farmerCashSalesDashboardBtn" to="/priceApp">
                  See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                </Link>
              </p>
            </div>
            <div>
              <p
                className="py-1 m-0"
                style={{ backgroundColor: "#203509", color: "#ffffff" }}
              >
                Previous Sourcing Data
              </p>

              <div className="d-flex">
                <div className="ms-3 mt-3">
                  <p className="m-0">District: </p>
                  <Select
                    placeholder="Select District"
                    value={previousSourcingSelectedYear}
                    onChange={handleYearSelect}
                    style={{ width: "200px" }}
                  >
                    <Option value="">All Year</Option>
                    <Option value="2022">2022</Option>
                    <Option value="2023">2023</Option>
                    <Option value="2024">2024</Option>
                  </Select>
                </div>
                <div className="ms-3 mt-3">
                  <p className="m-0">Product Name: </p>
                  <Select
                    placeholder="Select Product Name"
                    onChange={handleProductSelect}
                    value={previousSourcingSelectedProduct}
                    style={{ width: "200px" }}
                    showSearch
                  >
                    <Option value="">All Products</Option>
                    <Option value="Maize">Maize</Option>
                    <Option value="DORB">DORB</Option>
                    <Option value="Broken">Broken</Option>
                    <Option value="Crude Oil">Crude Oil</Option>
                    <Option value="Soyabean">Soyabean</Option>
                    <Option value="Rice Polish">Rice Polish</Option>
                  </Select>
                </div>
              </div>
              <Table
                className="mt-2 border mx-2"
                columns={previousSourcingColumn}
                dataSource={sourcingPreviousFilteredData}
                rowKey={(record) => record.id}
                size="small"
                scroll={{ y: 230 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
