import { Button, Form, Input, Modal, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";

export default function StockUpdateModal({
  visible,
  handleModalClose,
  data,
  fetchStockList,
}) {
  const [form] = Form.useForm();
  const [stockLoading, setStockLoading] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      center_id: data?.center?.center?.employee_id,
      product_id: data?.product?.product_id,
      batch_id: data?.batch_id,
      product_name: data?.product?.product_name,
      purchase_price: data?.purchase_price,
      selling_rate_farmer: data?.selling_rate_farmer || null,
      selling_rate_retailer: data?.selling_rate_retailer || null,
      selling_rate_contract_farmer: data?.selling_rate_contract_farmer || null,
      selling_rate_institutional_buyer:
        data?.selling_rate_institutional_buyer || null,
    });
  }, [data, form]);

  const handleUpdate = (value) => {
    axios
      .patch(`${baseUrl}/inventory/stock_price_update/${data?.id}/`, value, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Stock Product Selling Price Updated Successful",
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            fetchStockList();
            handleModalClose();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  return (
    <Modal
      title="Stock Product Price Update"
      open={visible}
      onCancel={handleModalClose}
      footer={false}
    >
      <Form form={form} onFinish={handleUpdate} layout="horizontal">
        <Form.Item
          label="Center ID"
          name="center_id"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Center ID required" }]}
        >
          <Input placeholder="Center ID" readOnly />
        </Form.Item>
        <Form.Item
          label="Product ID"
          name="product_id"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Product ID required" }]}
        >
          <Input placeholder="Product ID" readOnly />
        </Form.Item>
        <Form.Item
          label="Product Name"
          name="product_name"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Product Name required" }]}
        >
          <Input placeholder="Product Name" readOnly />
        </Form.Item>
        <Form.Item
          label="Batch ID"
          name="batch_id"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Batch ID required" }]}
        >
          <Input placeholder="Batch ID" readOnly />
        </Form.Item>
        <Form.Item
          label="Purchase Price"
          name="purchase_price"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Purchase price required" }]}
        >
          <Input placeholder="Purchase Price" readOnly />
        </Form.Item>

        <Form.Item
          label="Selling Price Farmer"
          name="selling_rate_farmer"
          labelCol={{ span: 8 }}
          rules={[
            { required: true, message: "Farmer selling price required" },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only English numbers",
            },
          ]}
        >
          <Input placeholder="Farmer Price" />
        </Form.Item>
        <Form.Item
          label="Selling Price Retailer"
          name="selling_rate_retailer"
          labelCol={{ span: 8 }}
          rules={[
            { required: true, message: "Retailer selling price required" },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only English numbers",
            },
          ]}
        >
          <Input placeholder="Retailer Price" />
        </Form.Item>
       
        <Form.Item
          label="Selling Price Contract Farmer"
          name="selling_rate_contract_farmer"
          labelCol={{ span: 11 }}
          rules={[
            {
              required: true,
              message: "Contract Farmer selling price required",
            },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only English numbers",
            },
          ]}
        >
          <Input placeholder="Contract Farmer Price" />
        </Form.Item>
        <Form.Item
          label="Selling Price Institutional Buyer"
          name="selling_rate_institutional_buyer"
          labelCol={{ span: 11 }}
          rules={[
            {
              required: true,
              message: "Institutional Buyer selling price required",
            },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only English numbers",
            },
          ]}
        >
          <Input placeholder="Institutional Buyer Price" />
        </Form.Item>
        <Form.Item>
          <Button
            className="ms-3"
            type="primary"
            style={{
              backgroundColor: "#38512e",
              borderColor: "#38512e",
            }}
            htmlType="submit"
            disabled={stockLoading}
          >
            Update
            {stockLoading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
