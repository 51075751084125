const sourcingPreviousData = [
  {
    "id": 1,
    "Year": "2022",
    "Month": "May",
    "Product": "Maize",
    "Party_Name": "Astha",
    "Quantity": 475809,
    "Purchase": 15905001,
    "Sales": 16057824,
    "Profit": 152823
  },
  {
    "id": 2,
    "Year": "2022",
    "Month": "June",
    "Product": "Maize",
    "Party_Name": "Astha",
    "Quantity": 839173,
    "Purchase": 28446479,
    "Sales": 28621766,
    "Profit": 175287
  },
  {
    "id": 3,
    "Year": "2022",
    "Month": "June",
    "Product": "Soyabean",
    "Party_Name": "Astha",
    "Quantity": 127413,
    "Purchase": 7203069,
    "Sales": 7265566,
    "Profit": 62497
  },
  {
    "id": 4,
    "Year": "2022",
    "Month": "September",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 13778,
    "Purchase": 401993,
    "Sales": 388667,
    "Profit": -13326
  },
  {
    "id": 5,
    "Year": "2022",
    "Month": "October",
    "Product": "Broken",
    "Party_Name": "ITC",
    "Quantity": 200580,
    "Purchase": 5925000,
    "Sales": 6314290,
    "Profit": 389290
  },
  {
    "id": 6,
    "Year": "2022",
    "Month": "October",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 145040,
    "Purchase": 4301114,
    "Sales": 4091580,
    "Profit": -209534
  },
  {
    "id": 7,
    "Year": "2022",
    "Month": "October",
    "Product": "DORB",
    "Party_Name": "New Hope",
    "Quantity": 123019,
    "Purchase": 3507500,
    "Sales": 3716348,
    "Profit": 208848
  },
  {
    "id": 8,
    "Year": "2022",
    "Month": "October",
    "Product": "Crude Oil",
    "Party_Name": "Astha",
    "Quantity": 12470,
    "Purchase": 1403750,
    "Sales": 1521340,
    "Profit": 117590
  },
  {
    "id": 9,
    "Year": "2022",
    "Month": "November",
    "Product": "Broken",
    "Party_Name": "ITC",
    "Quantity": 35700,
    "Purchase": 1006000,
    "Sales": 1070490,
    "Profit": 64490
  },
  {
    "id": 10,
    "Year": "2022",
    "Month": "November",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 202999,
    "Purchase": 5842885,
    "Sales": 6029417,
    "Profit": 186532
  },
  {
    "id": 11,
    "Year": "2022",
    "Month": "November",
    "Product": "DORB",
    "Party_Name": "New Hope",
    "Quantity": 85619,
    "Purchase": 2455250,
    "Sales": 2650757,
    "Profit": 195507
  },
  {
    "id": 12,
    "Year": "2022",
    "Month": "November",
    "Product": "Crude Oil",
    "Party_Name": "Astha",
    "Quantity": 24950,
    "Purchase": 2807500,
    "Sales": 3043900,
    "Profit": 236400
  },
  {
    "id": 13,
    "Year": "2022",
    "Month": "December",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 86164,
    "Purchase": 3018253,
    "Sales": 2983955,
    "Profit": -34298
  },
  {
    "id": 14,
    "Year": "2022",
    "Month": "December",
    "Product": "DORB",
    "Party_Name": "Astha",
    "Quantity": 25080,
    "Purchase": 701500,
    "Sales": 682176,
    "Profit": -19324
  },
  {
    "id": 15,
    "Year": "2022",
    "Month": "December",
    "Product": "DORB",
    "Party_Name": "New Hope",
    "Quantity": 12265,
    "Purchase": 350750,
    "Sales": 379712,
    "Profit": 28962
  },
  {
    "id": 16,
    "Year": "2022",
    "Month": "December",
    "Product": "Crude Oil",
    "Party_Name": "Astha",
    "Quantity": 12490,
    "Purchase": 1403750,
    "Sales": 1523780,
    "Profit": 120030
  },
  {
    "id": 17,
    "Year": "2023",
    "Month": "January",
    "Product": "Broken",
    "Party_Name": "ITC",
    "Quantity": 60130,
    "Purchase": 1687418,
    "Sales": 1813700,
    "Profit": 126282
  },
  {
    "id": 18,
    "Year": "2023",
    "Month": "January",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 142691,
    "Purchase": 5150833,
    "Sales": 5200795,
    "Profit": 49962
  },
  {
    "id": 19,
    "Year": "2023",
    "Month": "January",
    "Product": "DORB",
    "Party_Name": "New Hope",
    "Quantity": 24352,
    "Purchase": 670250,
    "Sales": 644352,
    "Profit": -25898
  },
  {
    "id": 20,
    "Year": "2023",
    "Month": "January",
    "Product": "DORB",
    "Party_Name": "Astha",
    "Quantity": 50000,
    "Purchase": 1403000,
    "Sales": 1355000,
    "Profit": -48000
  },
  {
    "id": 21,
    "Year": "2023",
    "Month": "January",
    "Product": "Crude Oil",
    "Party_Name": "Astha",
    "Quantity": 237500,
    "Purchase": 25487208,
    "Sales": 28100000,
    "Profit": 2612792
  },
  {
    "id": 22,
    "Year": "2023",
    "Month": "February",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 73746,
    "Purchase": 2734763,
    "Sales": 2799382,
    "Profit": 64619
  },
  {
    "id": 23,
    "Year": "2023",
    "Month": "February",
    "Product": "Crude Oil",
    "Party_Name": "Astha",
    "Quantity": 75000,
    "Purchase": 7579036,
    "Sales": 8850000,
    "Profit": 1270964
  },
  {
    "id": 24,
    "Year": "2023",
    "Month": "February",
    "Product": "Broken",
    "Party_Name": "ITC",
    "Quantity": 39855,
    "Purchase": 1369506,
    "Sales": 1514490,
    "Profit": 144984
  },
  {
    "id": 25,
    "Year": "2023",
    "Month": "March",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 29783,
    "Purchase": 1096095,
    "Sales": 1130553,
    "Profit": 34458
  },
  {
    "id": 26,
    "Year": "2023",
    "Month": "March",
    "Product": "Broken",
    "Party_Name": "ITC",
    "Quantity": 99180,
    "Purchase": 3309333,
    "Sales": 3768840,
    "Profit": 459507
  },
  {
    "id": 27,
    "Year": "2023",
    "Month": "March",
    "Product": "Crude Oil",
    "Party_Name": "Astha",
    "Quantity": 37500,
    "Purchase": 3789518,
    "Sales": 4050000,
    "Profit": 260482
  },
  {
    "id": 28,
    "Year": "2023",
    "Month": "April",
    "Product": "Broken",
    "Party_Name": "ITC",
    "Quantity": 19840,
    "Purchase": 709290,
    "Sales": 753920,
    "Profit": 44630
  },
  {
    "id": 29,
    "Year": "2023",
    "Month": "April",
    "Product": "Broken",
    "Party_Name": "New Hope",
    "Quantity": 58001,
    "Purchase": 2249940,
    "Sales": 2172716,
    "Profit": -77224
  },
  {
    "id": 30,
    "Year": "2023",
    "Month": "April",
    "Product": "DORB",
    "Party_Name": "Astha",
    "Quantity": 25000,
    "Purchase": 639000,
    "Sales": 700000,
    "Profit": 61000
  },
  {
    "id": 31,
    "Year": "2023",
    "Month": "April",
    "Product": "Crude Oil",
    "Party_Name": "Astha",
    "Quantity": 12500,
    "Purchase": 1263173,
    "Sales": 1350000,
    "Profit": 86827
  },
  {
    "id": 32,
    "Year": "2023",
    "Month": "April",
    "Product": "Maize",
    "Party_Name": "Riyad Trading",
    "Quantity": 3265,
    "Purchase": 75911,
    "Sales": 89000,
    "Profit": 13089
  },
  {
    "id": 33,
    "Year": "2023",
    "Month": "May",
    "Product": "Maize",
    "Party_Name": "Various Party",
    "Quantity": 575998,
    "Purchase": 15089617,
    "Sales": 15348993,
    "Profit": 259376
  },
  {
    "id": 34,
    "Year": "2023",
    "Month": "May",
    "Product": "Soyabean",
    "Party_Name": "Nutrila",
    "Quantity": 5117,
    "Purchase": 293176,
    "Sales": 295507,
    "Profit": 2331
  },
  {
    "id": 35,
    "Year": "2023",
    "Month": "June",
    "Product": "Maize",
    "Party_Name": "Various Party",
    "Quantity": 202440,
    "Purchase": 4967539,
    "Sales": 4998342,
    "Profit": 30803
  },
  {
    "id": 36,
    "Year": "2023",
    "Month": "July",
    "Product": "Maize",
    "Party_Name": "Various Party",
    "Quantity": 278105,
    "Purchase": 7738143,
    "Sales": 7858564,
    "Profit": 120421
  },
  {
    "id": 37,
    "Year": "2023",
    "Month": "July",
    "Product": "Soyabean",
    "Party_Name": "Astha",
    "Quantity": 11563,
    "Purchase": 825905,
    "Sales": 838318,
    "Profit": 12413
  },
  {
    "id": 38,
    "Year": "2023",
    "Month": "August",
    "Product": "Maize",
    "Party_Name": "Various Party",
    "Quantity": 85390,
    "Purchase": 2464500,
    "Sales": 2485333,
    "Profit": 20833
  },
  {
    "id": 39,
    "Year": "2023",
    "Month": "October",
    "Product": "DORB",
    "Party_Name": "Astha",
    "Quantity": 26000,
    "Purchase": 729560,
    "Sales": 742560,
    "Profit": 13000
  },
  {
    "id": 40,
    "Year": "2024",
    "Month": "March",
    "Product": "Rice Polish",
    "Party_Name": "Astha",
    "Quantity": 88070,
    "Purchase": 2888696,
    "Sales": 2906310,
    "Profit": 17614
  }
]

  export default sourcingPreviousData;