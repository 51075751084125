import React, { useEffect, useState } from "react";
import "./CreateProfilePage.css";
import { Form, Input, Button, Col, Select } from "antd";
import authHeader from "../Authentication/authHeader";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import baseUrl from "../../config";

export default function CreateProfilePage() {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const [userList, setUserList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axios.get(`${baseUrl}/employee/users/`,{headers:authHeader()}).then((results) => {
      setUserList(results?.data);
    });
    
  }, []);

  const handleSubmit = (data) => {
    fetch(`${baseUrl}/employee/create_profile/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        ...authHeader(),
      },
    })
      .then((data) => {
        if(data?.status === 201){
          Swal.fire({
            title: "Employee Profile",
            text: "Created Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            history.push("/employee");
          });
        }
        else{
          Swal.fire({
            title: "Error",
            text: data?.statusText,
            icon: "error",
            confirmButtonText: "Retry",
          })
        }       
      })
      .catch((err) => {
        Swal.fire({
          title: "Unsuccessfully",
          text: err.message,
          icon: "error",
          confirmButtonText: "Retry",
        })
      });
  };
  return (
    <div className="createProfilePage">
      <div className="createProfileHeaderSection">
        <h3 style={{ color: "#38512e" }}>CREATE EMPLOYEE PROFILE</h3>
      </div>

      <div className="createProfileContainer">
        <div className="createProfilePageSection">
          <header className="text-center mt-4"><strong>Employee Profile Create Form</strong></header>
          <Form
            className="mt-5"
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            {/* User */}
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                hasFeedback
                label="User"
                name="user"
                rules={[{ required: true, message: "Please select employee" }]}
              >
                <Select placeholder="User">
                  {userList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.first_name + ' ' + item.last_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* ID */}
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                hasFeedback
                label="Employee ID"
                name="employee_id"
                rules={[
                  { required: true, message: "Please enter Employee ID" },
                ]}
              >
                <Input placeholder="Employee ID" />
              </Form.Item>
            </Col>

            {/* Phone Number */}
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                hasFeedback
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter employee's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Phone Number" type="tel" />
              </Form.Item>
            </Col>
           
            {/* National ID */}
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                hasFeedback
                label="National ID"
                name="nationalID"
                initialValue={0}
              >
                <Input placeholder="National ID" />
              </Form.Item>
            </Col>
            {/* Address Details */}
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                label="Address Details"
                name="address_details"
                hasFeedback
                initialValue=""
              >
                <TextArea placeholder="Enter address details" />
              </Form.Item>
            </Col>

            {/* Submit Button */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </div>
      </div>
    </div>
  );
}
