import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Spin, Modal, DatePicker } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import dayjs from "dayjs";

export default function WorkOrderEditModal({
  visible,
  handleWorkOrderEditModalClose,
  data,
  fetchWorkOrder,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dateFormat = "YYYY-MM-DD";
  const [loading, setLoading] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState("");
  const [ipInfo, setIPInfo] = useState([]);
  const [sourcingProductsList, setSourcingProductsList] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      work_order_date: data?.work_order_date,
      company_id: data?.company?.company_id,
      work_order_id: data?.work_order_id,
      sourcing_product_id: data?.sourcing_product?.sourcing_product_id,
      product_quantity_kg: data?.product_quantity_kg,
      rate_tk_per_kg: data?.rate_tk_per_kg,
      deadline: dayjs(data?.deadline, dateFormat),
      wo_status: data?.wo_status,
      remarks: data?.remarks,
    });
  }, [data]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/ip/ip_info`, {
        headers: authHeader(),
      })
      .then((data) => setIPInfo(data?.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/sourcingProductList`, {
        headers: authHeader(),
      })
      .then((data) => setSourcingProductsList(data?.data));
  }, []);

  const handleDeadlineDateChange = (date, dateString) => {
    setDeadlineDate(dateString);
  };

  const handleProductChange = () => {
    form.setFieldsValue({
      product_quantity_kg: undefined,
      rate_tk_per_kg: undefined,
    });
  };

  const handleSubmit = (data) => {
    data.deadline =
      deadlineDate !== "" ? deadlineDate : dayjs(data?.deadline, dateFormat);
    axios
      .patch(
        `${baseUrl}/sourcing/workOrderUpdate/${data?.work_order_id}/`,
        data,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        if (data?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Work Order Info Update Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            handleWorkOrderEditModalClose();
            fetchWorkOrder();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <Modal
      title="Work Order Edit"
      open={visible}
      onCancel={handleWorkOrderEditModalClose}
      footer={false}
    >
      <Form
        className="register-form"
        form={form}
        onFinish={handleSubmit}
        layout="horizontal"
      >
        <Form.Item
          hasFeedback
          label="Work Order Date"
          name="work_order_date"
          labelCol={{ span: 8 }}
          rules={[{ required: true, message: "Please enter work order date" }]}
        >
          <Input placeholder="Work Order Date" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Company Name"
          name="company_id"
          labelCol={{ span: 8 }}
          rules={[{ required: true, message: "Please select company" }]}
        >
          <Select
            placeholder="Select company"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled
          >
            {ipInfo?.map((ip) => (
              <Option key={ip.company_id} value={ip.company_id}>
                {ip.company_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="PO(Purchase Order)"
          name="work_order_id"
          labelCol={{ span: 8 }}
        >
          <Input placeholder="Purchase order" readOnly />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Product List"
          name="sourcing_product_id"
          labelCol={{ span: 8 }}
          rules={[{ required: true, message: "Please select product" }]}
        >
          <Select
            placeholder="Select product"
            onChange={handleProductChange}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {sourcingProductsList?.map((ip) => (
              <Option
                key={ip.sourcing_product_id}
                value={ip.sourcing_product_id}
              >
                {ip.product_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Work Order Quantity"
          name="product_quantity_kg"
          labelCol={{ span: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter ordered quantity",
            },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only english number",
            },
          ]}
        >
          <Input placeholder="Work Order Quantity" type="tel" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Rate (Tk/Kg)"
          name="rate_tk_per_kg"
          labelCol={{ span: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter rate",
            },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Only english number",
            },
          ]}
        >
          <Input placeholder="Rate (Tk/Kg)" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Deadline"
          name="deadline"
          labelCol={{ span: 8 }}
        >
          <DatePicker format="YYYY-MM-DD" onChange={handleDeadlineDateChange} />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Work Order Status"
          name="wo_status"
          initialValue=""
          labelCol={{ span: 8 }}
        >
          <Select placeholder="Select Status">
            <Option value="Due">Due</Option>
            <Option value="Complete">Complete</Option>
          </Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Remarks"
          name="remarks"
          initialValue=""
          labelCol={{ span: 8 }}
        >
          <Input placeholder="Remarks" />
        </Form.Item>

        {data?.wo_status !== "Complete" && (
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Update
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
