import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPointRight,
} from "@fortawesome/free-solid-svg-icons";

export default function DatabaseDashboard() {
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState([]);
  const fetchCountData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/api/database_entities_count/`,
        {
          headers: authHeader(),
        }
      );
      setCountData(response?.data);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCountData();
  }, []);
  return (
    <div className="consultationDashboard">
      <div className="dashboardNameSection">
        <h3 style={{ color: "#38512e" }}>DATABASE DASHBOARD</h3>
      </div>
      <div className="consultationDashboardContainer shadow-sm rounded">
        <div className="px-3 pt-4">
          <div className="shadow-sm border text-center mx-2">
            <p
              className="py-1 m-0"
              style={{ backgroundColor: "#203509", color: "#ffffff" }}
            >
              Total Registrations
            </p>
            <div className="contractGridUpper px-2 mb-4">
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Farmer
                </p>
                <p className="py-4">{countData?.farmer_count || 0}</p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/databases/farmer">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Retailer
                </p>
                <p className="py-4">{countData?.retailer_count || 0}</p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/databases/retailer">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Trader
                </p>
                <p className="py-4">{countData?.trader_count || 0}</p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/databases/trader">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  LSP
                </p>
                <p className="py-4">{countData?.lsp_count || 0}</p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/databases/lsp">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  ASP
                </p>
                <p className="py-4">{countData?.asp_count || 0}</p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/databases/asp">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#203509" }}
                >
                  Institutional Buyer
                </p>
                <p className="py-4">{countData?.institutional_buyer_count || 0}</p>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/databases/institutionalBuyer">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
