import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { Button, Image, Input, Modal } from "antd";
import Swal from "sweetalert2";
import baseUrl from "../../config";

export default function LSPProfile() {
  const [lspProfileDetails, setLspProfileDetails] = useState([]);
  const [printBtnVisible, setPrintBtnVisible] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { lsp_id } = useParams();
  useEffect(() => {
    axios.get(`${baseUrl}/lsp/lspProfile/${lsp_id}/`).then((data) => {
      setLspProfileDetails(data?.data);
    });
  }, [lsp_id]);

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePhoneNumberCheck = () => {
    if (parseInt(phoneNumber) === parseInt(lspProfileDetails?.phone)) {
      setOpenModal(false);
    } else {
      Swal.fire({
        title: "Error",
        text: "Phone number didn't match",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePrint = () => {
    setPrintBtnVisible(false);
    document.title = `Farmer Profile -${lspProfileDetails?.record_id}`;
    setTimeout(() => {
      window.print();
      window.close();
      document.title = "Agrigate Dashboard";
      setPrintBtnVisible(true);
    }, 5000);
  };

  return (
    <>
      <div></div>
      <Modal
        title="Please enter registered LSP phone number (রেজিস্টার্ড এল এস পি এর মোবাইল নম্বর )"
        centered
        closable={false}
        open={openModal}
        footer={[
          <Button key="submit" type="primary" onClick={handlePhoneNumberCheck}>
            OK
          </Button>,
        ]}
      >
        <Input
          placeholder="LSP Phone Number"
          inputMode="numeric"
          onChange={handlePhoneNumber}
        />
      </Modal>
      <div
        className="p-5 container"
        style={{ display: openModal ? "none" : "block" }}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          LSP Information
        </div>
        <div className="d-flex justify-content-end">
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#203509" }}
            >
              <tbody className="m-5">
                <tr>
                  <th className="p-2 w-25">LSP ID Number</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.lsp_id}
                  </td>
                </tr>
                <tr>
                  <th className="p-2 w-25">LSP Name</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">LSP's Phone Number</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {"0" + lspProfileDetails?.phone}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">National ID</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.nid}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">District</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.district?.district_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Upazila</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.upazila?.upazila_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Union</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.union?.union_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Village</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.village}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">GPS</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {lspProfileDetails?.latitude +
                      ", " +
                      lspProfileDetails?.longitude}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="w-50 ms-5">
            <p style={{ color: "#203509" }}>
              <strong>LSP Image:</strong>
            </p>
            <Image
              className="border"
              width={350}
              height={250}
              src={lspProfileDetails?.profile_image}
              alt="LSP Image"
            />
          </div>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Service Info
        </div>
        <div className="w-50">
          <table
            className="w-100 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#203509" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Service Type</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {lspProfileDetails?.service_type}
                </td>
              </tr>
              <tr>
                <th className="p-2 w-25">Livestock Type</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {lspProfileDetails?.livestock_type}
                </td>
              </tr>
              <tr>
                <th className="p-2">Institution Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {lspProfileDetails?.institution_name}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#203509", color: "#ffffff" }}
        >
          Service with Agrigate
        </div>
        <table
          className="w-100 table-bordered text-center"
          style={{ border: "0.3px solid lightgray ", color: "#203509" }}
        >
          <tbody className="m-5">
            <tr>
              <th>Invoice</th>
              <th>Name of Service</th>
              <th>Quantity</th>
              <th>Amount</th>
            </tr>
            {/* {lspProfileDetails?.product_buying_info?.map((product, index) => (
              <tr key={index}>
                <td className="py-2">{product?.created_at?.slice(0, 10)}</td>
                <td>{product?.sales_id}</td>
                <td>{product?.invoice_products__product__product_name}</td>
                <td>{product?.invoice_products__quantity}</td>
                <td>
                  {product?.invoice_products__product_total_price?.toLocaleString()}
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>
    </>
  );
}
