
import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import { Button, Table, Input, Tooltip , Select} from "antd";
import authHeader from "../Authentication/authHeader";
import { Link } from "react-router-dom/";
import PortraitIcon from "@mui/icons-material/Portrait";


export default function ASPDashboard() {
  const { Option } = Select;
  const [aspList, setAspList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aspCountData, setAspCountData] = useState({});
  const [aspFilterData, setAspFilterData] = useState("all");
  const { Search } = Input;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");

  useEffect(() => {
      axios
        .get(`${baseUrl}/asp/aspCountData/${aspFilterData}/`, {
          headers: authHeader(),
        })
        .then((results) => {
            setAspCountData(results?.data);
        });
    }, [aspFilterData]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/asp/aspList/`, {
        headers: authHeader(),
        params: {
          page: currentPage,
          filter: filter,
        },
      })
      .then((results) => {
        setAspList(results?.data.results || []);
        setTotalItems(results?.data.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, filter]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lspDistrictValue = (value) => {
    setAspFilterData(value);
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const columns = [
    {
      title: "ASP ID",
      dataIndex: "asp_id",
      width: "20%",
    },
    {
      title: "ASP Name",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Phone",
      dataIndex: "phone",

      width: "14%",
      render: (text) => {
        return "0" + text; // Add '0' to the beginning of the phone number
      },
    },
    {
      title: "District",
      render: (record) => record?.district?.district_name,

      width: "12%",
    },
    {
      title: "Upazila",
      render: (record) => record?.upazila?.upazila_name,
      width: "12%",
    },
    {
      title: "Union",
      render: (record) => record?.union?.union_name,
      width: "12%",
    },
    {
      title: "Action",
      key: "actions",
      width: "10%",
      render: (record) => (
        <>
          <Link
            className="actionBtnDesign me-4 shadow"
            to={`/aspProfile/${record?.asp_id}`}
            target="_blank"
          >
            <Tooltip title="Profile">
              <PortraitIcon />
            </Tooltip>
          </Link>
        </>
      ),
    },
  ];

 
  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h3 style={{ color: "#38512e" }}>ASP'S DASHBOARD</h3>
      </div>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="farmerBoxSection">
          <div className="farmerCardDesign">
            <p className="m-3" style={{ fontSize: "18px" }}>
              Total Registered ASP
            </p>
            <p className="m-3" style={{ fontSize: "30px" }}>
              {aspCountData?.total_count || 0}
            </p>
          </div>
          <div className="farmerCardDesign">
            <p className="mt-3 ms-3">District Filter:</p>
            <Select
              className="ms-3 w-50"
              name="Select"
              onChange={lspDistrictValue}
              placeholder="Select District"
              showSearch
            >
              <Option value="all">District</Option>
              {aspCountData?.district_values?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="m-2 mt-4 d-flex justify-content-end align-items-center">
          <Search
            className="w-25"
            placeholder="search here..."
            allowClear
            onSearch={handleFilterChange}
            enterButton={
              <Button
                type="primary"
                style={{ backgroundColor: "#38512e", borderColor: "#38512e" }}
              >
                Search
              </Button>
            }
          />
        </div>
        <div className="m-2 border">
          <Table
            columns={columns}
            dataSource={aspList}
            loading={loading}
            rowKey={(record) => record.asp_id}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, 
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </div>
  );
}

